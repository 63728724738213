<template>
  <div>
    <h2 class="mb-5">Sidebar Layout</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#sidebar-layout'">Sidebar Layout</vs-button></div>
      </div>
    </vs-card>
    <!-- Sidebar -->
    <vue-accordion class="mb-6">
      <template v-slot:title>
        <h6 class="mt-5 mb-3 font-medium" id="sidebar-layout">Sidebar Layout</h6>
      </template>
      <template v-slot:content>
        <h1 class="page-title -mb-5">Heading 1</h1>
        <span class="toolbar-btn-group flex justify-end mb-2">
          <vs-button class="toolbar-btn" type="border" icon="exit_to_app" @click="popupOpen = true">Cancel</vs-button>
          <vs-button class="toolbar-btn" icon="save" @click="validationError = !validationError">Save</vs-button>
        </span>
        <div class="flex justify-end">
          <vs-alert color="warning" icon="error_outlined" validation-error v-if="validationError" class="mt-2 mb-4">
            Please populate all required fields before proceeding.
          </vs-alert>
        </div>
        <vs-popup title="Warning Heading" header-icon="warning" class="close-icon-hidden" :active.sync="popupOpen">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non ipsum id elementum ac gravida. Nullam in pharea nunc eget volutpat sed tincidunt quisque sit.</p>
          <div class="popup-action-btn-container flex justify-end">
            <vs-button size="large" action-btn>Save</vs-button>
            <vs-button @click="popupOpen=false" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
          </div>
        </vs-popup>
        <div class="sidebar-layout mb-8">
          <div class="sidebar-layout-list">
            <vs-sidebar static-position type="sidebar-menu" default-index="1" color="primary" class="sidebarx mb-20" spacer v-model="active">
              <vs-sidebar-item index="1" href="javascript:void(0)">
                <div class="sidebar-item">
                  <div class="sidebar-item-title">Text</div>
                </div>
              </vs-sidebar-item>
              <vs-sidebar-item index="2" href="javascript:void(0)">
                <div class="sidebar-item">
                  <div class="sidebar-item-title">Text <vs-icon icon="error_outline" color="warning" size="small" class="ml-3" /></div>
                  <ul class="sidebar-subitems-container">
                    <li class="sidebar-subitem" :class="activeSubitem === 1 ? 'sidebar-subitem-active': ''" index="1" @click="switchActiveSubItem(1)">
                      <a @click="show('first')">Text</a>
                    </li>
                    <li class="sidebar-subitem" :class="activeSubitem === 2 ? 'sidebar-subitem-active': ''" index="2" @click="switchActiveSubItem(2)">
                      <a @click="show('second')">Text</a> <vs-icon icon="error_outline" color="warning" size="small" class="ml-3" />
                    </li>
                    <li class="sidebar-subitem" :class="activeSubitem === 3 ? 'sidebar-subitem-active': ''" index="3" @click="switchActiveSubItem(3)">
                      <a>Text</a>
                    </li>
                    <li class="sidebar-subitem" :class="activeSubitem === 4 ? 'sidebar-subitem-active': ''" index="4" @click="switchActiveSubItem(4)">
                      <a>Text</a> <vs-icon icon="error_outline" color="warning" size="small" class="ml-3" />
                    </li>
                  </ul>
                </div>
              </vs-sidebar-item>
              <vs-sidebar-item index="3" href="javascript:void(0)">
                <div class="sidebar-item">
                  <div class="sidebar-item-title">Text <vs-icon icon="error_outline" color="warning" size="small" class="ml-3" /></div>
                </div>
              </vs-sidebar-item>
              <vs-sidebar-item index="4" href="javascript:void(0)">
                <div class="sidebar-item">
                  <div class="sidebar-item-title">Text</div>
                </div>
              </vs-sidebar-item>
              <vs-sidebar-item index="5" href="javascript:void(0)">
                <div class="sidebar-item">
                  <div class="sidebar-item-title">Text</div>
                </div>
              </vs-sidebar-item>
              <vs-sidebar-item index="6" href="javascript:void(0)">
                <div class="sidebar-item">
                  <div class="sidebar-item-title">Text</div>
                </div>
              </vs-sidebar-item>
              <vs-sidebar-item index="7" href="javascript:void(0)">
                <div class="sidebar-item">
                  <div class="sidebar-item-title">Text</div>
                </div>
              </vs-sidebar-item>
              <vs-sidebar-item index="8" href="javascript:void(0)">
                <div class="sidebar-item">
                  <div class="sidebar-item-title">Text</div>
                </div>
              </vs-sidebar-item>
              <vs-sidebar-item index="9" href="javascript:void(0)">
                <div class="sidebar-item">
                  <div class="sidebar-item-title">Text</div>
                </div>
              </vs-sidebar-item>
              <vs-sidebar-item index="10" href="javascript:void(0)">
                <div class="sidebar-item">
                  <div class="sidebar-item-title">Text</div>
                </div>
              </vs-sidebar-item>
              <vs-sidebar-item index="11" href="javascript:void(0)">
                <div class="sidebar-item">
                  <div class="sidebar-item-title">Text</div>
                </div>
              </vs-sidebar-item>
              <vs-sidebar-item index="12" href="javascript:void(0)">
                <div class="sidebar-item">
                  <div class="sidebar-item-title">Text</div>
                </div>
              </vs-sidebar-item>
            </vs-sidebar>
          </div>
          <div class="sidebar-layout-content">
            <vs-card type="toolbar-sidebar-page" class="isResponsiveCard self-start">
              <div>
                <label>First Table card [tabs] - Used when there is a tab component on top of the table card</label>
                <div>
                  This card has been made responsive just for demonstration purposes. If a card is responsive depends on the requirement of the page.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </div>
              </div>
            </vs-card>
          </div>
        </div>
      </template>
      <template v-slot:code>
        <pre>
          <Prism class="language-js">
    &lt;h1 class="page-title -mb-5"&gt;Heading 1&lt;/h1&gt;
    &lt;span class="toolbar-btn-group flex justify-end mb-2"&gt;
      &lt;vs-button class="toolbar-btn" type="border" icon="exit_to_app" @click="popupOpen = true"&gt;Cancel&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" icon="save" @click="validationError = !validationError"&gt;Save&lt;/vs-button&gt;
    &lt;/span&gt;
    &lt;div class="flex justify-end"&gt;
      &lt;vs-alert color="warning" icon="error_outlined" validation-error v-if="validationError" class="mt-2 mb-4"&gt;
        Please populate all required fields before proceeding.
      &lt;/vs-alert&gt;
    &lt;/div&gt;
    &lt;vs-popup title="Warning Heading" header-icon="warning" class="close-icon-hidden" :active.sync="popupOpen"&gt;
      &lt;p&gt;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non ipsum id elementum ac gravida. Nullam in pharea nunc eget volutpat sed tincidunt quisque sit.&lt;/p&gt;
      &lt;div class="popup-action-btn-container flex justify-end"&gt;
        &lt;vs-button size="large" action-btn&gt;Save&lt;/vs-button&gt;
        &lt;vs-button @click="popupOpen=false" size="large" type="flat" class="ml-6 md:ml-10"&gt;Cancel&lt;/vs-button&gt;
      &lt;/div&gt;
    &lt;/vs-popup&gt;
    &lt;div class="sidebar-layout mb-8"&gt;
      &lt;div class="sidebar-layout-list"&gt;
        &lt;vs-sidebar static-position type="sidebar-menu" default-index="1" color="primary" class="sidebarx mb-20" spacer v-model="active"&gt;
          &lt;vs-sidebar-item index="1" href="javascript:void(0)"&gt;
            &lt;div class="sidebar-item"&gt;
              &lt;div class="sidebar-item-title"&gt;Text&lt;/div&gt;
            &lt;/div&gt;
          &lt;/vs-sidebar-item&gt;
          &lt;vs-sidebar-item index="2" href="javascript:void(0)"&gt;
            &lt;div class="sidebar-item"&gt;
              &lt;div class="sidebar-item-title val-error"&gt;Text&lt;/div&gt;
              &lt;ul class="sidebar-subitems-container"&gt;
                &lt;li class="sidebar-subitem" :class="activeSubitem === 1 ? 'sidebar-subitem-active': ''" index="1" @click="switchActiveSubItem(1)"&gt;
                  &lt;a&gt;Text&lt;/a&gt;
                &lt;/li&gt;
                &lt;li class="sidebar-subitem" :class="activeSubitem === 2 ? 'sidebar-subitem-active': ''" index="2" @click="switchActiveSubItem(2)"&gt;
                  &lt;a class="val-error"&gt;Text&lt;/a&gt;
                &lt;/li&gt;
                &lt;li class="sidebar-subitem" :class="activeSubitem === 3 ? 'sidebar-subitem-active': ''" index="3" @click="switchActiveSubItem(3)"&gt;
                  &lt;a&gt;Text&lt;/a&gt;
                &lt;/li&gt;
                &lt;li class="sidebar-subitem" :class="activeSubitem === 4 ? 'sidebar-subitem-active': ''" index="4" @click="switchActiveSubItem(4)"&gt;
                  &lt;a class="val-error"&gt;Text&lt;/a&gt;
                &lt;/li&gt;
              &lt;/ul&gt;
            &lt;/div&gt;
          &lt;/vs-sidebar-item&gt;
          &lt;vs-sidebar-item index="3" href="javascript:void(0)"&gt;
            &lt;div class="sidebar-item"&gt;
              &lt;div class="sidebar-item-title val-error"&gt;Text&lt;/div&gt;
            &lt;/div&gt;
          &lt;/vs-sidebar-item&gt;
          &lt;vs-sidebar-item index="4" href="javascript:void(0)"&gt;
            &lt;div class="sidebar-item"&gt;
              &lt;div class="sidebar-item-title"&gt;Text&lt;/div&gt;
            &lt;/div&gt;
          &lt;/vs-sidebar-item&gt;
          &lt;vs-sidebar-item index="5" href="javascript:void(0)"&gt;
            &lt;div class="sidebar-item"&gt;
              &lt;div class="sidebar-item-title"&gt;Text&lt;/div&gt;
            &lt;/div&gt;
          &lt;/vs-sidebar-item&gt;
          &lt;vs-sidebar-item index="6" href="javascript:void(0)"&gt;
            &lt;div class="sidebar-item"&gt;
              &lt;div class="sidebar-item-title"&gt;Text&lt;/div&gt;
            &lt;/div&gt;
          &lt;/vs-sidebar-item&gt;
          &lt;vs-sidebar-item index="7" href="javascript:void(0)"&gt;
            &lt;div class="sidebar-item"&gt;
              &lt;div class="sidebar-item-title"&gt;Text&lt;/div&gt;
            &lt;/div&gt;
          &lt;/vs-sidebar-item&gt;
          &lt;vs-sidebar-item index="8" href="javascript:void(0)"&gt;
            &lt;div class="sidebar-item"&gt;
              &lt;div class="sidebar-item-title"&gt;Text&lt;/div&gt;
            &lt;/div&gt;
          &lt;/vs-sidebar-item&gt;
          &lt;vs-sidebar-item index="9" href="javascript:void(0)"&gt;
            &lt;div class="sidebar-item"&gt;
              &lt;div class="sidebar-item-title"&gt;Text&lt;/div&gt;
            &lt;/div&gt;
          &lt;/vs-sidebar-item&gt;
          &lt;vs-sidebar-item index="10" href="javascript:void(0)"&gt;
            &lt;div class="sidebar-item"&gt;
              &lt;div class="sidebar-item-title"&gt;Text&lt;/div&gt;
            &lt;/div&gt;
          &lt;/vs-sidebar-item&gt;
          &lt;vs-sidebar-item index="11" href="javascript:void(0)"&gt;
            &lt;div class="sidebar-item"&gt;
              &lt;div class="sidebar-item-title"&gt;Text&lt;/div&gt;
            &lt;/div&gt;
          &lt;/vs-sidebar-item&gt;
          &lt;vs-sidebar-item index="12" href="javascript:void(0)"&gt;
            &lt;div class="sidebar-item"&gt;
              &lt;div class="sidebar-item-title"&gt;Text&lt;/div&gt;
            &lt;/div&gt;
          &lt;/vs-sidebar-item&gt;
        &lt;/vs-sidebar&gt;
      &lt;/div&gt;
      &lt;div class="sidebar-layout-content"&gt;
        &lt;vs-card type="toolbar-sidebar-page" class="isResponsiveCard self-start"&gt;
          &lt;div&gt;
            &lt;label&gt;Table card [tabs] - Used when there is a tab component on top of the table card&lt;/label&gt;
            &lt;div&gt;
              This card has been made responsive just for demonstration purposes. If a card is responsive depends on the requirement of the page.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/vs-card&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;script&gt;
      export default {
        data:() => ({
          validationError: false,
          activeSubitem: 1,
          popupOpen: false,
        }),
        methods: {
          show(showContent) {
            this.$emit('show', showContent);
          },
          switchActiveSubItem(subItemIndex) {
            this.activeSubitem = subItemIndex;
          },
        }
      };
    &lt;/script&gt;
          </Prism>
        </pre>
      </template>
    </vue-accordion>
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";

  export default {
    data() {
      return {
        validationError: false,
        activeSubitem: 1,
        popupOpen: false,
      }
    },
    components: {
      Prism,
      VueAccordion,
    },
    methods: {
      switchActiveSubItem(subItemIndex) {
        this.activeSubitem = subItemIndex;
      },
    }
  };
</script>