<template>
  <div>
    <h2 class="mb-5">Colors</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#colors-neutrals'">Neutrals</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#colors-primary'">Primary</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#colors-secondary'">Secondary</vs-button></div> 
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#colors-accent'">Accent</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#colors-accent-2'">Accent 2</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#colors-accent-3'">Accent 3</vs-button></div> 
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#colors-success'">Success</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#colors-warning'">Warning</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#colors-error'">Error</vs-button></div> 
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#colors-backgrounds'">Backgrounds</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#colors-interactions'">Interactions</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#colors-borders'">Borders</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#colors-texts'">Texts</vs-button></div> 
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#colors-shades'">Shades</vs-button></div>
      </div>
    </vs-card>
    <!-- Neutral Colors Section starts -->
    <h3 class="mb-5" id="colors-neutrals">Neutrals</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Used as supporting secondary colours</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div>
              <h6 class="mb-4 text-neutral-50">neutral-50</h6>
              <vs-button color="" type="neutral-50" size="large">#fbfbfb</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-neutral-100">neutral-100</h6>
              <vs-button color="" type="neutral-100" size="large">#f7f7f7</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-neutral-150">neutral-150</h6>
              <vs-button color="" type="neutral-150" size="large">#F2F2F2</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-neutral-200">neutral-200</h6>
              <vs-button color="" type="neutral-200" size="large">#ECECEC</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-neutral-250">neutral-250</h6>
              <vs-button color="" type="neutral-250" size="large">#e7e7e7</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-neutral-300">neutral-300</h6>
              <vs-button color="" type="neutral-300" size="large">#dedede</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-neutral-400">neutral-400</h6>
              <vs-button color="" type="neutral-400" size="large">#c4c4c4</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-neutral-500">neutral-500</h6>
              <vs-button color="" type="neutral-500" size="large">#aaaaaa</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-neutral-600">neutral-600</h6>
              <vs-button color="" type="neutral-600" size="large">#707070</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-neutral-700">neutral-700</h6>
              <vs-button color="" type="neutral-700" size="large">#595959</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-neutral-800">neutral-800</h6>
              <vs-button color="" type="neutral-800" size="large">#373737</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-neutral-900">neutral-900</h6>
              <vs-button color="" type="neutral-900" size="large">#0D0F11</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;!-- Text Color --&gt;                 &lt;!-- Background Color --&gt;
    class="text-neutral-50"             class="bg-neutral-50"
    class="text-neutral-100"            class="bg-neutral-100"
    class="text-neutral-150"            class="bg-neutral-150"
    class="text-neutral-200"            class="bg-neutral-200"
    class="text-neutral-250"            class="bg-neutral-250"
    class="text-neutral-300"            class="bg-neutral-300"
    class="text-neutral-400"            class="bg-neutral-400"
    class="text-neutral-500"            class="bg-neutral-500"
    class="text-neutral-600"            class="bg-neutral-600"
    class="text-neutral-700"            class="bg-neutral-700"
    class="text-neutral-800"            class="bg-neutral-800"
    class="text-neutral-900"            class="bg-neutral-900"
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Neutral Colors Section ends -->

    <!-- Primary Colors Section starts -->
    <h3 class="mb-5" id="colors-primary">Primary</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Used across interactive elements such as CTAs, links, inputs, active states, etc</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div>
              <h6 class="mb-4 text-primary-50">primary-50</h6>
              <vs-button color="" type="primary-50" size="large">#EAF2FF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-primary-75">primary-75</h6>
              <vs-button color="" type="primary-75" size="large">#DBE6F7</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-primary-100">primary-100</h6>
              <vs-button color="" type="primary-100" size="large">#CEE1FF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-primary-200">primary-200</h6>
              <vs-button color="" type="primary-200" size="large">#9FC2FB</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-primary-300">primary-300</h6>
              <vs-button color="" type="primary-300" size="large">#7BA1DF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-primary-400">primary-400</h6>
              <vs-button color="" type="primary-400" size="large">#4984E2</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-primary-500">primary-500</h6>
              <vs-button color="" type="primary-500" size="large">#1B6EF3</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-primary-600">primary-600</h6>
              <vs-button color="" type="primary-600" size="large">#1F5BBA</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-primary-700">primary-700</h6>
              <vs-button color="" type="primary-700" size="large">#214E97</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-primary-800">primary-800</h6>
              <vs-button color="" type="primary-800" size="large">#244373</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;!-- Text Color --&gt;                 &lt;!-- Background Color --&gt;
    class="text-primary-50"             class="bg-primary-50"
    class="text-primary-75"             class="bg-primary-75"
    class="text-primary-100"            class="bg-primary-100"
    class="text-primary-200"            class="bg-primary-200"
    class="text-primary-300"            class="bg-primary-300"
    class="text-primary-400"            class="bg-primary-400"
    class="text-primary-500"            class="bg-primary-500"
    class="text-primary-600"            class="bg-primary-600"
    class="text-primary-700"            class="bg-primary-700"
    class="text-primary-800"            class="bg-primary-800"
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Rello Primary</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div>
              <h6 class="mb-4 text-rello-primary-50">rello-primary-50</h6>
              <vs-button color="" type="rello-primary-50" size="large">#E7F8FE</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-primary-75">rello-primary-75</h6>
              <vs-button color="" type="rello-primary-75" size="large">#D5ECF4</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-primary-100">rello-primary-100</h6>
              <vs-button color="" type="rello-primary-100" size="large">#C5E3ED</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-primary-200">rello-primary-200</h6>
              <vs-button color="" type="rello-primary-200" size="large">#96C8D9</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-primary-300">rello-primary-300</h6>
              <vs-button color="" type="rello-primary-300" size="large">#79A7B7</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-primary-400">rello-primary-400</h6>
              <vs-button color="" type="rello-primary-400" size="large">#3790AD</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-primary-500">rello-primary-500</h6>
              <vs-button color="" type="rello-primary-500" size="large">#207F9E</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-primary-600">rello-primary-600</h6>
              <vs-button color="" type="rello-primary-600" size="large">#166580</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-primary-700">rello-primary-700</h6>
              <vs-button color="" type="rello-primary-700" size="large">#175468</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-primary-800">rello-primary-800</h6>
              <vs-button color="" type="rello-primary-800" size="large">#184959</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;!-- Text Color --&gt;                       &lt;!-- Background Color --&gt;
    class="text-rello-primary-50"             class="bg-rello-primary-50"
    class="text-rello-primary-75"             class="bg-rello-primary-75"
    class="text-rello-primary-100"            class="bg-rello-primary-100"
    class="text-rello-primary-200"            class="bg-rello-primary-200"
    class="text-rello-primary-300"            class="bg-rello-primary-300"
    class="text-rello-primary-400"            class="bg-rello-primary-400"
    class="text-rello-primary-500"            class="bg-rello-primary-500"
    class="text-rello-primary-600"            class="bg-rello-primary-600"
    class="text-rello-primary-700"            class="bg-rello-primary-700"
    class="text-rello-primary-800"            class="bg-rello-primary-800"
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Primary Colors Section ends -->

    <!-- Secondary Colors Section starts -->
    <h3 class="mb-5" id="colors-secondary">Secondary</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Used alongside the primary palette to indicate secondary focus</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div>
              <h6 class="mb-4 text-secondary-50">secondary-50</h6>
              <vs-button color="" type="secondary-50" size="large">#EAEFF9</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-secondary-100">secondary-100</h6>
              <vs-button color="" type="secondary-100" size="large">#D9DDEB</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-secondary-200">secondary-200</h6>
              <vs-button color="" type="secondary-200" size="large">#A5AFD2</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-secondary-300">secondary-300</h6>
              <vs-button color="" type="secondary-300" size="large">#7C8ABC</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-secondary-400">secondary-400</h6>
              <vs-button color="" type="secondary-400" size="large">#5366AA</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-secondary-500">secondary-500</h6>
              <vs-button color="" type="secondary-500" size="large">#304799</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-secondary-600">secondary-600</h6>
              <vs-button color="" type="secondary-600" size="large">#1B3286</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-secondary-700">secondary-700</h6>
              <vs-button color="" type="secondary-700" size="large">#001D84</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-secondary-800">secondary-800</h6>
              <vs-button color="" type="secondary-800" size="large">#162866</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-secondary-900">secondary-900</h6>
              <vs-button color="" type="secondary-900" size="large">#162455</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;!-- Text Color --&gt;                    &lt;!-- Background Color --&gt;
    class="text-secondary-50"              class="bg-secondary-50"
    class="text-secondary-100"             class="bg-secondary-100"
    class="text-secondary-200"             class="bg-secondary-200"
    class="text-secondary-300"             class="bg-secondary-300"
    class="text-secondary-400"             class="bg-secondary-400"
    class="text-secondary-500"             class="bg-secondary-500"
    class="text-secondary-600"             class="bg-secondary-600"
    class="text-secondary-700"             class="bg-secondary-700"
    class="text-secondary-800"             class="bg-secondary-800"
    class="text-secondary-900"             class="bg-secondary-900"
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Rello Secondary</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div>
              <h6 class="mb-4 text-rello-secondary-50">rello-secondary-50</h6>
              <vs-button color="" type="rello-secondary-50" size="large">#E7F0F7</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-secondary-100">rello-secondary-100</h6>
              <vs-button color="" type="rello-secondary-100" size="large">#D9E2E8</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-secondary-200">rello-secondary-200</h6>
              <vs-button color="" type="rello-secondary-200" size="large">#9DB8CB</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-secondary-300">rello-secondary-300</h6>
              <vs-button color="" type="rello-secondary-300" size="large">#6F92AB</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-secondary-400">rello-secondary-400</h6>
              <vs-button color="" type="rello-secondary-400" size="large">#406E8E</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-secondary-500">rello-secondary-500</h6>
              <vs-button color="" type="rello-secondary-500" size="large">#1E5175</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-secondary-600">rello-secondary-600</h6>
              <vs-button color="" type="rello-secondary-600" size="large">#0F4267</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-secondary-700">rello-secondary-700</h6>
              <vs-button color="" type="rello-secondary-700" size="large">#003559</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-secondary-800">rello-secondary-800</h6>
              <vs-button color="" type="rello-secondary-800" size="large">#003255</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-secondary-900">rello-secondary-900</h6>
              <vs-button color="" type="rello-secondary-900" size="large">#0C2A40</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;!-- Text Color --&gt;                          &lt;!-- Background Color --&gt;
    class="text-rello-secondary-50"              class="bg-rello-secondary-50"
    class="text-rello-secondary-100"             class="bg-rello-secondary-100"
    class="text-rello-secondary-200"             class="bg-rello-secondary-200"
    class="text-rello-secondary-300"             class="bg-rello-secondary-300"
    class="text-rello-secondary-400"             class="bg-rello-secondary-400"
    class="text-rello-secondary-500"             class="bg-rello-secondary-500"
    class="text-rello-secondary-600"             class="bg-rello-secondary-600"
    class="text-rello-secondary-700"             class="bg-rello-secondary-700"
    class="text-rello-secondary-800"             class="bg-rello-secondary-800"
    class="text-rello-secondary-900"             class="bg-rello-secondary-900"
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Secondary Colors Section ends -->

    <!-- Accent Colors Section starts -->
    <h3 class="mb-5" id="colors-accent">Accent</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium"></h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div>
              <h6 class="mb-4 text-accent-50">accent-50</h6>
              <vs-button color="" type="accent-50" size="large">#E7F9FF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-100">accent-100</h6>
              <vs-button color="" type="accent-100" size="large">#D3F4FF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-200">accent-200</h6>
              <vs-button color="" type="accent-200" size="large">#BAEEFF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-300">accent-300</h6>
              <vs-button color="" type="accent-300" size="large">#8CE3FF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-400">accent-400</h6>
              <vs-button color="" type="accent-400" size="large">#5FD9FF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-500">accent-500</h6>
              <vs-button color="" type="accent-500" size="large">#00CFFF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-600">accent-600</h6>
              <vs-button color="" type="accent-600" size="large">#33ADE2</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-700">accent-700</h6>
              <vs-button color="" type="accent-700" size="large">#1290C7</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-800">accent-800</h6>
              <vs-button color="" type="accent-800" size="large">#0573A3</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-900">accent-900</h6>
              <vs-button color="" type="accent-900" size="large">#01425F</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;!-- Text Color --&gt;                 &lt;!-- Background Color --&gt;
    class="text-accent-50"              class="bg-accent-50"
    class="text-accent-100"             class="bg-accent-100"
    class="text-accent-200"             class="bg-accent-200"
    class="text-accent-300"             class="bg-accent-300"
    class="text-accent-400"             class="bg-accent-400"
    class="text-accent-500"             class="bg-accent-500"
    class="text-accent-600"             class="bg-accent-600"
    class="text-accent-700"             class="bg-accent-700"
    class="text-accent-800"             class="bg-accent-800"
    class="text-accent-900"             class="bg-accent-900"
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Accent Colors Section ends -->

    <!-- Accent 2 Colors Section starts -->
    <h3 class="mb-5" id="colors-accent-2">Accent 2</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium"></h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div>
              <h6 class="mb-4 text-accent-2-50">accent-2-50</h6>
              <vs-button color="" type="accent-2-50" size="large">#EDDBFF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-2-100">accent-2-100</h6>
              <vs-button color="" type="accent-2-100" size="large">#DEBDFF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-2-200">accent-2-200</h6>
              <vs-button color="" type="accent-2-200" size="large">#D1A2FF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-2-300">accent-2-300</h6>
              <vs-button color="" type="accent-2-300" size="large">#BF7FFF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-2-400">accent-2-400</h6>
              <vs-button color="" type="accent-2-400" size="large">#AA56FF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-2-500">accent-2-500</h6>
              <vs-button color="" type="accent-2-500" size="large">#9036EA</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-2-600">accent-2-600</h6>
              <vs-button color="" type="accent-2-600" size="large">#6F29B4</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-2-700">accent-2-700</h6>
              <vs-button color="" type="accent-2-700" size="large">#6510BA</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-2-800">accent-2-800</h6>
              <vs-button color="" type="accent-2-800" size="large">#5405A3</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-2-900">accent-2-900</h6>
              <vs-button color="" type="accent-2-900" size="large">#3C0275</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;!-- Text Color --&gt;                   &lt;!-- Background Color --&gt;
    class="text-accent-2-50"              class="bg-accent-2-50"
    class="text-accent-2-100"             class="bg-accent-2-100"
    class="text-accent-2-200"             class="bg-accent-2-200"
    class="text-accent-2-300"             class="bg-accent-2-300"
    class="text-accent-2-400"             class="bg-accent-2-400"
    class="text-accent-2-500"             class="bg-accent-2-500"
    class="text-accent-2-600"             class="bg-accent-2-600"
    class="text-accent-2-700"             class="bg-accent-2-700"
    class="text-accent-2-800"             class="bg-accent-2-800"
    class="text-accent-2-900"             class="bg-accent-2-900"
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Accent 2 Colors Section ends -->

    <!-- Accent 3 Colors Section starts -->
    <h3 class="mb-5" id="colors-accent-3">Accent 3</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium"></h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div>
              <h6 class="mb-4 text-accent-3-50">accent-3-50</h6>
              <vs-button color="" type="accent-3-50" size="large">#FFDBF5</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-3-100">accent-3-100</h6>
              <vs-button color="" type="accent-3-100" size="large">#FFBDEC</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-3-200">accent-3-200</h6>
              <vs-button color="" type="accent-3-200" size="large">#FFA2E5</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-3-300">accent-3-300</h6>
              <vs-button color="" type="accent-3-300" size="large">#FF7FDB</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-3-400">accent-3-400</h6>
              <vs-button color="" type="accent-3-400" size="large">#FF56CF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-3-500">accent-3-500</h6>
              <vs-button color="" type="accent-3-500" size="large">#EA36B7</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-3-600">accent-3-600</h6>
              <vs-button color="" type="accent-3-600" size="large">#D821A4</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-3-700">accent-3-700</h6>
              <vs-button color="" type="accent-3-700" size="large">#BA108A</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-3-800">accent-3-800</h6>
              <vs-button color="" type="accent-3-800" size="large">#A30576</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-accent-3-900">accent-3-900</h6>
              <vs-button color="" type="accent-3-900" size="large">#8C0164</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;!-- Text Color --&gt;                   &lt;!-- Background Color --&gt;
    class="text-accent-3-50"              class="bg-accent-3-50"
    class="text-accent-3-100"             class="bg-accent-3-100"
    class="text-accent-3-200"             class="bg-accent-3-200"
    class="text-accent-3-300"             class="bg-accent-3-300"
    class="text-accent-3-400"             class="bg-accent-3-400"
    class="text-accent-3-500"             class="bg-accent-3-500"
    class="text-accent-3-600"             class="bg-accent-3-600"
    class="text-accent-3-700"             class="bg-accent-3-700"
    class="text-accent-3-800"             class="bg-accent-3-800"
    class="text-accent-3-900"             class="bg-accent-3-900"
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Accent 3 Colors Section ends -->

    <!-- Success Colors Section starts -->
    <h3 class="mb-5" id="colors-success">Success</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Depict an emotion of positivity. Generally used across success, complete states</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div>
              <h6 class="mb-4 text-success-50">success-50</h6>
              <vs-button color="" type="success-50" size="large">#F4FFF9</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-success-100">success-100</h6>
              <vs-button color="" type="success-100" size="large">#DBFFEA</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-success-200">success-200</h6>
              <vs-button color="" type="success-200" size="large">#AAFACC</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-success-300">success-300</h6>
              <vs-button color="" type="success-300" size="large">#74EBA7</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-success-400">success-400</h6>
              <vs-button color="" type="success-400" size="large">#59D68E</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-success-500">success-500</h6>
              <vs-button color="" type="success-500" size="large">#46BC79</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-success-600">success-600</h6>
              <vs-button color="" type="success-600" size="large">#219653</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-success-700">success-700</h6>
              <vs-button color="" type="success-700" size="large">#157642</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-success-800">success-800</h6>
              <vs-button color="" type="success-800" size="large">#1A5D39</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-success-900">success-900</h6>
              <vs-button color="" type="success-900" size="large">#14482C</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;!-- Text Color --&gt;                  &lt;!-- Background Color --&gt;
    class="text-success-50"              class="bg-success-50"
    class="text-success-100"             class="bg-success-100"
    class="text-success-200"             class="bg-success-200"
    class="text-success-300"             class="bg-success-300"
    class="text-success-400"             class="bg-success-400"
    class="text-success-500"             class="bg-success-500"
    class="text-success-600"             class="bg-success-600"
    class="text-success-700"             class="bg-success-700"
    class="text-success-800"             class="bg-success-800"
    class="text-success-900"             class="bg-success-900"
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Success Colors Section ends -->

    <!-- Warning Colors Section starts -->
    <h3 class="mb-5" id="colors-warning">Warning</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Depict an emotion of holding. Generally used across warning or on-hold states</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div>
              <h6 class="mb-4 text-warning-50">warning-50</h6>
              <vs-button color="" type="warning-50" size="large">#FFFDF4</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-warning-100">warning-100</h6>
              <vs-button color="" type="warning-100" size="large">#FFF8DF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-warning-200">warning-200</h6>
              <vs-button color="" type="warning-200" size="large">#FFF4CD</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-warning-300">warning-300</h6>
              <vs-button color="" type="warning-300" size="large">#FFEA9C</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-warning-400">warning-400</h6>
              <vs-button color="" type="warning-400" size="large">#FCDA71</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-warning-500">warning-500</h6>
              <vs-button color="" type="warning-500" size="large">#F2C94C</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-warning-600">warning-600</h6>
              <vs-button color="" type="warning-600" size="large">#E5AF43</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-warning-700">warning-700</h6>
              <vs-button color="" type="warning-700" size="large">#D89A1E</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-warning-800">warning-800</h6>
              <vs-button color="" type="warning-800" size="large">#B98621</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-warning-900">warning-900</h6>
              <vs-button color="" type="warning-900" size="large">#956D07</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;!-- Text Color --&gt;                  &lt;!-- Background Color --&gt;
    class="text-warning-50"              class="bg-warning-50"
    class="text-warning-100"             class="bg-warning-100"
    class="text-warning-200"             class="bg-warning-200"
    class="text-warning-300"             class="bg-warning-300"
    class="text-warning-400"             class="bg-warning-400"
    class="text-warning-500"             class="bg-warning-500"
    class="text-warning-600"             class="bg-warning-600"
    class="text-warning-700"             class="bg-warning-700"
    class="text-warning-800"             class="bg-warning-800"
    class="text-warning-900"             class="bg-warning-900"
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Warning Colors Section ends -->

    <!-- Error Colors Section starts -->
    <h3 class="mb-5" id="colors-error">Error</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">These colors depict an emotion of negativity. Generally used across error states</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div>
              <h6 class="mb-4 text-error-50">error-50</h6>
              <vs-button color="" type="error-50" size="large">#FEF7F7</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-error-100">error-100</h6>
              <vs-button color="" type="error-100" size="large">#FFE5E5</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-error-200">error-200</h6>
              <vs-button color="" type="error-200" size="large">#FFC0C0</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-error-300">error-300</h6>
              <vs-button color="" type="error-300" size="large">#FF9494</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-error-400">error-400</h6>
              <vs-button color="" type="error-400" size="large">#F57070</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-error-500">error-500</h6>
              <vs-button color="" type="error-500" size="large">#F57070</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-error-600">error-600</h6>
              <vs-button color="" type="error-600" size="large">#EB5757</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-error-700">error-700</h6>
              <vs-button color="" type="error-700" size="large">#C03C3C</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-error-800">error-800</h6>
              <vs-button color="" type="error-800" size="large">#9B1D1D</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-error-900">error-900</h6>
              <vs-button color="" type="error-900" size="large">#7B1C1C</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;!-- Text Color --&gt;                &lt;!-- Background Color --&gt;
    class="text-error-50"              class="bg-error-50"
    class="text-error-100"             class="bg-error-100"
    class="text-error-200"             class="bg-error-200"
    class="text-error-300"             class="bg-error-300"
    class="text-error-400"             class="bg-error-400"
    class="text-error-500"             class="bg-error-500"
    class="text-error-600"             class="bg-error-600"
    class="text-error-700"             class="bg-error-700"
    class="text-error-800"             class="bg-error-800"
    class="text-error-900"             class="bg-error-900"
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Error Colors Section ends -->

    <!-- Backgrounds Colors Section starts -->
    <h3 class="mb-5" id="colors-backgrounds">Backgrounds</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Used as background colours for pages, containers etc</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div>
              <h6 class="mb-4 text-bg-page">page background</h6>
              <vs-button color="" type="bg-page" size="large">#e7e7e7</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-bg-container-25">container-25</h6>
              <vs-button color="" type="bg-container-25" size="large">rgba(#ffffff, 0.25)</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-bg-container-40">container-40</h6>
              <vs-button color="" type="bg-container-40" size="large">rgba(#ffffff, 0.4)</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-bg-container-60">container-60</h6>
              <vs-button color="" type="bg-container-60" size="large">rgba(#ffffff, 0.6)</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-bg-container-80">container-80</h6>
              <vs-button color="" type="bg-container-80" size="large">rgba(#ffffff, 0.8)</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-bg-container-grey">container-grey</h6>
              <vs-button color="" type="bg-container-grey" size="large">#f6f6f6</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-bg-header-fill">header-fill</h6>
              <vs-button color="" type="bg-header-fill" size="large">header-fill</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-bg-error">error</h6>
              <vs-button color="" type="bg-error" size="large">#FEF7F7</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-bg-warning">warning</h6>
              <vs-button color="" type="bg-warning" size="large">#FFFDF4</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-bg-success">success</h6>
              <vs-button color="" type="bg-success" size="large">#F4FFF9</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;!-- Text Color --&gt;                      &lt;!-- Background Color --&gt;
    class="text-bg-page"                     class="bg-page"
    class="text-bg-container-25"             class="bg-container-25"
    class="text-bg-container-40"             class="bg-container-40"
    class="text-bg-container-60"             class="bg-container-60"
    class="text-bg-container-80"             class="bg-container-80"
    class="text-bg-container-grey"           class="bg-container-grey"
    class="text-bg-header-fill"              class="bg-header-fill"
    class="text-bg-error"                    class="bg-error"
    class="text-bg-warning"                  class="bg-warning"
    class="text-bg-success"                  class="bg-success"
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Backgrounds Colors Section ends -->

    <!-- Interactions Colors Section starts -->
    <h3 class="mb-5" id="colors-interactions">Interactions</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Used for interactive elements</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div>
              <h6 class="mb-4 text-interaction-table-hover">table-hover</h6>
              <vs-button color="" type="interaction-table-hover" size="large">#FBFBFB</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-interaction-disabled">disabled</h6>
              <vs-button color="" type="interaction-disabled" size="large">#7ba1df</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-interaction-primary">primary</h6>
              <vs-button color="" type="interaction-primary" size="large">#1b6ef3</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-interaction-secondary">secondary</h6>
              <vs-button color="" type="interaction-secondary" size="large">#162866</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-interaction-tertiary">tertiary</h6>
              <vs-button color="" type="interaction-tertiary" size="large">#00CFFF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-interaction-error">error</h6>
              <vs-button color="" type="interaction-error" size="large">#eb5757</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-interaction-warning">warning</h6>
              <vs-button color="" type="interaction-warning" size="large">#F2C94C</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-interaction-success">success</h6>
              <vs-button color="" type="interaction-success" size="large">#219653</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;!-- Text Color --&gt;                           &lt;!-- Background Color --&gt;
    class="text-interaction-table-hover"          class="bg-interaction-table-hover"
    class="text-interaction-disabled"             class="bg-interaction-disabled"
    class="text-interaction-primary"              class="bg-interaction-primary"
    class="text-interaction-secondary"            class="bg-interaction-secondary"
    class="text-interaction-tertiary"             class="bg-interaction-tertiary"
    class="text-interaction-error"                class="bg-interaction-error"
    class="text-interaction-warning"              class="bg-interaction-warning"
    class="text-interaction-success"              class="bg-interaction-success"
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Rello</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div>
              <h6 class="mb-4 text-rello-interaction-disabled">disabled</h6>
              <vs-button color="" type="rello-interaction-disabled" size="large">#79A7B7</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-interaction-primary">primary</h6>
              <vs-button color="" type="rello-interaction-primary" size="large">#207F9E</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-rello-interaction-secondary">secondary</h6>
              <vs-button color="" type="rello-interaction-secondary" size="large">#003255</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;!-- Text Color --&gt;                           &lt;!-- Background Color --&gt;
    class="text-rello-interaction-disabled"       class="bg-rello-interaction-disabled"
    class="text-rello-interaction-primary"        class="bg-rello-interaction-primary"
    class="text-rello-interaction-secondary"      class="bg-rello-interaction-secondary"
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Interactions Colors Section ends -->

    <!-- Border Colors Section starts -->
    <h3 class="mb-5" id="colors-borders">Borders</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Used for borders on containers, headings, etc.</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div>
              <h6 class="mb-4 text-border-container">container</h6>
              <vs-button color="" type="border-container" size="large">#dedede</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-border-transparent">transparent</h6>
              <vs-button color="" type="border-transparent" size="large">rgba(#ffffff, 0.5)</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-border-divider">Divider</h6>
              <vs-button color="" type="border-divider" size="large">#C4C4C4</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;!-- Text Color --&gt;                    &lt;!-- Background Color --&gt;
    class="text-border-container"          class="bg-border-container"
    class="text-border-transparent"        class="bg-border-transparent"
    class="text-border-divider"            class="bg-border-divider"
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Borders Colors Section ends -->

    <!-- Texts Colors Section starts -->
    <h3 class="mb-5" id="colors-texts">Texts</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Used for headings and body copy</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div>
              <h6 class="mb-4 text-primary">primary</h6>
              <vs-button color="" type="text-primary" size="large">#0d0f11</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-body-dark">body dark</h6>
              <vs-button color="" type="text-body-dark" size="large">#595959</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-body">body</h6>
              <vs-button color="" type="text-body" size="large">#707070</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;!-- Text Color --&gt;            &lt;!-- Background Color --&gt;
    class="text-primary"           class="bg-primary"
    class="text-body-dark"         class="bg-body-dark"
    class="text-body"              class="bg-body"
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Texts Colors Section ends -->

    <!-- Shades Colors Section starts -->
    <h3 class="mb-5" id="colors-shades">Shades</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Used as supporting secondary colors in backgrounds, text colors, seperators, models, etc</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div>
              <h6 class="mb-4 text-shade-white">white</h6>
              <vs-button color="" type="shade-white" size="large">#FFFFFF</vs-button>
            </div>
            <div>
              <h6 class="mb-4 text-shade-black">black</h6>
              <vs-button color="" type="shade-black" size="large">#000000</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;!-- Text Color --&gt;               &lt;!-- Background Color --&gt;
    class="text-shade-white"          class="bg-shade-white"
    class="text-shade-black"          class="bg-shade-black"
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Shades Colors Section ends -->
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";

  export default {
    data:() => ({
    }),
    components: {
      Prism,
      VueAccordion,
    },
    methods: {
    }
  }
</script>