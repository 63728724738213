<template>
  <div>
    <h2 class="mb-5">Badges</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#badges-xsmall'">X Small</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#badges-small'">Small</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#badges-medium'">Medium</vs-button></div>
      </div>
    </vs-card>
    <!-- X-small Badge Section starts -->
    <h3 class="mb-5" id="badges-xsmall">X Small</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Filled</h6>
        </template>
        <template v-slot:content>
          <div class="grid sm:grid-cols-3 md:grid-cols-4 gap-6">
            <div>
              <vs-chip color="neutral" size="x-small">
                Neutral
              </vs-chip>
            </div>
            <div>
              <vs-chip color="warning" size="x-small">
                Warning
              </vs-chip>
            </div>
            <div>
              <vs-chip color="primary" size="x-small">
                Primary
              </vs-chip>
            </div> 
            <div>
              <vs-chip color="error" size="x-small">
                Error
              </vs-chip>
            </div>
            <div>
              <vs-chip color="success" size="x-small">
                Success
              </vs-chip>
            </div> 
            <div>
              <vs-chip color="secondary" size="x-small">
                Secondary
              </vs-chip>
            </div>
            <div>
              <vs-chip color="neutral-250" size="x-small">
                Neutral 250
              </vs-chip>
            </div>
            <div>
              <vs-chip color="secondary-50" size="x-small">
                Secondary 50
              </vs-chip>
            </div>
            <div>
              <vs-chip color="secondary-500" size="x-small">
                Secondary 500
              </vs-chip>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-chip color="neutral" size="x-small"&gt;Neutral&lt;/vs-chip&gt;
    &lt;vs-chip color="warning" size="x-small"&gt;Warning&lt;/vs-chip&gt;
    &lt;vs-chip color="primary" size="x-small"&gt;Primary&lt;/vs-chip&gt;
    &lt;vs-chip color="error" size="x-small"&gt;Error&lt;/vs-chip&gt;
    &lt;vs-chip color="success" size="x-small"&gt;Success&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary" size="x-small"&gt;Secondary&lt;/vs-chip&gt;
    &lt;vs-chip color="neutral-250" size="x-small"&gt;Neutral 250&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-50" size="x-small"&gt;Secondary 50&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-500" size="x-small"&gt;Secondary 500&lt;/vs-chip&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Outlined</h6>
        </template>
        <template v-slot:content>
          <div class="grid sm:grid-cols-3 md:grid-cols-4 gap-6">
            <div>
              <vs-chip color="neutral" size="x-small" variant="outlined">
                Neutral
              </vs-chip>
            </div>
            <div>
              <vs-chip color="warning" size="x-small" variant="outlined">
                Warning
              </vs-chip>
            </div>
            <div>
              <vs-chip color="primary" size="x-small" variant="outlined">
                Primary
              </vs-chip>
            </div> 
            <div>
              <vs-chip color="error" size="x-small" variant="outlined">
                Error
              </vs-chip>
            </div>
            <div>
              <vs-chip color="success" size="x-small" variant="outlined">
                Success
              </vs-chip>
            </div> 
            <div>
              <vs-chip color="secondary" size="x-small" variant="outlined">
                Secondary
              </vs-chip>
            </div>
            <div>
              <vs-chip color="neutral-250" size="x-small" variant="outlined">
                Neutral 250
              </vs-chip>
            </div>
            <div>
              <vs-chip color="secondary-50" size="x-small" variant="outlined">
                Secondary 50
              </vs-chip>
            </div>
            <div>
              <vs-chip color="secondary-500" size="x-small" variant="outlined">
                Secondary 500
              </vs-chip>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-chip color="neutral" size="x-small" variant="outlined"&gt;Neutral&lt;/vs-chip&gt;
    &lt;vs-chip color="warning" size="x-small" variant="outlined"&gt;Warning&lt;/vs-chip&gt;
    &lt;vs-chip color="primary" size="x-small" variant="outlined"&gt;Primary&lt;/vs-chip&gt;
    &lt;vs-chip color="error" size="x-small" variant="outlined"&gt;Error&lt;/vs-chip&gt;
    &lt;vs-chip color="success" size="x-small" variant="outlined"&gt;Success&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary" size="x-small" variant="outlined"&gt;Secondary&lt;/vs-chip&gt;
    &lt;vs-chip color="neutral-250" size="x-small" variant="outlined"&gt;Neutral 250&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-50" size="x-small" variant="outlined"&gt;Secondary 50&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-500" size="x-small" variant="outlined"&gt;Secondary 500&lt;/vs-chip&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion> 
    </vs-card>
    <!-- X-small Badge Section ends -->
    
    <!-- small Badge Section starts -->
    <h3 class="mb-5" id="badges-small">Small</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Filled</h6>
        </template>
        <template v-slot:content>
          <div class="grid sm:grid-cols-3 md:grid-cols-4 gap-6">
            <div>
              <vs-chip color="neutral" size="small">
                Neutral
              </vs-chip>
            </div>
            <div>
              <vs-chip color="warning" size="small">
                Warning
              </vs-chip>
            </div>
            <div>
              <vs-chip color="primary" size="small">
                Primary
              </vs-chip>
            </div> 
            <div>
              <vs-chip color="error" size="small">
                Error
              </vs-chip>
            </div>
            <div>
              <vs-chip color="success" size="small">
                Success
              </vs-chip>
            </div> 
            <div>
              <vs-chip color="secondary" size="small">
                Secondary
              </vs-chip>
            </div>
            <div>
              <vs-chip color="neutral-250" size="small">
                Neutral 250
              </vs-chip>
            </div>
            <div>
              <vs-chip color="secondary-50" size="small">
                Secondary 50
              </vs-chip>
            </div>
            <div>
              <vs-chip color="secondary-500" size="small">
                Secondary 500
              </vs-chip>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-chip color="neutral" size="small"&gt;Neutral&lt;/vs-chip&gt;
    &lt;vs-chip color="warning" size="small"&gt;Warning&lt;/vs-chip&gt;
    &lt;vs-chip color="primary" size="small"&gt;Primary&lt;/vs-chip&gt;
    &lt;vs-chip color="error" size="small"&gt;Error&lt;/vs-chip&gt;
    &lt;vs-chip color="success" size="small"&gt;Success&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary" size="small"&gt;Secondary&lt;/vs-chip&gt;
    &lt;vs-chip color="neutral-250" size="small"&gt;Neutral 250&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-50" size="small"&gt;Secondary 50&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-500" size="small"&gt;Secondary 500&lt;/vs-chip&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Outlined</h6>
        </template>
        <template v-slot:content>
          <div class="grid sm:grid-cols-3 md:grid-cols-4 gap-6">
            <div>
              <vs-chip color="neutral" size="small" variant="outlined">
                Neutral
              </vs-chip>
            </div>
            <div>
              <vs-chip color="warning" size="small" variant="outlined">
                Warning
              </vs-chip>
            </div>
            <div>
              <vs-chip color="primary" size="small" variant="outlined">
                Primary
              </vs-chip>
            </div> 
            <div>
              <vs-chip color="error" size="small" variant="outlined">
                Error
              </vs-chip>
            </div>
            <div>
              <vs-chip color="success" size="small" variant="outlined">
                Success
              </vs-chip>
            </div> 
            <div>
              <vs-chip color="secondary" size="small" variant="outlined">
                Secondary
              </vs-chip>
            </div>
            <div>
              <vs-chip color="neutral-250" size="small" variant="outlined">
                Neutral 250
              </vs-chip>
            </div>
            <div>
              <vs-chip color="secondary-50" size="small" variant="outlined">
                Secondary 50
              </vs-chip>
            </div>
            <div>
              <vs-chip color="secondary-500" size="small" variant="outlined">
                Secondary 500
              </vs-chip>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-chip color="neutral" size="small" variant="outlined"&gt;Neutral&lt;/vs-chip&gt;
    &lt;vs-chip color="warning" size="small" variant="outlined"&gt;Warning&lt;/vs-chip&gt;
    &lt;vs-chip color="primary" size="small" variant="outlined"&gt;Primary&lt;/vs-chip&gt;
    &lt;vs-chip color="error" size="small" variant="outlined"&gt;Error&lt;/vs-chip&gt;
    &lt;vs-chip color="success" size="small" variant="outlined"&gt;Success&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary" size="small" variant="outlined"&gt;Secondary&lt;/vs-chip&gt;
    &lt;vs-chip color="neutral-250" size="small" variant="outlined"&gt;Neutral 250&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-50" size="small" variant="outlined"&gt;Secondary 50&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-500" size="small" variant="outlined"&gt;Secondary 500&lt;/vs-chip&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- small Badge Section ends -->

    <!-- Medium Badge Section starts -->
    <h3 class="mb-5" id="badges-medium">Medium</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Filled</h6>
        </template>
        <template v-slot:content>
          <div class="grid sm:grid-cols-3 md:grid-cols-4 gap-6">
            <div>
              <vs-chip color="neutral" size="medium">
                Neutral
              </vs-chip>
            </div>
            <div>
              <vs-chip color="warning" size="medium">
                Warning
              </vs-chip>
            </div>
            <div>
              <vs-chip color="primary" size="medium">
                Primary
              </vs-chip>
            </div>
            <div>
              <vs-chip color="error" size="medium">
                Error
              </vs-chip>
            </div>
            <div>
              <vs-chip color="success" size="medium">
                Success
              </vs-chip>
            </div>
            <div>
              <vs-chip color="secondary" size="medium">
                Secondary
              </vs-chip>
            </div>
            <div>
              <vs-chip color="neutral-250" size="medium">
                Neutral 250
              </vs-chip>
            </div>
            <div>
              <vs-chip color="secondary-50" size="medium">
                Secondary 50
              </vs-chip>
            </div>
            <div>
              <vs-chip color="secondary-500" size="medium">
                Secondary 500
              </vs-chip>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-chip color="neutral" size="medium"&gt;Neutral&lt;/vs-chip&gt;
    &lt;vs-chip color="warning" size="medium"&gt;Warning&lt;/vs-chip&gt;
    &lt;vs-chip color="primary" size="medium"&gt;Primary&lt;/vs-chip&gt;
    &lt;vs-chip color="error" size="medium"&gt;Error&lt;/vs-chip&gt;
    &lt;vs-chip color="success" size="medium"&gt;Success&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary" size="medium"&gt;Secondary&lt;/vs-chip&gt;
    &lt;vs-chip color="neutral-250" size="medium"&gt;Neutral 250&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-50" size="medium"&gt;Secondary 50&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-500" size="medium"&gt;Secondary 500&lt;/vs-chip&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Outlined</h6>
        </template>
        <template v-slot:content>
          <div class="grid sm:grid-cols-3 md:grid-cols-4 gap-6">
            <div>
              <vs-chip color="neutral" size="medium" variant="outlined">
                Neutral
              </vs-chip>
            </div>
            <div>
              <vs-chip color="warning" size="medium" variant="outlined">
                Warning
              </vs-chip>
            </div>
            <div>
              <vs-chip color="primary" size="medium" variant="outlined">
                Primary
              </vs-chip>
            </div>
            <div>
              <vs-chip color="error" size="medium" variant="outlined">
                Error
              </vs-chip>
            </div>
            <div>
              <vs-chip color="success" size="medium" variant="outlined">
                Success
              </vs-chip>
            </div>
            <div>
              <vs-chip color="secondary" size="medium" variant="outlined">
                Secondary
              </vs-chip>
            </div>
            <div>
              <vs-chip color="neutral-250" size="medium" variant="outlined">
                Neutral 250
              </vs-chip>
            </div>
            <div>
              <vs-chip color="secondary-50" size="medium" variant="outlined">
                Secondary 50
              </vs-chip>
            </div>
            <div>
              <vs-chip color="secondary-500" size="medium" variant="outlined">
                Secondary 500
              </vs-chip>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-chip color="neutral" size="medium" variant="outlined"&gt;Neutral&lt;/vs-chip&gt;
    &lt;vs-chip color="warning" size="medium" variant="outlined"&gt;Warning&lt;/vs-chip&gt;
    &lt;vs-chip color="primary" size="medium" variant="outlined"&gt;Primary&lt;/vs-chip&gt;
    &lt;vs-chip color="error" size="medium" variant="outlined"&gt;Error&lt;/vs-chip&gt;
    &lt;vs-chip color="success" size="medium" variant="outlined"&gt;Success&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary" size="medium" variant="outlined"&gt;Secondary&lt;/vs-chip&gt;
    &lt;vs-chip color="neutral-250" size="medium" variant="outlined"&gt;Neutral 250&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-50" size="medium" variant="outlined"&gt;Secondary 50&lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-500" size="medium" variant="outlined"&gt;Secondary 500&lt;/vs-chip&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Medium Badge Section ends -->
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";

  export default {
    data() {
        return {
      }
    },
    components: {
      Prism,
      VueAccordion,
    },
    methods: {
    }
  }
</script>