<template>
  <div>
    <h2 class="mb-5">Progress indicator</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#communication-list'">Progress indicator</vs-button></div>
      </div>
    </vs-card>
    <div class="flex flex-col mb-8">
      <vs-card type="toolbar-sidebar-page" class="mb-6">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium" id="progress-indicator">Progress indicator</h6>
          </template>
          <template v-slot:content>
            <v-stepper :steps="steps" v-model="step"></v-stepper>
            <template v-if="step === 1">
              <!-- Step 1 Content -->
            </template>
            <template v-if="step === 2">
              <!-- Step 2 Content -->
            </template>
            <template v-if="step === 3">
              <!-- Step 3 Content -->
            </template>
            <template v-if="step === 4">
              <!-- Step 4 Content -->
            </template>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;v-stepper :steps="steps" v-model="step"&gt;&lt;/v-stepper&gt;
    &lt;template v-if="step === 1"&gt;
      &lt;!-- Step 1 Content --&gt;
    &lt;/template&gt;
    &lt;template v-if="step === 2"&gt;
      &lt;!-- Step 2 Content --&gt;
    &lt;/template&gt;
    &lt;template v-if="step === 3"&gt;
      &lt;!-- Step 3 Content --&gt;
    &lt;/template&gt;
    &lt;template v-if="step === 4"&gt;
      &lt;!-- Step 4 Content --&gt;
    &lt;/template&gt;

    &lt;script&gt;
      import { VStepper } from "vue-stepper-component";

      export default {
        data() {
          return {
            steps: 4,
            step: 1,
          }
        },
        components: {
          VStepper,
        },
      }
    &lt;/script&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>
      </vs-card>
    </div>
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";
  import { VStepper } from "vue-stepper-component";

  export default {
    data() {
      return {
        steps: 4,
        step: 1,
      }
    },
    components: {
      Prism,
      VueAccordion,
      VStepper,
    },
  }
</script>