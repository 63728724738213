<template>
  <div>
    <h2 class="mb-5">Headings</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#large-headings'">Large Headings</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#small-headings'">Small Headings</vs-button></div>
      </div>
    </vs-card>
    <!-- Large headings -->
    <h3 class="mb-5" id="large-headings">Large</h3>
    <div class="flex flex-col mb-8">
      <!-- simple heading -->
      <vs-card type="toolbar-sidebar-page" class="mb-6">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Simple Heading</h6>
          </template>
          <template v-slot:content>
            <h4 class="in-card-heading__lg">
              <span class="in-card-heading-title">This is simple heading</span>
            </h4>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;h4 class="in-card-heading__lg"&gt;
      &lt;span class="in-card-heading-title"&gt;This is simple heading&lt;/span&gt;
    &lt;/h4&gt;
            </Prism>
          </pre>
          </template>
        </vue-accordion>
      </vs-card>

      <!-- heading with tooltip -->
      <vs-card type="toolbar-sidebar-page" class="mb-6">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Heading with tooltip</h6>
          </template>
          <template v-slot:content>
            <h4 class="in-card-heading__lg">
              <span class="in-card-heading-title">Heading: With tooltip</span>
              <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
            </h4>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;h4 class="in-card-heading__lg"&gt;
      &lt;span class="in-card-heading-title"&gt;Heading: With tooltip&lt;/span&gt;
      &lt;vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"&gt;&lt;span&gt;?&lt;/span&gt;&lt;/vs-tooltip&gt;
    &lt;/h4&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>
      </vs-card>

      <!-- heading without divider -->
      <vs-card type="toolbar-sidebar-page" class="mb-6">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Heading without divider</h6>
          </template>
          <template v-slot:content>
            <h4 class="in-card-heading__lg mb-3">
              <span class="in-card-heading-title">Heading: without divider</span>
            </h4>
            <!-- heading with tooltip and without divider-->
            <h4 class="in-card-heading__lg">
              <span class="in-card-heading-title">Heading: with tooltip and no divider</span>
              <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
            </h4>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;h4 class="in-card-heading__lg"&gt;
      &lt;span class="in-card-heading-title"&gt;Heading: without divider&lt;/span&gt;
    &lt;/h4&gt;
    &lt;!-- heading with tooltip and without divider--&gt;
    &lt;h4 class="in-card-heading__lg"&gt;
      &lt;span class="in-card-heading-title"&gt;Heading: with tooltip and no divider&lt;/span&gt;
      &lt;vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"&gt;&lt;span&gt;?&lt;/span&gt;&lt;/vs-tooltip&gt;
    &lt;/h4&gt;
            </Prism>
          </pre>
          </template>
        </vue-accordion>
      </vs-card>

      <!-- collapsible heading -->
      <vs-card type="toolbar-sidebar-page" class="mb-6">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Collapsible Heading</h6>
          </template>
          <template v-slot:content>
            <!-- Collapsed heading -->
            <vs-collapse class="mb-3">
              <vs-collapse-item>
                  <div slot="header">
                    <h4 slot="header" class="in-card-heading__lg">
                      <span class="in-card-heading-title">Heading: collapsible</span>
                    </h4>
                    <hr class="heading-divider"/>
                  </div>
                  <span>card open</span>
              </vs-collapse-item>
            </vs-collapse>
            <!-- Collapsed heading with tooltip -->
            <vs-collapse>
              <vs-collapse-item>
                  <div slot="header">
                    <h4 slot="header" class="in-card-heading__lg">
                      <span class="in-card-heading-title">Heading: collapsible with tooltip</span>
                      <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
                    </h4>
                    <hr class="heading-divider"/>
                  </div>
                  <span>card open</span>
              </vs-collapse-item>
            </vs-collapse>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;!-- Collapsed heading --&gt;
      &lt;vs-collapse&gt;
        &lt;vs-collapse-item&gt;
            &lt;div slot="header"&gt;
              &lt;h4 slot="header" class="in-card-heading__lg"&gt;
                &lt;span class="in-card-heading-title"&gt;Heading: collapsible&lt;/span&gt;
              &lt;/h4&gt;
              &lt;hr class="heading-divider"/&gt;
            &lt;/div&gt;
            &lt;span&gt;card open&lt;/span&gt;
        &lt;/vs-collapse-item&gt;
      &lt;/vs-collapse&gt;
      
      &lt;!-- Collapsed heading with tooltip --&gt;
      &lt;vs-collapse&gt;
        &lt;vs-collapse-item&gt;
            &lt;div slot="header"&gt;
              &lt;h4 slot="header" class="in-card-heading__lg"&gt;
                &lt;span class="in-card-heading-title"&gt;Heading: collapsible with tooltip&lt;/span&gt;
                &lt;vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"&gt;&lt;span&gt;?&lt;/span&gt;&lt;/vs-tooltip&gt;
              &lt;/h4&gt;
              &lt;hr class="heading-divider"/&gt;
            &lt;/div&gt;
            &lt;span&gt;card open&lt;/span&gt;
        &lt;/vs-collapse-item&gt;
      &lt;/vs-collapse&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>
      </vs-card>

      <!-- heading with link -->
      <vs-card type="toolbar-sidebar-page" class="mb-6">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Heading with link</h6>
          </template>
          <template v-slot:content>
            <h4 class="in-card-heading__lg">
              <span class="in-card-heading-title">Heading: with link</span>
              <vs-button type="flat" class="inline-flex ml-auto" icon="add">Link Text</vs-button>
            </h4>

            <hr class="heading-divider mb-3" />

            <!-- heading with link and tooltip-->
            <h4 class="in-card-heading__lg">
              <span class="in-card-heading-title">Heading: with link and tooltip</span>
              <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
              <vs-button type="flat" class="inline-flex ml-auto" icon="add">Link Text</vs-button>
            </h4>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;h4 class="in-card-heading__lg"&gt;
      &lt;span class="in-card-heading-title"&gt;Heading: with link&lt;/span&gt;
      &lt;vs-button type="flat" class="inline-flex ml-auto" icon="add"&gt;Link Text&lt;/vs-button&gt;
    &lt;/h4&gt;

    &lt;!-- heading with link and tooltip--&gt;
    &lt;h4 class="in-card-heading__lg"&gt;
      &lt;span class="in-card-heading-title"&gt;Heading: with link and tooltip&lt;/span&gt;
      &lt;vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"&gt;&lt;span&gt;?&lt;/span&gt;&lt;/vs-tooltip&gt;
      &lt;vs-button type="flat" class="inline-flex ml-auto" icon="add"&gt;Link Text&lt;/vs-button&gt;
    &lt;/h4&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>
      </vs-card>

      <!-- heading with icon -->
      <vs-card type="toolbar-sidebar-page" class="mb-6">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Heading with icon</h6>
          </template>
          <template v-slot:content>
            <h4 class="in-card-heading__lg">
              <vs-icon class="in-card-heading-icon">grade</vs-icon>
              <span class="in-card-heading-title">Heading: with icon</span>
            </h4>

            <hr class="heading-divider mb-3" />
  
            <!-- heading with icon and tooltip -->
            <h4 class="in-card-heading__lg">
              <vs-icon class="in-card-heading-icon">grade</vs-icon>
              <span class="in-card-heading-title">Heading: with icon tooltip</span>
              <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
            </h4>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;h4 class="in-card-heading__lg"&gt;
      &lt;vs-icon class="in-card-heading-icon"&gt;grade&lt;/vs-icon&gt;
      &lt;span class="in-card-heading-title"&gt;Heading: with icon&lt;/span&gt;
    &lt;/h4&gt;

    &lt;!-- heading with icon and tooltip --&gt;
    &lt;h4 class="in-card-heading__lg"&gt;
      &lt;vs-icon class="in-card-heading-icon"&gt;grade&lt;/vs-icon&gt;
      &lt;span class="in-card-heading-title"&gt;Heading: with icon tooltip&lt;/span&gt;
      &lt;vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"&gt;&lt;span&gt;?&lt;/span&gt;&lt;/vs-tooltip&gt;
    &lt;/h4&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>
      </vs-card>
    </div>

    <!-- Small Headings -->
    <h3 class="mb-5" id="small-headings">Small Headings</h3>
    <div class="flex flex-col">
      <!-- simple heading -->
      <vs-card type="toolbar-sidebar-page" class="mb-6">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Simple Heading</h6>
          </template>
          <template v-slot:content>
            <h5 class="in-card-heading__sm">
              <span class="in-card-heading-title">This is simple heading</span>
            </h5>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;h5 class="in-card-heading__sm"&gt;
      &lt;span class="in-card-heading-title"&gt;This is simple heading&lt;/span&gt;
    &lt;/h5&gt;
              </Prism>
          </pre>
          </template>
        </vue-accordion>
      </vs-card>

      <!-- heading with tooltip -->
      <vs-card type="toolbar-sidebar-page" class="mb-6">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Heading with tooltip</h6>
          </template>
          <template v-slot:content>
            <h5 class="in-card-heading__sm">
              <span class="in-card-heading-title">Heading: with tooltip</span>
              <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
            </h5>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;h5 class="in-card-heading__sm"&gt;
      &lt;span class="in-card-heading-title"&gt;Heading: with tooltip&lt;/span&gt;
      &lt;vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"&gt;
        &lt;span&gt;?&lt;/span&gt;
      &lt;/vs-tooltip&gt;
    &lt;/h5&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>
      </vs-card>

      <!-- heading without divider -->
      <vs-card type="toolbar-sidebar-page" class="mb-6">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Heading without divider</h6>
          </template>
          <template v-slot:content>
            <h5 class="in-card-heading__sm mb-3">
              <span class="in-card-heading-title">Heading: without divider</span>
            </h5>
            <!-- heading with tooltip and without divider-->
            <h5 class="in-card-heading__sm">
              <span class="in-card-heading-title">Heading: with tooltip and no divider</span>
              <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
            </h5>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;h5 class="in-card-heading__sm"&gt;
      &lt;span class="in-card-heading-title"&gt;Heading: without divider&lt;/span&gt;
    &lt;/h5&gt;
    &lt;!-- heading with tooltip and without divider--&gt;
    &lt;h5 class="in-card-heading__sm"&gt;
      &lt;span class="in-card-heading-title"&gt;Heading: with tooltip and no divider&lt;/span&gt;
      &lt;vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"&gt;
        &lt;span&gt;?&lt;/span&gt;
      &lt;/vs-tooltip&gt;
    &lt;/h5&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>
      </vs-card>

      <!-- collapsible heading -->
      <vs-card type="toolbar-sidebar-page" class="mb-6">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Collapsible Heading</h6>
          </template>
          <template v-slot:content>
            <!-- Collapsed heading -->
            <vs-collapse class="mb-3">
              <vs-collapse-item>
                  <div slot="header">
                    <h5 slot="header" class="in-card-heading__sm">
                      <span class="in-card-heading-title">Heading: collapsible</span>
                    </h5>
                    <hr class="heading-divider" />
                  </div>
                  <span>card open</span>
              </vs-collapse-item>
            </vs-collapse>

            <!-- Collapsed heading with tooltip -->
            <vs-collapse>
              <vs-collapse-item>
                <div slot="header">
                  <h5 slot="header" class="in-card-heading__sm">
                    <span class="in-card-heading-title">Heading: collapsible with tooltip</span>
                    <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
                  </h5>
                  <hr class="heading-divider"/>
                </div>
                <span>card open</span>
              </vs-collapse-item>
            </vs-collapse>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;!-- Collapsed heading --&gt;
    &lt;vs-collapse&gt;
      &lt;vs-collapse-item&gt;
          &lt;div slot="header"&gt;
            &lt;h5 slot="header" class="in-card-heading__sm"&gt;
              &lt;span class="in-card-heading-title"&gt;Heading: collapsible&lt;/span&gt;
            &lt;/h5&gt;
            &lt;hr class="heading-divider" /&gt;
          &lt;/div&gt;
          &lt;span&gt;card open&lt;/span&gt;
      &lt;/vs-collapse-item&gt;
    &lt;/vs-collapse&gt;

    &lt;!-- Collapsed heading with tooltip --&gt;
    &lt;vs-collapse&gt;
      &lt;vs-collapse-item&gt;
        &lt;div slot="header"&gt;
          &lt;h5 slot="header" class="in-card-heading__sm"&gt;
            &lt;span class="in-card-heading-title"&gt;Heading: collapsible with tooltip&lt;/span&gt;
            &lt;vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"&gt;&lt;span&gt;?&lt;/span&gt;&lt;/vs-tooltip&gt;
          &lt;/h5&gt;
          &lt;hr class="heading-divider" /&gt;
        &lt;/div&gt;
        &lt;span&gt;card open&lt;/span&gt;
      &lt;/vs-collapse-item&gt;
    &lt;/vs-collapse&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>
      </vs-card>

      <!-- heading with link -->
      <vs-card type="toolbar-sidebar-page" class="mb-6">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Heading with link</h6>
          </template>
          <template v-slot:content>
            <h5 class="in-card-heading__sm">
              <span class="in-card-heading-title">Heading: with link</span>
              <vs-button type="flat" class="inline-flex ml-auto" icon="add">Link Text</vs-button>
            </h5>

            <hr class="heading-divider mb-3" />
            
            <!-- heading with link and tooltip-->
            <h5 class="in-card-heading__sm">
              <span class="in-card-heading-title">Heading: with link and tooltip</span>
              <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
              <vs-button type="flat" class="inline-flex ml-auto" icon="add">Link Text</vs-button>
            </h5>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;h5 class="in-card-heading__sm"&gt;
      &lt;span class="in-card-heading-title"&gt;Heading: with link&lt;/span&gt;
      &lt;vs-button type="flat" class="inline-flex ml-auto" icon="add"&gt;Link Text&lt;/vs-button&gt;
    &lt;/h5&gt;
    
    &lt;!-- heading with link and tooltip--&gt;
    &lt;h5 class="in-card-heading__sm"&gt;
      &lt;span class="in-card-heading-title"&gt;Heading: with link and tooltip&lt;/span&gt;
      &lt;vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"&gt;&lt;span&gt;?&lt;/span&gt;&lt;/vs-tooltip&gt;
      &lt;vs-button type="flat" class="inline-flex ml-auto" icon="add"&gt;Link Text&lt;/vs-button&gt;
    &lt;/h5&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>
      </vs-card>

      <!-- heading with icon -->
      <vs-card type="toolbar-sidebar-page" class="mb-6">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Heading with icon</h6>
          </template>
          <template v-slot:content>
            <h5 class="in-card-heading__sm">
              <vs-icon class="in-card-heading-icon">grade</vs-icon>
              <span class="in-card-heading-title">Heading: with icon</span>
            </h5>

            <hr class="heading-divider mb-3" />
  
            <!-- heading with icon and tooltip -->
            <h5 class="in-card-heading__sm">
              <vs-icon class="in-card-heading-icon">grade</vs-icon>
              <span class="in-card-heading-title">Heading: with icon tooltip</span>
              <vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"><span>?</span></vs-tooltip>
            </h5>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;h5 class="in-card-heading__sm"&gt;
      &lt;vs-icon class="in-card-heading-icon"&gt;grade&lt;/vs-icon&gt;
      &lt;span class="in-card-heading-title"&gt;Heading: with icon&lt;/span&gt;
    &lt;/h5&gt;

    &lt;!-- heading with icon and tooltip --&gt;
    &lt;h5 class="in-card-heading__sm"&gt;
      &lt;vs-icon class="in-card-heading-icon"&gt;grade&lt;/vs-icon&gt;
      &lt;span class="in-card-heading-title"&gt;Heading: with icon tooltip&lt;/span&gt;
      &lt;vs-tooltip class="in-card-heading-tooltip" text="Tooltip Default"&gt;&lt;span&gt;?&lt;/span&gt;&lt;/vs-tooltip&gt;
    &lt;/h5&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>
      </vs-card>
    </div>
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";

  export default {
    data() {
      return {
      }
    },
    components: {
      Prism,
      VueAccordion,
    },
    methods: {
    }
  }
</script>