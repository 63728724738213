<template>
  <div>
    <h2 class="mb-5">Popups</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#popup-default'">Default/Regular Popup</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#icon-hidden-popup'">Close icon hidden Popup</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#success-popup'">Success Popup</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#page-card-standard'">Error Popup</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#warning-popup'">Warning Popup</vs-button></div>
      </div>
    </vs-card>
    <!-- Default Popup -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="popup-default">Default/Regular Popup</h6>
        </template>
        <template v-slot:content>
          <div class="text-center">
            <vs-button @click="defaultOpen=true" color="primary">Open Default/Regular popup</vs-button>
            <vs-popup title="Heading" :active.sync="defaultOpen">
              <p class="popup-message">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non ipsum id elementum ac gravida. Nullam in pharea nunc eget volutpat sed tincidunt quisque sit.</p>
              <div class="popup-action-btn-container flex justify-end">
                <vs-button size="large" action-btn>Button Text</vs-button>
                <vs-button @click="defaultOpen=false" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
              </div>
            </vs-popup>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-button @click="defaultOpen=true" color="primary"&gt;Open Default/Regular popup&lt;/vs-button&gt;
    &lt;vs-popup title="Heading" :active.sync="defaultOpen"&gt;
      &lt;p class="popup-message"&gt;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non ipsum id elementum ac gravida. Nullam in pharea nunc eget volutpat sed tincidunt quisque sit.&lt;/p&gt;
      &lt;div class="popup-action-btn-container flex justify-end"&gt;
        &lt;vs-button size="large" action-btn&gt;Button Text&lt;/vs-button&gt;
        &lt;vs-button @click="defaultOpen=false" size="large" type="flat" class="ml-6 md:ml-10"&gt;Cancel&lt;/vs-button&gt;
      &lt;/div&gt;
    &lt;/vs-popup&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            defaultOpen: false,
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- Open Close icon hidden popup -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="icon-hidden-popup">Close icon hidden Popup</h6>
        </template>
        <template v-slot:content>
          <div class="text-center">
            <vs-button @click="closeHiddenOpen=true" color="primary">Open Close icon hidden popup</vs-button>
            <vs-popup title="Warning heading" class="close-hidden" :active.sync="closeHiddenOpen">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non ipsum id elementum ac gravida. Nullam in pharea nunc eget volutpat sed tincidunt quisque sit.</p>
              <div class="popup-action-btn-container flex justify-end">
                <vs-button size="large" action-btn>Save</vs-button>
                <vs-button @click="closeHiddenOpen=false" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
              </div>
            </vs-popup>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-button @click="closeHiddenOpen=true" color="primary"&gt;Open Close icon hidden popup&lt;/vs-button&gt;
    &lt;vs-popup title="Warning heading" class="close-hidden" :active.sync="closeHiddenOpen"&gt;
      &lt;p&gt;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non ipsum id elementum ac gravida. Nullam in pharea nunc eget volutpat sed tincidunt quisque sit.&lt;/p&gt;
      &lt;div class="popup-action-btn-container flex justify-end"&gt;
        &lt;vs-button size="large" action-btn&gt;Save&lt;/vs-button&gt;
        &lt;vs-button @click="closeHiddenOpen=false" size="large" type="flat" class="ml-6 md:ml-10"&gt;Cancel&lt;/vs-button&gt;
      &lt;/div&gt;
    &lt;/vs-popup&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            closeHiddenOpen: false,
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- success popup -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="success-popup">Success Popup</h6>
        </template>
        <template v-slot:content>
          <div class="text-center">
            <vs-button @click="successOpen=true">Open Success popup</vs-button>
            <vs-popup title="Success heading" header-icon="success" class="close-icon-hidden" :active.sync="successOpen">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non ipsum id elementum ac gravida. Nullam in pharea nunc eget volutpat sed tincidunt quisque sit.</p>
              <div class="popup-action-btn-container flex justify-end">
                <vs-button size="large" action-btn>Save</vs-button>
                <vs-button @click="successOpen=false" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
              </div>
            </vs-popup>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-button @click="successOpen=true"&gt;Open Success popup&lt;/vs-button&gt;
    &lt;vs-popup title="Success heading" header-icon="success" class="close-icon-hidden" :active.sync="successOpen"&gt;
      &lt;p&gt;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non ipsum id elementum ac gravida. Nullam in pharea nunc eget volutpat sed tincidunt quisque sit.&lt;/p&gt;
      &lt;div class="popup-action-btn-container flex justify-end"&gt;
        &lt;vs-button size="large" action-btn&gt;Save&lt;/vs-button&gt;
        &lt;vs-button @click="successOpen=false" size="large" type="flat" class="ml-6 md:ml-10"&gt;Cancel&lt;/vs-button&gt;
      &lt;/div&gt;
    &lt;/vs-popup&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            successOpen: false,
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- error popup -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="error-popup">Error Popup</h6>
        </template>
        <template v-slot:content>
          <div class="text-center">
            <vs-button @click="errorOpen=true">Open Error popup</vs-button>
            <vs-popup title="Error heading" header-icon="error" :active.sync="errorOpen">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non ipsum id elementum ac gravida. Nullam in pharea nunc eget volutpat sed tincidunt quisque sit.</p>
              <div class="popup-action-btn-container flex justify-end">
                <vs-button size="large" action-btn>Save</vs-button>
                <vs-button @click="errorOpen=false" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
              </div>
            </vs-popup>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-button @click="errorOpen=true"&gt;Open Error popup&lt;/vs-button&gt;
    &lt;vs-popup title="Error heading" header-icon="error" :active.sync="errorOpen"&gt;
      &lt;p&gt;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non ipsum id elementum ac gravida. Nullam in pharea nunc eget volutpat sed tincidunt quisque sit.&lt;/p&gt;
      &lt;div class="popup-action-btn-container flex justify-end"&gt;
        &lt;vs-button size="large" action-btn&gt;Save&lt;/vs-button&gt;
        &lt;vs-button @click="errorOpen=false" size="large" type="flat" class="ml-6 md:ml-10"&gt;Cancel&lt;/vs-button&gt;
      &lt;/div&gt;
    &lt;/vs-popup&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            errorOpen: false,
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- warning popup -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="warning-popup">Warning Popup</h6>
        </template>
        <template v-slot:content>
          <div class="text-center">
            <vs-button @click="warningOpen=true">Open Warning popup</vs-button>
            <vs-popup title="Warning heading" header-icon="warning" class="close-icon-hidden" :active.sync="warningOpen">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non ipsum id elementum ac gravida. Nullam in pharea nunc eget volutpat sed tincidunt quisque sit.</p>
              <div class="popup-action-btn-container flex justify-end">
                <vs-button size="large" action-btn>Save</vs-button>
                <vs-button @click="warningOpen=false" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
              </div>
            </vs-popup>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-button @click="warningOpen=true"&gt;Open Warning popup&lt;/vs-button&gt;
    &lt;vs-popup title="Warning heading" header-icon="warning" class="close-icon-hidden" :active.sync="warningOpen"&gt;
      &lt;p&gt;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non ipsum id elementum ac gravida. Nullam in pharea nunc eget volutpat sed tincidunt quisque sit.&lt;/p&gt;
      &lt;div class="popup-action-btn-container flex justify-end"&gt;
        &lt;vs-button size="large" action-btn&gt;Save&lt;/vs-button&gt;
        &lt;vs-button @click="warningOpen=false" size="large" type="flat" class="ml-6 md:ml-10"&gt;Cancel&lt;/vs-button&gt;
      &lt;/div&gt;
    &lt;/vs-popup&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            warningOpen: false,
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";

  export default {
    data() {
      return {
        defaultOpen: false,
        closeHiddenOpen: false,
        successOpen: false,
        errorOpen: false,
        warningOpen: false,
      }
    },
    components: {
      Prism,
      VueAccordion,
    },
    methods: {
    }
  }
</script>