<template>
  <div>
    <h2 class="mb-5">Payment</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#payment-method'">Payment Method</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#payment-type-icon'">Payment type icon</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#payment-method-button'">Payment method button</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#card-type-icon'">Card type icon</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#display-payment-method'">Display only - payment method</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#security-code-tooltip'">Security code tooltip</vs-button></div>
      </div>
    </vs-card>
    <!-- Payment method starts -->
    <h3 class="mb-5" id="payment-method">Payment method</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Card Payment</h6>
        </template>
        <template v-slot:content>
          <payment-method :showButton="false"></payment-method>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-row class="pt-8" vs-w="12" vs-align="flex-start" vs-type="flex" vs-justify="center"&gt;
      &lt;vs-col vs-md="6" vs-sm="12" class="max-w-420"&gt;
        &lt;div class="flex flex-row justify-center gap-4"&gt;
          &lt;div class="w-1/2 md:w-2/5" @click="selectTab(1)"&gt;
            &lt;vs-chip :class="`w-full tabs-chip ${ tabIndex == 1 ? 'chip-active' : '' }`" color="neutral-150" size="large" variant="outlined" type="multiline"&gt;
              &lt;CardPayIcon class="chip-icon" /&gt;
              &lt;div class="chip-text"&gt;Card&lt;/div&gt;
            &lt;/vs-chip&gt;
          &lt;/div&gt;
          &lt;div class="w-1/2 md:w-2/5" @click="selectTab(2)"&gt;
            &lt;vs-chip :class="`w-full tabs-chip ${ tabIndex == 2 ? 'chip-active' : '' }`" color="neutral-150" size="large" variant="outlined" type="multiline"&gt;
              &lt;DirectDebitIcon class="chip-icon" /&gt;
              &lt;div class="chip-text"&gt;Direct debit&lt;/div&gt;
            &lt;/vs-chip&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;vs-divider class="my-8" /&gt;
        &lt;div class="flex flex-col gap-y-8 mb-8" v-if="tabIndex == 1"&gt;
          &lt;div class="flex md:flex-row flex-wrap justify-center gap-x-3 gap-y-2"&gt;
            &lt;div class="text-center" :class="cardType === 'visa' || cardType === 'default' ? '' : 'opacity-30'"&gt;
              &lt;img :src="visaCard" alt="visa" /&gt;
              &lt;div class="text-xs font-light leading-tight"&gt;1.1%&lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="text-center" :class="cardType === 'mastercard' || cardType === 'default' ? '' : 'opacity-30'"&gt;
              &lt;img :src="masterCard" alt="mastercard" /&gt;
              &lt;div class="text-xs font-light leading-tight"&gt;1.1%&lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="text-center" :class="cardType === 'amex' || cardType === 'default' ? '' : 'opacity-30'"&gt;
              &lt;img :src="amexCard" alt="amex" /&gt;
              &lt;div class="text-xs font-light leading-tight"&gt;1.1%&lt;/div&gt;
            &lt;/div&gt;
          &lt;/div&gt;
          &lt;div class="w-full"&gt;&lt;vs-input label="Name on card" v-model="cardName" :success="cardNameValid" icon="done" icon-after="true" icon-size="small" @change="checkFieldValidation('cardName')" /&gt;&lt;/div&gt;
          &lt;div class="w-full"&gt;
            &lt;div class="custom-input-field" :class="[!cardValid || !isCardAllowed || showCardNumberIsRequired ? 'hasError' : '', cardFocused ? 'isFocused' : '', cardSuccess ? 'isValid' : '']" &gt;
              &lt;label for="number_div" class="input-label"&gt;Card number&lt;/label&gt;
              &lt;div id="number_div" style="height: 28px"&gt;&lt;/div&gt;
              &lt;vs-icon class="validation-icon" icon="done" icon-after="true" icon-size="small" /&gt;
            &lt;/div&gt;
            &lt;span class="span-text-validation-danger" v-if="!isCardAllowed && !showCardNumberIsRequired"&gt;&lt;span class="span-text-validation"&gt;This card type is not supported. Please try another card&lt;/span&gt;&lt;/span&gt;
            &lt;span class="span-text-validation-danger" v-if="showCardNumberIsRequired"&gt;&lt;span class="span-text-validation"&gt;Card number is required&lt;/span&gt;&lt;/span&gt;
            &lt;span class="span-text-validation-danger" v-if="!cardValid && !showCardNumberIsRequired && isCardAllowed"&gt;&lt;span class="span-text-validation"&gt;Invalid card number&lt;/span&gt;&lt;/span&gt;
          &lt;/div&gt;
          &lt;div class="flex flex-row gap-x-6"&gt;
            &lt;div class="w-1/2"&gt;
              &lt;vs-input class="input-arial" :class="showExpDateIsRequired || showExpired ? 'hasError' : ''" label="Expiry date" placeholder="MM/YY" v-mask="'##/##'" v-model="expiryDate" :success="dateSuccess" icon="done" icon-after="true" icon-size="small" @keyup.native="handleExpiryCardField" @blur="handleExpiryCardField1" /&gt;
              &lt;span class="span-text-validation-danger" v-if="showExpDateIsRequired"&gt;&lt;span class="span-text-validation"&gt;Card expiry date is required&lt;/span&gt;&lt;/span&gt;
              &lt;span class="span-text-validation-danger" v-else-if="showExpired"&gt;&lt;span class="span-text-validation"&gt;Expiry date is invalid&lt;/span&gt;&lt;/span&gt;
            &lt;/div&gt;
            &lt;div class="w-1/2"&gt;
              &lt;div class="custom-input-field" :class="[securityFocused ? 'isFocused' : '', showCvcIsRequired ? 'hasError' : '', cvvSuccess ? 'isValid' : '', isInvalidCvv ? 'hasError' : '' ]"&gt;
                &lt;label class="input-label"&gt;
                  Security code
                  &lt;popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '-55px, 2px' }}}"&gt;
                    &lt;template slot="reference" class="top"&gt;&lt;ToolTipIcon width="12px" height="12px" class="vs-icon" /&gt;&lt;/template&gt;
                    &lt;div class="popper py-2 px-4 text-center" v-if="cardType == 'amex'"&gt;
                      &lt;p class="text-primary"&gt;The 4 digits on the front of your card.&lt;/p&gt;
                      &lt;div class="flex flex-row gap-x-4"&gt;
                        &lt;div&gt;&lt;img :src="amex1" alt="" /&gt;&lt;/div&gt;
                      &lt;/div&gt;
                    &lt;/div&gt;
                    &lt;div class="popper py-2 px-4" v-else-if="['visa', 'mastercard'].includes(cardType)"&gt;
                      &lt;p class="text-primary"&gt;The 3 digits on the back of your card.&lt;/p&gt;
                      &lt;div class="flex flex-row gap-x-4"&gt;
                        &lt;div&gt;&lt;img :src="general1" alt="" /&gt;&lt;/div&gt;
                      &lt;/div&gt;
                    &lt;/div&gt;
                    &lt;div class="popper py-2 px-4 text-center" v-else&gt;
                      &lt;p&gt;For American Express (left), the 4 digits on the front of your card.&lt;/p&gt;
                      &lt;p&gt;For all other cards (right), the 3 digits on the back of your card.&lt;/p&gt;
                      &lt;div class="flex flex-row pt-2 gap-x-4"&gt;
                        &lt;div&gt;&lt;img :src="amex" alt="" /&gt;&lt;/div&gt;
                        &lt;div&gt;&lt;img :src="general" alt="" /&gt;&lt;/div&gt;
                      &lt;/div&gt;
                    &lt;/div&gt;
                  &lt;/popper&gt;
                &lt;/label&gt;
                &lt;div id="cvv_div" style="height: 28px"&gt;&lt;/div&gt;
                &lt;vs-icon class="validation-icon" icon="done" icon-after="true" icon-size="small" /&gt;
              &lt;/div&gt;
              &lt;span class="span-text-validation-danger" v-if="showCvcIsRequired"&gt;&lt;span class="span-text-validation"&gt;Security code is required&lt;/span&gt;&lt;/span&gt;
              &lt;span class="span-text-validation-danger" v-else-if="isInvalidCvv"&gt;&lt;span class="span-text-validation"&gt;Security code is invalid&lt;/span&gt;&lt;/span&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;div class="flex flex-col gap-y-8 mb-8" v-if="tabIndex == 2"&gt;
          &lt;div class="w-full"&gt;&lt;vs-input label="Account name" v-model="accountName" :success="accountNameValid" icon="done" icon-after="true" icon-size="small" @change="checkFieldValidation('accountName')" /&gt;&lt;/div&gt;
          &lt;div class="flex flex-row gap-x-6"&gt;
            &lt;div class="w-2/5"&gt;
              &lt;vs-input
                label="BSB"
                :class="!bsbError ? 'hasError' : ''"
                placeholder="000 - 000"
                v-mask="'###-###'"
                v-model="bsb"
                :success="bsbSuccess"
                icon="done"
                icon-after="true"
                icon-size="small"
                @blur="checkBsbValidity"
                @keyup="handleBsb"
              /&gt;
              &lt;span class="span-text-validation-danger" v-if="!showBsbValid"&gt;&lt;span class="span-text-validation"&gt;Invalid BSB&lt;/span&gt;&lt;/span&gt;
            &lt;/div&gt;
            &lt;div class="w-3/5"&gt;&lt;vs-input label="Account number" v-model="value7" :success="accountNumberValid" icon="done" icon-after="true" icon-size="small" @change="checkFieldValidation('accountNumber')" /&gt;&lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/vs-col&gt;
    &lt;/vs-row&gt;

    &lt;script&gt;
      import CardPayIcon from "../../components/design-sys-components/icons/CardPayIcon";
      import DirectDebitIcon from "../../components/design-sys-components/icons/DirectDebitIcon";
      import ToolTipIcon from "../../components/design-sys-components/icons/ToolTipIcon";
      // Third party import
      import { mask } from 'vue-the-mask';
      import Popper from 'vue-popperjs';
      // Credit Cards
      const visaCard = require("@/assets/images/cards/visa.svg");
      const masterCard = require("@/assets/images/cards/mastercard.svg");
      const amexCard = require("@/assets/images/cards/amex.svg");
      // Tooltip images
      const amex = require("@/assets/images/cards/amex.png");
      const amex1 = require("@/assets/images/cards/amex-1.png");
      const general = require("@/assets/images/cards/general.png");
      const general1 = require("@/assets/images/cards/general-1.png");

      export default {
        data() {
          return {
            visaCard: visaCard,
            masterCard: masterCard,
            amexCard: amexCard,
            amex: amex,
            amex1: amex1,
            general: general,
            general1: general1,
            tabIndex: 1,
            cardName: '',
            expiryDate: '',
            accountName: '',
            bsb: '',
          }
        },
        components: {
          Popper,
          CardPayIcon,
          DirectDebitIcon,
        },
        methods: {
          selectTab(index) {
            this.tabIndex = index;
          }
        },
        directives: {
          mask
        },
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="payment-type-icon">Payment type icon</h6>
        </template>
        <template v-slot:content>
          <div class="flex flex-row flex-wrap items-center gap-6">
            <PaypalIcon />
            <AliPayIcon />
            <ApplePayIcon />
            <CardPayIcon />
            <DirectDebitIcon />
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;PaypalIcon /&gt;
    &lt;AliPayIcon /&gt;
    &lt;ApplePayIcon /&gt;
    &lt;CardPayIcon /&gt;
    &lt;DirectDebitIcon /&gt;

    &lt;script&gt;
      // Icons import
      import PaypalIcon from "../../components/design-sys-components/icons/PaypalIcon";
      import AliPayIcon from "../../components/design-sys-components/icons/AliPayIcon";
      import ApplePayIcon from "../../components/design-sys-components/icons/ApplePayIcon";
      import CardPayIcon from "../../components/design-sys-components/icons/CardPayIcon";
      import DirectDebitIcon from "../../components/design-sys-components/icons/DirectDebitIcon";

      export default {
        data() {
          return {
          }
        },
        components: {
          PaypalIcon,
          AliPayIcon,
          ApplePayIcon,
          CardPayIcon,
          DirectDebitIcon,
        },
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="payment-method-button">Payment method button</h6>
        </template>
        <template v-slot:content>
          <h6 class="mb-8">Contains payment type icon component. Text can be customised</h6>
          <div class="flex flex-row flex-wrap gap-8">
            <vs-chip class="tabs-chip chip-active" color="neutral-150" size="large" variant="outlined" type="multiline">
              <CardPayIcon class="chip-icon" />
              <div class="chip-text">Card</div>
            </vs-chip>
            <vs-chip class="tabs-chip" color="neutral-150" size="large" variant="outlined" type="multiline">
              <DirectDebitIcon class="chip-icon" />
              <div class="chip-text">Direct debit</div>
            </vs-chip>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="flex flex-row flex-wrap gap-8"&gt;
      &lt;vs-chip class="tabs-chip chip-active" color="secondary" size="large" variant="outlined" type="multiline"&gt;
        &lt;CardPayIcon class="chip-icon" /&gt;
        &lt;div class="chip-text"&gt;Card&lt;/div&gt;
      &lt;/vs-chip&gt;
      &lt;vs-chip class="tabs-chip" color="neutral-150" size="large" variant="outlined" type="multiline"&gt;
        &lt;DirectDebitIcon class="chip-icon" /&gt;
        &lt;div class="chip-text"&gt;Direct debit&lt;/div&gt;
      &lt;/vs-chip&gt;
    &lt;/div&gt;

    &lt;script&gt;
      // Icons import
      import CardPayIcon from "../../components/design-sys-components/icons/CardPayIcon";
      import DirectDebitIcon from "../../components/design-sys-components/icons/DirectDebitIcon";

      export default {
        data() {
          return {
          }
        },
        components: {
          CardPayIcon,
          DirectDebitIcon,
        },
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="card-type-icon">Card type icon</h6>
        </template>
        <template v-slot:content>
          <div class="flex flex-row flex-wrap gap-x-6 gap-y-4">
            <div>
              <img :src="visaCard" alt="visa" />
            </div>
            <div>
              <img :src="masterCard" alt="mastercard" />
            </div>
            <div>
              <img :src="amexCard" alt="amex" />
            </div>
            <div>
              <img :src="dinersClubCard" alt="diners-club" />
            </div>
            <div>
              <img :src="unionpayCard" alt="unionpay" />
            </div>
            <div>
              <img :src="directDebitCard" alt="direct-debit" />
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;img :src="visaCard" alt="visa" /&gt;
    &lt;img :src="masterCard" alt="mastercard" /&gt;
    &lt;img :src="amexCard" alt="amex" /&gt;
    &lt;img :src="dinersClubCard" alt="diners-club" /&gt;
    &lt;img :src="unionpayCard" alt="unionpay" /&gt;
    &lt;img :src="directDebitCard" alt="direct-debit" /&gt;

    &lt;script&gt;
      // Credit Cards
      const visaCard = require("@/assets/images/cards/visa.svg");
      const masterCard = require("@/assets/images/cards/mastercard.svg");
      const amexCard = require("@/assets/images/cards/amex.svg");
      const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
      const unionpayCard = require("@/assets/images/cards/unionpay.svg");
      const directDebitCard = require("@/assets/images/cards/directdebit.svg");

      export default {
        data() {
          return {
            visaCard: visaCard,
            masterCard: masterCard,
            amexCard: amexCard,
            dinersClubCard: dinersClubCard,
            unionpayCard: unionpayCard,
            directDebitCard: directDebitCard,
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="display-payment-method">Display only - payment method</h6>
        </template>
        <template v-slot:content>
          <div class="flex flex-col gap-y-10">
            <div class="flex flex-row items-center gap-x-4 text-sm font-normal text-body">
              <img :src="directDebitCard" alt="direct-debit" />
              <span>Commonwealth Bank</span>
              <span>**** 1234</span>
            </div>
            <div class="flex flex-row items-center gap-x-4 text-sm font-normal text-body">
              <img :src="visaCard" alt="visa" />
              <span>**** 1234</span>
              <span>01/24</span>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="flex flex-row items-center gap-x-4 text-sm font-normal text-body"&gt;
      &lt;img :src="directDebitCard" alt="direct-debit" /&gt;
      &lt;span&gt;Commonwealth Bank&lt;/span&gt;
      &lt;span&gt;**** 1234&lt;/span&gt;
    &lt;/div&gt;
    &lt;div class="flex flex-row items-center gap-x-4 text-sm font-normal text-body"&gt;
      &lt;img :src="visaCard" alt="visa" /&gt;
      &lt;span&gt;**** 1234&lt;/span&gt;
      &lt;span&gt;01/24&lt;/span&gt;
    &lt;/div&gt;

    &lt;script&gt;
      // Credit Cards
      const visaCard = require("@/assets/images/cards/visa.svg");
      const directDebitCard = require("@/assets/images/cards/directdebit.svg");

      export default {
        data() {
          return {
            visaCard: visaCard,
            directDebitCard: directDebitCard,
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="security-code-tooltip">Security code tooltip</h6>
        </template>
        <template v-slot:content>
          <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-6">
            <div>
              <label class="input-label bg-transparent p-0">
                Security code
                <popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '-55px, 2px' }}}">
                  <a href="#" slot="reference" class="top"><ToolTipIcon width="12px" height="12px" class="vs-icon" /></a>
                  <div class="popper py-2 px-4">
                    <p class="text-body-dark">For American Express (left), the 4 digits on the front of your card.</p>
                    <p class="text-body-dark">For all other cards (right), the 3 digits on the back of your card.</p>
                    <div class="flex flex-row pt-2 gap-x-4">
                      <div><img :src="amex" alt="" /></div>
                      <div><img :src="general" alt="" /></div>
                    </div>
                  </div>
                </popper>
              </label>
            </div>
            <div>
              <label class="input-label bg-transparent">
                Security code
                <popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '-55px, 2px' }}}">
                  <a href="#" slot="reference" class="top"><ToolTipIcon width="12px" height="12px" class="vs-icon" /></a>
                  <div class="popper py-2 px-4 text-center">
                    <p class="text-primary">The 4 digits on the front of your card.</p>
                    <div class="flex flex-row gap-x-4">
                      <div><img :src="amex1" alt="" /></div>
                    </div>
                  </div>
                </popper>
              </label>
            </div>
            <div>
              <label class="input-label bg-transparent p-0">
                Security code
                <popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '-55px, 2px' }}}">
                  <a href="#" slot="reference" class="top"><ToolTipIcon width="12px" height="12px" class="vs-icon" /></a>
                  <div class="popper py-2 px-4 text-center">
                    <p class="text-primary">The 3 digits on the back of your card.</p>
                    <div class="flex flex-row gap-x-4">
                      <div><img :src="general1" alt="" /></div>
                    </div>
                  </div>
                </popper>
              </label>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;label class="input-label"&gt;
      Security code
      &lt;popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '-55px, 2px' }}}"&gt;
        &lt;a href="#" slot="reference" class="top"&gt;&lt;ToolTipIcon width="12px" height="12px" class="vs-icon" /&gt;&lt;/a&gt;
        &lt;div class="popper py-2 px-4"&gt;
          &lt;p class="text-body-dark"&gt;For American Express (left), the 4 digits on the front of your card.&lt;/p&gt;
          &lt;p class="text-body-dark"&gt;For all other cards (right), the 3 digits on the back of your card.&lt;/p&gt;
          &lt;div class="flex flex-row pt-2 gap-x-4"&gt;
            &lt;div&gt;&lt;img :src="amex" alt="" /&gt;&lt;/div&gt;
            &lt;div&gt;&lt;img :src="general" alt="" /&gt;&lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/popper&gt;
    &lt;/label&gt;

    &lt;label class="input-label"&gt;
      Security code
      &lt;popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '-55px, 2px' }}}"&gt;
        &lt;a href="#" slot="reference" class="top"&gt;&lt;ToolTipIcon width="12px" height="12px" class="vs-icon" /&gt;&lt;/a&gt;
        &lt;div class="popper py-2 px-4 text-center"&gt;
          &lt;p class="text-primary"&gt;The 4 digits on the front of your card.&lt;/p&gt;
          &lt;div class="flex flex-row gap-x-4"&gt;
            &lt;div&gt;&lt;img :src="amex1" alt="" /&gt;&lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/popper&gt;
    &lt;/label&gt;

    &lt;label class="input-label"&gt;
      Security code
      &lt;popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '-55px, 2px' }}}"&gt;
        &lt;a href="#" slot="reference" class="top"&gt;&lt;ToolTipIcon width="12px" height="12px" class="vs-icon" /&gt;&lt;/a&gt;
        &lt;div class="popper py-2 px-4 text-center"&gt;
          &lt;p class="text-primary"&gt;The 3 digits on the back of your card.&lt;/p&gt;
          &lt;div class="flex flex-row gap-x-4"&gt;
            &lt;div&gt;&lt;img :src="general1" alt="" /&gt;&lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/popper&gt;
    &lt;/label&gt;

    &lt;script&gt;
      // Third party import
      import Popper from 'vue-popperjs';
      // Tooltip images
      const amex = require("@/assets/images/cards/amex.png");
      const amex1 = require("@/assets/images/cards/amex-1.png");
      const general = require("@/assets/images/cards/general.png");
      const general1 = require("@/assets/images/cards/general-1.png");

      export default {
        data() {
          return {
            amex: amex,
            amex1: amex1,
            general: general,
            general1: general1,
          }
        },
        components: {
          Popper,
        },
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Payment method ends -->
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";
  import PaypalIcon from "../../components/design-sys-components/icons/PaypalIcon";
  import AliPayIcon from "../../components/design-sys-components/icons/AliPayIcon";
  import ApplePayIcon from "../../components/design-sys-components/icons/ApplePayIcon";
  import CardPayIcon from "../../components/design-sys-components/icons/CardPayIcon";
  import DirectDebitIcon from "../../components/design-sys-components/icons/DirectDebitIcon";
  import ToolTipIcon from "../../components/design-sys-components/icons/ToolTipIcon";
  import PaymentMethod from "../../components/PaymentMethod";
  // Third party import
  import Popper from 'vue-popperjs';

  // Credit Cards
  const visaCard = require("@/assets/images/cards/visa.svg");
  const masterCard = require("@/assets/images/cards/mastercard.svg");
  const amexCard = require("@/assets/images/cards/amex.svg");
  const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
  const unionpayCard = require("@/assets/images/cards/unionpay.svg");
  const directDebitCard = require("@/assets/images/cards/directdebit.svg");
  // Tooltip images
  const amex = require("@/assets/images/cards/amex.png");
  const amex1 = require("@/assets/images/cards/amex-1.png");
  const general = require("@/assets/images/cards/general.png");
  const general1 = require("@/assets/images/cards/general-1.png");

  export default {
    data() {
      return {
        visaCard: visaCard,
        masterCard: masterCard,
        amexCard: amexCard,
        dinersClubCard: dinersClubCard,
        unionpayCard: unionpayCard,
        directDebitCard: directDebitCard,
        amex: amex,
        amex1: amex1,
        general: general,
        general1: general1,
      }
    },
    components: {
      Prism,
      VueAccordion,
      Popper,
      PaypalIcon,
      AliPayIcon,
      ApplePayIcon,
      CardPayIcon,
      DirectDebitIcon,
      ToolTipIcon,
      PaymentMethod,
    },
  }
</script>