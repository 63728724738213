<template>
  <div>
    <h2 class="mb-5">Make a payment</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#single-payment'">Single payment</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#multiple-payments'">Multiple payment</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#multiple-payments-overdue'">Overdue</vs-button></div>
      </div>
    </vs-card>

    <!-- Payment buttons starts -->
    <h3 class="mb-5">Make payment</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="single-payment">Single payment (with success message on making payment)</h6>
        </template>
        <template v-slot:content>
          <div class="rounded-t-2xl md-rounded-t-3xl bg-container-80 p-4">
            <div class="flex flex-row justify-between">
              <div>
                <p class="font-light text-sm text-body">
                  Amount paid
                </p>
                <h3>$ 2,100.00</h3>
              </div>
              <div class="flex flex-col text-right">
                <p class="font-light text-sm text-body">Remaining balance</p>
                <h3>$ 6,300.00</h3>
              </div>
            </div>
            <div class="relative">
              <vs-progress :height="12" :percent="30" color="success-300" class="mb-2"></vs-progress>
            </div>
          </div>
          <div class="bg-container-60 p-4 md:p-6 rounded-b-2xl md-rounded-b-3xl">
            <vs-button color="primary" size="large" class="block w-full max-w-400 mx-auto" @click="toggleExpand" v-if="!isExpanded">Go to payment</vs-button>
            <div v-if="isExpanded && !isExpanded1 && !isExpanded2" class="md:px-2">
              <div>
                <h4>Make a payment</h4>
              </div>
              <div class="px-4 py-2 md:py-0 md:mt-5 mb-5 md:mb-10">
                <div class="border-b-list">
                  <div class="flex flex-row items-center justify-between py-4">
                    <vs-radio v-model="selectedPayment" vs-name="radios1" vs-value="true">Remaining balance</vs-radio>
                    <p>$6,300.00</p>
                  </div>
                </div>
              </div>
              <vs-button color="primary" :disabled="!selectedPayment" size="large" class="block w-full max-w-400 mx-auto mb-4" @click="toggleExpand1">Go to payment</vs-button>
              <vs-button type="flat" @click="toggleExpand" size="medium" class="block mx-auto mb-2 md:mb-0">Cancel</vs-button>
            </div>
            <div v-show="isExpanded1 && !isExpanded2" class="md:px-2">
              <div>
                <h4>Make a payment</h4>
              </div>
              <div class="px-4 py-2 md:py-0 md:mt-5 mb-5 md:mb-8">
                <div class="flex flex-col border-b-list">
                  <div class="flex flex-row justify-between align-center py-4">
                    <div class="flex flex-row items-center">
                      <vs-radio v-model="radios2" vs-name="radios2" id="paymentOption1" vs-value="paymentOption1" />
                      <label for="paymentOption1" class="flex flex-row items-center pointer">
                        <img :src="visaCard" alt="visa" />
                        <p><span class="ml-4 md:ml-6">**** 1234</span><span class="ml-4 md:ml-6">01/24</span></p>
                      </label>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between py-4">
                    <div class="flex flex-row items-center">
                      <vs-radio v-model="radios2" vs-name="radios2" id="paymentOption2" vs-value="paymentOption2" />
                      <label for="paymentOption2" class="flex flex-row items-center pointer">
                        <img :src="visaCard" alt="visa" />
                        <p><span class="ml-4 md:ml-6">**** 1234</span><span class="ml-4 md:ml-6">01/24</span></p>
                      </label>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between py-4">
                    <div class="flex flex-row items-center">
                      <vs-radio v-model="radios2" vs-name="radios2" id="paymentOption3" vs-value="paymentOption3" />
                      <label for="paymentOption3" class="flex flex-row items-center pointer">
                        <img :src="directDebitCard" alt="visa" />
                        <p><span class="ml-4 md:ml-6">Commonwealth Bank</span><span class="ml-4 md:ml-6">**** 1234</span></p>
                      </label>
                    </div>
                  </div>
                </div>
                <hr class="mb-6" />
                <div class="text-center mb-4 pb-2"><vs-button type="flat" class="mx-auto" @click="toggleExpand2" size="medium">Use another payment method</vs-button></div>
              </div>
              <vs-button color="primary" size="large" class="block w-full max-w-400 mx-auto mb-4" @click="showSuccessMessage">Pay $2,100.00</vs-button>
              <vs-button type="flat" @click="toggleExpand1" size="medium" class="block mx-auto mb-2 md:mb-0">Cancel</vs-button>
            </div>
            <div v-show="isExpanded2">
              <h4>Make a payment</h4>
              <vs-row class="pt-5" vs-w="12" vs-align="flex-start" vs-type="flex" vs-justify="center">
                <vs-col vs-md="6" vs-sm="12" class="max-w-420">
                  <div class="payment-cards gap-4">
                    <div @click="selectTab(1)">
                      <vs-chip :class="`w-full tabs-chip ${ tabIndex == 1 ? 'chip-active' : '' }`" color="neutral-150" size="large" variant="outlined" type="multiline">
                        <CardPayIcon class="chip-icon" />
                        <div class="chip-text">Card</div>
                      </vs-chip>
                    </div>
                    <div @click="selectTab(2)">
                      <vs-chip :class="`w-full tabs-chip ${ tabIndex == 2 ? 'chip-active' : '' }`" color="neutral-150" size="large" variant="outlined" type="multiline">
                        <DirectDebitIcon class="chip-icon" />
                        <div class="chip-text">Direct debit</div>
                      </vs-chip>
                    </div>
                    <div @click="selectTab(3)">
                      <vs-chip :class="`w-full tabs-chip ${ tabIndex == 3 ? 'chip-active' : '' }`" color="neutral-150" size="large" variant="outlined" type="multiline">
                        <BankTransferIcon class="chip-icon" />
                        <div class="chip-text">Bank transfer</div>
                      </vs-chip>
                    </div>
                  </div>
                  <vs-divider class="my-8" />
                  <div class="flex flex-col gap-y-8" v-if="tabIndex == 1">
                    <div class="flex md:flex-row flex-wrap justify-center gap-x-3 gap-y-2">
                      <div class="text-center" :class="cardType === 'visa' || cardType === 'default' ? '' : 'opacity-30'">
                        <img :src="visaCard" alt="visa" />
                        <div class="text-xs font-light leading-tight">1.1%</div>
                      </div>
                      <div class="text-center" :class="cardType === 'mastercard' || cardType === 'default' ? '' : 'opacity-30'">
                        <img :src="masterCard" alt="mastercard" />
                        <div class="text-xs font-light leading-tight">1.1%</div>
                      </div>
                      <div class="text-center" :class="cardType === 'amex' || cardType === 'default' ? '' : 'opacity-30'">
                        <img :src="amexCard" alt="amex" />
                        <div class="text-xs font-light leading-tight">1.1%</div>
                      </div>
                    </div>
                    <div class="w-full"><vs-input label="Name on card" v-model="value1" :success="cardNameValid" icon="done" icon-after="true" icon-size="small" @change="checkFieldValidation('cardName')" /></div>
                    <div class="w-full">
                      <div class="custom-input-field" :class="[!cardValid || !isCardAllowed || showCardNumberIsRequired ? 'hasError' : '', cardFocused ? 'isFocused' : '', cardSuccess ? 'isValid' : '']" >
                        <label for="number_div" class="input-label">Card number</label>
                        <div id="number_div" style="height: 28px"></div>
                        <vs-icon class="validation-icon" icon="done" icon-after="true" icon-size="small" />
                      </div>
                      <span class="span-text-validation-danger" v-if="!isCardAllowed && !showCardNumberIsRequired"><span class="span-text-validation">This card type is not supported. Please try another card</span></span>
                      <span class="span-text-validation-danger" v-if="showCardNumberIsRequired"><span class="span-text-validation">Card number is required</span></span>
                      <span class="span-text-validation-danger" v-if="!cardValid && !showCardNumberIsRequired && isCardAllowed"><span class="span-text-validation">Invalid card number</span></span>
                    </div>
                    <div class="flex flex-row gap-x-6">
                      <div class="w-1/2">
                        <vs-input class="input-arial" :class="showExpDateIsRequired || showExpired ? 'hasError' : ''" label="Expiry date" placeholder="MM/YY" v-mask="'##/##'" v-model="value3" :success="dateSuccess" icon="done" icon-after="true" icon-size="small" @keyup.native="handleExpiryCardField" @blur="handleExpiryCardField1" />
                        <span class="span-text-validation-danger" v-if="showExpDateIsRequired"><span class="span-text-validation">Card expiry date is required</span></span>
                        <span class="span-text-validation-danger" v-else-if="showExpired"><span class="span-text-validation">Expiry date is invalid</span></span>
                      </div>
                      <div class="w-1/2">
                        <div class="custom-input-field" :class="[securityFocused ? 'isFocused' : '', showCvcIsRequired ? 'hasError' : '', cvvSuccess ? 'isValid' : '', isInvalidCvv ? 'hasError' : '' ]">
                          <label class="input-label">
                            Security code 
                            <popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '-55px, 2px' }}}">
                              <a href="#" slot="reference" class="top"><ToolTipIcon width="12px" height="12px" class="vs-icon" /></a>
                              <div class="popper py-2 px-4 text-center" v-if="cardType == 'amex'">
                                <p class="text-primary">The 4 digits on the front of your card.</p>
                                <div class="flex flex-row gap-x-4">
                                  <div><img :src="amex1" alt="" /></div>
                                </div>
                              </div>
                              <div class="popper py-2 px-4" v-else-if="['visa', 'mastercard'].includes(cardType)">
                                <p class="text-primary">The 3 digits on the back of your card.</p>
                                <div class="flex flex-row gap-x-4">
                                  <div><img :src="general1" alt="" /></div>
                                </div>
                              </div>
                              <div class="popper py-2 px-4 text-center" v-else>
                                <p>For American Express (left), the 4 digits on the front of your card.</p>
                                <p>For all other cards (right), the 3 digits on the back of your card.</p>
                                <div class="flex flex-row pt-2 gap-x-4">
                                  <div><img :src="amex" alt="" /></div>
                                  <div><img :src="general" alt="" /></div>
                                </div>
                              </div>
                            </popper>
                          </label>
                          <div id="cvv_div" style="height: 28px"></div>
                          <vs-icon class="validation-icon" icon="done" icon-after="true" icon-size="small" />
                        </div>
                        <span class="span-text-validation-danger" v-if="showCvcIsRequired"><span class="span-text-validation">Security code is required</span></span>
                        <span class="span-text-validation-danger" v-else-if="isInvalidCvv"><span class="span-text-validation">Security code is invalid</span></span>
                      </div>
                    </div>
                    <div class="flex flex-row items-center -mt-2">
                      <vs-switch v-model="switch1" class="mr-4" />
                      <p class="text-sm">Make this my default payment method</p>
                    </div>
                    <div class="flex flex-row items-start mb-4">
                      <vs-checkbox v-model="acceptTerms" name="acceptTerms" :checked="acceptTerms" id="terms"> </vs-checkbox>
                      <label for="terms" class="ancillary cursor-pointer">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, nibh fames vulputate cursus ornare consectetur. Molestie parturient fermentum, quisque eget interdum amet magna.</label>
                    </div>
                    <div class="pb-2 md:pb-0">
                      <vs-button :disabled="!acceptTerms" color="primary" class="w-full mb-4" size="large" @click="showSuccessMessage">Pay $2,100.00</vs-button>
                      <vs-button type="flat" @click="toggleExpand2" size="medium" class="block mx-auto">Cancel</vs-button>
                    </div>
                  </div>
                  <div class="flex flex-col gap-y-8" v-if="tabIndex == 2">
                    <div class="w-full"><vs-input label="Account name" v-model="value5" :success="accountNameValid" icon="done" icon-after="true" icon-size="small" @change="checkFieldValidation('accountName')" /></div>
                    <div class="flex flex-row gap-x-6">
                      <div class="w-2/5">
                        <vs-input 
                          label="BSB" 
                          :class="!bsbError ? 'hasError' : ''"
                          placeholder="000 - 000"
                          v-mask="'###-###'"
                          v-model="value6"
                          :success="bsbSuccess"
                          icon="done"
                          icon-after="true"
                          icon-size="small"
                          @blur="checkBsbValidity"
                          @keyup="handleBsb"
                        />
                        <span class="span-text-validation-danger" v-if="!showBsbValid"><span class="span-text-validation">Invalid BSB</span></span>
                      </div>
                      <div class="w-3/5"><vs-input label="Account number" v-model="value7" :success="accountNumberValid" icon="done" icon-after="true" icon-size="small" @change="checkFieldValidation('accountNumber')" /></div>
                    </div>
                    <div class="flex flex-row items-center -mt-2">
                      <vs-switch v-model="switch1" class="mr-4" />
                      <p class="text-sm">Make this my default payment method</p>
                    </div>
                    <div class="flex flex-row items-start mb-4">
                      <vs-checkbox v-model="acceptTerms" name="acceptTerms" :checked="acceptTerms" id="terms"> </vs-checkbox>
                      <label for="terms" class="ancillary cursor-pointer">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, nibh fames vulputate cursus ornare consectetur. Molestie parturient fermentum, quisque eget interdum amet magna.</label>
                    </div>
                    <div class="pb-2 md:pb-0">
                      <vs-button :disabled="!acceptTerms" color="primary" class="w-full mb-4" size="large" @click="showSuccessMessage">Pay $2,100.00</vs-button>
                      <vs-button type="flat" @click="toggleExpand2" size="medium" class="block mx-auto">Cancel</vs-button>
                    </div>
                  </div>
                  <div class="flex flex-col gap-y-8" v-if="tabIndex == 3">
                    <div>
                      <div class="text-center mb-6 md:mb-5">
                        <p class="text-sm">Transfer amount</p>
                        <h2>$ 5,002.00</h2>
                      </div>
                      <div class="flex flex-col gap-y-4 md:gap-y-3">
                        <div class="flex flex-row items-center gap-x-6">
                          <p class="text-sm text-right w-1/2">Account name</p>
                          <p class="text-primary text-left w-1/2">Rello Finance Pty Ltd</p>
                        </div>
                        <div class="flex flex-row items-center gap-x-6">
                          <p class="text-sm text-right w-1/2">B2B</p>
                          <p class="text-primary text-left w-1/2">062 - 692</p>
                        </div>
                        <div class="flex flex-row items-center gap-x-6">
                          <p class="text-sm text-right w-1/2">Account number</p>
                          <p class="text-primary text-left w-1/2">3778 5303</p>
                        </div>
                        <div class="flex flex-row items-center gap-x-6">
                          <p class="text-sm text-right w-1/2">Reference</p>
                          <p class="text-primary text-left w-1/2">
                            PR-1234-5678
                            <vs-icon class="ml-3 pointer icon-gray" icon="content_copy" icon-size="small" />
                          </p>
                        </div>
                        <vs-divider class="my-8 max-w-xs mx-auto" />
                      </div>
                      <p class="text-body mb-8">You’ll get a confirmation email and/or text message once your bank transfer is registered. This can take up to 3 business days.</p>
                      <h3 class="text-body text-center">Thank you!</h3>
                    </div>
                    <div class="pb-2 md:pb-0">
                      <vs-button color="primary" class="w-full" size="large" @click="toggleExpand2">Close</vs-button>
                    </div>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="rounded-t-2xl md-rounded-t-3xl bg-container-80 p-4"&gt;
      &lt;div class="flex flex-row justify-between"&gt;
        &lt;div&gt;
          &lt;p class="font-light text-sm text-body"&gt;
            Amount paid
          &lt;/p&gt;
          &lt;h3&gt;$ 2,100.00&lt;/h3&gt;
        &lt;/div&gt;
        &lt;div class="flex flex-col text-right"&gt;
          &lt;p class="font-light text-sm text-body"&gt;Remaining balance&lt;/p&gt;
          &lt;h3&gt;$ 6,300.00&lt;/h3&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div class="relative"&gt;
        &lt;vs-progress :height="12" :percent="30" color="success-300" class="mb-2"&gt;&lt;/vs-progress&gt;
      &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class="bg-container-60 p-4 md:p-6 rounded-b-2xl md-rounded-b-3xl"&gt;
      &lt;vs-button color="primary" size="large" class="block w-full max-w-400 mx-auto" @click="toggleExpand" v-if="!isExpanded"&gt;Go to payment&lt;/vs-button&gt;
      &lt;div v-if="isExpanded && !isExpanded1 && !isExpanded2" class="md:px-2"&gt;
        &lt;div&gt;
          &lt;h4&gt;Make a payment&lt;/h4&gt;
        &lt;/div&gt;
        &lt;div class="px-4 py-2 md:py-0 md:mt-5 mb-5 md:mb-10"&gt;
          &lt;div class="border-b-list"&gt;
            &lt;div class="flex flex-row items-center justify-between py-4"&gt;
              &lt;vs-radio v-model="selectedPayment" vs-name="radios1" vs-value="true"&gt;Remaining balance&lt;/vs-radio&gt;
              &lt;p&gt;$6,300.00&lt;/p&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;vs-button color="primary" :disabled="!selectedPayment" size="large" class="block w-full max-w-400 mx-auto mb-4" @click="toggleExpand1"&gt;Go to payment&lt;/vs-button&gt;
        &lt;vs-button type="flat" @click="toggleExpand" size="medium" class="block mx-auto mb-2 md:mb-0"&gt;Cancel&lt;/vs-button&gt;
      &lt;/div&gt;
      &lt;div v-show="isExpanded1 && !isExpanded2" class="md:px-2"&gt;
        &lt;div&gt;
          &lt;h4&gt;Make a payment&lt;/h4&gt;
        &lt;/div&gt;
        &lt;div class="px-4 py-2 md:py-0 md:mt-5 mb-5 md:mb-8"&gt;
          &lt;div class="flex flex-col border-b-list"&gt;
            &lt;div class="flex flex-row justify-between align-center py-4"&gt;
              &lt;div class="flex flex-row items-center"&gt;
                &lt;vs-radio v-model="radios2" vs-name="radios2" id="paymentOption1" vs-value="paymentOption1" /&gt;
                &lt;label for="paymentOption1" class="flex flex-row items-center pointer"&gt;
                  &lt;img :src="visaCard" alt="visa" /&gt;
                  &lt;p&gt;&lt;span class="ml-4 md:ml-6"&gt;**** 1234&lt;/span&gt;&lt;span class="ml-4 md:ml-6"&gt;01/24&lt;/span&gt;&lt;/p&gt;
                &lt;/label&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="flex flex-row justify-between py-4"&gt;
              &lt;div class="flex flex-row items-center"&gt;
                &lt;vs-radio v-model="radios2" vs-name="radios2" id="paymentOption2" vs-value="paymentOption2" /&gt;
                &lt;label for="paymentOption2" class="flex flex-row items-center pointer"&gt;
                  &lt;img :src="visaCard" alt="visa" /&gt;
                  &lt;p&gt;&lt;span class="ml-4 md:ml-6"&gt;**** 1234&lt;/span&gt;&lt;span class="ml-4 md:ml-6"&gt;01/24&lt;/span&gt;&lt;/p&gt;
                &lt;/label&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="flex flex-row justify-between py-4"&gt;
              &lt;div class="flex flex-row items-center"&gt;
                &lt;vs-radio v-model="radios2" vs-name="radios2" id="paymentOption3" vs-value="paymentOption3" /&gt;
                &lt;label for="paymentOption3" class="flex flex-row items-center pointer"&gt;
                  &lt;img :src="directDebitCard" alt="visa" /&gt;
                  &lt;p&gt;&lt;span class="ml-4 md:ml-6"&gt;Commonwealth Bank&lt;/span&gt;&lt;span class="ml-4 md:ml-6"&gt;**** 1234&lt;/span&gt;&lt;/p&gt;
                &lt;/label&gt;
              &lt;/div&gt;
            &lt;/div&gt;
          &lt;/div&gt;
          &lt;hr class="mb-6" /&gt;
          &lt;div class="text-center mb-4 pb-2"&gt;&lt;vs-button type="flat" class="mx-auto" @click="toggleExpand2" size="medium"&gt;Use another payment method&lt;/vs-button&gt;&lt;/div&gt;
        &lt;/div&gt;
        &lt;vs-button color="primary" size="large" class="block w-full max-w-400 mx-auto mb-4" @click="showSuccessMessage"&gt;Pay $2,100.00&lt;/vs-button&gt;
        &lt;vs-button type="flat" @click="toggleExpand1" size="medium" class="block mx-auto mb-2 md:mb-0"&gt;Cancel&lt;/vs-button&gt;
      &lt;/div&gt;
      &lt;div v-show="isExpanded2"&gt;
        &lt;h4&gt;Make a payment&lt;/h4&gt;
        &lt;vs-row class="pt-5" vs-w="12" vs-align="flex-start" vs-type="flex" vs-justify="center"&gt;
          &lt;vs-col vs-md="6" vs-sm="12" class="max-w-420"&gt;
            &lt;div class="payment-cards gap-4"&gt;
              &lt;div @click="selectTab(1)"&gt;
                &lt;vs-chip :class="`w-full tabs-chip ${ tabIndex == 1 ? 'chip-active' : '' }`" color="neutral-150" size="large" variant="outlined" type="multiline"&gt;
                  &lt;CardPayIcon class="chip-icon" /&gt;
                  &lt;div class="chip-text"&gt;Card&lt;/div&gt;
                &lt;/vs-chip&gt;
              &lt;/div&gt;
              &lt;div @click="selectTab(2)"&gt;
                &lt;vs-chip :class="`w-full tabs-chip ${ tabIndex == 2 ? 'chip-active' : '' }`" color="neutral-150" size="large" variant="outlined" type="multiline"&gt;
                  &lt;DirectDebitIcon class="chip-icon" /&gt;
                  &lt;div class="chip-text"&gt;Direct debit&lt;/div&gt;
                &lt;/vs-chip&gt;
              &lt;/div&gt;
              &lt;div @click="selectTab(3)"&gt;
                &lt;vs-chip :class="`w-full tabs-chip ${ tabIndex == 3 ? 'chip-active' : '' }`" color="neutral-150" size="large" variant="outlined" type="multiline"&gt;
                  &lt;BankTransferIcon class="chip-icon" /&gt;
                  &lt;div class="chip-text"&gt;Bank transfer&lt;/div&gt;
                &lt;/vs-chip&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;vs-divider class="my-8" /&gt;
            &lt;div class="flex flex-col gap-y-8" v-if="tabIndex == 1"&gt;
              &lt;div class="flex md:flex-row flex-wrap justify-center gap-x-3 gap-y-2"&gt;
                &lt;div class="text-center" :class="cardType === 'visa' || cardType === 'default' ? '' : 'opacity-30'"&gt;
                  &lt;img :src="visaCard" alt="visa" /&gt;
                  &lt;div class="text-xs font-light leading-tight"&gt;1.1%&lt;/div&gt;
                &lt;/div&gt;
                &lt;div class="text-center" :class="cardType === 'mastercard' || cardType === 'default' ? '' : 'opacity-30'"&gt;
                  &lt;img :src="masterCard" alt="mastercard" /&gt;
                  &lt;div class="text-xs font-light leading-tight"&gt;1.1%&lt;/div&gt;
                &lt;/div&gt;
                &lt;div class="text-center" :class="cardType === 'amex' || cardType === 'default' ? '' : 'opacity-30'"&gt;
                  &lt;img :src="amexCard" alt="amex" /&gt;
                  &lt;div class="text-xs font-light leading-tight"&gt;1.1%&lt;/div&gt;
                &lt;/div&gt;
              &lt;/div&gt;
              &lt;div class="w-full"&gt;&lt;vs-input label="Name on card" v-model="value1" :success="cardNameValid" icon="done" icon-after="true" icon-size="small" @change="checkFieldValidation('cardName')" /&gt;&lt;/div&gt;
              &lt;div class="w-full"&gt;
                &lt;div class="custom-input-field" :class="[!cardValid || !isCardAllowed || showCardNumberIsRequired ? 'hasError' : '', cardFocused ? 'isFocused' : '', cardSuccess ? 'isValid' : '']" &gt;
                  &lt;label for="number_div" class="input-label"&gt;Card number&lt;/label&gt;
                  &lt;div id="number_div" style="height: 28px"&gt;&lt;/div&gt;
                  &lt;vs-icon class="validation-icon" icon="done" icon-after="true" icon-size="small" /&gt;
                &lt;/div&gt;
                &lt;span class="span-text-validation-danger" v-if="!isCardAllowed && !showCardNumberIsRequired"&gt;&lt;span class="span-text-validation"&gt;This card type is not supported. Please try another card&lt;/span&gt;&lt;/span&gt;
                &lt;span class="span-text-validation-danger" v-if="showCardNumberIsRequired"&gt;&lt;span class="span-text-validation"&gt;Card number is required&lt;/span&gt;&lt;/span&gt;
                &lt;span class="span-text-validation-danger" v-if="!cardValid && !showCardNumberIsRequired && isCardAllowed"&gt;&lt;span class="span-text-validation"&gt;Invalid card number&lt;/span&gt;&lt;/span&gt;
              &lt;/div&gt;
              &lt;div class="flex flex-row gap-x-6"&gt;
                &lt;div class="w-1/2"&gt;
                  &lt;vs-input class="input-arial" :class="showExpDateIsRequired || showExpired ? 'hasError' : ''" label="Expiry date" placeholder="MM/YY" v-mask="'##/##'" v-model="value3" :success="dateSuccess" icon="done" icon-after="true" icon-size="small" @keyup.native="handleExpiryCardField" @blur="handleExpiryCardField1" /&gt;
                  &lt;span class="span-text-validation-danger" v-if="showExpDateIsRequired"&gt;&lt;span class="span-text-validation"&gt;Card expiry date is required&lt;/span&gt;&lt;/span&gt;
                  &lt;span class="span-text-validation-danger" v-else-if="showExpired"&gt;&lt;span class="span-text-validation"&gt;Expiry date is invalid&lt;/span&gt;&lt;/span&gt;
                &lt;/div&gt;
                &lt;div class="w-1/2"&gt;
                  &lt;div class="custom-input-field" :class="[securityFocused ? 'isFocused' : '', showCvcIsRequired ? 'hasError' : '', cvvSuccess ? 'isValid' : '', isInvalidCvv ? 'hasError' : '' ]"&gt;
                    &lt;label class="input-label"&gt;
                      Security code 
                      &lt;popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '-55px, 2px' }}}"&gt;
                        &lt;a href="#" slot="reference" class="top"&gt;&lt;ToolTipIcon width="12px" height="12px" class="vs-icon" /&gt;&lt;/a&gt;
                        &lt;div class="popper py-2 px-4 text-center" v-if="cardType == 'amex'"&gt;
                          &lt;p class="text-primary"&gt;The 4 digits on the front of your card.&lt;/p&gt;
                          &lt;div class="flex flex-row gap-x-4"&gt;
                            &lt;div&gt;&lt;img :src="amex1" alt="" /&gt;&lt;/div&gt;
                          &lt;/div&gt;
                        &lt;/div&gt;
                        &lt;div class="popper py-2 px-4" v-else-if="['visa', 'mastercard'].includes(cardType)"&gt;
                          &lt;p class="text-primary"&gt;The 3 digits on the back of your card.&lt;/p&gt;
                          &lt;div class="flex flex-row gap-x-4"&gt;
                            &lt;div&gt;&lt;img :src="general1" alt="" /&gt;&lt;/div&gt;
                          &lt;/div&gt;
                        &lt;/div&gt;
                        &lt;div class="popper py-2 px-4 text-center" v-else&gt;
                          &lt;p&gt;For American Express (left), the 4 digits on the front of your card.&lt;/p&gt;
                          &lt;p&gt;For all other cards (right), the 3 digits on the back of your card.&lt;/p&gt;
                          &lt;div class="flex flex-row pt-2 gap-x-4"&gt;
                            &lt;div&gt;&lt;img :src="amex" alt="" /&gt;&lt;/div&gt;
                            &lt;div&gt;&lt;img :src="general" alt="" /&gt;&lt;/div&gt;
                          &lt;/div&gt;
                        &lt;/div&gt;
                      &lt;/popper&gt;
                    &lt;/label&gt;
                    &lt;div id="cvv_div" style="height: 28px"&gt;&lt;/div&gt;
                    &lt;vs-icon class="validation-icon" icon="done" icon-after="true" icon-size="small" /&gt;
                  &lt;/div&gt;
                  &lt;span class="span-text-validation-danger" v-if="showCvcIsRequired"&gt;&lt;span class="span-text-validation"&gt;Security code is required&lt;/span&gt;&lt;/span&gt;
                  &lt;span class="span-text-validation-danger" v-else-if="isInvalidCvv"&gt;&lt;span class="span-text-validation"&gt;Security code is invalid&lt;/span&gt;&lt;/span&gt;
                &lt;/div&gt;
              &lt;/div&gt;
              &lt;div class="flex flex-row items-center -mt-2"&gt;
                &lt;vs-switch v-model="switch1" class="mr-4" /&gt;
                &lt;p class="text-sm"&gt;Make this my default payment method&lt;/p&gt;
              &lt;/div&gt;
              &lt;div class="flex flex-row items-start mb-4"&gt;
                &lt;vs-checkbox v-model="acceptTerms" name="acceptTerms" :checked="acceptTerms" id="terms"&gt; &lt;/vs-checkbox&gt;
                &lt;label for="terms" class="ancillary cursor-pointer"&gt;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, nibh fames vulputate cursus ornare consectetur. Molestie parturient fermentum, quisque eget interdum amet magna.&lt;/label&gt;
              &lt;/div&gt;
              &lt;div class="pb-2 md:pb-0"&gt;
                &lt;vs-button :disabled="!acceptTerms" color="primary" class="w-full mb-4" size="large" @click="showSuccessMessage"&gt;Pay $2,100.00&lt;/vs-button&gt;
                &lt;vs-button type="flat" @click="toggleExpand2" size="medium" class="block mx-auto"&gt;Cancel&lt;/vs-button&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="flex flex-col gap-y-8" v-if="tabIndex == 2"&gt;
              &lt;div class="w-full"&gt;&lt;vs-input label="Account name" v-model="value5" :success="accountNameValid" icon="done" icon-after="true" icon-size="small" @change="checkFieldValidation('accountName')" /&gt;&lt;/div&gt;
              &lt;div class="flex flex-row gap-x-6"&gt;
                &lt;div class="w-2/5"&gt;
                  &lt;vs-input 
                    label="BSB" 
                    :class="!bsbError ? 'hasError' : ''"
                    placeholder="000 - 000"
                    v-mask="'###-###'"
                    v-model="value6"
                    :success="bsbSuccess"
                    icon="done"
                    icon-after="true"
                    icon-size="small"
                    @blur="checkBsbValidity"
                    @keyup="handleBsb"
                  /&gt;
                  &lt;span class="span-text-validation-danger" v-if="!showBsbValid"&gt;&lt;span class="span-text-validation"&gt;Invalid BSB&lt;/span&gt;&lt;/span&gt;
                &lt;/div&gt;
                &lt;div class="w-3/5"&gt;&lt;vs-input label="Account number" v-model="value7" :success="accountNumberValid" icon="done" icon-after="true" icon-size="small" @change="checkFieldValidation('accountNumber')" /&gt;&lt;/div&gt;
              &lt;/div&gt;
              &lt;div class="flex flex-row items-center -mt-2"&gt;
                &lt;vs-switch v-model="switch1" class="mr-4" /&gt;
                &lt;p class="text-sm"&gt;Make this my default payment method&lt;/p&gt;
              &lt;/div&gt;
              &lt;div class="flex flex-row items-start mb-4"&gt;
                &lt;vs-checkbox v-model="acceptTerms" name="acceptTerms" :checked="acceptTerms" id="terms"&gt; &lt;/vs-checkbox&gt;
                &lt;label for="terms" class="ancillary cursor-pointer"&gt;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, nibh fames vulputate cursus ornare consectetur. Molestie parturient fermentum, quisque eget interdum amet magna.&lt;/label&gt;
              &lt;/div&gt;
              &lt;div class="pb-2 md:pb-0"&gt;
                &lt;vs-button :disabled="!acceptTerms" color="primary" class="w-full mb-4" size="large" @click="showSuccessMessage"&gt;Pay $2,100.00&lt;/vs-button&gt;
                &lt;vs-button type="flat" @click="toggleExpand2" size="medium" class="block mx-auto"&gt;Cancel&lt;/vs-button&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="flex flex-col gap-y-8" v-if="tabIndex == 3"&gt;
              &lt;div&gt;
                &lt;div class="text-center mb-6 md:mb-5"&gt;
                  &lt;p class="text-sm"&gt;Transfer amount&lt;/p&gt;
                  &lt;h2&gt;$ 5,002.00&lt;/h2&gt;
                &lt;/div&gt;
                &lt;div class="flex flex-col gap-y-4 md:gap-y-3"&gt;
                  &lt;div class="flex flex-row items-center gap-x-6"&gt;
                    &lt;p class="text-sm text-right w-1/2"&gt;Account name&lt;/p&gt;
                    &lt;p class="text-primary text-left w-1/2"&gt;Rello Finance Pty Ltd&lt;/p&gt;
                  &lt;/div&gt;
                  &lt;div class="flex flex-row items-center gap-x-6"&gt;
                    &lt;p class="text-sm text-right w-1/2"&gt;B2B&lt;/p&gt;
                    &lt;p class="text-primary text-left w-1/2"&gt;062 - 692&lt;/p&gt;
                  &lt;/div&gt;
                  &lt;div class="flex flex-row items-center gap-x-6"&gt;
                    &lt;p class="text-sm text-right w-1/2"&gt;Account number&lt;/p&gt;
                    &lt;p class="text-primary text-left w-1/2"&gt;3778 5303&lt;/p&gt;
                  &lt;/div&gt;
                  &lt;div class="flex flex-row items-center gap-x-6"&gt;
                    &lt;p class="text-sm text-right w-1/2"&gt;Reference&lt;/p&gt;
                    &lt;p class="text-primary text-left w-1/2"&gt;
                      PR-1234-5678
                      &lt;vs-icon class="ml-3 pointer icon-gray" icon="content_copy" icon-size="small" /&gt;
                    &lt;/p&gt;
                  &lt;/div&gt;
                  &lt;vs-divider class="my-8 max-w-xs mx-auto" /&gt;
                &lt;/div&gt;
                &lt;p class="text-body mb-8"&gt;You’ll get a confirmation email and/or text message once your bank transfer is registered. This can take up to 3 business days.&lt;/p&gt;
                &lt;h3 class="text-body text-center"&gt;Thank you!&lt;/h3&gt;
              &lt;/div&gt;
              &lt;div class="pb-2 md:pb-0"&gt;
                &lt;vs-button color="primary" class="w-full" size="large" @click="toggleExpand2"&gt;Close&lt;/vs-button&gt;
              &lt;/div&gt;
            &lt;/div&gt;
          &lt;/vs-col&gt;
        &lt;/vs-row&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;script&gt;
    import Prism from "vue-prism-component";
    import VueAccordion from "./VueAccordion";
    import { mask } from 'vue-the-mask';
    import PaypalIcon from "../../components/design-sys-components/icons/PaypalIcon";
    import AliPayIcon from "../../components/design-sys-components/icons/AliPayIcon";
    import ApplePayIcon from "../../components/design-sys-components/icons/ApplePayIcon";
    import CardPayIcon from "../../components/design-sys-components/icons/CardPayIcon";
    import DirectDebitIcon from "../../components/design-sys-components/icons/DirectDebitIcon";
    import ToolTipIcon from "../../components/design-sys-components/icons/ToolTipIcon";
    import BankTransferIcon from "../../components/design-sys-components/icons/BankTransferIcon";
    // Third party import
    import Popper from 'vue-popperjs';
    import moment from "moment";

    // Credit Cards
    const visaCard = require("@/assets/images/cards/visa.svg");
    const masterCard = require("@/assets/images/cards/mastercard.svg");
    const amexCard = require("@/assets/images/cards/amex.svg");
    const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
    const unionpayCard = require("@/assets/images/cards/unionpay.svg");
    const directDebitCard = require("@/assets/images/cards/directdebit.svg");
    // Tooltip images
    const amex = require("@/assets/images/cards/amex.png");
    const amex1 = require("@/assets/images/cards/amex-1.png");
    const general = require("@/assets/images/cards/general.png");
    const general1 = require("@/assets/images/cards/general-1.png");

    export default {
      data() {
        return {
          value1: '',
          value2: '',
          value3: '',
          value4: '',
          value5: '',
          value6: '',
          value7: '',
          visaCard: visaCard,
          masterCard: masterCard,
          amexCard: amexCard,
          dinersClubCard: dinersClubCard,
          unionpayCard: unionpayCard,
          directDebitCard: directDebitCard,
          amex: amex,
          amex1: amex1,
          general: general,
          general1: general1,
          tabIndex: 1,
          till_public_key: "ZVbc1fL9SYAiHe3hyZvg",
          paymentJSObject: undefined,
          card_status: {},
          card_options: [
            { card: { slug: "visa" } },
            { card: { slug: "mastercard" } },
            { card: { slug: "amex" } },
          ],
          isCardAllowed: true,
          showCardNumberIsRequired: false,
          showCvcIsRequired: false,
          dateSuccess: false,
          dateError: true,
          showExpDateIsRequired: false,
          showExpired: false,
          showBsbValid: true,
          bsbSuccess: false,
          bsbError: true,
          cardType: "default",
          cardValid: true,
          cardValidity: true,
          cardFocused: false,
          securityFocused: false,
          cardSuccess: false,
          cvvSuccess: false,
          cardNameValid: false,
          accountNameValid: false,
          accountNumberValid: false,
          isInvalidCvv: false,
          isExpanded: false,
          selectedPayment: false,
          isExpanded1: false,
          isExpanded2: false,
          acceptTerms: false,
          radios2: 'paymentOption1',
          switch1: false,
          selectedPayment1: 'single',
        }
      },
      components: {
        Prism,
        VueAccordion,
        Popper,
        PaypalIcon,
        AliPayIcon,
        ApplePayIcon,
        CardPayIcon,
        DirectDebitIcon,
        ToolTipIcon,
        BankTransferIcon,
      },
      directives: {
        mask
      },
      methods: {
        selectTab(index) {
          this.tabIndex = index;
          if (this.tabIndex == 1) {
            this.paymentJSObject = undefined;
            this.initializePaymentObject();
            this.cardSuccess = false;
            this.cvvSuccess = false;
            this.cardNameValid = false;
            this.isInvalidCvv = false;
            this.dateSuccess = false;
            this.value1 = '';
            this.value3 = '';
            this.cardType = 'default';
          } else {
            this.bsbSuccess = false;
            this.accountNameValid = false;
            this.accountNumberValid = false;
            this.value5 = '';
            this.value6 = '';
            this.value7 = '';
          }
        },
        async initializePaymentObject() {
          try {
            if (this.paymentJSObject != undefined) {
              delete this.paymentJSObject;
              return;
            } else {
              this.paymentJSObject = new PaymentJs();
              await this.paymentJSObject.init(this.till_public_key, "number_div", "cvv_div", this.setPaymentStyle);
            }
          } catch(e) {
            return;
          }
        },

        setPaymentStyle(payment) {
          payment.setNumberStyle({
            "width": "100%",
            "border": "none",
            "box-sizing": "border-box",
            "height": "28px",
            "outline": 0,
            "font-family": "Arial",
            "font-size": "14px",
            "color": "#0D0F11",
            "padding": "0 8px",
            "background": "#FBFBFB",
          });
          payment.setCvvStyle({
            "width": "100%",
            "border": "none",
            "outline": 0,
            "font-family": "Arial",
            "box-sizing": "border-box",
            "height": "28px",
            "font-size": "14px",
            "color": "#0D0F11",
            "padding": "0 8px",
            "background": "#FBFBFB",
          });
          payment.numberOn("input", this.checkCardValidity);
          payment.cvvOn("input", this.checkCvvValidity);
          payment.setNumberPlaceholder("0000 0000 0000 0000");
          payment.numberOn('focus', this.handleCardFocus);
          payment.cvvOn('focus', this.handleCvvFocus);
          payment.numberOn('blur', this.handleCardBlur);
          payment.cvvOn('blur', this.handleCvvBlur);
        },

        checkCardValidity(data) {
          this.card_status = data;
          this.cardValid = true;
          if (this.card_status.numberLength == 0) {
            this.showCardNumberIsRequired = true;
            this.cardType = "";
          }

          if (this.card_status.numberLength > 0) {
            this.showCardNumberIsRequired = false;
          }

          let checkCardAllowedStatus = this.card_options.find((card_option) => {
            return card_option.card.slug === data.cardType;
          });

          if (data.cardType) {
            this.cardType = data.cardType;
            this.cardValidity = data.validNumber;
            this.cardSuccess = data.validNumber && checkCardAllowedStatus;
            if (!checkCardAllowedStatus) {
              this.isCardAllowed = false;
            } else {
              this.isCardAllowed = true;
            }
          } else {
            this.cardSuccess = false;
          }
        },

        handleCardBlur() {
          this.cardValid = this.cardValidity;
          this.cardFocused = false;
        },

        handleCardFocus() {
          this.cardFocused = true;
        },

        handleCvvBlur() {
          this.securityFocused = false;
        },

        handleCvvFocus() {
          this.securityFocused = true;
        },

        checkCvvValidity(data) {
          this.card_status = data;
          this.cvvSuccess = data.validCvv;
          if (this.card_status.cvvLength == 0) {
            this.showCvcIsRequired = true;
          }
          if (this.card_status.cvvLength > 0) {
            this.showCvcIsRequired = false;
          }
          this.isInvalidCvv = !data.validCvv;
        },

        handleExpiryCardField(event) {
          const value = event.target.value;
          this.showExpDateIsRequired = (value.length === 0);
          this.checkExpiryDate(event);
          if (value.length < 5) {
            this.dateSuccess = false;
            return;
          }
          this.showExpired = this.checkExpiryDate(event);
          this.dateSuccess = (value.length == 5 && !this.showExpired);
        },
        handleExpiryCardField1(event) {
          if (event.target.value.length < 5) {
            this.showExpired = true;
            this.dateSuccess = false;
          }
        },

        checkExpiryDate(event) {
          const expiryDate = this.value3.split("/");
          const firstValue = expiryDate[0].charAt(0);
          if (firstValue >= 2) {
            this.value3 = "0" + firstValue;
          }
          if (event.key == '/' && this.value3.length == 1) {
            this.value3 = "0" + firstValue + "/";
          }
          if (expiryDate.length == 2) {
            const fullDate = `20${expiryDate[1]}-${expiryDate[0]}`;
            if (moment(fullDate).isSame(moment(new Date()).format("YYYY-MM"))) {
              return false;
            }
            return !moment(fullDate).isAfter(new Date());
          }
          return false;
        },

        handleBsb() {
          const bsbData = this.value6.split("-");
          if (bsbData.length == 2 && bsbData[1].length == 3) {
            this.showBsbValid = true;
            this.bsbSuccess = this.showBsbValid;
          }
        },

        checkBsbValidity() {
          const bsbData = this.value6.split("-");
          this.showBsbValid = (bsbData[0].length == 3 && bsbData[1].length == 3);
          this.bsbSuccess = this.showBsbValid;
          this.bsbError = this.showBsbValid;
        },

        checkFieldValidation(field) {
          if (field == 'cardName') {
            this.cardNameValid = this.value1.length > 1;
          } else if (field == 'accountName') {
            this.accountNameValid = this.value5.length > 1;
          } else if (field == 'accountNumber') {
            this.accountNumberValid = this.value7.length > 1;
          }
        },
        toggleExpand() {
          this.isExpanded = !this.isExpanded;
        },
        toggleExpand1() {
          this.isExpanded1 = !this.isExpanded1;
        },
        toggleExpand2() {
          this.isExpanded2 = !this.isExpanded2;
        },
        showSuccessMessage(title = 'Success', message = 'Success') {
          this.$toast.success(<div class="container"><h4>Payment processed</h4><p>Your payment of $2,100.00 was processed successfully.</p></div>, {
            position: "bottom-left",
            timeout: 5000,
            closeOnClick: false,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: false,
            draggablePercent: 0.1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: {
              iconClass: "material-icons",
              iconChildren: "check_circle_outline",
              iconTag: "span"
            },
            rtl: false,
            message: message,
          });
        },
        showErrorMessage(title = 'Error', message = 'Error') {
          this.$toast.error(<div class="container"><h4>Payment failed</h4><p>&lt;Error code - 000&gt;</p></div>, {
            position: "bottom-left",
            timeout: false,
            closeOnClick: false,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: false,
            draggablePercent: 0.1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: {
              iconClass: "material-icons-outlined",
              iconChildren: "dangerous",
              iconTag: "span"
            },
            rtl: false,
            message: message,
          });
        },
      },
      mounted() {
        this.initializePaymentObject();
      },
      watch: {
        value6: function(val) {
          if (val.length == 0) {
            this.showBsbValid = true;
          }
        }
      },
    }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-10 mb-4 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="multiple-payments">Multiple payment (with error message on making payment)</h6>
        </template>
        <template v-slot:content>
          <div class="rounded-t-2xl md-rounded-t-3xl bg-container-80 p-4">
            <div class="flex flex-row justify-between">
              <div>
                <p class="font-light text-sm text-body">
                  Amount paid
                </p>
                <h3>$ 2,100.00</h3>
              </div>
              <div class="flex flex-col text-right">
                <p class="font-light text-sm text-body">Remaining balance</p>
                <h3>$ 6,300.00</h3>
              </div>
            </div>
            <div class="relative">
              <vs-progress :height="12" :percent="30" color="success-300" class="mb-2"></vs-progress>
            </div>
          </div>
          <div class="bg-container-60 p-4 md:p-6 rounded-b-2xl md-rounded-b-3xl">
            <vs-button color="primary" size="large" class="block w-full max-w-400 mx-auto" @click="toggleExpand" v-if="!isExpanded">Go to payment</vs-button>
            <div v-if="isExpanded && !isExpanded1 && !isExpanded2" class="md:px-2">
              <div>
                <h4>Make a payment</h4>
              </div>
              <div class="px-4 py-2 md:py-0 md:mt-5 mb-5 md:mb-10">
                <div class="border-b-list">
                  <div class="flex flex-row items-center justify-between py-4">
                    <vs-radio v-model="selectedPayment1" vs-name="radios3" vs-value="single">Single payment</vs-radio>
                    <p>$2,100.00</p>
                  </div>
                  <div class="flex flex-row items-center justify-between py-4">
                    <vs-radio v-model="selectedPayment1" vs-name="radios3" vs-value="remaining">Remaining balance</vs-radio>
                    <p>$6,300.00</p>
                  </div>
                </div>
              </div>
              <vs-button color="primary" :disabled="!selectedPayment1" size="large" class="block w-full max-w-400 mx-auto mb-4" @click="toggleExpand1">Go to payment</vs-button>
              <vs-button type="flat" @click="toggleExpand" size="medium" class="block mx-auto mb-2 md:mb-0">Cancel</vs-button>
            </div>
            <div v-show="isExpanded1 && !isExpanded2" class="md:px-2">
              <div>
                <h4>Make a payment</h4>
              </div>
              -- PAYMENT OPTION PLACEHOLDER --
              <vs-button color="primary" size="large" class="block w-full max-w-400 mx-auto mb-4" @click="showErrorMessage">Pay $2,100.00</vs-button>
              <vs-button type="flat" @click="toggleExpand1" size="medium" class="block mx-auto mb-2 md:mb-0">Cancel</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="px-4 py-2 md:py-0 md:mt-5 mb-5 md:mb-10"&gt;
      &lt;div class="border-b-list"&gt;
        &lt;div class="flex flex-row items-center justify-between py-4"&gt;
          &lt;vs-radio v-model="selectedPayment1" vs-name="radios3" vs-value="single"&gt;Single payment&lt;/vs-radio&gt;
          &lt;p&gt;$2,100.00&lt;/p&gt;
        &lt;/div&gt;
        &lt;div class="flex flex-row items-center justify-between py-4"&gt;
          &lt;vs-radio v-model="selectedPayment1" vs-name="radios3" vs-value="remaining"&gt;Remaining balance&lt;/vs-radio&gt;
          &lt;p&gt;$6,300.00&lt;/p&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-10 mb-4 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="multiple-payments-overdue">Multiple payment with one overdue</h6>
        </template>
        <template v-slot:content>
          <div class="rounded-t-2xl md-rounded-t-3xl bg-container-80 p-4">
            <div class="flex flex-row justify-between">
              <div>
                <p class="font-light text-sm text-body">
                  Amount paid
                </p>
                <h3>$ 2,100.00</h3>
              </div>
              <div class="flex flex-col text-right">
                <p class="font-light text-sm text-body">Remaining balance</p>
                <h3>$ 6,300.00</h3>
              </div>
            </div>
            <div class="relative">
              <vs-progress :height="12" :percent="30" color="success-300" class="mb-2"></vs-progress>
            </div>
          </div>
          <div class="bg-container-60 p-4 md:p-6 rounded-b-2xl md-rounded-b-3xl">
            <vs-button color="primary" size="large" class="block w-full max-w-400 mx-auto" @click="toggleExpand" v-if="!isExpanded">Go to payment</vs-button>
            <div v-if="isExpanded && !isExpanded1 && !isExpanded2" class="md:px-2">
              <div>
                <h4>Make a payment</h4>
              </div>
              <div class="px-4 py-2 md:py-0 md:mt-5 mb-5 md:mb-10">
                <div class="border-b-list">
                  <div class="flex flex-row items-center justify-between py-4">
                    <vs-radio v-model="selectedPayment3" vs-name="radios4" vs-value="single">Single payment</vs-radio>
                    <p>$2,100.00</p>
                  </div>
                  <div class="flex flex-row items-center justify-between py-4">
                    <vs-radio v-model="selectedPayment3" vs-name="radios4" vs-value="remaining">Remaining balance</vs-radio>
                    <p>$6,300.00</p>
                  </div>
                  <div class="flex flex-row items-center justify-between py-4">
                    <vs-radio v-model="selectedPayment3" vs-name="radios4" vs-value="overdue">Overdue amount</vs-radio>
                    <p>$2,100.00</p>
                  </div>
                </div>
              </div>
              <vs-button color="primary" :disabled="!selectedPayment1" size="large" class="block w-full max-w-400 mx-auto mb-4" @click="toggleExpand1">Go to payment</vs-button>
              <vs-button type="flat" @click="toggleExpand" size="medium" class="block mx-auto mb-2 md:mb-0">Cancel</vs-button>
            </div>
            <div v-show="isExpanded1 && !isExpanded2" class="md:px-2">
              <div>
                <h4>Make a payment</h4>
              </div>
              -- PAYMENT OPTION PLACEHOLDER --
              <vs-button color="primary" size="large" class="block w-full max-w-400 mx-auto mb-4" @click="showSuccessMessage">Pay $2,100.00</vs-button>
              <vs-button type="flat" @click="toggleExpand1" size="medium" class="block mx-auto mb-2 md:mb-0">Cancel</vs-button>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="px-4 py-2 md:py-0 md:mt-5 mb-5 md:mb-10"&gt;
      &lt;div class="border-b-list"&gt;
        &lt;div class="flex flex-row items-center justify-between py-4"&gt;
          &lt;vs-radio v-model="selectedPayment3" vs-name="radios4" vs-value="single"&gt;Single payment&lt;/vs-radio&gt;
          &lt;p&gt;$2,100.00&lt;/p&gt;
        &lt;/div&gt;
        &lt;div class="flex flex-row items-center justify-between py-4"&gt;
          &lt;vs-radio v-model="selectedPayment3" vs-name="radios4" vs-value="remaining"&gt;Remaining balance&lt;/vs-radio&gt;
          &lt;p&gt;$6,300.00&lt;/p&gt;
        &lt;/div&gt;
        &lt;div class="flex flex-row items-center justify-between py-4"&gt;
          &lt;vs-radio v-model="selectedPayment3" vs-name="radios4" vs-value="overdue"&gt;Overdue amount&lt;/vs-radio&gt;
          &lt;p&gt;$2,100.00&lt;/p&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Payment buttons starts -->
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";
  import { mask } from 'vue-the-mask';
  import PaypalIcon from "../../components/design-sys-components/icons/PaypalIcon";
  import AliPayIcon from "../../components/design-sys-components/icons/AliPayIcon";
  import ApplePayIcon from "../../components/design-sys-components/icons/ApplePayIcon";
  import CardPayIcon from "../../components/design-sys-components/icons/CardPayIcon";
  import DirectDebitIcon from "../../components/design-sys-components/icons/DirectDebitIcon";
  import ToolTipIcon from "../../components/design-sys-components/icons/ToolTipIcon";
  import BankTransferIcon from "../../components/design-sys-components/icons/BankTransferIcon";
  // Third party import
  import Popper from 'vue-popperjs';
  import moment from "moment";

  // Credit Cards
  const visaCard = require("@/assets/images/cards/visa.svg");
  const masterCard = require("@/assets/images/cards/mastercard.svg");
  const amexCard = require("@/assets/images/cards/amex.svg");
  const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
  const unionpayCard = require("@/assets/images/cards/unionpay.svg");
  const directDebitCard = require("@/assets/images/cards/directdebit.svg");
  // Tooltip images
  const amex = require("@/assets/images/cards/amex.png");
  const amex1 = require("@/assets/images/cards/amex-1.png");
  const general = require("@/assets/images/cards/general.png");
  const general1 = require("@/assets/images/cards/general-1.png");

  export default {
    data() {
      return {
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
        value6: '',
        value7: '',
        visaCard: visaCard,
        masterCard: masterCard,
        amexCard: amexCard,
        dinersClubCard: dinersClubCard,
        unionpayCard: unionpayCard,
        directDebitCard: directDebitCard,
        amex: amex,
        amex1: amex1,
        general: general,
        general1: general1,
        tabIndex: 1,
        till_public_key: "ZVbc1fL9SYAiHe3hyZvg",
        paymentJSObject: undefined,
        card_status: {},
        card_options: [
          { card: { slug: "visa" } },
          { card: { slug: "mastercard" } },
          { card: { slug: "amex" } },
        ],
        isCardAllowed: true,
        showCardNumberIsRequired: false,
        showCvcIsRequired: false,
        dateSuccess: false,
        dateError: true,
        showExpDateIsRequired: false,
        showExpired: false,
        showBsbValid: true,
        bsbSuccess: false,
        bsbError: true,
        cardType: "default",
        cardValid: true,
        cardValidity: true,
        cardFocused: false,
        securityFocused: false,
        cardSuccess: false,
        cvvSuccess: false,
        cardNameValid: false,
        accountNameValid: false,
        accountNumberValid: false,
        isInvalidCvv: false,
        isExpanded: false,
        selectedPayment: false,
        isExpanded1: false,
        isExpanded2: false,
        acceptTerms: false,
        radios2: 'paymentOption1',
        switch1: false,
        selectedPayment1: 'single',
      }
    },
    components: {
      Prism,
      VueAccordion,
      Popper,
      PaypalIcon,
      AliPayIcon,
      ApplePayIcon,
      CardPayIcon,
      DirectDebitIcon,
      ToolTipIcon,
      BankTransferIcon,
    },
    directives: {
      mask
    },
    methods: {
      selectTab(index) {
        this.tabIndex = index;
        if (this.tabIndex == 1) {
          this.paymentJSObject = undefined;
          this.initializePaymentObject();
          this.cardSuccess = false;
          this.cvvSuccess = false;
          this.cardNameValid = false;
          this.isInvalidCvv = false;
          this.dateSuccess = false;
          this.value1 = '';
          this.value3 = '';
          this.cardType = 'default';
        } else {
          this.bsbSuccess = false;
          this.accountNameValid = false;
          this.accountNumberValid = false;
          this.value5 = '';
          this.value6 = '';
          this.value7 = '';
        }
      },
      async initializePaymentObject() {
        try {
          if (this.paymentJSObject != undefined) {
            delete this.paymentJSObject;
            return;
          } else {
            this.paymentJSObject = new PaymentJs();
            await this.paymentJSObject.init(this.till_public_key, "number_div", "cvv_div", this.setPaymentStyle);
          }
        } catch(e) {
          return;
        }
      },

      setPaymentStyle(payment) {
        payment.setNumberStyle({
          "width": "100%",
          "border": "none",
          "box-sizing": "border-box",
          "height": "28px",
          "outline": 0,
          "font-family": "Arial",
          "font-size": "14px",
          "color": "#0D0F11",
          "padding": "0 8px",
          "background": "#FBFBFB",
        });
        payment.setCvvStyle({
          "width": "100%",
          "border": "none",
          "outline": 0,
          "font-family": "Arial",
          "box-sizing": "border-box",
          "height": "28px",
          "font-size": "14px",
          "color": "#0D0F11",
          "padding": "0 8px",
          "background": "#FBFBFB",
        });
        payment.numberOn("input", this.checkCardValidity);
        payment.cvvOn("input", this.checkCvvValidity);
        payment.setNumberPlaceholder("0000 0000 0000 0000");
        payment.numberOn('focus', this.handleCardFocus);
        payment.cvvOn('focus', this.handleCvvFocus);
        payment.numberOn('blur', this.handleCardBlur);
        payment.cvvOn('blur', this.handleCvvBlur);
      },

      checkCardValidity(data) {
        this.card_status = data;
        this.cardValid = true;
        if (this.card_status.numberLength == 0) {
          this.showCardNumberIsRequired = true;
          this.cardType = "";
        }

        if (this.card_status.numberLength > 0) {
          this.showCardNumberIsRequired = false;
        }

        let checkCardAllowedStatus = this.card_options.find((card_option) => {
          return card_option.card.slug === data.cardType;
        });

        if (data.cardType) {
          this.cardType = data.cardType;
          this.cardValidity = data.validNumber;
          this.cardSuccess = data.validNumber && checkCardAllowedStatus;
          if (!checkCardAllowedStatus) {
            this.isCardAllowed = false;
          } else {
            this.isCardAllowed = true;
          }
        } else {
          this.cardSuccess = false;
        }
      },

      handleCardBlur() {
        this.cardValid = this.cardValidity;
        this.cardFocused = false;
      },

      handleCardFocus() {
        this.cardFocused = true;
      },

      handleCvvBlur() {
        this.securityFocused = false;
      },

      handleCvvFocus() {
        this.securityFocused = true;
      },

      checkCvvValidity(data) {
        this.card_status = data;
        this.cvvSuccess = data.validCvv;
        if (this.card_status.cvvLength == 0) {
          this.showCvcIsRequired = true;
        }
        if (this.card_status.cvvLength > 0) {
          this.showCvcIsRequired = false;
        }
        this.isInvalidCvv = !data.validCvv;
      },

      handleExpiryCardField(event) {
        const value = event.target.value;
        this.showExpDateIsRequired = (value.length === 0);
        this.checkExpiryDate(event);
        if (value.length < 5) {
          this.dateSuccess = false;
          return;
        }
        this.showExpired = this.checkExpiryDate(event);
        this.dateSuccess = (value.length == 5 && !this.showExpired);
      },
      handleExpiryCardField1(event) {
        if (event.target.value.length < 5) {
          this.showExpired = true;
          this.dateSuccess = false;
        }
      },

      checkExpiryDate(event) {
        const expiryDate = this.value3.split("/");
        const firstValue = expiryDate[0].charAt(0);
        if (firstValue >= 2) {
          this.value3 = "0" + firstValue;
        }
        if (event.key == '/' && this.value3.length == 1) {
          this.value3 = "0" + firstValue + "/";
        }
        if (expiryDate.length == 2) {
          const fullDate = `20${expiryDate[1]}-${expiryDate[0]}`;
          if (moment(fullDate).isSame(moment(new Date()).format("YYYY-MM"))) {
            return false;
          }
          return !moment(fullDate).isAfter(new Date());
        }
        return false;
      },

      handleBsb() {
        const bsbData = this.value6.split("-");
        if (bsbData.length == 2 && bsbData[1].length == 3) {
          this.showBsbValid = true;
          this.bsbSuccess = this.showBsbValid;
        }
      },

      checkBsbValidity() {
        const bsbData = this.value6.split("-");
        this.showBsbValid = (bsbData[0].length == 3 && bsbData[1].length == 3);
        this.bsbSuccess = this.showBsbValid;
        this.bsbError = this.showBsbValid;
      },

      checkFieldValidation(field) {
        if (field == 'cardName') {
          this.cardNameValid = this.value1.length > 1;
        } else if (field == 'accountName') {
          this.accountNameValid = this.value5.length > 1;
        } else if (field == 'accountNumber') {
          this.accountNumberValid = this.value7.length > 1;
        }
      },
      toggleExpand() {
        this.isExpanded = !this.isExpanded;
      },
      toggleExpand1() {
        this.isExpanded1 = !this.isExpanded1;
      },
      toggleExpand2() {
        this.isExpanded2 = !this.isExpanded2;
      },
      showSuccessMessage(title = 'Success', message = 'Success') {
        this.$toast.success(<div class="container"><h4>Payment processed</h4><p>Your payment of $2,100.00 was processed successfully.</p></div>, {
          position: "bottom-left",
          timeout: 5000,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: "material-icons",
            iconChildren: "check_circle_outline",
            iconTag: "span"
          },
          rtl: false,
          message: message,
        });
      },
      showErrorMessage(title = 'Error', message = 'Error') {
        this.$toast.error(<div class="container"><h4>Payment failed</h4><p>&lt;Error code - 000&gt;</p></div>, {
          position: "bottom-left",
          timeout: false,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: "material-icons-outlined",
            iconChildren: "dangerous",
            iconTag: "span"
          },
          rtl: false,
          message: message,
        });
      },
    },
    mounted() {
      this.initializePaymentObject();
    },
    watch: {
      value6: function(val) {
        if (val.length == 0) {
          this.showBsbValid = true;
        }
      }
    },
  }
</script>