<template>
  <div>
    <h2 class="mb-5">Product Fields</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#field-types'">Field types</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#editable-fields'">Editable Fields</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#viewable-fields'">Viewable Fields</vs-button></div>
      </div>
    </vs-card>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="field-types">Field types</h6>
        </template>
        <template v-slot:content>
          <div class="flex flex-row flex-wrap items-center gap-6">
            <div class="flex flex-row flex-wrap gap-2 mb-3">
              <vs-chip color="secondary-500" size="x-small" variant="outlined">
                <vs-icon type="flat" icon="text_fields" icon-size="small" />
                Text
              </vs-chip>
              <vs-chip color="secondary-500" size="x-small" variant="outlined">
                <vs-icon type="flat" class="skew-x-15" icon="tag" icon-size="medium" />
                Number
              </vs-chip>
              <vs-chip color="secondary-500" size="x-small" variant="outlined">
                <vs-icon type="flat" icon="attach_money" icon-size="small" />
                Currency
              </vs-chip>
              <vs-chip color="secondary-500" size="x-small" variant="outlined">
                <vs-icon type="flat" class="material-icons-outlined" icon-size="tiny">scale</vs-icon>
                Unit
              </vs-chip>
              <vs-chip color="secondary-500" size="x-small" variant="outlined">
                <vs-icon type="flat" class="material-icons-outlined" icon-size="tiny">calendar_month</vs-icon>
                Date
              </vs-chip>
              <vs-chip color="secondary-500" size="x-small" variant="outlined">
                <vs-icon type="flat" icon="view_headline" icon-size="medium" />
                Long text
              </vs-chip>
              <vs-chip color="secondary-500" size="x-small" variant="outlined">
                <vs-icon type="flat" icon="list" icon-size="medium" />
                Dropdown
              </vs-chip>
              <vs-chip color="secondary-500" size="x-small" variant="outlined">
                <vs-icon type="flat" icon="file_present" icon-size="small" />
                File upload
              </vs-chip>
              <vs-chip color="secondary-500" size="x-small" variant="outlined">
                <vs-icon type="flat" icon="radio_button_checked" icon-size="small" />
                Radio button
              </vs-chip>
              <vs-chip color="secondary-500" size="x-small" variant="outlined">
              <vs-icon type="flat" class="material-icons-outlined" icon-size="small">check_box</vs-icon>
                Checkbox
              </vs-chip>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-chip color="secondary-500" size="x-small" variant="outlined"&gt;
      &lt;vs-icon type="flat" icon="text_fields" icon-size="small" /&gt;
      Text
    &lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-500" size="x-small" variant="outlined"&gt;
      &lt;vs-icon type="flat" class="skew-x-15" icon="tag" icon-size="medium" /&gt;
      Number
    &lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-500" size="x-small" variant="outlined"&gt;
      &lt;vs-icon type="flat" icon="attach_money" icon-size="small" /&gt;
      Currency
    &lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-500" size="x-small" variant="outlined"&gt;
      &lt;vs-icon type="flat" class="material-icons-outlined" icon-size="tiny"&gt;scale&lt;/vs-icon&gt;
      Unit
    &lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-500" size="x-small" variant="outlined"&gt;
      &lt;vs-icon type="flat" class="material-icons-outlined" icon-size="tiny"&gt;calendar_month&lt;/vs-icon&gt;
      Date
    &lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-500" size="x-small" variant="outlined"&gt;
      &lt;vs-icon type="flat" icon="view_headline" icon-size="medium" /&gt;
      Long text
    &lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-500" size="x-small" variant="outlined"&gt;
      &lt;vs-icon type="flat" icon="list" icon-size="medium" /&gt;
      Dropdown
    &lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-500" size="x-small" variant="outlined"&gt;
      &lt;vs-icon type="flat" icon="file_present" icon-size="small" /&gt;
      File upload
    &lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-500" size="x-small" variant="outlined"&gt;
      &lt;vs-icon type="flat" icon="radio_button_checked" icon-size="small" /&gt;
      Radio button
    &lt;/vs-chip&gt;
    &lt;vs-chip color="secondary-500" size="x-small" variant="outlined"&gt;
    &lt;vs-icon type="flat" class="material-icons-outlined" icon-size="small"&gt;check_box&lt;/vs-icon&gt;
      Checkbox
    &lt;/vs-chip&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="editable-fields">Editable Fields</h6>
        </template>
        <template v-slot:content>
          <div class="custom-collapsible-container flex flex-col gap-y-4">
            <div class="custom-collapsible" :class="{open: openedId === 1 && isOpened}">
              <div class="cc-header">
                <div class="cc-toolbar">
                  <h4 class="mb-3">Field name</h4>
                  <div class="cc-actions">
                    <vs-icon type="flat" icon="edit" icon-size="medium" />
                    <vs-icon type="flat" class="-mr-2" :icon="openedId === 1 && isOpened ? 'expand_less' : 'expand_more'" icon-size="large" @click.prevent="toggleCollapse(1)" />
                  </div>
                </div>
                <div class="flex flex-row flex-wrap gap-x-4 mb-3">
                  <vs-chip color="secondary-500" size="x-small" variant="outlined">
                    <vs-icon type="flat" icon="text_fields" icon-size="small" />
                    Text
                  </vs-chip>
                  <vs-chip color="neutral-250" size="x-small">
                    PF-0000-0000
                  </vs-chip>
                </div>
                <div>
                  <p class="text-sm">Product field description.....Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ut volutpat quam sit eu non lectus et.</p>
                </div>
              </div>
              <div class="cc-content" v-show="openedId === 1 && isOpened">
                <div class="grid md:grid-cols-3 gap-10 md:gap-4 sm-divide-y md-divide-x">
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">When sending requests</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by merchant</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">emergency</vs-icon>required to send request</li>
                      </ul>
                    </div>
                  </div>
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">On payment pages</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">emergency</vs-icon>required to submit payment</li>
                      </ul>
                    </div>
                  </div>
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">After request is accepted</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by merchant</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by merchant</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="custom-collapsible" :class="{open: openedId === 2 && isOpened}">
              <div class="cc-header">
                <div class="cc-toolbar">
                  <h4 class="mb-3">Field name</h4>
                  <div class="cc-actions">
                    <vs-icon type="flat" icon="edit" icon-size="medium" />
                    <vs-icon type="flat" class="-mr-2" :icon="openedId === 2 && isOpened ? 'expand_less' : 'expand_more'" icon-size="large" @click.prevent="toggleCollapse(2)" />
                  </div>
                </div>
                <div class="flex flex-row flex-wrap gap-x-4 mb-3">
                  <vs-chip color="secondary-500" size="x-small" variant="outlined">
                    <vs-icon type="flat" class="skew-x-15" icon="tag" icon-size="medium" />
                    Number
                  </vs-chip>
                  <vs-chip color="neutral-250" size="x-small">
                    PF-0000-0000
                  </vs-chip>
                </div>
                <div>
                  <p class="text-sm">Product field description.....Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ut volutpat quam sit eu non lectus et.</p>
                </div>
              </div>
              <div class="cc-content" v-show="openedId === 2 && isOpened">
                <div class="grid md:grid-cols-3 gap-10 md:gap-4 sm-divide-y md-divide-x">
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">When sending requests</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by merchant</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">emergency</vs-icon>required to send request</li>
                      </ul>
                    </div>
                  </div>
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">On payment pages</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">emergency</vs-icon>required to submit payment</li>
                      </ul>
                    </div>
                  </div>
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">After request is accepted</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by merchant</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by merchant</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="custom-collapsible" :class="{open: openedId === 3 && isOpened}">
              <div class="cc-header">
                <div class="cc-toolbar">
                  <h4 class="mb-3">Field name</h4>
                  <div class="cc-actions">
                    <vs-icon type="flat" icon="edit" icon-size="medium" />
                    <vs-icon type="flat" class="-mr-2" :icon="openedId === 3 && isOpened ? 'expand_less' : 'expand_more'" icon-size="large" @click.prevent="toggleCollapse(3)" />
                  </div>
                </div>
                <div class="flex flex-row flex-wrap gap-x-4 mb-3">
                  <vs-chip color="secondary-500" size="x-small" variant="outlined">
                    <vs-icon type="flat" icon="attach_money" icon-size="small" />
                    Currency
                  </vs-chip>
                  <vs-chip color="neutral-250" size="x-small">
                    PF-0000-0000
                  </vs-chip>
                </div>
                <div>
                  <p class="text-sm">Product field description.....Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ut volutpat quam sit eu non lectus et.</p>
                </div>
              </div>
              <div class="cc-content" v-show="openedId === 3 && isOpened">
                <div class="grid md:grid-cols-3 gap-10 md:gap-4 sm-divide-y md-divide-x">
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">When sending requests</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by merchant</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">emergency</vs-icon>required to send request</li>
                      </ul>
                    </div>
                  </div>
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">On payment pages</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">emergency</vs-icon>required to submit payment</li>
                      </ul>
                    </div>
                  </div>
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">After request is accepted</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by merchant</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by merchant</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="custom-collapsible-container flex flex-col gap-y-4"&gt;
      &lt;div class="custom-collapsible" :class="{open: openedId === 1 && isOpened}"&gt;
        &lt;div class="cc-header"&gt;
          &lt;div class="cc-toolbar"&gt;
            &lt;h4 class="mb-3"&gt;Field name&lt;/h4&gt;
            &lt;div class="cc-actions"&gt;
              &lt;vs-icon type="flat" icon="edit" icon-size="medium" /&gt;
              &lt;vs-icon type="flat" class="-mr-2" :icon="openedId === 1 && isOpened ? 'expand_less' : 'expand_more'" icon-size="large" @click.prevent="toggleCollapse(1)" /&gt;
            &lt;/div&gt;
          &lt;/div&gt;
          &lt;div class="flex flex-row flex-wrap gap-x-4 mb-3"&gt;
            &lt;vs-chip color="secondary-500" size="x-small" variant="outlined"&gt;
              &lt;vs-icon type="flat" icon="text_fields" icon-size="small" /&gt;
              Text
            &lt;/vs-chip&gt;
            &lt;vs-chip color="neutral-250" size="x-small"&gt;
              PF-0000-0000
            &lt;/vs-chip&gt;
          &lt;/div&gt;
          &lt;div&gt;
            &lt;p class="text-sm"&gt;Product field description.....Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ut volutpat quam sit eu non lectus et.&lt;/p&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;div class="cc-content" v-show="openedId === 1 && isOpened"&gt;
          &lt;div class="grid md:grid-cols-3 gap-10 md:gap-4 sm-divide-y md-divide-x"&gt;
            &lt;div class="md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3 text-center md:text-left"&gt;When sending requests&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by merchant&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;emergency&lt;/vs-icon&gt;required to send request&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3 text-center md:text-left"&gt;On payment pages&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;emergency&lt;/vs-icon&gt;required to submit payment&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3 text-center md:text-left"&gt;After request is accepted&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by merchant&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by merchant&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div class="custom-collapsible" :class="{open: openedId === 2 && isOpened}"&gt;
        &lt;div class="cc-header"&gt;
          &lt;div class="cc-toolbar"&gt;
            &lt;h4 class="mb-3"&gt;Field name&lt;/h4&gt;
            &lt;div class="cc-actions"&gt;
              &lt;vs-icon type="flat" icon="edit" icon-size="medium" /&gt;
              &lt;vs-icon type="flat" class="-mr-2" :icon="openedId === 2 && isOpened ? 'expand_less' : 'expand_more'" icon-size="large" @click.prevent="toggleCollapse(2)" /&gt;
            &lt;/div&gt;
          &lt;/div&gt;
          &lt;div class="flex flex-row flex-wrap gap-x-4 mb-3"&gt;
            &lt;vs-chip color="secondary-500" size="x-small" variant="outlined"&gt;
              &lt;vs-icon type="flat" class="skew-x-15" icon="tag" icon-size="medium" /&gt;
              Number
            &lt;/vs-chip&gt;
            &lt;vs-chip color="neutral-250" size="x-small"&gt;
              PF-0000-0000
            &lt;/vs-chip&gt;
          &lt;/div&gt;
          &lt;div&gt;
            &lt;p class="text-sm"&gt;Product field description.....Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ut volutpat quam sit eu non lectus et.&lt;/p&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;div class="cc-content" v-show="openedId === 2 && isOpened"&gt;
          &lt;div class="grid md:grid-cols-3 gap-10 md:gap-4 sm-divide-y md-divide-x"&gt;
            &lt;div class="md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3 text-center md:text-left"&gt;When sending requests&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by merchant&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;emergency&lt;/vs-icon&gt;required to send request&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3 text-center md:text-left"&gt;On payment pages&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;emergency&lt;/vs-icon&gt;required to submit payment&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3 text-center md:text-left"&gt;After request is accepted&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by merchant&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by merchant&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div class="custom-collapsible" :class="{open: openedId === 3 && isOpened}"&gt;
        &lt;div class="cc-header"&gt;
          &lt;div class="cc-toolbar"&gt;
            &lt;h4 class="mb-3"&gt;Field name&lt;/h4&gt;
            &lt;div class="cc-actions"&gt;
              &lt;vs-icon type="flat" icon="edit" icon-size="medium" /&gt;
              &lt;vs-icon type="flat" class="-mr-2" :icon="openedId === 3 && isOpened ? 'expand_less' : 'expand_more'" icon-size="large" @click.prevent="toggleCollapse(3)" /&gt;
            &lt;/div&gt;
          &lt;/div&gt;
          &lt;div class="flex flex-row flex-wrap gap-x-4 mb-3"&gt;
            &lt;vs-chip color="secondary-500" size="x-small" variant="outlined"&gt;
              &lt;vs-icon type="flat" icon="attach_money" icon-size="small" /&gt;
              Currency
            &lt;/vs-chip&gt;
            &lt;vs-chip color="neutral-250" size="x-small"&gt;
              PF-0000-0000
            &lt;/vs-chip&gt;
          &lt;/div&gt;
          &lt;div&gt;
            &lt;p class="text-sm"&gt;Product field description.....Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ut volutpat quam sit eu non lectus et.&lt;/p&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;div class="cc-content" v-show="openedId === 3 && isOpened"&gt;
          &lt;div class="grid md:grid-cols-3 gap-10 md:gap-4 sm-divide-y md-divide-x"&gt;
            &lt;div class="md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3 text-center md:text-left"&gt;When sending requests&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by merchant&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;emergency&lt;/vs-icon&gt;required to send request&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3 text-center md:text-left"&gt;On payment pages&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;emergency&lt;/vs-icon&gt;required to submit payment&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3 text-center md:text-left"&gt;After request is accepted&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by merchant&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by merchant&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            openedId: null,
            isOpened: false,
          }
        },
        methods: {
          toggleCollapse(id) {
            if (id == this.openedId) {
              this.isOpened = !this.isOpened;
            } else {
              this.openedId = id;
              this.isOpened = true;
            }
          },
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="viewable-fields">Viewable Fields</h6>
        </template>
        <template v-slot:content>
          <div class="custom-collapsible-container flex flex-col gap-y-4">
            <div class="custom-collapsible" :class="{open: openedId === 4 && isOpened}">
              <div class="cc-header">
                <div class="cc-toolbar">
                  <h4 class="mb-3">Field name</h4>
                  <div class="cc-actions">
                    <vs-button type="flat">View</vs-button>
                    <vs-icon type="flat" class="-mr-2" :icon="openedId === 4 && isOpened ? 'expand_less' : 'expand_more'" icon-size="large" @click.prevent="toggleCollapse(4)" />
                  </div>
                </div>
                <div class="flex flex-row flex-wrap gap-x-4 mb-3">
                  <vs-chip color="secondary-500" size="x-small" variant="outlined">
                    <vs-icon type="flat" class="material-icons-outlined" icon-size="tiny">scale</vs-icon>
                    Unit
                  </vs-chip>
                  <vs-chip color="neutral-250" size="x-small">
                    PF-0000-0000
                  </vs-chip>
                </div>
                <div>
                  <p class="text-sm">Product field description.....Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ut volutpat quam sit eu non lectus et.</p>
                </div>
              </div>
              <div class="cc-content" v-show="openedId === 4 && isOpened">
                <div class="grid md:grid-cols-3 gap-10 md:gap-4 sm-divide-y md-divide-x">
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">When sending requests</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by merchant</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">emergency</vs-icon>required to send request</li>
                      </ul>
                    </div>
                  </div>
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">On payment pages</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">emergency</vs-icon>required to submit payment</li>
                      </ul>
                    </div>
                  </div>
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">After request is accepted</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by merchant</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by merchant</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="custom-collapsible" :class="{open: openedId === 5 && isOpened}">
              <div class="cc-header">
                <div class="cc-toolbar">
                  <h4 class="mb-3">Field name</h4>
                  <div class="cc-actions">
                    <vs-button type="flat">View</vs-button>
                    <vs-icon type="flat" class="-mr-2" :icon="openedId === 5 && isOpened ? 'expand_less' : 'expand_more'" icon-size="large" @click.prevent="toggleCollapse(5)" />
                  </div>
                </div>
                <div class="flex flex-row flex-wrap gap-x-4 mb-3">
                  <vs-chip color="secondary-500" size="x-small" variant="outlined">
                    <vs-icon type="flat" class="material-icons-outlined" icon-size="tiny">calendar_month</vs-icon>
                    Date
                  </vs-chip>
                  <vs-chip color="neutral-250" size="x-small">
                    PF-0000-0000
                  </vs-chip>
                </div>
                <div>
                  <p class="text-sm">Product field description.....Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ut volutpat quam sit eu non lectus et.</p>
                </div>
              </div>
              <div class="cc-content" v-show="openedId === 5 && isOpened">
                <div class="grid md:grid-cols-3 gap-10 md:gap-4 sm-divide-y md-divide-x">
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">When sending requests</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by merchant</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">emergency</vs-icon>required to send request</li>
                      </ul>
                    </div>
                  </div>
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">On payment pages</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">emergency</vs-icon>required to submit payment</li>
                      </ul>
                    </div>
                  </div>
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">After request is accepted</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by merchant</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by merchant</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="custom-collapsible" :class="{open: openedId === 6 && isOpened}">
              <div class="cc-header">
                <div class="cc-toolbar">
                  <h4 class="mb-3">Field name</h4>
                  <div class="cc-actions">
                    <vs-button type="flat">View</vs-button>
                    <vs-icon type="flat" class="-mr-2" :icon="openedId === 6 && isOpened ? 'expand_less' : 'expand_more'" icon-size="large" @click.prevent="toggleCollapse(6)" />
                  </div>
                </div>

                <div class="flex flex-row flex-wrap gap-x-4 mb-3">
                  <vs-chip color="secondary-500" size="x-small" variant="outlined">
                    <vs-icon type="flat" icon="view_headline" icon-size="medium" />
                    Long text
                  </vs-chip>
                  <vs-chip color="neutral-250" size="x-small">
                    PF-0000-0000
                  </vs-chip>
                </div>
                <div>
                  <p class="text-sm">Product field description.....Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ut volutpat quam sit eu non lectus et.</p>
                </div>
              </div>
              <div class="cc-content" v-show="openedId === 6 && isOpened">
                <div class="grid md:grid-cols-3 gap-10 md:gap-4 sm-divide-y md-divide-x">
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">When sending requests</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by merchant</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">emergency</vs-icon>required to send request</li>
                      </ul>
                    </div>
                  </div>
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">On payment pages</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">emergency</vs-icon>required to submit payment</li>
                      </ul>
                    </div>
                  </div>
                  <div class="md:px-4 lg:px-10">
                    <div class="px-4">
                      <p class="text-sm text-primary mb-3 text-center md:text-left">After request is accepted</p>
                      <ul class="list-icon">
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by customer</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">visibility</vs-icon>viewable by merchant</li>
                        <li><vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny">border_color</vs-icon>editable by merchant</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="custom-collapsible-container flex flex-col gap-y-4"&gt;
      &lt;div class="custom-collapsible" :class="{open: openedId === 4 && isOpened}"&gt;
        &lt;div class="cc-header"&gt;
          &lt;div class="cc-toolbar"&gt;
            &lt;h4 class="mb-3"&gt;Field name&lt;/h4&gt;
            &lt;div class="cc-actions"&gt;
              &lt;vs-button type="flat"&gt;View&lt;/vs-button&gt;
              &lt;vs-icon type="flat" class="-mr-2" :icon="openedId === 4 && isOpened ? 'expand_less' : 'expand_more'" icon-size="large" @click.prevent="toggleCollapse(4)" /&gt;
            &lt;/div&gt;
          &lt;/div&gt;
          &lt;div class="flex flex-row flex-wrap gap-x-4 mb-3"&gt;
            &lt;vs-chip color="secondary-500" size="x-small" variant="outlined"&gt;
              &lt;vs-icon type="flat" class="material-icons-outlined" icon-size="tiny"&gt;scale&lt;/vs-icon&gt;
              Unit
            &lt;/vs-chip&gt;
            &lt;vs-chip color="neutral-250" size="x-small"&gt;
              PF-0000-0000
            &lt;/vs-chip&gt;
          &lt;/div&gt;
          &lt;div&gt;
            &lt;p class="text-sm"&gt;Product field description.....Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ut volutpat quam sit eu non lectus et.&lt;/p&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;div class="cc-content" v-show="openedId === 4 && isOpened"&gt;
          &lt;div class="grid md:grid-cols-3 gap-10 md:gap-4 sm-divide-y md-divide-x"&gt;
            &lt;div class="md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3 text-center md:text-left"&gt;When sending requests&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by merchant&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;emergency&lt;/vs-icon&gt;required to send request&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3 text-center md:text-left"&gt;On payment pages&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;emergency&lt;/vs-icon&gt;required to submit payment&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3 text-center md:text-left"&gt;After request is accepted&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by merchant&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by merchant&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div class="custom-collapsible" :class="{open: openedId === 5 && isOpened}"&gt;
        &lt;div class="cc-header"&gt;
          &lt;div class="cc-toolbar"&gt;
            &lt;h4 class="mb-3"&gt;Field name&lt;/h4&gt;
            &lt;div class="cc-actions"&gt;
              &lt;vs-button type="flat"&gt;View&lt;/vs-button&gt;
              &lt;vs-icon type="flat" class="-mr-2" :icon="openedId === 5 && isOpened ? 'expand_less' : 'expand_more'" icon-size="large" @click.prevent="toggleCollapse(5)" /&gt;
            &lt;/div&gt;
          &lt;/div&gt;
          &lt;div class="flex flex-row flex-wrap gap-x-4 mb-3"&gt;
            &lt;vs-chip color="secondary-500" size="x-small" variant="outlined"&gt;
              &lt;vs-icon type="flat" class="material-icons-outlined" icon-size="tiny"&gt;calendar_month&lt;/vs-icon&gt;
              Date
            &lt;/vs-chip&gt;
            &lt;vs-chip color="neutral-250" size="x-small"&gt;
              PF-0000-0000
            &lt;/vs-chip&gt;
          &lt;/div&gt;
          &lt;div&gt;
            &lt;p class="text-sm"&gt;Product field description.....Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ut volutpat quam sit eu non lectus et.&lt;/p&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;div class="cc-content" v-show="openedId === 5 && isOpened"&gt;
          &lt;div class="grid md:grid-cols-3 gap-10 md:gap-4 sm-divide-y md-divide-x"&gt;
            &lt;div class="md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3 text-center md:text-left"&gt;When sending requests&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by merchant&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;emergency&lt;/vs-icon&gt;required to send request&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3 text-center md:text-left"&gt;On payment pages&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;emergency&lt;/vs-icon&gt;required to submit payment&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3 text-center md:text-left"&gt;After request is accepted&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by merchant&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by merchant&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div class="custom-collapsible" :class="{open: openedId === 6 && isOpened}"&gt;
        &lt;div class="cc-header"&gt;
          &lt;div class="cc-toolbar"&gt;
            &lt;h4 class="mb-3"&gt;Field name&lt;/h4&gt;
            &lt;div class="cc-actions"&gt;
              &lt;vs-button type="flat"&gt;View&lt;/vs-button&gt;
              &lt;vs-icon type="flat" class="-mr-2" :icon="openedId === 6 && isOpened ? 'expand_less' : 'expand_more'" icon-size="large" @click.prevent="toggleCollapse(6)" /&gt;
            &lt;/div&gt;
          &lt;/div&gt;

          &lt;div class="flex flex-row flex-wrap gap-x-4 mb-3"&gt;
            &lt;vs-chip color="secondary-500" size="x-small" variant="outlined"&gt;
              &lt;vs-icon type="flat" icon="view_headline" icon-size="medium" /&gt;
              Long text
            &lt;/vs-chip&gt;
            &lt;vs-chip color="neutral-250" size="x-small"&gt;
              PF-0000-0000
            &lt;/vs-chip&gt;
          &lt;/div&gt;
          &lt;div&gt;
            &lt;p class="text-sm"&gt;Product field description.....Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ut volutpat quam sit eu non lectus et.&lt;/p&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;div class="cc-content" v-show="openedId === 6 && isOpened"&gt;
          &lt;div class="grid md:grid-cols-3 gap-4 xs-divide-y sm-divide-y md-divide-x"&gt;
            &lt;div class="pt-4 md:pt-0 md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3"&gt;When sending requests&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by merchant&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;emergency&lt;/vs-icon&gt;required to send request&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="pt-4 md:pt-0 md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3 text-center sm:text-left"&gt;On payment pages&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;emergency&lt;/vs-icon&gt;required to submit payment&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="pt-4 md:pt-0 md:px-4 lg:px-10"&gt;
              &lt;div class="px-4"&gt;
                &lt;p class="text-sm text-primary mb-3 text-center sm:text-left"&gt;After request is accepted&lt;/p&gt;
                &lt;ul class="list-icon"&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by customer&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;visibility&lt;/vs-icon&gt;viewable by merchant&lt;/li&gt;
                  &lt;li&gt;&lt;vs-icon type="flat" class="mr-2 material-icons-outlined" icon-size="tiny"&gt;border_color&lt;/vs-icon&gt;editable by merchant&lt;/li&gt;
                &lt;/ul&gt;
              &lt;/div&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            openedId: null,
            isOpened: false,
          }
        },
        methods: {
          toggleCollapse(id) {
            if (id == this.openedId) {
              this.isOpened = !this.isOpened;
            } else {
              this.openedId = id;
              this.isOpened = true;
            }
          },
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Product fields ends -->
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";

  export default {
    data() {
      return {
        openedId: null,
        isOpened: false,
      }
    },
    components: {
      Prism,
      VueAccordion,
    },
    methods: {
      toggleCollapse(id) {
        if (id == this.openedId) {
          this.isOpened = !this.isOpened;
        } else {
          this.openedId = id;
          this.isOpened = true;
        }
      },
    }
  }
</script>