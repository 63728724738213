<template>
  <div>
    <div class="flex justify-between items-center mb-4">
      <slot name="title" />
      <vs-icon
        icon="code"
        size="small"
        class="cursor-pointer"
        @click="toggleAccordion()"
        :aria-expanded="isOpen"
        :aria-controls="`collapse${_uid}`"
      />
    </div>
    <slot name="content" />
    <div v-show="isOpen" :id="`collapse${_uid}`" class="my-4 rounded-lg overflow-auto">
      <slot name="code" />
    </div>  
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
