<template>
  <div>
    <h2 class="mb-5">Transaction cards</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#no-transaction'">No transaction</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#successful-transaction'">Successful transaction</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#failed-transaction'">Failed transaction</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#transaction-badge'">Transaction with badges</vs-button></div>
      </div>
    </vs-card>

    <!-- Payment method starts -->
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="no-transaction">No transaction</h6>
        </template>
        <template v-slot:content>
          <vs-card class="ds-card mb-4 md:mb-6">
            <div slot="header">
              <h4>Transactions</h4>
            </div>
            <div class="text-center pt-2 md:pt-4">
              <p>No transaction available</p>
            </div>
          </vs-card>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-card class="ds-card mb-4 md:mb-6"&gt;
      &lt;div slot="header"&gt;
        &lt;h4&gt;
          Transactions
        &lt;/h4&gt;
      &lt;/div&gt;
      &lt;div class="text-center pt-2 md:pt-4"&gt;
        &lt;p&gt;No transaction available&lt;/p&gt;
      &lt;/div&gt;
    &lt;/vs-card&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="successful-transaction">Successful transaction</h6>
        </template>
        <template v-slot:content>
          <vs-card class="ds-card mb-4 md:mb-6">
            <div slot="header">
              <h4>Transactions</h4>
            </div>
            <div class="flex flex-row justify-between pt-4 pb-5">
              <div class="flex flex-row gap-x-4">
                <vs-icon icon="done" bg="success" round class="self-start md:mt-1" size="tiny"></vs-icon>
                <div class="flex flex-col gap-y-2">
                  <div class="flex flex-row gap-x-4">
                    <p class="text-primary">21 May 2022</p>
                  </div>
                </div>
              </div>
              <div class="flex flex-col text-right gap-y-2">
                <p class="text-primary">$ 1,450.00</p>
                <p class="text-body-dark text-sm">TX-111-1111</p>
              </div>
            </div>
          </vs-card>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-card class="ds-card mb-4 md:mb-6"&gt;
      &lt;div slot="header"&gt;
        &lt;h4&gt;Transactions&lt;/h4&gt;
      &lt;/div&gt;
      &lt;div class="flex flex-row justify-between pt-4 pb-5"&gt;
        &lt;div class="flex flex-row gap-x-4"&gt;
          &lt;vs-icon icon="done" bg="success" round class="self-start md:mt-1" size="tiny"&gt;&lt;/vs-icon&gt;
          &lt;div class="flex flex-col gap-y-2"&gt;
            &lt;div class="flex flex-row gap-x-4"&gt;
              &lt;p class="text-primary"&gt;21 May 2022&lt;/p&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;div class="flex flex-col text-right gap-y-2"&gt;
          &lt;p class="text-primary"&gt;$ 1,450.00&lt;/p&gt;
          &lt;p class="text-body-dark text-sm"&gt;TX-111-1111&lt;/p&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/vs-card&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="failed-transaction">Failed transaction</h6>
        </template>
        <template v-slot:content>
          <vs-card class="ds-card mb-4 md:mb-6">
            <div slot="header">
              <h4>Transactions</h4>
            </div>
            <div class="flex flex-row justify-between pt-4 pb-5">
              <div class="flex flex-row gap-x-4">
                <vs-icon icon="close" bg="danger" round class="self-start md:mt-1" size="tiny"></vs-icon>
                <div class="flex flex-col gap-y-2">
                  <div class="flex flex-row gap-x-4">
                    <p class="text-primary">21 May 2022</p>
                  </div>
                </div>
              </div>
              <div class="flex flex-col text-right gap-y-2">
                <p class="text-primary">$ 1,450.00</p>
                <p class="text-body-dark text-sm">TX-111-1111</p>
              </div>
            </div>
          </vs-card>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-card class="ds-card mb-4 md:mb-6"&gt;
      &lt;div slot="header"&gt;
        &lt;h4&gt;Transactions&lt;/h4&gt;
      &lt;/div&gt;
      &lt;div class="flex flex-row justify-between pt-4 pb-5"&gt;
        &lt;div class="flex flex-row gap-x-4"&gt;
          &lt;vs-icon icon="close" bg="danger" round class="self-start md:mt-1" size="tiny"&gt;&lt;/vs-icon&gt;
          &lt;div class="flex flex-col gap-y-2"&gt;
            &lt;div class="flex flex-row gap-x-4"&gt;
              &lt;p class="text-primary"&gt;21 May 2022&lt;/p&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;div class="flex flex-col text-right gap-y-2"&gt;
          &lt;p class="text-primary"&gt;$ 1,450.00&lt;/p&gt;
          &lt;p class="text-body-dark text-sm"&gt;TX-111-1111&lt;/p&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/vs-card&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="transaction-badge">Transaction with badges</h6>
        </template>
        <template v-slot:content>
          <vs-card class="ds-card mb-4 md:mb-6">
            <div slot="header">
              <h4>Transactions</h4>
            </div>
            <div class="flex flex-row justify-between pt-4 pb-5">
              <div class="flex flex-row gap-x-4">
                <vs-icon icon="done" bg="success" round class="self-start md:mt-1" size="tiny"></vs-icon>
                <div class="flex flex-col gap-y-2">
                  <div class="flex flex-row gap-x-4">
                    <p class="text-primary">21 May 2022</p>
                  </div>
                  <vs-chip color="neutral" size="small" class="self-start">Sweep</vs-chip>
                </div>
              </div>
              <div class="flex flex-col text-right gap-y-2">
                <p class="text-primary">$ 1,450.00</p>
                <p class="text-body-dark text-sm">TX-111-1111</p>
              </div>
            </div>
          </vs-card>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-card class="ds-card mb-4 md:mb-6"&gt;
      &lt;div slot="header"&gt;
        &lt;h4&gt;Transactions&lt;/h4&gt;
      &lt;/div&gt;
      &lt;div class="flex flex-row justify-between pt-4 pb-5"&gt;
        &lt;div class="flex flex-row gap-x-4"&gt;
          &lt;vs-icon icon="done" bg="success" round class="self-start md:mt-1" size="tiny"&gt;&lt;/vs-icon&gt;
          &lt;div class="flex flex-col gap-y-2"&gt;
            &lt;div class="flex flex-row gap-x-4"&gt;
              &lt;p class="text-primary"&gt;21 May 2022&lt;/p&gt;
            &lt;/div&gt;
            &lt;vs-chip color="neutral" size="small" class="self-start"&gt;Sweep&lt;/vs-chip&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;div class="flex flex-col text-right gap-y-2"&gt;
          &lt;p class="text-primary"&gt;$ 1,450.00&lt;/p&gt;
          &lt;p class="text-body-dark text-sm"&gt;TX-111-1111&lt;/p&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/vs-card&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Payment method ends -->
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";

  export default {
    data() {
      return {
      }
    },
    components: {
      Prism,
      VueAccordion,
    },
  }
</script>