<template>
  <div>
    <h2 class="mb-5">Typography</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#display-1'">Display 1</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#display-2'">Display 2</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#heading-1'">Heading 1</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#heading-2'">Heading 2</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#heading-3'">Heading 3</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#heading-4'">Heading 4</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#heading-5'">Heading 5</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#paragraph'">Paragraph</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#ancillary'">Ancillary</vs-button></div>
      </div>
    </vs-card>
    <!-- Large headings -->
    <div class="flex flex-col mb-8">
      <!-- simple heading -->
      <vs-card type="toolbar-sidebar-page" class="mb-6">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium" id="display-1">Display 1</h6>
          </template>
          <template v-slot:content>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <h1 class="text-2xl">Display 1</h1>
                <h1 class="text-2xl">Regular</h1>
              </div>
              <div>
                <h1 class="text-2xl font-medium">Display 1</h1>
                <h1 class="text-2xl font-medium">Medium</h1>
              </div>
              <div>
                <h1 class="text-2xl font-semibold">Display 1</h1>
                <h1 class="text-2xl font-semibold">Semi Bold</h1>
              </div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4"&gt;
      &lt;div&gt;
        &lt;h1 class="text-2xl"&gt;Display 1&lt;/h1&gt;
        &lt;h1 class="text-2xl"&gt;Regular&lt;/h1&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h1 class="text-2xl font-medium"&gt;Display 1&lt;/h1&gt;
        &lt;h1 class="text-2xl font-medium"&gt;Medium&lt;/h1&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h1 class="text-2xl font-semibold"&gt;Display 1&lt;/h1&gt;
        &lt;h1 class="text-2xl font-semibold"&gt;Semi Bold&lt;/h1&gt;
      &lt;/div&gt;
    &lt;/div&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>

        <hr class="my-6 line-hr" />

        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium" id="display-2">Display 2</h6>
          </template>
          <template v-slot:content>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <h1 class="text-xl">Display 2</h1>
                <h1 class="text-xl">Regular</h1>
              </div>
              <div>
                <h1 class="text-xl font-medium">Display 2</h1>
                <h1 class="text-xl font-medium">Medium</h1>
              </div>
              <div>
                <h1 class="text-xl font-semibold">Display 2</h1>
                <h1 class="text-xl font-semibold">Semi Bold</h1>
              </div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4"&gt;
      &lt;div&gt;
        &lt;h1 class="text-xl"&gt;Display 2&lt;/h1&gt;
        &lt;h1 class="text-xl"&gt;Regular&lt;/h1&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h1 class="text-xl font-medium"&gt;Display 2&lt;/h1&gt;
        &lt;h1 class="text-xl font-medium"&gt;Medium&lt;/h1&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h1 class="text-xl font-semibold"&gt;Display 2&lt;/h1&gt;
        &lt;h1 class="text-xl font-semibold"&gt;Semi Bold&lt;/h1&gt;
      &lt;/div&gt;
    &lt;/div&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>

        <hr class="my-6 line-hr" />

        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium" id="display-3">Display 3</h6>
          </template>
          <template v-slot:content>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <h1 class="text-lg">Display 3</h1>
                <h1 class="text-lg">Regular</h1>
              </div>
              <div>
                <h1 class="text-lg font-medium">Display 3</h1>
                <h1 class="text-lg font-medium">Medium</h1>
              </div>
              <div>
                <h1 class="text-lg font-semibold">Display 3</h1>
                <h1 class="text-lg font-semibold">Semi Bold</h1>
              </div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4"&gt;
      &lt;div&gt;
        &lt;h1 class="text-lg"&gt;Display 3&lt;/h1&gt;
        &lt;h1 class="text-lg"&gt;Regular&lt;/h1&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h1 class="text-lg font-medium"&gt;Display 3&lt;/h1&gt;
        &lt;h1 class="text-lg font-medium"&gt;Medium&lt;/h1&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h1 class="text-lg font-semibold"&gt;Display 3&lt;/h1&gt;
        &lt;h1 class="text-lg font-semibold"&gt;Semi Bold&lt;/h1&gt;
      &lt;/div&gt;
    &lt;/div&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>

        <hr class="my-6 line-hr" />

        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium" id="heading-1">Heading 1</h6>
          </template>
          <template v-slot:content>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <h1>Heading 1</h1>
                <h1>Regular</h1>
              </div>
              <div>
                <h1 class="font-medium">Heading 1</h1>
                <h1 class="font-medium">Medium</h1>
              </div>
              <div>
                <h1 class="uppercase">Heading 1</h1>
                <h1 class="uppercase">Regular caps</h1>
              </div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4"&gt;
      &lt;div&gt;
        &lt;h1&gt;Heading 1&lt;/h1&gt;
        &lt;h1&gt;Regular&lt;/h1&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h1 class="font-medium"&gt;Heading 1&lt;/h1&gt;
        &lt;h1 class="font-medium"&gt;Medium&lt;/h1&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h1 class="uppercase"&gt;Heading 1&lt;/h1&gt;
        &lt;h1 class="uppercase"&gt;Regular caps&lt;/h1&gt;
      &lt;/div&gt;
    &lt;/div&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>

        <hr class="my-6 line-hr" />

        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium" id="heading-2">Heading 2</h6>
          </template>
          <template v-slot:content>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <h2>Heading 2</h2>
                <h2>Regular</h2>
              </div>
              <div>
                <h2 class="font-medium">Heading 2</h2>
                <h2 class="font-medium">Medium</h2>
              </div>
              <div>
                <h2 class="uppercase">Heading 2</h2>
                <h2 class="uppercase">Regular caps</h2>
              </div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4"&gt;
      &lt;div&gt;
        &lt;h2&gt;Heading 2&lt;/h2&gt;
        &lt;h2&gt;Regular&lt;/h2&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h2 class="font-medium"&gt;Heading 2&lt;/h2&gt;
        &lt;h2 class="font-medium"&gt;Medium&lt;/h2&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h2 class="uppercase"&gt;Heading 2&lt;/h2&gt;
        &lt;h2 class="uppercase"&gt;Regular caps&lt;/h2&gt;
      &lt;/div&gt;
    &lt;/div&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>

        <hr class="my-6 line-hr" />

        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium" id="heading-3">Heading 3</h6>
          </template>
          <template v-slot:content>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <h3>Heading 3</h3>
                <h3>Regular</h3>
              </div>
              <div>
                <h3 class="font-medium">Heading 3</h3>
                <h3 class="font-medium">Medium</h3>
              </div>
              <div>
                <h3 class="uppercase">Heading 3</h3>
                <h3 class="uppercase">Regular caps</h3>
              </div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4"&gt;
      &lt;div&gt;
        &lt;h3&gt;Heading 3&lt;/h3&gt;
        &lt;h3&gt;Regular&lt;/h3&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h3 class="font-medium"&gt;Heading 3&lt;/h3&gt;
        &lt;h3 class="font-medium"&gt;Medium&lt;/h3&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h3 class="uppercase"&gt;Heading 3&lt;/h3&gt;
        &lt;h3 class="uppercase"&gt;Regular caps&lt;/h3&gt;
      &lt;/div&gt;
    &lt;/div&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>

        <hr class="my-6 line-hr" />

        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium" id="heading-4">Heading 4</h6>
          </template>
          <template v-slot:content>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <h4>Heading 4</h4>
                <h4>Regular</h4>
              </div>
              <div>
                <h4 class="font-medium">Heading 4</h4>
                <h4 class="font-medium">Medium</h4>
              </div>
              <div>
                <h4 class="uppercase">Heading 4</h4>
                <h4 class="uppercase">Regular caps</h4>
              </div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4"&gt;
      &lt;div&gt;
        &lt;h4&gt;Heading 4&lt;/h4&gt;
        &lt;h4&gt;Regular&lt;/h4&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h4 class="font-medium"&gt;Heading 4&lt;/h4&gt;
        &lt;h4 class="font-medium"&gt;Medium&lt;/h4&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h4 class="uppercase"&gt;Heading 4&lt;/h4&gt;
        &lt;h4 class="uppercase"&gt;Regular caps&lt;/h4&gt;
      &lt;/div&gt;
    &lt;/div&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>

        <hr class="my-6 line-hr" />

        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium" id="heading-5">Heading 5</h6>
          </template>
          <template v-slot:content>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <h5>Heading 5</h5>
                <h5>Regular</h5>
              </div>
              <div>
                <h5 class="font-medium">Heading 5</h5>
                <h5 class="font-medium">Medium</h5>
              </div>
              <div>
                <h5 class="uppercase">Heading 5</h5>
                <h5 class="uppercase">Regular caps</h5>
              </div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4"&gt;
      &lt;div&gt;
        &lt;h5&gt;Heading 5&lt;/h5&gt;
        &lt;h5&gt;Regular&lt;/h5&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h5 class="font-medium"&gt;Heading 5&lt;/h5&gt;
        &lt;h5 class="font-medium"&gt;Medium&lt;/h5&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h5 class="uppercase"&gt;Heading 5&lt;/h5&gt;
        &lt;h5 class="uppercase"&gt;Regular caps&lt;/h5&gt;
      &lt;/div&gt;
    &lt;/div&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>

        <hr class="my-6 line-hr" />

        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium" id="paragraph">Paragraph</h6>
          </template>
          <template v-slot:content>
            <div class="flex flex-col gap-y-4">
              <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div>
                  <p class="font-light text-lg">Paragraph large</p>
                  <p class="font-light text-lg">Light</p>
                </div>
                <div>
                  <p class="text-lg">Paragraph large</p>
                  <p class="text-lg">Regular</p>
                </div>
                <div>
                  <p class="text-lg font-medium">Paragraph large</p>
                  <p class="text-lg font-medium">Medium</p>
                </div>
              </div>
              <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div>
                  <p class="font-light">Paragraph</p>
                  <p class="font-light">Light</p>
                </div>
                <div>
                  <p>Paragraph</p>
                  <p>Regular</p>
                </div>
                <div>
                  <p class="font-medium">Paragraph</p>
                  <p class="font-medium text">Medium</p>
                </div>
              </div>
              <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div>
                  <p class="text-sm font-light">Paragraph small</p>
                  <p class="text-sm font-light">Light</p>
                </div>
                <div>
                  <p>Paragraph small</p>
                  <p>Regular</p>
                </div>
                <div>
                  <p class="text-sm font-medium">Paragraph small</p>
                  <p class="text-sm font-medium">Medium</p>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;div class="flex flex-col gap-y-4"&gt;
      &lt;div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4"&gt;
        &lt;div&gt;
          &lt;p class="font-light text-lg"&gt;Paragraph large&lt;/p&gt;
          &lt;p class="font-light text-lg"&gt;Light&lt;/p&gt;
        &lt;/div&gt;
        &lt;div&gt;
          &lt;p class="text-lg"&gt;Paragraph large&lt;/p&gt;
          &lt;p class="text-lg"&gt;Regular&lt;/p&gt;
        &lt;/div&gt;
        &lt;div&gt;
          &lt;p class="text-lg font-medium"&gt;Paragraph large&lt;/p&gt;
          &lt;p class="text-lg font-medium"&gt;Medium&lt;/p&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4"&gt;
        &lt;div&gt;
          &lt;p class="font-light"&gt;Paragraph&lt;/p&gt;
          &lt;p class="font-light"&gt;Light&lt;/p&gt;
        &lt;/div&gt;
        &lt;div&gt;
          &lt;p&gt;Paragraph&lt;/p&gt;
          &lt;p&gt;Regular&lt;/p&gt;
        &lt;/div&gt;
        &lt;div&gt;
          &lt;p class="font-medium"&gt;Paragraph&lt;/p&gt;
          &lt;p class="font-medium text"&gt;Medium&lt;/p&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4"&gt;
        &lt;div&gt;
          &lt;p class="text-sm font-light"&gt;Paragraph small&lt;/p&gt;
          &lt;p class="text-sm font-light"&gt;Light&lt;/p&gt;
        &lt;/div&gt;
        &lt;div&gt;
          &lt;p&gt;Paragraph small&lt;/p&gt;
          &lt;p&gt;Regular&lt;/p&gt;
        &lt;/div&gt;
        &lt;div&gt;
          &lt;p class="text-sm font-medium"&gt;Paragraph small&lt;/p&gt;
          &lt;p class="text-sm font-medium"&gt;Medium&lt;/p&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>

        <hr class="my-6 line-hr" />

        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium" id="ancillary">Ancillary</h6>
          </template>
          <template v-slot:content>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <p class="ancillary font-light">Ancillary</p>
                <p class="ancillary font-light">Light</p>
              </div>
              <div>
                <p class="ancillary">Ancillary</p>
                <p class="ancillary">Regular</p>
              </div>
              <div>
                <p class="ancillary font-medium">Ancillary</p>
                <p class="ancillary font-medium">Medium</p>
              </div>
              <div>
                <p class="ancillary uppercase">Ancillary</p>
                <p class="ancillary uppercase">Caps</p>
              </div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4"&gt;
      &lt;div&gt;
        &lt;p class="ancillary font-light"&gt;Ancillary&lt;/p&gt;
        &lt;p class="ancillary font-light"&gt;Light&lt;/p&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;p class="ancillary"&gt;Ancillary&lt;/p&gt;
        &lt;p class="ancillary"&gt;Regular&lt;/p&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;p class="ancillary font-medium"&gt;Ancillary&lt;/p&gt;
        &lt;p class="ancillary font-medium"&gt;Medium&lt;/p&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;p class="ancillary uppercase"&gt;Ancillary&lt;/p&gt;
        &lt;p class="ancillary uppercase"&gt;Caps&lt;/p&gt;
      &lt;/div&gt;
    &lt;/div&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>
      </vs-card>
    </div>
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";

  export default {
    data() {
      return {
      }
    },
    components: {
      Prism,
      VueAccordion,
    },
    methods: {
    }
  }
</script>