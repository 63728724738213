<template>
  <div>
    <h2 class="mb-5">Extend Due date</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#success-message'">Success message</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#error-message'">Error message</vs-button></div>
      </div>
    </vs-card>
    <!-- extend card starts -->
    <h3 class="mb-5" id="extend-card">Extend Card</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="success-message">Success message</h6>
        </template>
        <template v-slot:content>
          <div v-if="!isExpanded" class="bg-container-60 px-4 py-3 md:py-2 md:px-8 rounded-2xl md-rounded-3xl items-center grid grid-cols-3 md:grid-cols-5">
            <div class="col-span-2 md:col-span-4 py-1">
              <p class="text-sm">Extend your due date to</p>
              <h4>24 July 2023</h4>
            </div>
            <div class="col-span-1">
              <vs-button type="border" class="w-full" @click="toggleExpand">Extend</vs-button>
            </div>
          </div>
          <div v-else class="bg-container-60 rounded-2xl px-4 md:px-8 pt-3 md:pt-6 pb-6 md:pb-8">
            <h4 class="mb-6 md:mb-5">Extend due date</h4>
            <p class="text-center mb-6 md:mb-5">Fee will be charged to your saved payment method</p>
            <div class="flex flex-row mb-8 pb-1 md:pb-2">
              <div class="w-10/12 md:w-1/2 lg:w-1/3 mx-auto">
                <div class="flex flex-row pt-1">
                  <div class="w-1/2 text-center">
                    <p class="text-sm text-body-dark">New due date</p>
                    <p class="text-lg text-primary">3 Jun 2022</p>
                  </div>
                  <div class="w-1/2 text-center">
                    <p class="text-sm text-body-dark">Fee</p>
                    <p class="text-primary text-lg flex items-center justify-center"><span class="font-light text-body-dark text-sm">$</span><span class="pl-2">12.00</span></p>
                  </div>
                </div>
              </div>
            </div>
            <vs-button color="primary" size="large" class="block w-full mx-auto max-w-400 mb-4" @click="showSuccessMessage">Confirm & pay fee</vs-button>
            <vs-button type="flat" @click="toggleExpand" size="medium" class="block mx-auto md:mb-2">Cancel</vs-button>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div v-if="!isExpanded" class="bg-container-60 px-4 py-3 md:py-2 md:px-8 rounded-2xl md-rounded-3xl items-center grid grid-cols-3 md:grid-cols-5"&gt;
      &lt;div class="col-span-2 md:col-span-4 py-1"&gt;
        &lt;p class="text-sm"&gt;Extend your due date to&lt;/p&gt;
        &lt;h4&gt;24 July 2023&lt;/h4&gt;
      &lt;/div&gt;
      &lt;div class="col-span-1"&gt;
        &lt;vs-button type="border" class="w-full" @click="toggleExpand"&gt;Extend&lt;/vs-button&gt;
      &lt;/div&gt;
    &lt;/div&gt;
    &lt;div v-else class="bg-container-60 rounded-2xl px-4 md:px-8 pt-3 md:pt-6 pb-6 md:pb-8"&gt;
      &lt;h4 class="mb-6 md:mb-5"&gt;Extend due date&lt;/h4&gt;
      &lt;p class="text-center mb-6 md:mb-5"&gt;Fee will be charged to your saved payment method&lt;/p&gt;
      &lt;div class="flex flex-row mb-8 pb-1 md:pb-2"&gt;
        &lt;div class="w-10/12 md:w-1/2 lg:w-1/3 mx-auto"&gt;
          &lt;div class="flex flex-row pt-1"&gt;
            &lt;div class="w-1/2 text-center"&gt;
              &lt;p class="text-sm text-body-dark"&gt;New due date&lt;/p&gt;
              &lt;p class="text-lg text-primary"&gt;3 Jun 2022&lt;/p&gt;
            &lt;/div&gt;
            &lt;div class="w-1/2 text-center"&gt;
              &lt;p class="text-sm text-body-dark"&gt;Fee&lt;/p&gt;
              &lt;p class="text-primary text-lg flex items-center justify-center"&gt;&lt;span class="font-light text-body-dark text-sm"&gt;$&lt;/span&gt;&lt;span class="pl-2"&gt;12.00&lt;/span&gt;&lt;/p&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;vs-button color="primary" size="large" class="block w-full mx-auto max-w-400 mb-4" @click="showSuccessMessage"&gt;Confirm & pay fee&lt;/vs-button&gt;
      &lt;vs-button type="flat" @click="toggleExpand" size="medium" class="block mx-auto md:mb-2"&gt;Cancel&lt;/vs-button&gt;
    &lt;/div&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            isExpanded: false,
          }
        },
        methods: {
          showSuccessMessage(title = 'Success', message='Success') {
            this.$toast.success(&lt;div class="container"&gt;&lt;h4&gt;Due date extended&lt;/h4&gt;&lt;/div&gt;, {
              position: "bottom-left",
              timeout: 5000,
              closeOnClick: false,
              pauseOnFocusLoss: false,
              pauseOnHover: false,
              draggable: false,
              draggablePercent: 0.1,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: {
                iconClass: "material-icons",
                iconChildren: "check_circle_outline",
                iconTag: "span"
              },
              rtl: false,
              message: message,
            });
          },
          showErrorMessage(title = 'Error', message = 'Error') {
            this.$toast.error(&lt;div class="container"&gt;&lt;h4&gt;Error extending due date&lt;/h4&gt;&lt;p&gt;&lt;Error code - 000&gt;&lt;/p&gt;&lt;/div&gt;, {
              position: "bottom-left",
              timeout: false,
              closeOnClick: false,
              pauseOnFocusLoss: false,
              pauseOnHover: false,
              draggable: false,
              draggablePercent: 0.1,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: {
                iconClass: "material-icons-outlined",
                iconChildren: "dangerous",
                iconTag: "span"
              },
              rtl: false,
              message: message,
            });
          },
          toggleExpand() {
            this.isExpanded = !this.isExpanded;
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="error-message">Error message</h6>
        </template>
        <template v-slot:content>
          <div v-if="!isExpanded" class="bg-container-60 px-4 py-3 md:py-2 md:px-8 rounded-2xl md-rounded-3xl items-center grid grid-cols-3 md:grid-cols-5">
            <div class="col-span-2 md:col-span-4 py-1">
              <p class="text-sm">Extend your due date to</p>
              <h4>24 July 2023</h4>
            </div>
            <div class="col-span-1">
              <vs-button type="border" class="w-full" @click="toggleExpand">Extend</vs-button>
            </div>
          </div>
          <div v-else class="bg-container-60 rounded-2xl px-4 md:px-8 pt-3 md:pt-6 pb-6 md:pb-8">
            <h4 class="mb-6 md:mb-5">Extend due date</h4>
            <p class="text-center mb-6 md:mb-5">Fee will be charged to your saved payment method</p>
            <div class="flex flex-row mb-8 pb-1 md:pb-2">
              <div class="w-10/12 md:w-1/2 lg:w-1/3 mx-auto">
                <div class="flex flex-row pt-1">
                  <div class="w-1/2 text-center">
                    <p class="text-sm text-body-dark">New due date</p>
                    <p class="text-lg text-primary">3 Jun 2022</p>
                  </div>
                  <div class="w-1/2 text-center">
                    <p class="text-sm text-body-dark">Fee</p>
                    <p class="text-primary text-lg flex items-center justify-center"><span class="font-light text-body-dark text-sm">$</span><span class="pl-2">12.00</span></p>
                  </div>
                </div>
              </div>
            </div>
            <vs-button color="primary" size="large" class="block w-full mx-auto max-w-400 mb-4" @click="showErrorMessage">Confirm & pay fee</vs-button>
            <vs-button type="flat" @click="toggleExpand" size="medium" class="block mx-auto md:mb-2">Cancel</vs-button>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div v-if="!isExpanded" class="bg-container-60 px-4 py-3 md:py-2 md:px-8 rounded-2xl md-rounded-3xl items-center grid grid-cols-3 md:grid-cols-5"&gt;
      &lt;div class="col-span-2 md:col-span-4 py-1"&gt;
        &lt;p class="text-sm"&gt;Extend your due date to&lt;/p&gt;
        &lt;h4&gt;24 July 2023&lt;/h4&gt;
      &lt;/div&gt;
      &lt;div class="col-span-1"&gt;
        &lt;vs-button type="border" class="w-full" @click="toggleExpand"&gt;Extend&lt;/vs-button&gt;
      &lt;/div&gt;
    &lt;/div&gt;
    &lt;div v-else class="bg-container-60 rounded-2xl px-4 md:px-8 pt-3 md:pt-6 pb-6 md:pb-8"&gt;
      &lt;h4 class="mb-6 md:mb-5"&gt;Extend due date&lt;/h4&gt;
      &lt;p class="text-center mb-6 md:mb-5"&gt;Fee will be charged to your saved payment method&lt;/p&gt;
      &lt;div class="flex flex-row mb-8 pb-1 md:pb-2"&gt;
        &lt;div class="w-10/12 md:w-1/2 lg:w-1/3 mx-auto"&gt;
          &lt;div class="flex flex-row pt-1"&gt;
            &lt;div class="w-1/2 text-center"&gt;
              &lt;p class="text-sm text-body-dark"&gt;New due date&lt;/p&gt;
              &lt;p class="text-lg text-primary"&gt;3 Jun 2022&lt;/p&gt;
            &lt;/div&gt;
            &lt;div class="w-1/2 text-center"&gt;
              &lt;p class="text-sm text-body-dark"&gt;Fee&lt;/p&gt;
              &lt;p class="text-primary text-lg flex items-center justify-center"&gt;&lt;span class="font-light text-body-dark text-sm"&gt;$&lt;/span&gt;&lt;span class="pl-2"&gt;12.00&lt;/span&gt;&lt;/p&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;vs-button color="primary" size="large" class="block w-full mx-auto max-w-400 mb-4" @click="showErrorMessage"&gt;Confirm & pay fee&lt;/vs-button&gt;
      &lt;vs-button type="flat" @click="toggleExpand" size="medium" class="block mx-auto md:mb-2"&gt;Cancel&lt;/vs-button&gt;
    &lt;/div&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            isExpanded1: false,
          }
        },
        methods: {
          showSuccessMessage(title = 'Success', message='Success') {
            this.$toast.success(&lt;div class="container"&gt;&lt;h4&gt;Due date extended&lt;/h4&gt;&lt;/div&gt;, {
              position: "bottom-left",
              timeout: 5000,
              closeOnClick: false,
              pauseOnFocusLoss: false,
              pauseOnHover: false,
              draggable: false,
              draggablePercent: 0.1,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: {
                iconClass: "material-icons",
                iconChildren: "check_circle_outline",
                iconTag: "span"
              },
              rtl: false,
              message: message,
            });
          },
          showErrorMessage(title = 'Error', message = 'Error') {
            this.$toast.error(&lt;div class="container"&gt;&lt;h4&gt;Error extending due date&lt;/h4&gt;&lt;p&gt;&lt;Error code - 000&gt;&lt;/p&gt;&lt;/div&gt;, {
              position: "bottom-left",
              timeout: false,
              closeOnClick: false,
              pauseOnFocusLoss: false,
              pauseOnHover: false,
              draggable: false,
              draggablePercent: 0.1,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: {
                iconClass: "material-icons-outlined",
                iconChildren: "dangerous",
                iconTag: "span"
              },
              rtl: false,
              message: message,
            });
          },
          toggleExpand1() {
            this.isExpanded1 = !this.isExpanded1;
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- extend card end -->
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";

  export default {
    data() {
      return {
        isExpanded: false,
        isExpanded1: false,
      }
    },
    components: {
      Prism,
      VueAccordion,
    },
    methods: {
      showSuccessMessage(title = 'Success', message='Success') {
        this.$toast.success(<div class="container"><h4>Due date extended</h4></div>, {
          position: "bottom-left",
          timeout: 5000,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: "material-icons",
            iconChildren: "check_circle_outline",
            iconTag: "span"
          },
          rtl: false,
          message: message,
        });
      },
      showErrorMessage(title = 'Error', message = 'Error') {
        this.$toast.error(<div class="container"><h4>Error extending due date</h4><p>&lt;Error code - 000&gt;</p></div>, {
          position: "bottom-left",
          timeout: false,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: "material-icons-outlined",
            iconChildren: "dangerous",
            iconTag: "span"
          },
          rtl: false,
          message: message,
        });
      },
      toggleExpand() {
        this.isExpanded = !this.isExpanded;
      },
      toggleExpand1() {
        this.isExpanded1 = !this.isExpanded1;
      }
    }
  }
</script>