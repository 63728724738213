<template>
  <div>
    <h2 class="mb-5">Input Fields</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#basic-input'">Basic Input</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#dropdown-select'">Dropdown / Select Fields</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#unit-input'">Unit Input</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#date-input'">Date Input</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#search-input'">Search Input</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#currency-input'">Currency Input</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#file-input'">File Upload</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#icon-right-input'">Icon Right Input</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#special-inputs'">Special Inputs</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#image-uploaded'">Image uploaded</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#address-block'">Address Block</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#code-input'">Code Input</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#editable-fields'">Editable Fields</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#format-fields'">Format Fields</vs-button></div>
      </div>
    </vs-card>

    <!-- INPUT FIELDS -->
    <h3 class="mb-5" id="basic-input">Basic</h3>
    <!-- BASE TEXT INPUT -->
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Base Text Input with label</h6>
        </template>
        <template v-slot:content>
          <!-- Base text input with label -->
          <div class="grid md:grid-cols-2 gap-6">
            <div><h6 class="mb-4">Default</h6><vs-input label="Label" placeholder="Required" v-model="value1"/></div>
            <div><h6 class="mb-4">Error</h6><vs-input label="Label" placeholder="Required" :danger="true" danger-text="Error Message" v-model="value1"/></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input disabled="true" class="isDisabled" label="Label" v-model="value5"/></div>
            <div><h6 class="mb-4">View only</h6><vs-input class="isReadonly" readonly="true" label="Label" v-model="value1"/></div>
          </div>

          <hr class="my-6 line-hr" />

          <!-- Base text input with label with field content -->
          <div class="grid md:grid-cols-2 gap-6">
            <div><h6 class="mb-4">Default</h6><vs-input label="Label" placeholder="Required" :value="'Field content'" /></div>
            <div><h6 class="mb-4">Error</h6><vs-input label="Label" placeholder="Required" :danger="true" danger-text="Error Message" :value="'Field content'" /></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input disabled="true" class="isDisabled" label="Label" :value="'Field content'"/></div>
            <div><h6 class="mb-4">View only</h6><vs-input class="isReadonly" readonly="true" label="Label" :value="'Field content'"/></div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-input label="Label" placeholder="Required" v-model="value1" /&gt;
    &lt;vs-input label="Label" placeholder="Required" :danger="true" danger-text="Error Message" v-model="value1" /&gt;
    &lt;vs-input disabled="true" class="isDisabled" label="Label" v-model="value5" /&gt;
    &lt;vs-input class="isReadonly" readonly="true" label="Label" v-model="value1" /&gt;
    ---------------------------------------------------------------------------------------------------------------------------
    &lt;vs-input label="Label" placeholder="Required" :value="'Field content'" /&gt;
    &lt;vs-input label="Label" placeholder="Required" :danger="true" danger-text="Error Message" :value="'Field content'" /&gt;
    &lt;vs-input disabled="true" class="isDisabled" label="Label" :value="'Field content'" /&gt;
    &lt;vs-input class="isReadonly" readonly="true" label="Label" :value="'Field content'" /&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            value1: '',
            value2: '',
            value5: '',
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Base Text Input without label</h6>
        </template>
        <template v-slot:content>
          <!-- Base text input without label -->
          <div class="grid md:grid-cols-2 gap-6">
            <div><h6 class="mb-4">Default</h6><vs-input placeholder="Required" class="no-label-input" v-model="value1"/></div>
            <div><h6 class="mb-4">Error</h6><vs-input placeholder="Required" class="no-label-input" :danger="true" danger-text="Error Message" v-model="value1"/></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input placeholder="Disabled input" disabled="true" class="isDisabled no-label-input" v-model="value5"/></div>
            <div><h6 class="mb-4">View only</h6><vs-input class="isReadonly no-label-input" readonly="true" v-model="value1"/></div>
          </div>

          <hr class="my-6 line-hr" />

          <!-- Base text input field without label but with field content -->
          <div class="grid md:grid-cols-2 gap-6">
            <div><h6 class="mb-4">Default</h6><vs-input placeholder="Required" class="no-label-input" :value="'Field content'"/></div>
            <div><h6 class="mb-4">Error</h6><vs-input placeholder="Required" class="no-label-input" :danger="true" danger-text="Error Message" :value="'Field content'" /></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input disabled="true" class="isDisabled no-label-input" :value="'Field content'" /></div>
            <div><h6 class="mb-4">View only</h6><vs-input class="isReadonly no-label-input" readonly="true" :value="'Field content'"/></div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-input placeholder="Required" class="no-label-input" v-model="value1" /&gt;
    &lt;vs-input placeholder="Required" class="no-label-input" :danger="true" danger-text="Error Message" v-model="value1" /&gt;
    &lt;vs-input placeholder="Disabled input" disabled="true" class="isDisabled no-label-input" v-model="value5" /&gt;
    &lt;vs-input class="isReadonly no-label-input" readonly="true" v-model="value1"/&gt;
    -----------------------------------------------------------------------------------------------------------------------------
    &lt;vs-input placeholder="Required" class="no-label-input" :value="'Field content'" /&gt;
    &lt;vs-input placeholder="Required" class="no-label-input" :danger="true" danger-text="Error Message" :value="'Field content'" /&gt;
    &lt;vs-input disabled="true" class="isDisabled no-label-input" :value="'Field content'" /&gt;
    &lt;vs-input class="isReadonly no-label-input" readonly="true" :value="'Field content'"/&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            value1: '',
            value5: '',
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- Select Fields -->
    <h3 class="mb-5" id="dropdown-select">Dropdown / Select Fields</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Select Fields with label</h6>
        </template>
        <template v-slot:content>
          <!-- Select fiels with label -->
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <h6 class="mb-4">Default</h6>
              <vs-select placeholder="Required" label="Label" v-model="select1">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" />
              </vs-select>
            </div>
            <div>
              <h6 class="mb-4">Error</h6>
              <vs-select placeholder="Required" :danger="true" danger-text= "Error message" label="Label" v-model="select1">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" />
              </vs-select>
            </div>
            <div>
              <h6 class="mb-4">Disabled</h6>
              <vs-select placeholder="Required" :disabled="true" label="Label" v-model="select1">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" />
              </vs-select>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-select placeholder="Required" label="Label" v-model="select1"&gt;
      &lt;vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" /&gt;
    &lt;/vs-select&gt;
    &lt;vs-select placeholder="Required" :danger="true" danger-text= "Error message" label="Label" v-model="select1"&gt;
      &lt;vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" /&gt;
    &lt;/vs-select&gt;
    &lt;vs-select placeholder="Required" :disabled="true" label="Label" v-model="select1"&gt;
      &lt;vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" /&gt;
    &lt;/vs-select&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            select1Normal: '',
            options1: [
              {text: 'IT', value: 0},
              {text: 'Blade Runner', value: 2},
              {text: 'Thor Ragnarok', value: 3},
              {text: 'Thor Ragnarok', value: 3},
              {text: 'Thor Ragnarok', value: 3},
              {text: 'Field content', value: 3},
            ]
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Select Fields without label</h6>
        </template>
        <template v-slot:content>
          <!-- select fields without label -->
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <h6 class="mb-4">Default</h6>
              <vs-select placeholder="Required" class="no-label-select" v-model="select1">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" />
              </vs-select>
            </div>
            <div>
              <h6 class="mb-4">Error</h6>
              <vs-select placeholder="Required" :danger="true" danger-text= "Error message" class="no-label-select" v-model="select1">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" />
              </vs-select>
            </div>
            <div>
              <h6 class="mb-4">Disabled</h6>
              <vs-select placeholder="Required" :disabled="true" class="no-label-select" v-model="select1">
                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" />
              </vs-select>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-select placeholder="Required" class="no-label-select" v-model="select1"&gt;
      &lt;vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" /&gt;
    &lt;/vs-select&gt;
    &lt;vs-select placeholder="Required" :danger="true" danger-text= "Error message" class="no-label-select" v-model="select1"&gt;
      &lt;vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" /&gt;
    &lt;/vs-select&gt;
    &lt;vs-select placeholder="Required" :disabled="true" class="no-label-select" v-model="select1"&gt;
      &lt;vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in options1" /&gt;
    &lt;/vs-select&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            select1Normal: '',
            options1: [
              {text: 'IT', value: 0},
              {text: 'Blade Runner', value: 2},
              {text: 'Thor Ragnarok', value: 3},
              {text: 'Thor Ragnarok', value: 3},
              {text: 'Thor Ragnarok', value: 3},
              {text: 'Field content', value: 3},
            ]
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- UNIT INPUT -->
    <h3 class="mb-5" id="unit-input">Unit</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Base Text Unit Input with label</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div><h6 class="mb-4">Default</h6><vs-input label="Label" placeholder="Required" v-model="value1" class="hasUnit" description-text="unit"/></div>
            <div><h6 class="mb-4">Error</h6><vs-input label="Label" placeholder="Required" :danger="true" class="hasUnit" description-text="unit" danger-text="Error Message" v-model="value1"/></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input disabled="true" class="isDisabled hasUnit" label="Label" v-model="value5"/></div>
            <div><h6 class="mb-4">View only</h6><vs-input class="isReadonly hasUnit" readonly="true" label="Label" v-model="value1"/></div>
          </div>

          <hr class="my-6 line-hr" />

          <!-- Base Text Unit Input with label and field content -->
          <div class="grid md:grid-cols-2 gap-6">
            <div><h6 class="mb-4">Default</h6><vs-input label="Label" placeholder="Required" :value="'Field content'" class="hasUnit" description-text="unit"/></div>
            <div><h6 class="mb-4">Error</h6><vs-input label="Label" placeholder="Required" :value="'Field content'" :danger="true" class="hasUnit" description-text="unit" danger-text="Error Message" /></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input disabled="true" class="isDisabled hasUnit" :value="'Field content'" label="Label" /></div>
            <div><h6 class="mb-4">View only</h6><vs-input class="isReadonly hasUnit" readonly="true" :value="'Field content'" label="Label" /></div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-input label="Label" placeholder="Required" v-model="value1" class="hasUnit" description-text="unit" /&gt;
    &lt;vs-input label="Label" placeholder="Required" :danger="true" class="hasUnit" description-text="unit" danger-text="Error Message" v-model="value1" /&gt;
    &lt;vs-input disabled="true" class="isDisabled hasUnit" label="Label" v-model="value5" /&gt;
    &lt;vs-input class="isReadonly hasUnit" readonly="true" label="Label" v-model="value1" /&gt;
    ------------------------------------------------------------------------------------------------------------------------------------------------------------
    &lt;vs-input label="Label" placeholder="Required" :value="'Field content'" class="hasUnit" description-text="unit" /&gt;
    &lt;vs-input label="Label" placeholder="Required" :value="'Field content'" :danger="true" class="hasUnit" description-text="unit" danger-text="Error Message" /&gt;
    &lt;vs-input disabled="true" class="isDisabled hasUnit" :value="'Field content'" label="Label" /&gt;
    &lt;vs-input class="isReadonly hasUnit" readonly="true" :value="'Field content'" label="Label" /&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            value1: '',
            value2: '',
            value5: '',
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Base Text Unit Input without label</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div><h6 class="mb-4">Default</h6><vs-input placeholder="Required" v-model="value1" class="hasUnit no-label-input" description-text="unit"/></div>
            <div><h6 class="mb-4">Error</h6><vs-input placeholder="Required" :danger="true" class="hasUnit no-label-input" description-text="unit" danger-text="Error Message" v-model="value1"/></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input placeholder="Disabled unit input" disabled="true" class="isDisabled hasUnit no-label-input" v-model="value5"/></div>
            <div><h6 class="mb-4">View only</h6><vs-input class="isReadonly hasUnit no-label-input" readonly="true" v-model="value1"/></div>
          </div>

          <hr class="my-6 line-hr" />

          <!-- Base Text Unit Input without label but with field content -->
          <div class="grid md:grid-cols-2 gap-6">
            <div><h6 class="mb-4">Default</h6><vs-input placeholder="Required" :value="'Field content'" class="hasUnit no-label-input" description-text="unit" /></div>
            <div><h6 class="mb-4">Error</h6><vs-input placeholder="Required" :value="'Field content'" :danger="true" class="hasUnit no-label-input" description-text="unit" danger-text="Error Message"/></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input disabled="true" :value="'Field content'" class="isDisabled hasUnit no-label-input" description-text="unit"/></div>
            <div><h6 class="mb-4">View only</h6><vs-input class="isReadonly hasUnit no-label-input" :value="'Field content'" readonly="true" description-text="unit"/></div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-input placeholder="Required" v-model="value1" class="hasUnit no-label-input" description-text="unit" /&gt;
    &lt;vs-input placeholder="Required" :danger="true" class="hasUnit no-label-input" description-text="unit" danger-text="Error Message" v-model="value1" /&gt;
    &lt;vs-input placeholder="Disabled unit input" disabled="true" class="isDisabled hasUnit no-label-input" v-model="value5" /&gt;
    &lt;vs-input class="isReadonly hasUnit" readonly="true" v-model="value1" /&gt;
    --------------------------------------------------------------------------------------------------------------------------------------------------------------
    &lt;vs-input placeholder="Required" :value="'Field content'" class="hasUnit no-label-input" description-text="unit" /&gt;
    &lt;vs-input placeholder="Required" :value="'Field content'" :danger="true" class="hasUnit no-label-input" description-text="unit" danger-text="Error Message" /&gt;
    &lt;vs-input disabled="true" :value="'Field content'" class="isDisabled hasUnit no-label-input" description-text="unit" /&gt;
    &lt;vs-input class="isReadonly hasUnit" :value="'Field content'" readonly="true" description-text="unit" /&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            value1: '',
            value2: '',
            value5: '',
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- DATE INPUT -->
    <h3 class="mb-5" id="date-input">Date Input</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Base Date Input with label</h6>
        </template>
        <template v-slot:content>
          <!-- Base date input with label -->
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <h6 class="mb-4">Default</h6>
              <div class="custom-input-field" :class="!showDateValid || errors.has('date') ? 'hasError' : ''">
                <label class="custom-label">Label</label>
                <the-mask
                  :mask="['##/##/####']"
                  v-model="value10"
                  masked
                  @blur.native="handleDateFormat"
                  @keyup.native="handleDateChange"
                  class="w-full custom-input"
                  data-vv-validate-on.native="blur"
                  v-validate="'required'"
                  name="date"
                  placeholder="DD/MM/YYYY - Required"
                />
              </div>
              <span class="span-text-validation-danger" v-if="errors.has('date')"><span class="span-text-validation">Date is required </span></span>
              <span class="span-text-validation-danger" v-if="!showDateValid"><span class="span-text-validation">Invalid date</span></span>
            </div>
            <div><h6 class="mb-4">Error</h6><vs-input label="Label" placeholder="DD/MM/YYYY - Required" v-mask="'##/##/####'" :danger="true" danger-text="Error Message" v-model="value10"/></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input disabled="true" placeholder="DD/MM/YYYY - Required" v-mask="'##/##/####'" class="isDisabled" label="Label" v-model="value10"/></div>
            <div><h6 class="mb-4">View only</h6><vs-input class="isReadonly" placeholder="DD/MM/YYYY - Required" v-mask="'##/##/####'" readonly="true" label="Label" v-model="value10"/></div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
      &lt;div class="custom-input-field" :class="!showDateValid || errors.has('date') ? 'hasError' : ''"&gt;
        &lt;label class="custom-label"&gt;Label&lt;/label&gt;
        &lt;the-mask
          :mask="['##/##/####']"
          v-model="value10"
          masked
          @blur.native="handleDateFormat"
          @keyup.native="handleDateChange"
          class="w-full custom-input"
          data-vv-validate-on.native="blur"
          v-validate="'required'"
          name="date"
          placeholder="DD/MM/YYYY - Required"
        /&gt;
      &lt;/div&gt;
      &lt;span class="span-text-validation-danger" v-if="errors.has('date')"&gt;&lt;span class="span-text-validation"&gt;Date is required &lt;/span&gt;&lt;/span&gt;
      &lt;span class="span-text-validation-danger" v-if="!showDateValid"&gt;&lt;span class="span-text-validation"&gt;Invalid date&lt;/span&gt;&lt;/span&gt;
    &lt;/div&gt;
    &lt;vs-input label="Label" placeholder="DD/MM/YYYY - Required" v-mask="'##/##/####'" :danger="true" danger-text="Error Message" v-model="value10"/&gt;
    &lt;vs-input disabled="true" placeholder="DD/MM/YYYY - Required" v-mask="'##/##/####'" class="isDisabled" label="Label" v-model="value10"/&gt;
    &lt;vs-input class="isReadonly" placeholder="DD/MM/YYYY - Required" v-mask="'##/##/####'" readonly="true" label="Label" v-model="value10"/&gt;

    &lt;script&gt;
      import { mask, TheMask } from 'vue-the-mask';
      import moment from "moment";

      export default {
        data() {
          return {
            value10: '',
            showDateValid: true,
          }
        },
        directives: {
          mask
        },
        methods: {
          handleDateFormat() {
            if (this.value10 == '') {
              this.showDateValid = true;
              return;
            }
            this.showDateValid = moment(this.value10, 'DD/MM/YYYY', true).isValid();
            if (!this.showDateValid) {
              return;
            }
            const year = moment(this.value10, "DD/MM/YYYY").year();
            this.showDateValid = (parseInt(year) <= 2999);
          },
          handleDateChange(event) {
            const value = event.target.value.split("/");
            const dayValue = value[0].charAt(0);
            const monthValue = value[1] ? value[1].charAt(0) : '';
            if (value[0].length == 1) {
              if (dayValue >= 4) {
                event.target.value = "0" + dayValue;
              }
              if (event.key == '/') {
                event.target.value = "0" + value[0] + "/";
              }
            }

            if (value[1].length == 1) {
              if (monthValue && monthValue >= 2) {
                event.target.value = value[0] + "/0" + monthValue;
              }
              if (event.key == '/') {
                event.target.value = value[0] + "/0" + monthValue + "/";
              }
            }
          },
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Base Date Input without label</h6>
        </template>
        <template v-slot:content>
          <!-- Base text input without label -->
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <h6 class="mb-4">Default</h6>
              <div class="custom-input-field no-label-input" :class="!showDateValid || errors.has('date') ? 'hasError' : ''">
                <the-mask
                  :mask="['##/##/####']"
                  v-model="value10"
                  masked
                  @blur.native="handleDateFormat"
                  @keyup.native="handleDateChange"
                  class="w-full custom-input"
                  data-vv-validate-on.native="blur"
                  v-validate="'required'"
                  name="date"
                  placeholder="DD/MM/YYYY - Required"
                />
              </div>
              <span class="span-text-validation-danger" v-if="errors.has('date')"><span class="span-text-validation">Date is required </span></span>
              <span class="span-text-validation-danger" v-if="!showDateValid"><span class="span-text-validation">Invalid date</span></span>
            </div>
            <div><h6 class="mb-4">Error</h6><vs-input placeholder="DD/MM/YYYY - Required" v-mask="'##/##/####'" class="no-label-input" :danger="true" danger-text="Error Message" v-model="value10" /></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input placeholder="Disabled input" disabled="true" v-mask="'##/##/####'" class="isDisabled no-label-input" v-model="value10" /></div>
            <div><h6 class="mb-4">View only</h6><vs-input placeholder="DD/MM/YYYY - Required" v-mask="'##/##/####'" class="no-label-input isReadonly" readonly="true" v-model="value10" /></div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="custom-input-field no-label-input" :class="!showDateValid || errors.has('date') ? 'hasError' : ''"&gt;
      &lt;the-mask
        :mask="['##/##/####']"
        v-model="value10"
        masked
        @blur.native="handleDateFormat"
        @keyup.native="handleDateChange"
        class="w-full custom-input"
        data-vv-validate-on.native="blur"
        v-validate="'required'"
        name="date"
        placeholder="DD/MM/YYYY - Required"
      /&gt;
    &lt;/div&gt;
    &lt;span class="span-text-validation-danger" v-if="errors.has('date')"&gt;&lt;span class="span-text-validation"&gt;Date is required &lt;/span&gt;&lt;/span&gt;
    &lt;span class="span-text-validation-danger" v-if="!showDateValid"&gt;&lt;span class="span-text-validation"&gt;Invalid date&lt;/span&gt;&lt;/span&gt;
    &lt;vs-input placeholder="DD/MM/YYYY - Required" v-mask="'##/##/####'" class="no-label-input" :danger="true" danger-text="Error Message" v-model="value10" /&gt;
    &lt;vs-input placeholder="Disabled input" disabled="true" v-mask="'##/##/####'" class="isDisabled no-label-input" v-model="value10" /&gt;
    &lt;vs-input placeholder="DD/MM/YYYY - Required" v-mask="'##/##/####'" class="no-label-input isReadonly" readonly="true" v-model="value10" /&gt;

    &lt;script&gt;
      import { mask, TheMask } from 'vue-the-mask';
      import moment from "moment";

      export default {
        data() {
          return {
            value10: '',
            showDateValid: true,
          }
        },
        directives: {
          mask
        },
        methods: {
          handleDateFormat() {
            if (this.value10 == '') {
              this.showDateValid = true;
              return;
            }
            this.showDateValid = moment(this.value10, 'DD/MM/YYYY', true).isValid();
            if (!this.showDateValid) {
              return;
            }
            const year = moment(this.value10, "DD/MM/YYYY").year();
            this.showDateValid = (parseInt(year) <= 2999);
          },
          handleDateChange(event) {
            const value = event.target.value.split("/");
            const dayValue = value[0].charAt(0);
            const monthValue = value[1] ? value[1].charAt(0) : '';
            if (value[0].length == 1) {
              if (dayValue >= 4) {
                event.target.value = "0" + dayValue;
              }
              if (event.key == '/') {
                event.target.value = "0" + value[0] + "/";
              }
            }

            if (value[1].length == 1) {
              if (monthValue && monthValue >= 2) {
                event.target.value = value[0] + "/0" + monthValue;
              }
              if (event.key == '/') {
                event.target.value = value[0] + "/0" + monthValue + "/";
              }
            }
          },
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Vue2 Datepicker with label</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <h6 class="mb-4">Default</h6>
              <div class="input-datepicker" :class="!showDatePickerValid ? 'hasError' : ''">
                <label class="input-label">Label</label>
                <date-picker
                  v-model="value1"
                  format="DD/MM/YYYY"
                  type="date"
                  name="date2"
                  placeholder="DD/MM/YYYY - Required"
                  input-class="datepicker-input w-full"
                  lang="jp"
                  v-mask="'##/##/####'"
                  @blur="handleDatePickerFormat"
                  @change="handleDatePickerFormat"
                  data-vv-as="date"
                  v-validate="'required'"
                  @keyup.native="handleDateChange"
                ></date-picker>
                <span class="span-text-validation-danger" v-if="errors.has('date2')"><span class="span-text-validation">Date is required </span></span>
                <span class="span-text-validation-danger" v-if="!showDatePickerValid"><span class="span-text-validation">Invalid date</span></span>
              </div>
            </div>
            <div>
              <h6 class="mb-4">Error</h6>
              <div class="input-datepicker input-icon-validate-danger">
                <label class="input-label">Label</label>
                <date-picker
                  v-model="value1"
                  format="DD/MM/YYYY"
                  type="date"
                  name="date3"
                  placeholder="DD/MM/YYYY - Required"
                  input-class="datepicker-input w-full"
                  lang="jp"
                  v-mask="'##/##/####'"
                  @blur="handleDatePickerFormat"
                  @change="handleDatePickerFormat"
                  data-vv-as="date"
                  v-validate="'required'"
                  @keyup.native="handleDateChange"
                ></date-picker>
                <span class="span-text-validation-danger"><span class="span-text-validation">Error Message</span></span>
              </div>
            </div>
            <div>
              <h6 class="mb-4">Disabled</h6>
              <div class="input-datepicker disabled">
                <label class="input-label">Label</label>
                <date-picker
                  v-model="value1"
                  disabled
                  format="DD/MM/YYYY"
                  type="date"
                  name="date4"
                  placeholder="DD/MM/YYYY - Required"
                  input-class="datepicker-input w-full"
                  lang="jp"
                  v-mask="'##/##/####'"
                  @blur="handleDatePickerFormat"
                  @change="handleDatePickerFormat"
                  data-vv-as="date"
                  v-validate="'required'"
                  @keyup.native="handleDateChange"
                ></date-picker>
              </div>
            </div>
            <div>
              <h6 class="mb-4">View only</h6>
              <div class="input-datepicker isReadonly">
                <label class="input-label">Label</label>
                <date-picker
                  v-model="value1"
                  disabled
                  format="DD/MM/YYYY"
                  type="date"
                  name="date5"
                  placeholder="DD/MM/YYYY - Required"
                  input-class="datepicker-input w-full"
                  lang="jp"
                  v-mask="'##/##/####'"
                  @blur="handleDatePickerFormat"
                  @change="handleDatePickerFormat"
                  data-vv-as="date"
                  v-validate="'required'"
                  @keyup.native="handleDateChange"
                ></date-picker>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="input-datepicker" :class="!showDatePickerValid ? 'hasError' : ''"&gt;
      &lt;label class="input-label"&gt;Label&lt;/label&gt;
      &lt;date-picker
        v-model="value1"
        format="DD/MM/YYYY"
        type="date"
        name="date2"
        placeholder="DD/MM/YYYY - Required"
        input-class="datepicker-input w-full"
        lang="jp"
        v-mask="'##/##/####'"
        @blur="handleDatePickerFormat"
        @change="handleDatePickerFormat"
        data-vv-as="date"
        v-validate="'required'"
        @keyup.native="handleDateChange"
      &gt;&lt;/date-picker&gt;
      &lt;span class="span-text-validation-danger" v-if="errors.has('date2')"&gt;&lt;span class="span-text-validation"&gt;Date is required &lt;/span&gt;&lt;/span&gt;
      &lt;span class="span-text-validation-danger" v-if="!showDatePickerValid"&gt;&lt;span class="span-text-validation"&gt;Invalid date&lt;/span&gt;&lt;/span&gt;
    &lt;/div&gt;

    &lt;div class="input-datepicker input-icon-validate-danger"&gt;
      &lt;label class="input-label"&gt;Label&lt;/label&gt;
      &lt;date-picker
        v-model="value1"
        format="DD/MM/YYYY"
        type="date"
        name="date3"
        placeholder="DD/MM/YYYY - Required"
        input-class="datepicker-input w-full"
        lang="jp"
        v-mask="'##/##/####'"
        @blur="handleDatePickerFormat"
        @change="handleDatePickerFormat"
        data-vv-as="date"
        v-validate="'required'"
        @keyup.native="handleDateChange"
      &gt;&lt;/date-picker&gt;
      &lt;span class="span-text-validation-danger"&gt;&lt;span class="span-text-validation"&gt;Error Message&lt;/span&gt;&lt;/span&gt;
    &lt;/div&gt;

    &lt;div class="input-datepicker disabled"&gt;
      &lt;label class="input-label"&gt;Label&lt;/label&gt;
      &lt;date-picker
        v-model="value1"
        disabled
        format="DD/MM/YYYY"
        type="date"
        name="date4"
        placeholder="DD/MM/YYYY - Required"
        input-class="datepicker-input w-full"
        lang="jp"
        v-mask="'##/##/####'"
        @blur="handleDatePickerFormat"
        @change="handleDatePickerFormat"
        data-vv-as="date"
        v-validate="'required'"
        @keyup.native="handleDateChange"
      &gt;&lt;/date-picker&gt;
    &lt;/div&gt;

    &lt;div class="input-datepicker isReadonly"&gt;
      &lt;label class="input-label"&gt;Label&lt;/label&gt;
      &lt;date-picker
        v-model="value1"
        disabled
        format="DD/MM/YYYY"
        type="date"
        name="date5"
        placeholder="DD/MM/YYYY - Required"
        input-class="datepicker-input w-full"
        lang="jp"
        v-mask="'##/##/####'"
        @blur="handleDatePickerFormat"
        @change="handleDatePickerFormat"
        data-vv-as="date"
        v-validate="'required'"
        @keyup.native="handleDateChange"
      &gt;&lt;/date-picker&gt;
    &lt;/div&gt;

    &lt;script&gt;
      import DatePicker from "vue2-datepicker";
      import { mask } from 'vue-the-mask';
      import moment from "moment";

      // Required to align the datepicker to the left
      DatePicker.methods.displayPopup = function() {
        this.position = {
          left: 0,
          top: '100%'
        }
      }

      export default {
        data() {
          return {
            value1: '',
            showDateValid: true,
            showDatePickerValid: true,
          }
        },
        components: {
          DatePicker,
        },
        directives: {
          mask
        },
        methods: {
          handleDatePickerFormat() {
            if (!this.value1) {
              this.showDatePickerValid = true;
              return;
            }
            this.showDatePickerValid = moment(this.value1, 'DD/MM/YYYY', true).isValid();
            if (!this.showDatePickerValid) {
              return;
            }
            this.showDatePickerValid = moment(this.value1, 'DD/MM/YYYY', true).isValid();
            const year = moment(this.value1, "DD/MM/YYYY").year();
            this.showDatePickerValid = (parseInt(year) <= 2999);
          },
          handleDateChange(event) {
            const value = event.target.value.split("/");
            const dayValue = value[0].charAt(0);
            const monthValue = value[1] ? value[1].charAt(0) : '';
            if (value[0].length == 1) {
              if (dayValue >= 4) {
                event.target.value = "0" + dayValue;
              }
              if (event.key == '/') {
                event.target.value = "0" + value[0] + "/";
              }
            }

            if (value[1].length == 1) {
              if (monthValue && monthValue >= 2) {
                event.target.value = value[0] + "/0" + monthValue;
              }
              if (event.key == '/') {
                event.target.value = value[0] + "/0" + monthValue + "/";
              }
            }
          },
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Vue2 Datepicker without label</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <h6 class="mb-4">Default</h6>
              <div class="input-datepicker no-label-input" :class="!showDatePickerValid ? 'hasError' : ''">
                <date-picker
                  v-model="value1"
                  format="DD/MM/YYYY"
                  type="date"
                  name="date5"
                  placeholder="DD/MM/YYYY - Required"
                  input-class="datepicker-input w-full"
                  lang="jp"
                  v-mask="'##/##/####'"
                  @blur="handleDatePickerFormat"
                  @change="handleDatePickerFormat"
                  data-vv-as="date"
                  v-validate="'required'"
                  @keyup.native="handleDateChange"
                ></date-picker>
                <span class="span-text-validation-danger" v-if="errors.has('date5')"><span class="span-text-validation">Date is required </span></span>
                <span class="span-text-validation-danger" v-if="!showDatePickerValid"><span class="span-text-validation">Invalid date</span></span>
              </div>
            </div>
            <div>
              <h6 class="mb-4">Error</h6>
              <div class="input-datepicker no-label-input input-icon-validate-danger">
                <date-picker
                  v-model="value1"
                  format="DD/MM/YYYY"
                  type="date"
                  name="date6"
                  placeholder="DD/MM/YYYY - Required"
                  input-class="datepicker-input w-full"
                  lang="jp"
                  v-mask="'##/##/####'"
                  @keyup.native="handleDateChange"
                ></date-picker>
                <div class="span-text-validation-danger vs-input--text-validation-span">
                  <span class="span-text-validation"> Error Message </span>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mb-4">Disabled</h6>
              <div class="input-datepicker no-label-input">
                <date-picker
                  v-model="value1"
                  disabled
                  format="DD/MM/YYYY"
                  type="date"
                  name="date7"
                  placeholder="DD/MM/YYYY - Required"
                  input-class="datepicker-input w-full"
                  lang="jp"
                  v-mask="'##/##/####'"
                  @keyup.native="handleDateChange"
                ></date-picker>
              </div>
            </div>
            <div>
              <h6 class="mb-4">View only</h6>
              <div class="input-datepicker no-label-input isReadonly">
                <date-picker
                  v-model="value1"
                  disabled
                  format="DD/MM/YYYY"
                  type="date"
                  name="date8"
                  placeholder="DD/MM/YYYY - Required"
                  input-class="datepicker-input w-full"
                  lang="jp"
                  v-mask="'##/##/####'"
                  @keyup.native="handleDateChange"
                ></date-picker>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="input-datepicker" :class="!showDatePickerValid ? 'hasError' : ''"&gt;
      &lt;date-picker
        v-model="value1"
        format="DD/MM/YYYY"
        type="date"
        name="date5"
        placeholder="DD/MM/YYYY - Required"
        input-class="datepicker-input w-full"
        lang="jp"
        v-mask="'##/##/####'"
        @blur="handleDatePickerFormat"
        @change="handleDatePickerFormat"
        data-vv-as="date"
        v-validate="'required'"
        @keyup.native="handleDateChange"
      &gt;&lt;/date-picker&gt;
      &lt;span class="span-text-validation-danger" v-if="errors.has('date5')"&gt;&lt;span class="span-text-validation"&gt;Date is required &lt;/span&gt;&lt;/span&gt;
      &lt;span class="span-text-validation-danger" v-if="!showDatePickerValid"&gt;&lt;span class="span-text-validation"&gt;Invalid date&lt;/span&gt;&lt;/span&gt;
    &lt;/div&gt;

    &lt;div class="input-datepicker input-icon-validate-danger"&gt;
      &lt;date-picker
        v-model="value1"
        format="DD/MM/YYYY"
        type="date"
        name="date6"
        placeholder="DD/MM/YYYY - Required"
        input-class="datepicker-input w-full"
        lang="jp"
        v-mask="'##/##/####'"
        @keyup.native="handleDateChange"
      &gt;&lt;/date-picker&gt;
      &lt;span class="span-text-validation-danger vs-input--text-validation-span"&gt;
        &lt;span class="span-text-validation"&gt; Error Message &lt;/span&gt;
      &lt;/span&gt;
    &lt;/div&gt;

    &lt;div class="input-datepicker disabled"&gt;
      &lt;date-picker
        v-model="value1"
        disabled
        format="DD/MM/YYYY"
        type="date"
        name="date7"
        placeholder="DD/MM/YYYY - Required"
        input-class="datepicker-input w-full"
        lang="jp"
        v-mask="'##/##/####'"
        @keyup.native="handleDateChange"
      &gt;&lt;/date-picker&gt;
    &lt;/div&gt;

    &lt;div class="input-datepicker isReadonly"&gt;
      &lt;date-picker
        v-model="value1"
        disabled
        format="DD/MM/YYYY"
        type="date"
        name="date8"
        placeholder="DD/MM/YYYY - Required"
        input-class="datepicker-input w-full"
        lang="jp"
        v-mask="'##/##/####'"
        @keyup.native="handleDateChange"
      &gt;&lt;/date-picker&gt;
    &lt;/div&gt;

    &lt;script&gt;
      import DatePicker from "vue2-datepicker";
      import { mask } from 'vue-the-mask';
      import moment from "moment";

      // Required to align the datepicker to the left
      DatePicker.methods.displayPopup = function() {
        this.position = {
          left: 0,
          top: '100%'
        }
      }

      export default {
        data() {
          return {
            value1: '',
            showDateValid: true,
            showDatePickerValid: true,
          }
        },
        components: {
          DatePicker,
        },
        directives: {
          mask
        },
        methods: {
          handleDatePickerFormat() {
            if (!this.value1) {
              this.showDatePickerValid = true;
              return;
            }
            this.showDatePickerValid = moment(this.value1, 'DD/MM/YYYY', true).isValid();
            if (!this.showDatePickerValid) {
              return;
            }
            this.showDatePickerValid = moment(this.value1, 'DD/MM/YYYY', true).isValid();
            const year = moment(this.value1, "DD/MM/YYYY").year();
            this.showDatePickerValid = (parseInt(year) <= 2999);
          },
          handleDateChange(event) {
            const value = event.target.value.split("/");
            const dayValue = value[0].charAt(0);
            const monthValue = value[1] ? value[1].charAt(0) : '';
            if (value[0].length == 1) {
              if (dayValue >= 4) {
                event.target.value = "0" + dayValue;
              }
              if (event.key == '/') {
                event.target.value = "0" + value[0] + "/";
              }
            }

            if (value[1].length == 1) {
              if (monthValue && monthValue >= 2) {
                event.target.value = value[0] + "/0" + monthValue;
              }
              if (event.key == '/') {
                event.target.value = value[0] + "/0" + monthValue + "/";
              }
            }
          },
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- VUE2 DATEPICKER END -->

    <!-- INPUT WITH ICON -->
    <h3 class="mb-5" id="search-input">Search</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Input with label</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div><h6 class="mb-4">Default</h6><vs-input label="test" icon="search" icon-after="true" placeholder="Search" v-model="value5"/></div>
            <div><h6 class="mb-4">Error</h6><vs-input label="test" icon="search" icon-after="true" placeholder="Search" :danger="true" danger-text="Error Message" v-model="value5"/></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input label="test" icon="search" icon-after="true" placeholder="Search" disabled="true" class="isDisabled" v-model="value5"/></div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-input label="test" icon="search" icon-after="true" placeholder="Search" v-model="value5" /&gt;
    &lt;vs-input label="test" icon="search" icon-after="true" placeholder="Search" :danger="true" danger-text="Error Message" v-model="value5" /&gt;
    &lt;vs-input label="test" icon="search" icon-after="true" placeholder="Search" disabled="true" class="isDisabled" v-model="value5" /&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            value5: '',
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Search Input without label</h6>
        </template>
        <template v-slot:content>
          <!-- Search Input without label -->
          <div class="grid md:grid-cols-2 gap-6">
            <div><h6 class="mb-4">Default</h6><vs-input icon="search" icon-after="true" placeholder="Search" class="no-label-input" v-model="value5"/></div>
            <div><h6 class="mb-4">Error</h6><vs-input icon="search" icon-after="true" placeholder="Search" class="no-label-input" :danger="true" danger-text="Error Message" v-model="value5"/></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input icon="search" icon-after="true" placeholder="Search" disabled="true" class="isDisabled no-label-input" v-model="value5"/></div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-input icon="search" icon-after="true" placeholder="Search" class="no-label-input" v-model="value5" /&gt;
    &lt;vs-input icon="search" icon-after="true" placeholder="Search" class="no-label-input" :danger="true" danger-text="Error Message" v-model="value5" /&gt;
    &lt;vs-input icon="search" icon-after="true" placeholder="Search" disabled="true" class="isDisabled no-label-input" v-model="value5" /&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            value5: '',
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- CURRENCY LEFT INPUT -->
    <h3 class="mb-5" id="currency-input">Currency</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Currency Left Input with label</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div><h6 class="mb-4">Default</h6><vs-input label="Label" placeholder="Required" v-model="value9" class="hasUnit hasUnitLeft" description-text="$"/></div>
            <div><h6 class="mb-4">Error</h6><vs-input label="Label" placeholder="Required" :danger="true" class="hasUnit hasUnitLeft" description-text="$" danger-text="Error Message" v-model="value9"/></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input disabled="true" class="isDisabled hasUnit hasUnitLeft" description-text="$" label="Label" v-model="value9"/></div>
            <div><h6 class="mb-4">View only</h6><vs-input class="isReadonly hasUnit hasUnitLeft" readonly="true" description-text="$" label="Label" v-model="value9"/></div>
          </div>

          <hr class="my-6 line-hr" />

          <!-- Currency Left Input with label and field content -->
          <div class="grid md:grid-cols-2 gap-6">
            <div><h6 class="mb-4">Default</h6><vs-input label="Label" placeholder="Required" :value="'Field content'" class="hasUnit hasUnitLeft" description-text="$"/></div>
            <div><h6 class="mb-4">Error</h6><vs-input label="Label" placeholder="Required" :value="'Field content'" :danger="true" class="hasUnit hasUnitLeft" description-text="$" danger-text="Error Message"/></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input disabled="true" class="isDisabled hasUnit hasUnitLeft" :value="'Field content'" description-text="$" label="Label"/></div>
            <div><h6 class="mb-4">View only</h6><vs-input class="isReadonly hasUnit hasUnitLeft" :value="'Field content'" readonly="true" description-text="$" label="Label"/></div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-input label="Label" placeholder="Required" v-model="value9" class="hasUnit hasUnitLeft" description-text="$" /&gt;
    &lt;vs-input label="Label" placeholder="Required" :danger="true" class="hasUnit hasUnitLeft" description-text="$" danger-text="Error Message" v-model="value1" /&gt;
    &lt;vs-input disabled="true" class="isDisabled hasUnit hasUnitLeft" description-text="$" label="Label" v-model="value9" /&gt;
    &lt;vs-input class="isReadonly hasUnit hasUnitLeft" readonly="true" description-text="$" label="Label" v-model="value9" /&gt;
    ----------------------------------------------------------------------------------------------------------------------------------------------------------------------
    &lt;vs-input label="Label" placeholder="Required" :value="'Field content'" class="hasUnit hasUnitLeft" description-text="$" /&gt;
    &lt;vs-input label="Label" placeholder="Required" :value="'Field content'" :danger="true" class="hasUnit hasUnitLeft" description-text="$" danger-text="Error Message" /&gt;
    &lt;vs-input disabled="true" class="isDisabled hasUnit hasUnitLeft" :value="'Field content'" description-text="$" label="Label" /&gt;
    &lt;vs-input class="isReadonly hasUnit hasUnitLeft" :value="'Field content'" readonly="true" description-text="$" label="Label" /&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            value1: '',
            value2: '',
            value5: '',
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Currency Left Input without label</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div><h6 class="mb-4">Default</h6><vs-input placeholder="Required" v-model="value9" class="hasUnit hasUnitLeft no-label-input" description-text="$"/></div>
            <div><h6 class="mb-4">Error</h6><vs-input placeholder="Required" :danger="true" class="hasUnit hasUnitLeft no-label-input" description-text="$" danger-text="Error Message" v-model="value9"/></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input disabled="true" class="isDisabled hasUnit hasUnitLeft no-label-input" description-text="$" v-model="value9"/></div>
            <div><h6 class="mb-4">View only</h6><vs-input class="isReadonly hasUnit hasUnitLeft no-label-input" readonly="true" description-text="$" v-model="value9"/></div>
          </div>

          <hr class="my-6 line-hr" />

          <!-- Currency Left Input without label but with field content -->
          <div class="grid md:grid-cols-2 gap-6">
            <div><h6 class="mb-4">Default</h6><vs-input placeholder="Required" :value="'Field content'" class="hasUnit hasUnitLeft no-label-input"  description-text="$"/></div>
            <div><h6 class="mb-4">Error</h6><vs-input placeholder="Required" :value="'Field content'" :danger="true" class="hasUnit hasUnitLeft no-label-input" description-text="$" danger-text="Error Message"/></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input disabled="true" class="isDisabled hasUnit hasUnitLeft no-label-input" :value="'Field content'" description-text="$"/></div>
            <div><h6 class="mb-4">View only</h6><vs-input class="isReadonly hasUnit hasUnitLeft no-label-input" :value="'Field content'" readonly="true" description-text="$"/></div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-input placeholder="Required" v-model="value9" class="hasUnit hasUnitLeft no-label-input" description-text="$" /&gt;
    &lt;vs-input placeholder="Required" :danger="true" class="hasUnit hasUnitLeft no-label-input" description-text="$" danger-text="Error Message" v-model="value1" /&gt;
    &lt;vs-input disabled="true" class="isDisabled hasUnit hasUnitLeft no-label-input" description-text="$" v-model="value9" /&gt;
    &lt;vs-input class="isReadonly hasUnit hasUnitLeft no-label-input" readonly="true" description-text="$" v-model="value9" /&gt;
    -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
    &lt;vs-input placeholder="Required" :value="'Field content'" class="hasUnit hasUnitLeft no-label-input" description-text="$" /&gt;
    &lt;vs-input placeholder="Required" :value="'Field content'" :danger="true" class="hasUnit hasUnitLeft no-label-input" description-text="$" danger-text="Error Message" /&gt;
    &lt;vs-input disabled="true" class="isDisabled hasUnit hasUnitLeft no-label-input" :value="'Field content'" description-text="$" /&gt;
    &lt;vs-input class="isReadonly hasUnit hasUnitLeft no-label-input" :value="'Field content'" readonly="true" description-text="$" /&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            value1: '',
            value2: '',
            value5: '',
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- CURRENCY LEFT INPUT END -->

    <!-- FILE UPLOAD INPUT -->
    <h3 class="mb-5" id="file-input">File Upload</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Default file upload button</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <h6 class="mb-4">Default</h6>
              <div class="input-file">
                <label class="input-label">Label</label>
                <vs-button type="flat" icon="icon-upload" icon-pack="feather" icon-size="small">Upload File</vs-button>
              </div>
            </div>
            <div>
              <h6 class="mb-4">Error</h6>
              <div class="input-file input-icon-validate-danger">
                <label class="input-label">Label</label>
                <vs-button type="flat" icon="icon-upload" icon-pack="feather" icon-size="small"> Upload File</vs-button>
                <div class="span-text-validation-danger vs-input--text-validation-span">
                  <span class="span-text-validation">Error Message </span>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mb-4">Disabled</h6>
              <div class="input-file disabled">
                <label class="input-label">Label</label>
                <vs-button type="flat" icon="icon-upload" icon-pack="feather" icon-size="small" disabled> Upload File</vs-button>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="input-file"&gt;
      &lt;label class="input-label"&gt;Label&lt;/label&gt;
      &lt;vs-button type="flat" icon="icon-upload" icon-pack="feather" icon-size="small"&gt;Upload File&lt;/vs-button&gt;
    &lt;/div&gt;

    &lt;div class="input-file input-icon-validate-danger"&gt;
      &lt;label class="input-label"&gt;Label&lt;/label&gt;
      &lt;vs-button type="flat" icon="icon-upload" icon-pack="feather" icon-size="small"&gt;Upload File&lt;/vs-button&gt;
      &lt;div class="span-text-validation-danger vs-input--text-validation-span"&gt;
        &lt;span class="span-text-validation"&gt;Error Message &lt;/span&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;div class="input-file disabled"&gt;
      &lt;label class="input-label"&gt;Label&lt;/label&gt;
      &lt;vs-button type="flat" icon="icon-upload" icon-pack="feather" icon-size="small" disabled&gt;Upload File&lt;/vs-button&gt;
    &lt;/div&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Default file upload without label</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <h6 class="mb-4">Default</h6>
              <div class="input-file">
                <vs-button type="flat" icon="icon-upload" icon-pack="feather" icon-size="small">Upload File</vs-button>
              </div>
            </div>
            <div>
              <h6 class="mb-4">Error</h6>
              <div class="input-file input-icon-validate-danger">
                <vs-button type="flat" icon="icon-upload" icon-pack="feather" icon-size="small"> Upload File</vs-button>
                <div class="span-text-validation-danger vs-input--text-validation-span">
                  <span class="span-text-validation">Error Message </span>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mb-4">Disabled</h6>
              <div class="input-file">
                <vs-button type="flat" icon="icon-upload" icon-pack="feather" icon-size="small" disabled> Upload File</vs-button>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="input-file"&gt;
      &lt;vs-button type="flat" icon="icon-upload" icon-pack="feather" icon-size="small"&gt;Upload File&lt;/vs-button&gt;
    &lt;/div&gt;

    &lt;div class="input-file input-icon-validate-danger"&gt;
      &lt;vs-button type="flat" icon="icon-upload" icon-pack="feather" icon-size="small"&gt;Upload File&lt;/vs-button&gt;
      &lt;div class="span-text-validation-danger vs-input--text-validation-span"&gt;
        &lt;span class="span-text-validation"&gt;Error Message &lt;/span&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;div class="input-file disabled"&gt;
      &lt;vs-button type="flat" icon="icon-upload" icon-pack="feather" icon-size="small" disabled&gt;Upload File&lt;/vs-button&gt;
    &lt;/div&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Custom file upload</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <h6 class="mb-4">Default</h6>
              <div class="input-file uploaded">
                <label class="input-label">Label</label>
                <div class="input-file-name">
                  <span class="file-name"><a>filename.pdf</a></span>
                  <vs-button color="" variant="icon-button" icon="delete_outline"></vs-button>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mb-4">Error</h6>
              <div class="input-file uploaded input-icon-validate-danger">
                <label class="input-label">Label</label>
                <div class="input-file-name">
                  <span class="file-name"><a>filename.pdf</a></span>
                  <vs-button color="" variant="icon-button" icon="delete_outline"></vs-button>
                </div>
                <div class="span-text-validation-danger vs-input--text-validation-span">
                  <span class="span-text-validation">Error Message </span>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mb-4">Disabled</h6>
              <div class="input-file uploaded disabled">
                <label class="input-label">Label</label>
                <div class="input-file-name">
                  <span class="file-name"><a>filename.pdf</a></span>
                  <vs-button color="" variant="icon-button" icon="delete_outline" disabled></vs-button>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mb-4">View only</h6>
              <div class="input-file uploaded isReadonly">
                <label class="input-label">Label</label>
                <div class="input-file-name">
                  <span class="file-name">filename.pdf</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="input-file uploaded"&gt;
      &lt;label class="input-label"&gt;Label&lt;/label&gt;
      &lt;div class="input-file-name"&gt;
        &lt;span class="file-name"&gt;&lt;a&gt;filename.pdf&lt;/a&gt;&lt;/span&gt;
        &lt;vs-button color="" variant="icon-button" icon="delete_outline"&gt;&lt;/vs-button&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;div class="input-file uploaded input-icon-validate-danger"&gt;
      &lt;label class="input-label"&gt;Label&lt;/label&gt;
      &lt;div class="input-file-name"&gt;
        &lt;span class="file-name"&gt;&lt;a&gt;filename.pdf&lt;/a&gt;&lt;/span&gt;
        &lt;vs-button color="" variant="icon-button" icon="delete_outline"&gt;&lt;/vs-button&gt;
      &lt;/div&gt;
      &lt;div class="span-text-validation-danger vs-input--text-validation-span"&gt;
        &lt;span class="span-text-validation"&gt;Error Message &lt;/span&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;div class="input-file uploaded disabled"&gt;
      &lt;label class="input-label"&gt;Label&lt;/label&gt;
      &lt;div class="input-file-name"&gt;
        &lt;span class="file-name"&gt;&lt;a&gt;filename.pdf&lt;/a&gt;&lt;/span&gt;
        &lt;vs-button color="" variant="icon-button" icon="delete_outline" disabled&gt;&lt;/vs-button&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;div class="input-file uploaded isReadonly"&gt;
      &lt;label class="input-label"&gt;Label&lt;/label&gt;
      &lt;div class="input-file-name"&gt;
        &lt;span class="file-name"&gt;&lt;a&gt;filename.pdf&lt;/a&gt;&lt;/span&gt;
        &lt;vs-button color="" variant="icon-button" icon="delete_outline"&gt;&lt;/vs-button&gt;
      &lt;/div&gt;
    &lt;/div&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Custom file upload without label</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <h6 class="mb-4">Default</h6>
              <div class="input-file uploaded">
                <div class="input-file-name">
                  <span class="file-name"><a>filename.pdf</a></span>
                  <vs-button color="" variant="icon-button" icon="delete_outline"></vs-button>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mb-4">Error</h6>
              <div class="input-file uploaded input-icon-validate-danger">
                <div class="input-file-name">
                  <span class="file-name"><a>filename.pdf</a></span>
                  <vs-button color="" variant="icon-button" icon="delete_outline"></vs-button>
                </div>
                <div class="span-text-validation-danger vs-input--text-validation-span">
                  <span class="span-text-validation">Error Message </span>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mb-4">Disabled</h6>
              <div class="input-file uploaded disabled">
                <div class="input-file-name">
                  <span class="file-name"><a>filename.pdf</a></span>
                  <vs-button color="" variant="icon-button" icon="delete_outline" disabled></vs-button>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mb-4">View only</h6>
              <div class="input-file uploaded isReadonly">
                <div class="input-file-name">
                  <span class="file-name">filename.pdf</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="input-file uploaded"&gt;
      &lt;div class="input-file-name"&gt;
        &lt;span class="file-name"&gt;&lt;a&gt;filename.pdf&lt;/a&gt;&lt;/span&gt;
        &lt;vs-button color="" variant="icon-button" icon="delete_outline"&gt;&lt;/vs-button&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;div class="input-file uploaded input-icon-validate-danger"&gt;
      &lt;div class="input-file-name"&gt;
        &lt;span class="file-name"&gt;&lt;a&gt;filename.pdf&lt;/a&gt;&lt;/span&gt;
        &lt;vs-button color="" variant="icon-button" icon="delete_outline"&gt;&lt;/vs-button&gt;
      &lt;/div&gt;
      &lt;div class="span-text-validation-danger vs-input--text-validation-span"&gt;
        &lt;span class="span-text-validation"&gt;Error Message &lt;/span&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;div class="input-file uploaded disabled"&gt;
      &lt;div class="input-file-name"&gt;
        &lt;span class="file-name"&gt;&lt;a&gt;filename.pdf&lt;/a&gt;&lt;/span&gt;
        &lt;vs-button color="" variant="icon-button" icon="delete_outline" disabled&gt;&lt;/vs-button&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;div class="input-file uploaded isReadonly"&gt;
      &lt;div class="input-file-name"&gt;
        &lt;span class="file-name"&gt;&lt;a&gt;filename.pdf&lt;/a&gt;&lt;/span&gt;
        &lt;vs-button color="" variant="icon-button" icon="delete_outline"&gt;&lt;/vs-button&gt;
      &lt;/div&gt;
    &lt;/div&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- FILE UPLOAD INPUT END -->

    <!-- INPUT WITH ICON -->
    <h3 class="mb-5" id="icon-right-input">Icon right input</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Input with label</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div><h6 class="mb-4">Default</h6><vs-input label="test" icon="done" icon-after="true" icon-size="small" placeholder="Required" v-model="value9" icon-visible="true" /></div>
            <div><h6 class="mb-4">Error</h6><vs-input label="test" icon="error_outline" icon-after="true" icon-size="small" placeholder="Required" :danger="true" danger-text="Error Message" v-model="value9" icon-visible="true" /></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input label="test" icon="done" icon-after="true" icon-size="small" placeholder="Required" disabled="true" class="isDisabled" v-model="value9" icon-visible="true" /></div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-input label="test" icon="done" icon-after="true" icon-size="small" placeholder="Required" v-model="value5" icon-visible="true" /&gt;
    &lt;vs-input label="test" icon="error_outline" icon-after="true" icon-size="small" placeholder="Required" :danger="true" danger-text="Error Message" v-model="value5" icon-visible="true" /&gt;
    &lt;vs-input label="test" icon="done" icon-after="true" icon-size="small" placeholder="Required" disabled="true" class="isDisabled" v-model="value5" icon-visible="true" /&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            value5: '',
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Input without label</h6>
        </template>
        <template v-slot:content>
          <!-- Search Input without label -->
          <div class="grid md:grid-cols-2 gap-6">
            <div><h6 class="mb-4">Default</h6><vs-input icon="done" icon-after="true" icon-size="small" placeholder="Required" class="no-label-input" v-model="value5" icon-visible="true" /></div>
            <div><h6 class="mb-4">Error</h6><vs-input icon="error_outline" icon-after="true" icon-size="small" placeholder="Required" class="no-label-input" :danger="true" danger-text="Error Message" v-model="value5" icon-visible="true" /></div>
            <div><h6 class="mb-4">Disabled</h6><vs-input icon="done" icon-after="true" icon-size="small" placeholder="Required" disabled="true" class="isDisabled no-label-input" v-model="value5" icon-visible="true" /></div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-input icon="done" icon-after="true" icon-size="small" placeholder="Required" class="no-label-input" v-model="value5" icon-visible="true" /&gt;
    &lt;vs-input icon="error_outline" icon-after="true" icon-size="small" placeholder="Required" class="no-label-input" :danger="true" danger-text="Error Message" v-model="value5" icon-visible="true" /&gt;
    &lt;vs-input icon="done" icon-after="true" icon-size="small" placeholder="Required" disabled="true" class="isDisabled no-label-input" v-model="value5" icon-visible="true" /&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            value5: '',
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- SPECIAL INPUTS -->
    <h3 class="mb-5" id="special-inputs">Special Inputs</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Checkboxes</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 gap-6">
            <div>
              <vs-checkbox v-model="checkBox1">Checked type 1</vs-checkbox>
            </div>
            <div>
              <vs-checkbox v-model="checkBox2">Unchecked type 1</vs-checkbox>
            </div>
            <div>
              <vs-checkbox disabled="true" v-model="checkBox1">Checked & Disabled</vs-checkbox>
            </div>
            <div>
              <vs-checkbox disabled="true" v-model="checkBox2">Unchecked & Disabled</vs-checkbox>
            </div>
            <div>
              <vs-checkbox icon="remove" v-model="checkBox1">Checked type 2</vs-checkbox>
            </div>
            <div>
              <vs-checkbox icon="remove" disabled="true" v-model="checkBox1">Disabled type 2</vs-checkbox>
            </div>
            <div>
              <vs-checkbox color="danger" v-model="checkBox1">Error</vs-checkbox>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-checkbox v-model="checkBox1"&gt;Checked type 1&lt;/vs-checkbox&gt;
    &lt;vs-checkbox v-model="checkBox2"&gt;Unchecked type 1&lt;/vs-checkbox&gt;
    &lt;vs-checkbox disabled="true" v-model="checkBox1"&gt;Checked & Disabled&lt;/vs-checkbox&gt;
    &lt;vs-checkbox disabled="true" v-model="checkBox2"&gt;Unchecked & Disabled&lt;/vs-checkbox&gt;
    &lt;vs-checkbox icon="remove" v-model="checkBox1"&gt;Checked type 2&lt;/vs-checkbox&gt;
    &lt;vs-checkbox icon="remove" disabled="true" v-model="checkBox1"&gt;Disabled type 2&lt;/vs-checkbox&gt;
    &lt;vs-checkbox color="danger" v-model="checkBox1"&gt;Error&lt;/vs-checkbox&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            checkBox1: true,
            checkBox2: false,
            checkBox3: false,
            checkBox4: false,
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
      <hr class="my-6 line-hr" />
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Radio</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 gap-6">
            <div>
              <vs-radio v-model="radios1" vs-name="radios1" vs-value="selected">Selected</vs-radio>
            </div>
            <div>
              <vs-radio v-model="radios1" vs-name="radios1" vs-value="unselected">Unselected</vs-radio>
            </div>
            <div>
              <vs-radio disabled="true" v-model="radios2" vs-name="radios2" vs-value="selected">Disabled</vs-radio>
            </div>
            <div>
              <vs-radio disabled="true" v-model="radios2" vs-name="radios2" vs-value="unselected">Disabled</vs-radio>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-radio v-model="radios1" vs-name="radios1" vs-value="selected"&gt;Selected&lt;/vs-radio&gt;
    &lt;vs-radio v-model="radios1" vs-name="radios1" vs-value="unselected"&gt;Unselected&lt;/vs-radio&gt;
    &lt;vs-radio disabled="true" v-model="radios2" vs-name="radios2" vs-value="selected"&gt;Disabled&lt;/vs-radio&gt;
    &lt;vs-radio disabled="true" v-model="radios2" vs-name="radios2" vs-value="unselected"&gt;Disabled&lt;/vs-radio&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            radios1: 'selected',
            radios2: 'selected',
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Toggle Switch</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 gap-6">
            <div class="flex flex-col items-start">
              <h6 class="mb-4">ON</h6>
              <vs-switch v-model="switch1" />
            </div>
            <div class="flex flex-col items-start">
              <h6 class="mb-4">OFF</h6>
              <vs-switch v-model="switch2" />
            </div>
            <div class="flex flex-col items-start">
              <h6 class="mb-4">Disabled ON</h6>
              <vs-switch disabled="true" v-model="switch3" />
            </div>
            <div class="flex flex-col items-start">
              <h6 class="mb-4">Disabled OFF</h6>
              <vs-switch disabled="true" v-model="switch4" />
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="grid grid-cols-2 gap-6"&gt;
      &lt;div class="flex flex-col items-start"&gt;
        &lt;h6 class="mb-4"&gt;ON&lt;/h6&gt;
        &lt;vs-switch v-model="switch1" /&gt;
      &lt;/div&gt;
      &lt;div class="flex flex-col items-start"&gt;
        &lt;h6 class="mb-4"&gt;OFF&lt;/h6&gt;
        &lt;vs-switch v-model="switch2" /&gt;
      &lt;/div&gt;
      &lt;div class="flex flex-col items-start"&gt;
        &lt;h6 class="mb-4"&gt;Disabled ON&lt;/h6&gt;
        &lt;vs-switch disabled="true" v-model="switch3" /&gt;
      &lt;/div&gt;
      &lt;div class="flex flex-col items-start"&gt;
        &lt;h6 class="mb-4"&gt;Disabled OFF&lt;/h6&gt;
        &lt;vs-switch disabled="true" v-model="switch4" /&gt;
      &lt;/div&gt;
    &lt;/div&gt;
    &lt;script&gt;
      export default {
        data() {
          return {
            switch1: true,
            switch2: false,
            switch3: true,
            switch4: false,
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Selection card</h6>
        </template>
        <template v-slot:content>
          <h6 class="mb-4">Radio cards</h6>
          <div class="grid grid-cols-2 gap-6">
            <div>
              <div class="selection-card" :class="{ selected: radioCard.cardOption === 'FIRST_CARD' }">
                <div class="selection-card-header">
                  <div class="selection-option">
                    <vs-radio v-model="radioCard.cardOption" vs-value="FIRST_CARD" vs-name="cardOption" id="cardOption">Text</vs-radio>
                  </div>
                </div>
                <div class="selection-card-body">
                  <p>Lorem ipsum dolor sit amet</p>
                </div>
              </div>
            </div>
            <div>
              <div class="selection-card" :class="{ selected: radioCard.cardOption === 'SECOND_CARD' }">
                <div class="selection-card-header">
                  <div class="selection-option">
                    <vs-radio v-model="radioCard.cardOption" vs-value="SECOND_CARD" vs-name="cardOption" id="cardOption">Text</vs-radio>
                  </div>
                </div>
                <div class="selection-card-body">
                  <p>Lorem ipsum dolor sit amet</p>
                </div>
              </div>
            </div>
          </div>
          <h6 class="mt-6 mb-4">Checkbox cards</h6>
          <div class="grid grid-cols-2 gap-6">
            <div>
              <div class="selection-card" :class="{ selected: checkboxCard.cardOption1 == true }">
                <div class="selection-card-header">
                  <div class="selection-option">
                    <vs-checkbox v-model="checkboxCard.cardOption1">Text</vs-checkbox>
                  </div>
                </div>
                <div class="selection-card-body">
                  <p>Lorem ipsum dolor sit amet</p>
                </div>
              </div>
            </div>
            <div>
              <div class="selection-card" :class="{ selected: checkboxCard.cardOption2 == true }">
                <div class="selection-card-header grayed-bg">
                  <div class="selection-option">
                    <vs-checkbox v-model="checkboxCard.cardOption2">Text</vs-checkbox>
                  </div>
                </div>
                <div class="selection-card-body">
                  <p>Lorem ipsum dolor sit amet</p>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    ----------------------- RADIO CARDS --------------------------------------
    &lt;div class="grid grid-cols-2 gap-6"&gt;
      &lt;div&gt;
        &lt;div class="selection-card" :class="{ selected: radioCard.cardOption === 'FIRST_CARD' }"&gt;
          &lt;div class="selection-card-header"&gt;
            &lt;div class="selection-option"&gt;
              &lt;vs-radio v-model="radioCard.cardOption" vs-value="FIRST_CARD" vs-name="cardOption" id="cardOption"&gt;Text&lt;/vs-radio&gt;
            &lt;/div&gt;
          &lt;/div&gt;
          &lt;div class="selection-card-body"&gt;
            &lt;p&gt;Lorem ipsum dolor sit amet&lt;/p&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;div class="selection-card" :class="{ selected: radioCard.cardOption === 'SECOND_CARD' }"&gt;
          &lt;div class="selection-card-header"&gt;
            &lt;div class="selection-option"&gt;
              &lt;vs-radio v-model="radioCard.cardOption" vs-value="SECOND_CARD" vs-name="cardOption" id="cardOption"&gt;Text&lt;/vs-radio&gt;
            &lt;/div&gt;
          &lt;/div&gt;
          &lt;div class="selection-card-body"&gt;
            &lt;p&gt;Lorem ipsum dolor sit amet&lt;/p&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;
    ----------------------- CHECKBOX CARDS --------------------------------------
    &lt;div class="grid grid-cols-2 gap-6"&gt;
      &lt;div&gt;
        &lt;div class="selection-card" :class="{ selected: checkboxCard.cardOption1 == true }"&gt;
          &lt;div class="selection-card-header"&gt;
            &lt;div class="selection-option"&gt;
              &lt;vs-checkbox v-model="checkboxCard.cardOption1"&gt;Text&lt;/vs-checkbox&gt;
            &lt;/div&gt;
          &lt;/div&gt;
          &lt;div class="selection-card-body"&gt;
            &lt;p&gt;Lorem ipsum dolor sit amet&lt;/p&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;div class="selection-card" :class="{ selected: checkboxCard.cardOption2 == true }"&gt;
          &lt;div class="selection-card-header grayed-bg"&gt;
            &lt;div class="selection-option"&gt;
              &lt;vs-checkbox v-model="checkboxCard.cardOption2"&gt;Text&lt;/vs-checkbox&gt;
            &lt;/div&gt;
          &lt;/div&gt;
          &lt;div class="selection-card-body"&gt;
            &lt;p&gt;Lorem ipsum dolor sit amet&lt;/p&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            radioCard: {
              cardOption: "FIRST_CARD",
            },
            checkboxCard: {
              cardOption1: true,
              cardOption2: false,
            }
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Selection card headers</h6>
        </template>
        <template v-slot:content>
          <div class="grid grid-cols-2 gap-6">
            <div>
              <div class="selection-card" :class="{ selected: radioCard.headerOption === 'FIRST_HEADER' }">
                <div class="selection-card-header">
                  <div class="selection-option">
                    <vs-radio v-model="radioCard.headerOption" vs-value="FIRST_HEADER" vs-name="headerOption" id="headerOption">Text</vs-radio>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="selection-card" :class="{ selected: radioCard.headerOption === 'SECOND_HEADER' }">
                <div class="selection-card-header grayed-bg">
                  <div class="selection-option">
                    <vs-radio v-model="radioCard.headerOption" vs-value="SECOND_HEADER" vs-name="headerOption" id="headerOption">Text</vs-radio>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="selection-card" :class="{ selected: checkboxCard.headerOption1 == true }">
                <div class="selection-card-header">
                  <div class="selection-option">
                    <vs-checkbox v-model="checkboxCard.headerOption1">Text</vs-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="selection-card" :class="{ selected: checkboxCard.headerOption2 == true }">
                <div class="selection-card-header grayed-bg">
                  <div class="selection-option">
                    <vs-checkbox v-model="checkboxCard.headerOption2">Text</vs-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="grid grid-cols-2 gap-6"&gt;
      &lt;div&gt;
        &lt;div class="selection-card" :class="{ selected: radioCard.headerOption === 'FIRST_HEADER' }"&gt;
          &lt;div class="selection-card-header"&gt;
            &lt;div class="selection-option"&gt;
              &lt;vs-radio v-model="radioCard.headerOption" vs-value="FIRST_HEADER" vs-name="headerOption" id="headerOption"&gt;Text&lt;/vs-radio&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;div class="selection-card" :class="{ selected: radioCard.headerOption === 'SECOND_HEADER' }"&gt;
          &lt;div class="selection-card-header grayed-bg"&gt;
            &lt;div class="selection-option"&gt;
              &lt;vs-radio v-model="radioCard.headerOption" vs-value="SECOND_HEADER" vs-name="headerOption" id="headerOption"&gt;Text&lt;/vs-radio&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;div class="selection-card" :class="{ selected: checkboxCard.headerOption1 == true }"&gt;
          &lt;div class="selection-card-header"&gt;
            &lt;div class="selection-option"&gt;
              &lt;vs-checkbox v-model="checkboxCard.headerOption1"&gt;Text&lt;/vs-checkbox&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;div class="selection-card" :class="{ selected: checkboxCard.headerOption2 == true }"&gt;
          &lt;div class="selection-card-header grayed-bg"&gt;
            &lt;div class="selection-option"&gt;
              &lt;vs-checkbox v-model="checkboxCard.headerOption2"&gt;Text&lt;/vs-checkbox&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            radioCard: {
              headerOption: "FIRST_HEADER",
            },
            checkboxCard: {
              headerOption1: true,
              headerOption2: false,
            }
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- IMAGE UPLOADED -->
    <h3 class="mb-5" id="image-uploaded">Image uploaded</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Upload field with image</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <h6 class="mb-4">Default</h6>
              <div class="input-file uploaded">
                <vs-button type="flat" icon="icon-upload" icon-pack="feather" icon-size="small" v-if="!fileUploaded" @click="toggleFileUpload">Upload File</vs-button>
                <div class="input-file-image" v-if="fileUploaded">
                  <span class="file-image"><a><img :src="uploadedImage" alt="direct-debit" /></a></span>
                  <vs-button color="" variant="icon-button" icon="delete_outline" @click="toggleFileUpload"></vs-button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="input-file uploaded"&gt;
      &lt;vs-button type="flat" icon="icon-upload" icon-pack="feather" icon-size="small" v-if="!fileUploaded" @click="toggleFileUpload"&gt;Upload File&lt;/vs-button&gt;
      &lt;div class="input-file-image" v-if="fileUploaded"&gt;
        &lt;span class="file-image"&gt;&lt;a&gt;&lt;img :src="uploadedImage" alt="direct-debit" /&gt;&lt;/a&gt;&lt;/span&gt;
        &lt;vs-button color="" variant="icon-button" icon="delete_outline" @click="toggleFileUpload"&gt;&lt;/vs-button&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            fileUploaded: 'true',
          },
          methods: {
            toggleFileUpload() {
              this.fileUploaded = !this.fileUploaded;
            },
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- ADDRESS BLOCK -->
    <h3 class="mb-5" id="address-block">Address Block</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Auto complete / Manual</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <div class="grid gap-y-6">
                <div>
                  <label class="input-label">Address</label>
                  <div v-if="!isExpanded" class="custom-input-field">
                    <vue-google-autocomplete ref="address" v-on:placechanged="getAddressData" id="map" classname="w-full custom-w" :country="['au']" placeholder="Start typing your address"></vue-google-autocomplete>
                  </div>
                  <vs-input v-else placeholder="Enter address" v-model="formData.street_name" />
                  <vs-button type="flat" class="mt-2" @click="toggleExpand" v-show="isExpanded" size="small">Back to auto-complete</vs-button>
                  <vs-button type="flat" class="mt-2" @click="toggleExpand" v-show="!isExpanded" size="small">Enter manually</vs-button>
                </div>
                <div v-show="isExpanded">
                  <div class="grid gap-y-6">
                    <div>
                      <vs-input label="Apt, Suite, Unit, Building, Floor" v-model="formData.street_number" />
                    </div>
                    <div>
                      <vs-input label="Suburb" v-model="formData.suburb" />
                    </div>
                    <div class="flex flex-row gap-x-6">
                      <div class="w-1/2">
                        <vs-input label="State" v-model="formData.state" />
                      </div>
                      <div class="w-1/2">
                        <vs-input label="Postcode" v-model="formData.postcode" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="grid gap-y-6"&gt;
      &lt;div&gt;
        &lt;label class="input-label"&gt;Address&lt;/label&gt;
        &lt;div v-if="!isExpanded" class="custom-input-field"&gt;
          &lt;vue-google-autocomplete ref="address" v-on:placechanged="getAddressData" id="map" classname="w-full custom-w" :country="['au']" placeholder="Start typing your address"&gt;&lt;/vue-google-autocomplete&gt;
        &lt;/div&gt;
        &lt;vs-input v-else placeholder="Enter address" v-model="formData.street_name" /&gt;
        &lt;vs-button type="flat" class="mt-2" @click="toggleExpand" v-show="isExpanded" size="small"&gt;Back to auto-complete&lt;/vs-button&gt;
        &lt;vs-button type="flat" class="mt-2" @click="toggleExpand" v-show="!isExpanded" size="small"&gt;Enter manually&lt;/vs-button&gt;
      &lt;/div&gt;
      &lt;div v-show="isExpanded"&gt;
        &lt;div class="grid gap-y-6"&gt;
          &lt;div&gt;
            &lt;vs-input label="Apt, Suite, Unit, Building, Floor" v-model="formData.street_number" /&gt;
          &lt;/div&gt;
          &lt;div&gt;
            &lt;vs-input label="Suburb" v-model="formData.suburb" /&gt;
          &lt;/div&gt;
          &lt;div class="flex flex-row gap-x-6"&gt;
            &lt;div class="w-1/2"&gt;
              &lt;vs-input label="State" v-model="formData.state" /&gt;
            &lt;/div&gt;
            &lt;div class="w-1/2"&gt;
              &lt;vs-input label="Postcode" v-model="formData.postcode" /&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;script&gt;
    import VueGoogleAutocomplete from "vue-google-autocomplete";

    export default {
      data() {
        return {
          isExpanded: false,
        },
        components: {
          VueGoogleAutocomplete,
        },
        methods: {
          toggleExpand() {
            this.isExpanded = !this.isExpanded;
          },
        }
      }
    }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- CODE INPUT -->
    <h3 class="mb-5" id="code-input">Code input</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Code input</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <h6 class="mb-4">Default</h6>
              <div class="otp-input-wrap">
                <v-otp-input
                  ref="otpInput1"
                  input-classes="otp-input"
                  separator=""
                  :num-inputs="6"
                  :should-auto-focus="true"
                  input-type="number"
                  @on-change="handleOnChange"
                  @on-complete="handleOnComplete"
                />
              </div>
            </div>
            <div>
              <h6 class="mb-4">Error</h6>
              <div class="otp-input-wrap">
                <v-otp-input
                  ref="otpInput1"
                  input-classes="otp-input"
                  separator=""
                  :num-inputs="6"
                  :should-auto-focus="true"
                  input-type="number"
                  @on-change="handleOnChange"
                  @on-complete="handleOnComplete"
                  :class="isInvalidCodce ? 'input-validate-danger' : ''"
                />
                <div v-if="isInvalidCodce" class="span-text-validation-danger"><span class="span-text-validation">Invalid code. Please try again</span></div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="otp-input-wrap"&gt;
      &lt;v-otp-input
        ref="otpInput1"
        input-classes="otp-input"
        separator=""
        :num-inputs="6"
        :should-auto-focus="true"
        input-type="number"
        @on-change="handleOnChange"
        @on-complete="handleOnComplete"
      /&gt;
    &lt;/div&gt;

    &lt;div class="otp-input-wrap"&gt;
      &lt;v-otp-input
        ref="otpInput1"
        input-classes="otp-input"
        separator=""
        :num-inputs="6"
        :should-auto-focus="true"
        input-type="number"
        @on-change="handleOnChange"
        @on-complete="handleOnComplete"
        :class="isInvalidCodce ? 'input-validate-danger' : ''"
      /&gt;
      &lt;div v-if="isInvalidCodce" class="span-text-validation-danger"&gt;&lt;span class="span-text-validation"&gt;Invalid code. Please try again&lt;/span&gt;&lt;/div&gt;
    &lt;/div&gt;

    &lt;script&gt;
      import OtpInput from "@bachdgvn/vue-otp-input";
      export default {
        data() {
          return {
            isInvalidCodce: true,
          },
          components: {
            'v-otp-input': OtpInput,
          },
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <h3 class="mb-5" id="editable-fields">Editable Fields</h3>
    <!-- EDITABLE FIELD START -->
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Basic fields</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <h6 class="mb-4">Default</h6>
              <div class="editable-field" :class="editedFieldId === 1 ? 'editable' : ''">
                <label class="input-label">Label</label>
                <template v-if="editedFieldId === 1">
                  <vs-input class="input-field" placeholder="Required" v-model="value1" :id="'field'+1" />
                  <div class="flex flex-row justify-end gap-x-6 mt-4">
                    <vs-button color="primary" size="small" @click.prevent="toggleEdit">Save</vs-button>
                    <vs-button type="flat" @click.prevent="toggleEdit(null, 'cancel')" size="small" :ref="btn-cancel">Cancel</vs-button>
                  </div>
                </template>
                <template v-else>
                  <vs-input placeholder="Required" readonly v-model="value1" />
                  <vs-icon type="flat" class="icon-gray edit-icon material-icons-outlined" icon="edit" icon-size="small" @click.prevent="toggleEdit(1)" />
                </template>
              </div>
            </div>
            <div>
              <h6 class="mb-4">Error</h6>
              <div class="editable-field input-icon-validate-danger" :class="editedFieldId === 2 ? 'editable' : ''">
                <label class="input-label danger">Label</label>
                <template v-if="editedFieldId === 2">
                  <vs-input class="input-field" placeholder="Required" v-model="value1" :id="'field'+2" :danger="true" danger-text="Error message" />
                  <div class="flex flex-row justify-end gap-x-6 mt-4">
                    <vs-button color="primary" size="small" @click.prevent="toggleEdit">Save</vs-button>
                    <vs-button type="flat" @click.prevent="toggleEdit(null, 'cancel')" size="small" :ref="btn-cancel">Cancel</vs-button>
                  </div>
                </template>
                <template v-else>
                  <vs-input placeholder="Required" readonly v-model="value1" />
                  <vs-icon type="flat" class="icon-gray edit-icon material-icons-outlined" icon="edit" icon-size="small" @click.prevent="toggleEdit(2)" />
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="grid md:grid-cols-2 gap-6"&gt;
      &lt;div&gt;
        &lt;h6 class="mb-4"&gt;Default&lt;/h6&gt;
        &lt;div class="editable-field" :class="editedFieldId === 1 ? 'editable' : ''"&gt;
          &lt;label class="input-label"&gt;Label&lt;/label&gt;
          &lt;template v-if="editedFieldId === 1"&gt;
            &lt;vs-input class="input-field" placeholder="Required" v-model="value1" :id="'field'+1" /&gt;
            &lt;div class="flex flex-row justify-end gap-x-6 mt-4"&gt;
              &lt;vs-button color="primary" size="small" @click.prevent="toggleEdit"&gt;Save&lt;/vs-button&gt;
              &lt;vs-button type="flat" @click.prevent="toggleEdit(null, 'cancel')" size="small" :ref="btn-cancel"&gt;Cancel&lt;/vs-button&gt;
            &lt;/div&gt;
          &lt;/template&gt;
          &lt;template v-else&gt;
            &lt;vs-input placeholder="Required" readonly v-model="value1" /&gt;
            &lt;vs-icon type="flat" class="icon-gray edit-icon material-icons-outlined" icon="edit" icon-size="small" @click.prevent="toggleEdit(1)" /&gt;
          &lt;/template&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h6 class="mb-4"&gt;Error&lt;/h6&gt;
        &lt;div class="editable-field input-icon-validate-danger" :class="editedFieldId === 2 ? 'editable' : ''"&gt;
          &lt;label class="input-label danger"&gt;Label&lt;/label&gt;
          &lt;template v-if="editedFieldId === 2"&gt;
            &lt;vs-input class="input-field" placeholder="Required" v-model="value1" :id="'field'+2" :danger="true" danger-text="Error message" /&gt;
            &lt;div class="flex flex-row justify-end gap-x-6 mt-4"&gt;
              &lt;vs-button color="primary" size="small" @click.prevent="toggleEdit"&gt;Save&lt;/vs-button&gt;
              &lt;vs-button type="flat" @click.prevent="toggleEdit(null, 'cancel')" size="small" :ref="btn-cancel"&gt;Cancel&lt;/vs-button&gt;
            &lt;/div&gt;
          &lt;/template&gt;
          &lt;template v-else&gt;
            &lt;vs-input placeholder="Required" readonly v-model="value1" /&gt;
            &lt;vs-icon type="flat" class="icon-gray edit-icon material-icons-outlined" icon="edit" icon-size="small" @click.prevent="toggleEdit(2)" /&gt;
          &lt;/template&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            value1: '',
            editedFieldId: null,
          },
          methods: {
            async setId(id) {
              this.editedFieldId = await id;
            },
            toggleEdit(id = null, action = null) {
              if (id) {
                this.setId(id).then(res => {
                  if (document.getElementById('field'+id)) {
                    document.getElementById('field'+id).focus();
                  }
                });
              } else {
                this.editedFieldId = null;
                if (action == 'cancel') {
                  this.value1 = '';
                }
              }
            },
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Address fields</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <h6 class="mb-4">Auto complete</h6>
              <div class="editable-field" :class="editedFieldId === 3 ? 'editable' : ''">
                <label class="input-label">Address</label>
                <template v-if="editedFieldId === 3">
                  <div class="custom-input-field">
                    <vue-google-autocomplete ref="address" v-on:placechanged="getAddressData" id="map" classname="w-full custom-w" :country="['au']" placeholder="Start typing your address" :id="'field'+3" v-model="rawAddress"></vue-google-autocomplete>
                  </div>
                  <div class="flex flex-row justify-end gap-x-6 mt-4">
                    <vs-button color="primary" size="small" @click.prevent="toggleEdit">Save</vs-button>
                    <vs-button type="flat" @click.prevent="toggleEdit(null, 'cancel')" size="small" :ref="btn-cancel">Cancel</vs-button>
                  </div>
                </template>
                <template v-else>
                  <vs-input placeholder="Required" v-model="rawAddress" readonly />
                  <vs-icon type="flat" class="icon-gray edit-icon material-icons-outlined" icon="edit" icon-size="small" @click.prevent="toggleEdit(3)" />
                </template>
              </div>
            </div>
            <div>
              <h6 class="mb-4">Manual/Auto Entry</h6>
              <div class="editable-field" :class="editedFieldId === 4 ? 'editable' : ''">
                <label class="input-label">Address</label>
                <template v-if="editedFieldId === 4">
                  <div class="grid gap-y-6">
                    <div>
                      <div v-if="!isExpanded2" class="custom-input-field">
                        <vue-google-autocomplete ref="address" v-on:placechanged="getAddressData" id="map" classname="w-full custom-w" :country="['au']" placeholder="Start typing your address" :id="'field'+4" v-model="rawAddress"></vue-google-autocomplete>
                      </div>
                      <vs-input v-else placeholder="Enter address" v-model="formData.street_name" />
                      <vs-button type="flat" class="mt-2" @click="toggleExpand2" v-show="isExpanded2" size="small">Back to auto-complete</vs-button>
                      <vs-button type="flat" class="mt-2" @click="toggleExpand2" v-show="!isExpanded2" size="small">Enter manually</vs-button>
                    </div>
                    <div v-show="isExpanded2">
                      <div class="grid gap-y-6">
                        <div>
                          <vs-input label="Apt, Suite, Unit, Building, Floor" v-model="formData.street_number" />
                        </div>
                        <div>
                          <vs-input label="Suburb" v-model="formData.suburb" />
                        </div>
                        <div class="flex flex-row gap-x-6">
                          <div class="w-1/2">
                            <vs-input label="State" v-model="formData.state" />
                          </div>
                          <div class="w-1/2">
                            <vs-input label="Postcode" v-model="formData.postcode" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-row justify-end gap-x-6 mt-4">
                    <vs-button color="primary" size="small" @click.prevent="toggleEdit">Save</vs-button>
                    <vs-button type="flat" @click.prevent="toggleEdit(null, 'cancel')" size="small" :ref="btn-cancel">Cancel</vs-button>
                  </div>
                </template>
                <template v-else>
                  <vs-input placeholder="Required" v-model="rawAddress" />
                  <vs-icon type="flat" class="icon-gray edit-icon material-icons-outlined" icon="edit" icon-size="small" @click.prevent="toggleEdit(4)" />
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="grid md:grid-cols-2 gap-6"&gt;
      &lt;div&gt;
        &lt;h6 class="mb-4"&gt;Auto complete&lt;/h6&gt;
        &lt;div class="editable-field" :class="editedFieldId === 3 ? 'editable' : ''"&gt;
          &lt;label class="input-label"&gt;Address&lt;/label&gt;
          &lt;template v-if="editedFieldId === 3"&gt;
            &lt;div class="custom-input-field"&gt;
              &lt;vue-google-autocomplete ref="address" v-on:placechanged="getAddressData" id="map" classname="w-full custom-w" :country="['au']" placeholder="Start typing your address" :id="'field'+3" v-model="rawAddress"&gt;&lt;/vue-google-autocomplete&gt;
            &lt;/div&gt;
            &lt;div class="flex flex-row justify-end gap-x-6 mt-4"&gt;
              &lt;vs-button color="primary" size="small" @click.prevent="toggleEdit"&gt;Save&lt;/vs-button&gt;
              &lt;vs-button type="flat" @click.prevent="toggleEdit(null, 'cancel')" size="small" :ref="btn-cancel"&gt;Cancel&lt;/vs-button&gt;
            &lt;/div&gt;
          &lt;/template&gt;
          &lt;template v-else&gt;
            &lt;vs-input placeholder="Required" v-model="rawAddress" readonly /&gt;
            &lt;vs-icon type="flat" class="icon-gray edit-icon material-icons-outlined" icon="edit" icon-size="small" @click.prevent="toggleEdit(3)" /&gt;
          &lt;/template&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;h6 class="mb-4"&gt;Manual/Auto Entry&lt;/h6&gt;
        &lt;div class="editable-field" :class="editedFieldId === 4 ? 'editable' : ''"&gt;
          &lt;label class="input-label"&gt;Address&lt;/label&gt;
          &lt;template v-if="editedFieldId === 4"&gt;
            &lt;div class="grid gap-y-6"&gt;
              &lt;div&gt;
                &lt;div v-if="!isExpanded2" class="custom-input-field"&gt;
                  &lt;vue-google-autocomplete ref="address" v-on:placechanged="getAddressData" id="map" classname="w-full custom-w" :country="['au']" placeholder="Start typing your address" :id="'field'+4" v-model="rawAddress"&gt;&lt;/vue-google-autocomplete&gt;
                &lt;/div&gt;
                &lt;vs-input v-else placeholder="Enter address" v-model="formData.street_name" /&gt;
                &lt;vs-button type="flat" class="mt-2" @click="toggleExpand2" v-show="isExpanded2" size="small"&gt;Back to auto-complete&lt;/vs-button&gt;
                &lt;vs-button type="flat" class="mt-2" @click="toggleExpand2" v-show="!isExpanded2" size="small"&gt;Enter manually&lt;/vs-button&gt;
              &lt;/div&gt;
              &lt;div v-show="isExpanded2"&gt;
                &lt;div class="grid gap-y-6"&gt;
                  &lt;div&gt;
                    &lt;vs-input label="Apt, Suite, Unit, Building, Floor" v-model="formData.street_number" /&gt;
                  &lt;/div&gt;
                  &lt;div&gt;
                    &lt;vs-input label="Suburb" v-model="formData.suburb" /&gt;
                  &lt;/div&gt;
                  &lt;div class="flex flex-row gap-x-6"&gt;
                    &lt;div class="w-1/2"&gt;
                      &lt;vs-input label="State" v-model="formData.state" /&gt;
                    &lt;/div&gt;
                    &lt;div class="w-1/2"&gt;
                      &lt;vs-input label="Postcode" v-model="formData.postcode" /&gt;
                    &lt;/div&gt;
                  &lt;/div&gt;
                &lt;/div&gt;
              &lt;/div&gt;
            &lt;/div&gt;
            &lt;div class="flex flex-row justify-end gap-x-6 mt-4"&gt;
              &lt;vs-button color="primary" size="small" @click.prevent="toggleEdit"&gt;Save&lt;/vs-button&gt;
              &lt;vs-button type="flat" @click.prevent="toggleEdit(null, 'cancel')" size="small" :ref="btn-cancel"&gt;Cancel&lt;/vs-button&gt;
            &lt;/div&gt;
          &lt;/template&gt;
          &lt;template v-else&gt;
            &lt;vs-input placeholder="Required" v-model="rawAddress" /&gt;
            &lt;vs-icon type="flat" class="icon-gray edit-icon material-icons-outlined" icon="edit" icon-size="small" @click.prevent="toggleEdit(4)" /&gt;
          &lt;/template&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            editedFieldId: null,
            isExpanded2: false,
          },
          components: {
            VueGoogleAutocomplete,
          },
          methods: {
            async setId(id) {
              this.editedFieldId = await id;
            },
            toggleEdit(id = null, action = null) {
              if (id) {
                this.setId(id).then(res => {
                  if (document.getElementById('field'+id)) {
                    document.getElementById('field'+id).focus();
                  }
                });
              } else {
                this.editedFieldId = null;
                if (action == 'cancel') {
                  this.value1 = '';
                }
              }
            },
            toggleExpand2() {
              this.isExpanded2 = !this.isExpanded2;
            },
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <h3 class="mb-5" id="format-fields">Format Fields</h3>
    <!-- FORMAT FIELD STARTS -->
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Format fields with label</h6>
        </template>
        <template v-slot:content>
          <!-- Format field with label -->
          <div class="grid md:grid-cols-2 gap-6">
            <!-- Default state -->
            <div>
              <h6 class="mb-4">Default</h6>
              <editor
                v-model="description"
                :counter="250"
                :editorOption="editorOption"
                :label="`Label`"
              ></editor>
            </div>

            <!-- Error state -->
            <div>
              <h6 class="mb-4">Error</h6>
              <editor
                v-model="errorState1"
                :counter="250"
                :editorOption="editorOption"
                :name="`message`"
                :validate="'required'"
                :label="`Label`"
                :hasError="true"
                :errorMessage="`Error message`"
              ></editor>
            </div>

            <!-- Disabled state -->
            <div :class="disableEditor ? 'opacity-75' : ''">
              <h6 class="mb-4">Disabled</h6>
              <editor
                :counter="250"
                :editorOption="editorOption"
                :label="`Label`"
                :disabled="disableEditor"
              ></editor>
            </div>
          </div>

          <hr class="my-6 line-hr" />

          <h6 class="mt-5 mb-3 font-medium">Format fields without label</h6>

          <!-- Format field without label -->
          <div class="grid md:grid-cols-2 gap-6">
            <!-- Default state -->
            <div>
              <h6 class="mb-4">Default</h6>
              <editor
                v-model="defaultState2"
                :counter="250"
                :editorOption="editorOption"
              ></editor>
            </div>

            <!-- Error state -->
            <div>
              <h6 class="mb-4">Error</h6>
              <editor
                v-model="errorState2"
                :counter="250"
                :editorOption="editorOption"
                :validate="'required'"
                :name="`editor name`"
                :hasError="true"
                :errorMessage="`Error message`"
              ></editor>
            </div>

            <!-- Disabled state -->
            <div :class="disableEditor ? 'opacity-75' : ''">
              <h6 class="mb-4">Disabled</h6>
              <editor
                :counter="250"
                :editorOption="editorOption"
                :disabled="disableEditor"
              ></editor>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
      &lt;editor v-model="description" :counter="250" :editorOption="editorOption" :label="`Label`"&gt;&lt;/editor&gt;
      &lt;editor v-model="errorState1" :counter="250" :editorOption="editorOption" :name="`message`" :validate="'required'" :label="`Label`" :hasError="true" :errorMessage="`Error message`"&gt;&lt;/editor&gt;
      &lt;editor :counter="250" :editorOption="editorOption" :label="`Label`" :disabled="disableEditor"&gt;&lt;/editor&gt;
      ------------------------------------------------------------------------------------------------------------------------------
      &lt;editor v-model="defaultState2" :counter="250" :editorOption="editorOption"&gt;&lt;/editor&gt;
      &lt;editor v-model="errorState2" :counter="250" :editorOption="editorOption" :validate="'required'" :name="`editor name`" :hasError="true" :errorMessage="`Error message`"&gt;&lt;/editor&gt;
      &lt;editor :counter="250" :editorOption="editorOption" :disabled="disableEditor"&gt;&lt;/editor&gt;

      &lt;script&gt;
        import Editor from "../../QuillEditorCustom.vue";

        export default {
          data() {
            return {
              description: "",
              errorState1: "",
              defaultState2: "",
              errorState2: "",
              disableEditor: true,
              editorOption: {
                modules: {
                  toolbar: [
                    [
                      "bold",
                      "italic",
                      "link",
                    ],
                  ],
                },
                placeholder: "Required"
              },
            }
          },
          components: {
            Editor,
          }
        }
      &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";
  import DatePicker from "vue2-datepicker";
  import { mask, TheMask } from 'vue-the-mask';
  import moment from "moment";
  import OtpInput from "@bachdgvn/vue-otp-input";
  import VueGoogleAutocomplete from "vue-google-autocomplete";
  import Editor from "../../QuillEditorCustom.vue";

  const uploadedImage = require("@/assets/images/uploads/a-logo.png");

  // Required for left alignment of datepicker
  DatePicker.methods.displayPopup = function() {
    this.position = {
      left: 0,
      top: '100%'
    }
  }

  export default {
    data:() => ({
      options1: [
        {text: 'IT', value: 0},
        {text: 'Blade Runner', value: 2},
        {text: 'Thor Ragnarok', value: 3},
        {text: 'Thor Ragnarok', value: 3},
        {text: 'Thor Ragnarok', value: 3},
        {text: 'Field content', value: 3},
      ],
      value1: '',
      value2: '',
      value5: '',
      value9: '',
      value10: '',
      showDateValid: true,
      showDatePickerValid: true,
      radios1: 'selected',
      radios2: 'selected',
      radios5: 'selected',
      radio6: 'selected',
      checkBox1: true,
      checkBox2: false,
      checkBox3: false,
      checkBox4: false,
      switch1: true,
      switch2: false,
      switch3: true,
      switch4: false,
      uploadedImage: uploadedImage,
      radioCard: {
        cardOption: "FIRST_CARD",
        headerOption: "FIRST_HEADER",
      },
      checkboxCard: {
        cardOption1: true,
        cardOption2: false,
        headerOption1: true,
        headerOption2: false,
      },
      isExpanded: false,
      isExpanded2: false,
      isInvalidCodce: true,
      editedFieldId: null,
      fileUploaded: 'true',
      addressData: "",
      formData: {
        street_number: "",
        postcode: "",
        street_name: "",
        suburb: "",
        state: "",
        rawAddress: "",
      },
      description: "",
      errorState1: "",
      defaultState2: "",
      errorState2: "",
      disableEditor: true,
      editorOption: {
        modules: {
          toolbar: [
            [
              "bold",
              "italic",
              "link",
            ],
          ],
        },
        placeholder: "Required"
      },
    }),
    directives: {
      mask
    },
    components: {
      TheMask,
      Prism,
      VueAccordion,
      DatePicker,
      'v-otp-input': OtpInput,
      VueGoogleAutocomplete,
      Editor,
    },
    methods: {
      toggleFileUpload() {
        this.fileUploaded = !this.fileUploaded;
      },
      toggleExpand() {
        this.isExpanded = !this.isExpanded;
      },
      toggleExpand2() {
        this.isExpanded2 = !this.isExpanded2;
      },
      async setId(id) {
        this.editedFieldId = await id;
      },

      toggleEdit(id = null, action = null) {
        if (id) {
          this.setId(id).then(res => {
            if (document.getElementById('field'+id)) {
              document.getElementById('field'+id).focus();
            }
          });
        } else {
          this.editedFieldId = null;
          if (action == 'cancel') {
            this.value1 = '';
          }
        }
      },

      handleDateFormat() {
        if (this.value10 == '') {
          this.showDateValid = true;
          return;
        }
        this.showDateValid = moment(this.value10, 'DD/MM/YYYY', true).isValid();
        if (!this.showDateValid) {
          return;
        }
        const year = moment(this.value10, "DD/MM/YYYY").year();
        this.showDateValid = (parseInt(year) <= 2999);
      },

      handleDatePickerFormat() {
        if (!this.value1) {
          this.showDatePickerValid = true;
          return;
        }
        this.showDatePickerValid = moment(this.value1, 'DD/MM/YYYY', true).isValid();
        if (!this.showDatePickerValid) {
          return;
        }
        this.showDatePickerValid = moment(this.value1, 'DD/MM/YYYY', true).isValid();
        const year = moment(this.value1, "DD/MM/YYYY").year();
        this.showDatePickerValid = (parseInt(year) <= 2999);
      },

      getAddressData: function(addressData, placeResultData, _id) {
        this.addressData = addressData;
        this.rawAddress = placeResultData.formatted_address;

        if (this.addressData.street_number) {
          this.formData.street_number = this.addressData.street_number;
        }

        if (this.addressData.postal_code) {
          this.formData.postcode = this.addressData.postal_code;
        }

        if (placeResultData.vicinity) {
          this.formData.suburb = placeResultData.vicinity;
        }

        if (this.addressData.administrative_area_level_1) {
          this.formData.state = this.addressData.administrative_area_level_1;
        }

        if (this.addressData.route) {
          this.formData.street_name = this.addressData.route;
          let street_name = this.formData.street_name;
          street_name = street_name.split(" ");

          if (street_name.length > 1) {
            let street_type = street_name[street_name.length - 1];

            if (street_type) {
              this.formData.street_type = street_type;
            }

            this.formData.street_name = street_name.join(" ");
          }

          this.formData.street_name = this.addressData.street_number + " " + this.addressData.route;
        }
      },

      handleDateChange(event) {
        const value = event.target.value.split("/");
        const dayValue = value[0].charAt(0);
        const monthValue = value[1] ? value[1].charAt(0) : '';
        if (value[0].length == 1) {
          if (dayValue >= 4) {
            event.target.value = "0" + dayValue;
          }
          if (event.key == '/') {
            event.target.value = "0" + value[0] + "/";
          }
        }

        if (value[1].length == 1) {
          if (monthValue && monthValue >= 2) {
            event.target.value = value[0] + "/0" + monthValue;
          }
          if (event.key == '/') {
            event.target.value = value[0] + "/0" + monthValue + "/";
          }
        }
      },
    }
  }
</script>

