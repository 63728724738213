<template>
  <div>
    <h2 class="mb-5">Grids</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#base-grid'">Base Grid</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#responsive-grid'">Responsive Grid</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#tailwind-grid'">Tailwind Grid System</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#tailwind-widths'">Tailwind Widths</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#breakpoints'">Breakpoints</vs-button></div>
      </div>
    </vs-card>

    <!-- BASIC GRID START -->
    <h3 class="mb-5" id="base-grid">Base</h3>
    <!-- BASE TEXT INPUT -->
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Base Vuesax Grid</h6>
        </template>
        <template v-slot:content>
          <!-- 100% width grid -->
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" class="border border-slate-400 bg-white">
              100%
            </vs-col>
          </vs-row>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-row&gt;
      &lt;vs-col vs-w="12"&gt;
        100%
      &lt;/vs-col&gt;
    &lt;/vs-row&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Two Column (50%)</h6>
        </template>
        <template v-slot:content>
          <!-- 50% width grid -->
          <vs-row>
            <vs-col :key="index" v-for="col,index in 2" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" class="border border-slate-400 bg-white">
              50%
            </vs-col>
          </vs-row>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-row&gt;
      &lt;vs-col vs-w="6"&gt;
        50%
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="6"&gt;
        50%
      &lt;/vs-col&gt;
    &lt;/vs-row&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Three Column (33.3%)</h6>
        </template>
        <template v-slot:content>
          <!-- 33.3% width grid -->
          <vs-row>
          <vs-col :key="index" v-for="col,index in 3" v-tooltip="'col - 4'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="4" class="border border-slate-400 bg-white">
            33.3%
          </vs-col>
        </vs-row>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-row&gt;
      &lt;vs-col vs-w="4"&gt;
        33.3%
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="4"&gt;
        33.3%
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="4"&gt;
        33.3%
      &lt;/vs-col&gt;
    &lt;/vs-row&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Four Column (25%)</h6>
        </template>
        <template v-slot:content>
          <!-- 25% width grid -->
          <vs-row>
            <vs-col :key="index" v-for="col,index in 4" v-tooltip="'col - 3'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="3" class="border border-slate-400 bg-white">
              25%
            </vs-col>
          </vs-row>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-row&gt;
      &lt;vs-col vs-w="3"&gt;
        25%
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="3"&gt;
        25%
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="3"&gt;
        25%
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="3"&gt;
        25%
      &lt;/vs-col&gt;
    &lt;/vs-row&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Six Column (16.6%)</h6>
        </template>
        <template v-slot:content>
          <!-- 16.6% width grid -->
          <vs-row>
            <vs-col :key="index" v-for="col,index in 6" v-tooltip="'col - 2'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="border border-slate-400 bg-white">
              16.6%
            </vs-col>
          </vs-row>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-row&gt;
      &lt;vs-col vs-w="2"&gt;
        16.6%
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="2"&gt;
        16.6%
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="2"&gt;
        16.6%
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="2"&gt;
        16.6%
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="2"&gt;
        16.6%
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="2"&gt;
        16.6%
      &lt;/vs-col&gt;
    &lt;/vs-row&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Twelve Column (8.3%)</h6>
        </template>
        <template v-slot:content>
          <!-- 8.3% width grid  -->
          <vs-row>
            <vs-col :key="index" v-for="col,index in 12" v-tooltip="'col - 1'" vs-type="flex" vs-justify="center" vs-align="center" vs-w="1" class="border border-slate-400 bg-white">
              8.3%
            </vs-col>
          </vs-row>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-row&gt;
      &lt;vs-col vs-w="1"&gt;
        8.3%
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="1"&gt;
        8.3
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="1"&gt;
        8.3
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="1"&gt;
        8.3
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="1"&gt;
        8.3%
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="1"&gt;
        8.3
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="1"&gt;
        8.3
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="1"&gt;
        8.3
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="1"&gt;
        8.3%
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="1"&gt;
        8.3
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="1"&gt;
        8.3
      &lt;/vs-col&gt;
      &lt;vs-col vs-w="1"&gt;
        8.3
      &lt;/vs-col&gt;
    &lt;/vs-row&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- BASIC GRID ENDS -->

    <!-- RESPONSIVE GRID START -->
    <h3 class="mb-5" id="responsive-grid">Responsive Grid</h3>
    <!-- BASE TEXT INPUT -->
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Responsive Vuesax Grid</h6>
        </template>
        <template v-slot:content>
          <!-- responsive grid -->
          <vs-row vs-w="12">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="4" vs-xs="12" class="border border-slate-400 bg-white">
              vs-lg="2" <br/>
              vs-sm="4" <br/>
              vs-xs="12"
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="4" vs-xs="12" class="border border-slate-400 bg-white">
              vs-lg="8"<br/>
              vs-sm="4"<br/>
              vs-xs="12"
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="4" vs-xs="12" class="border border-slate-400 bg-white">
              vs-lg="2"<br/>
              vs-sm="4"<br/>
              vs-xs="12"
            </vs-col>
          </vs-row>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-row vs-w="12"&gt;
      &lt;vs-col vs-lg="2" vs-sm="4" vs-xs="12"&gt;
        vs-lg="2"&lt;br/&gt;
        vs-sm="4"&lt;br/&gt;
        vs-xs="12"
      &lt;/vs-col&gt;
      &lt;vs-col vs-lg="8" vs-sm="4" vs-xs="12"&gt;
        vs-lg="8"&lt;br/&gt;
        vs-sm="4"&lt;br/&gt;
        vs-xs="12"
      &lt;/vs-col&gt;
      &lt;vs-col vs-lg="2" vs-sm="4" vs-xs="12"&gt;
        vs-lg="2"&lt;br/&gt;
        vs-sm="4"&lt;br/&gt;
        vs-xs="12"
      &lt;/vs-col&gt;
    &lt;/vs-row&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- RESPONSIVE GRID ENDS -->

    <!-- TAILWIND GRID START -->
    <h3 class="mb-5" id="tailwind-grid">Tailwind Grid System</h3>
    <!-- BASE TEXT INPUT -->
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Grid Columns Template</h6>
        </template>
        <template v-slot:content>
          <!-- tailwind grid -->
          <h6 class="mb-4">Use the grid-cols-{n} utilities to create grids with n equally sized columns.</h6>
          <div class="grid grid-cols-4 gap-4">
            <div class="border border-slate-400 text-center bg-white">01</div>
            <div class="border border-slate-400 text-center bg-white">02</div>
            <div class="border border-slate-400 text-center bg-white">03</div>
            <div class="border border-slate-400 text-center bg-white">04</div>
            <div class="border border-slate-400 text-center bg-white">05</div>
            <div class="border border-slate-400 text-center bg-white">06</div>
            <div class="border border-slate-400 text-center bg-white">07</div>
            <div class="border border-slate-400 text-center bg-white">08</div>
            <div class="border border-slate-400 text-center bg-white">09</div>
            <div class="border border-slate-400 text-center bg-white">10</div>
            <div class="border border-slate-400 text-center bg-white">11</div>
            <div class="border border-slate-400 text-center bg-white">12</div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="grid grid-cols-4 gap-4"&gt;
      &lt;div&gt;01&lt;/div&gt;
      &lt;div&gt;02&lt;/div&gt;
      &lt;div&gt;03&lt;/div&gt;
      &lt;div&gt;04&lt;/div&gt;
      &lt;div&gt;05&lt;/div&gt;
      &lt;div&gt;06&lt;/div&gt;
      &lt;div&gt;07&lt;/div&gt;
      &lt;div&gt;08&lt;/div&gt;
      &lt;div&gt;09&lt;/div&gt;
      &lt;div&gt;10&lt;/div&gt;
      &lt;div&gt;11&lt;/div&gt;
      &lt;div&gt;12&lt;/div&gt;
    &lt;/div&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Spanning columns</h6>
        </template>
        <template v-slot:content>
          <!-- tailwind grid -->
          <h6 class="mb-4">Use the col-span-{n} utilities to make an element span n columns.</h6>
          <div class="grid grid-cols-3 gap-4">
            <div class="border border-slate-400 text-center bg-white">01</div>
            <div class="border border-slate-400 text-center bg-white">02</div>
            <div class="border border-slate-400 text-center bg-white">03</div>
            <div class="col-span-2 border border-slate-400 text-center bg-white">04</div>
            <div class="border border-slate-400 text-center bg-white">05</div>
            <div class="border border-slate-400 text-center bg-white">06</div>
            <div class="col-span-2 border border-slate-400 text-center bg-white">07</div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="grid grid-cols-3 gap-4"&gt;
      &lt;div&gt;01&lt;/div&gt;
      &lt;div&gt;02&lt;/div&gt;
      &lt;div&gt;03&lt;/div&gt;
      &lt;div class="col-span-2"&gt;04&lt;/div&gt;
      &lt;div&gt;05&lt;/div&gt;
      &lt;div&gt;06&lt;/div&gt;
      &lt;div class="col-span-2"&gt;07&lt;/div&gt;
    &lt;/div&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Spanning rows</h6>
        </template>
        <template v-slot:content>
          <!-- tailwind grid -->
          <h6 class="mb-4">Use the row-span-{n} utilities to make an element span n columns.</h6>
          <div class="grid grid-rows-3 grid-flow-col gap-4">
            <div class="row-span-3 border border-slate-400 text-center bg-white grid place-content-center">01</div>
            <div class="col-span-2 border border-slate-400 text-center bg-white">02</div>
            <div class="row-span-2 col-span-2 border border-slate-400 text-center bg-white grid place-content-center">03</div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="grid grid-rows-3 grid-flow-col gap-4"&gt;
      &lt;div class="row-span-3"&gt;01&lt;/div&gt;
      &lt;div class="col-span-2"&gt;02&lt;/div&gt;
      &lt;div class="row-span-2 col-span-2"&gt;03&lt;/div&gt;
    &lt;/div&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Responsive Grid Columns Template</h6>
        </template>
        <template v-slot:content>
          <!-- tailwind grid -->
          <h6 class="mb-4">lg: 12 columns, md: 6 Columns, sm: 3 Columns, xs: 1 Column</h6>
          <div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-2 sm:gap-4 md:gap-3 lg:gap-2">
            <div class="border border-slate-400 text-center bg-white">01</div>
            <div class="border border-slate-400 text-center bg-white">02</div>
            <div class="border border-slate-400 text-center bg-white">03</div>
            <div class="border border-slate-400 text-center bg-white">04</div>
            <div class="border border-slate-400 text-center bg-white">05</div>
            <div class="border border-slate-400 text-center bg-white">06</div>
            <div class="border border-slate-400 text-center bg-white">07</div>
            <div class="border border-slate-400 text-center bg-white">08</div>
            <div class="border border-slate-400 text-center bg-white">09</div>
            <div class="border border-slate-400 text-center bg-white">10</div>
            <div class="border border-slate-400 text-center bg-white">11</div>
            <div class="border border-slate-400 text-center bg-white">12</div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-2 sm:gap-4 md:gap-3 lg:gap-2"&gt;
      &lt;div&gt;01&lt;/div&gt;
      &lt;div&gt;02&lt;/div&gt;
      &lt;div&gt;03&lt;/div&gt;
      &lt;div&gt;04&lt;/div&gt;
      &lt;div&gt;05&lt;/div&gt;
      &lt;div&gt;06&lt;/div&gt;
      &lt;div&gt;07&lt;/div&gt;
      &lt;div&gt;08&lt;/div&gt;
      &lt;div&gt;09&lt;/div&gt;
      &lt;div&gt;10&lt;/div&gt;
      &lt;div&gt;11&lt;/div&gt;
      &lt;div&gt;12&lt;/div&gt;
    &lt;/div&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Responsive Grid Rows Template</h6>
        </template>
        <template v-slot:content>
          <!-- tailwind grid -->
          <h6 class="mb-4">lg: 2 Rows, md: 3 Rows, sm: 4 Rows, xs: 6 Rows</h6>
          <div class="grid grid-flow-col grid-rows-6 sm:grid-rows-4 md:grid-rows-3 lg:grid-rows-2 gap-2 sm:gap-4 md:gap-3 lg:gap-2">
            <div class="border border-slate-400 text-center bg-white">01</div>
            <div class="border border-slate-400 text-center bg-white">02</div>
            <div class="border border-slate-400 text-center bg-white">03</div>
            <div class="border border-slate-400 text-center bg-white">04</div>
            <div class="border border-slate-400 text-center bg-white">05</div>
            <div class="border border-slate-400 text-center bg-white">06</div>
            <div class="border border-slate-400 text-center bg-white">07</div>
            <div class="border border-slate-400 text-center bg-white">08</div>
            <div class="border border-slate-400 text-center bg-white">09</div>
            <div class="border border-slate-400 text-center bg-white">10</div>
            <div class="border border-slate-400 text-center bg-white">11</div>
            <div class="border border-slate-400 text-center bg-white">12</div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="grid grid-flow-col grid-rows-6 sm:grid-rows-4 md:grid-rows-3 lg:grid-rows-2 gap-2 sm:gap-4 md:gap-3 lg:gap-2"&gt;
      &lt;div&gt;01&lt;/div&gt;
      &lt;div&gt;02&lt;/div&gt;
      &lt;div&gt;03&lt;/div&gt;
      &lt;div&gt;04&lt;/div&gt;
      &lt;div&gt;05&lt;/div&gt;
      &lt;div&gt;06&lt;/div&gt;
      &lt;div&gt;07&lt;/div&gt;
      &lt;div&gt;08&lt;/div&gt;
      &lt;div&gt;09&lt;/div&gt;
      &lt;div&gt;10&lt;/div&gt;
      &lt;div&gt;11&lt;/div&gt;
      &lt;div&gt;12&lt;/div&gt;
    &lt;/div&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- TAILWIND GRID ENDS -->

    <!-- TAILWIND WIDTHS START -->
    <h3 class="mb-5" id="tailwind-widths">Tailwind Widths</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Widths</h6>
        </template>
        <template v-slot:content>
          <!-- tailwind width -->
          <h6 class="mb-4">Utilities for setting the width of an element.</h6>
          <div class="flex">
            <div class="w-1/2 border border-slate-400 text-center bg-white">w-1/2</div>
            <div class="w-1/2 border border-slate-400 text-center bg-white">w-1/2</div>
          </div>
          <div class="flex">
            <div class="w-2/5 border border-slate-400 text-center bg-white">w-2/5</div>
            <div class="w-3/5 border border-slate-400 text-center bg-white">w-3/5</div>
          </div>
          <div class="flex">
            <div class="w-1/3 border border-slate-400 text-center bg-white">w-1/3</div>
            <div class="w-2/3 border border-slate-400 text-center bg-white">w-2/3</div>
          </div>
          <div class="flex">
            <div class="w-1/4 border border-slate-400 text-center bg-white">w-1/4</div>
            <div class="w-3/4 border border-slate-400 text-center bg-white">w-3/4</div>
          </div>
          <div class="flex">
            <div class="w-1/5 border border-slate-400 text-center bg-white">w-1/5</div>
            <div class="w-4/5 border border-slate-400 text-center bg-white">w-4/5</div>
          </div>
          <div class="flex">
            <div class="w-1/6 border border-slate-400 text-center bg-white">w-1/6</div>
            <div class="w-5/6 border border-slate-400 text-center bg-white">w-5/6</div>
          </div>
          <div class="flex">
            <div class="w-1/12 border border-slate-400 text-center bg-white">w-1/12</div>
            <div class="w-11/12 border border-slate-400 text-center bg-white">w-11/12</div>
          </div>
          <div class="w-full border border-slate-400 text-center bg-white">w-full</div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="flex"&gt;
      &lt;div class="w-1/2"&gt;w-1/2&lt;/div&gt;
      &lt;div class="w-1/2"&gt;w-1/2&lt;/div&gt;
    &lt;/div&gt;
    &lt;div class="flex"&gt;
      &lt;div class="w-2/5"&gt;w-2/5&lt;/div&gt;
      &lt;div class="w-3/5"&gt;w-3/5&lt;/div&gt;
    &lt;/div&gt;
    &lt;div class="flex"&gt;
      &lt;div class="w-1/3"&gt;w-1/3&lt;/div&gt;
      &lt;div class="w-2/3"&gt;w-2/3&lt;/div&gt;
    &lt;/div&gt;
    &lt;div class="flex"&gt;
      &lt;div class="w-1/4"&gt;w-1/4&lt;/div&gt;
      &lt;div class="w-3/4"&gt;w-3/4&lt;/div&gt;
    &lt;/div&gt;
    &lt;div class="flex"&gt;
      &lt;div class="w-1/5"&gt;w-1/5&lt;/div&gt;
      &lt;div class="w-4/5"&gt;w-4/5&lt;/div&gt;
    &lt;/div&gt;
    &lt;div class="flex"&gt;
      &lt;div class="w-1/6"&gt;w-1/6&lt;/div&gt;
      &lt;div class="w-5/6"&gt;w-5/6&lt;/div&gt;
    &lt;/div&gt;
    &lt;div class="flex"&gt;
      &lt;div class="w-1/12"&gt;w-1/12&lt;/div&gt;
      &lt;div class="w-11/12"&gt;w-11/12&lt;/div&gt;
    &lt;/div&gt;
    &lt;div class="w-full"&gt;w-full&lt;/div&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- TAILWIND WIDTHS ENDS -->

    <!-- BREAKPOINTS START -->
    <h3 class="mb-5" id="breakpoints">Breakpoints</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <!-- Vuesax breakpoints -->
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Vuesax Breakpoints</h6>
        </template>
        <template v-slot:content>
          <h6 class="mb-4">Only for reference.</h6>
          <div class="grid grid-cols-12 gap-2">
            <div class="col-span-6 sm:col-span-4 font-semibold">Breakpoint</div><div class="col-span-6 sm:col-span-3 font-semibold">Screen Size</div><div class="col-span-12 sm:col-span-5 font-semibold">Media Query</div>
            <div class="col-span-6 sm:col-span-4">breakpoint(xs)</div><div class="col-span-6 sm:col-span-3">320px</div><div class="col-span-12 sm:col-span-5">@media (min-width: 320px) { ... }</div>
            <div class="col-span-6 sm:col-span-4">breakpoint(sm)</div><div class="col-span-6 sm:col-span-3">768px</div><div class="col-span-12 sm:col-span-5">@media (min-width: 768px) { ... }</div>
            <div class="col-span-6 sm:col-span-4">breakpoint(md)</div><div class="col-span-6 sm:col-span-3">1095px</div><div class="col-span-12 sm:col-span-5">@media (min-width: 1095px) { ... }</div>
            <div class="col-span-6 sm:col-span-4">breakpoint(lg)</div><div class="col-span-6 sm:col-span-3">1919px plus</div><div class="col-span-12 sm:col-span-5">@media (min-width: 1919px) { ... }</div>
          </div>

          <hr class="my-6 line-hr" />

          <div class="grid grid-cols-12 gap-2">
            <div class="col-span-6 sm:col-span-4 font-semibold">Breakpoint</div><div class="col-span-6 sm:col-span-3 font-semibold">Screen Size</div><div class="col-span-12 sm:col-span-5 font-semibold">Media Query</div>
            <div class="col-span-6 sm:col-span-4">breakpointMax(xs)</div><div class="col-span-6 sm:col-span-3">768px</div><div class="col-span-12 sm:col-span-5">@media (max-width: 768px) { ... }</div>
            <div class="col-span-6 sm:col-span-4">breakpointMax(sm)</div><div class="col-span-6 sm:col-span-3">1095px</div><div class="col-span-12 sm:col-span-5">@media (max-width: 1095px) { ... }</div>
            <div class="col-span-6 sm:col-span-4">breakpointMax(md)</div><div class="col-span-6 sm:col-span-3">1919px</div><div class="col-span-12 sm:col-span-5">@media (max-width: 1919px) { ... }</div>
            <div class="col-span-6 sm:col-span-4">breakpointMax(lg)</div><div class="col-span-6 sm:col-span-3">2560px</div><div class="col-span-12 sm:col-span-5">@media (max-width: 2560px) { ... }</div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    @mixin breakpoint($point) {
      @if $point == menu {
        @media (min-width: 1100px) { @content; }
      } @else if $point == xxxl {
        @media (min-width: 4200px) { @content; }
      } @else if $point == xxl {
        @media (min-width: 3840px) { @content; }
      } @else if $point == lm {
        @media (min-width: 3440px) { @content; }
      } @else if $point == xl {
        @media (min-width: 2560px) { @content; }
      } @else if $point == lg {
        @media (min-width: 1919px) { @content; }
      } @else if $point == md {
        @media (min-width: 1095px) { @content; }
      } @else if $point == sm {
        @media (min-width: 768px) { @content; }
      } @else if $point == xs {
        @media (min-width: 320px) { @content; }
      }
    }
    @mixin breakpointMax($point) {
      @if $point == xxl {
        @media (max-width: 3840px) { @content; }
      } @else if $point == xl {
        @media (max-width: 3440px) { @content; }
      } @else if $point == lg {
        @media (max-width: 2560px) { @content; }
      } @else if $point == md {
        @media (max-width: 1919px) { @content; }
      } @else if $point == sm {
        @media (max-width: 1095px) { @content; }
      } @else if $point == xs {
        @media (max-width: 768px) { @content; }
      }
    }
            </Prism>
          </pre>
        </template>
      </vue-accordion>
      <!-- Vuesax breakpoints ends -->

      <hr class="heading-divider mb-3" />

      <!-- Twailwind breakpoints -->
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Tailwind Breakpoints</h6>
        </template>
        <template v-slot:content>
          <h6 class="mb-4">Only for reference.</h6>
          <div class="grid grid-cols-12 gap-2">
            <div class="col-span-6 sm:col-span-4 font-semibold">Breakpoint</div><div class="col-span-6 sm:col-span-3 font-semibold">Screen Size</div><div class="col-span-12 sm:col-span-5 font-semibold">Media Query</div>
            <div class="col-span-6 sm:col-span-4">sm:</div><div class="col-span-6 sm:col-span-3">768px</div><div class="col-span-12 sm:col-span-5">@media (min-width: 768px) { ... }</div>
            <div class="col-span-6 sm:col-span-4">md:</div><div class="col-span-6 sm:col-span-3">1095px</div><div class="col-span-12 sm:col-span-5">@media (min-width: 1095px) { ... }</div>
            <div class="col-span-6 sm:col-span-4">lg:</div><div class="col-span-6 sm:col-span-3">1919px</div><div class="col-span-12 sm:col-span-5">@media (min-width: 1919px) { ... }</div>
            <div class="col-span-6 sm:col-span-4">xl:</div><div class="col-span-6 sm:col-span-3">1920 plus</div><div class="col-span-12 sm:col-span-5">@media (min-width: 2560px) { ... }</div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    screens: {
      sm: "768px",
      md: "1095px",
      lg: "1919px",
      xl: "2560px",
      xxl: "3440px",
      "2xlm": { max: "3440px" },
      xlm: { max: "2560px" },
      lgm: { max: "1919px" },
      mdm: { max: "1095px" },
      smm: { max: "768px" },
    },
            </Prism>
          </pre>
        </template>
      </vue-accordion>
      <!-- Tailwind breakpoin End -->

      <hr class="heading-divider mb-3" />

      <!-- Custom breakpoints -->
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Custom Breakpoints</h6>
        </template>
        <template v-slot:content>
          <h6 class="mb-4">Only for reference.</h6>
          <div class="grid grid-cols-12 gap-2">
            <div class="col-span-6 sm:col-span-4 font-semibold">Breakpoint</div><div class="col-span-6 sm:col-span-3 font-semibold">Screen Size</div><div class="col-span-12 sm:col-span-5 font-semibold">Media Query</div>
            <div class="col-span-6 sm:col-span-4">respond(mobile)</div><div class="col-span-6 sm:col-span-3">768px</div><div class="col-span-12 sm:col-span-5">@media only screen and (max-width: 768px) { ... }</div>
            <div class="col-span-6 sm:col-span-4">respond(tablet)</div><div class="col-span-6 sm:col-span-3">1095px</div><div class="col-span-12 sm:col-span-5">@media only screen and (min-width: 768px) and (max-width: 1095px) { ... }</div>
            <div class="col-span-6 sm:col-span-4">respond(desktop)</div><div class="col-span-6 sm:col-span-3">1919px</div><div class="col-span-12 sm:col-span-5">@media only screen and (min-width: 1095px) and (max-width: 1920px) { ... }</div>
            <div class="col-span-6 sm:col-span-4">respond(large-desktop)</div><div class="col-span-6 sm:col-span-3">1920 plus</div><div class="col-span-12 sm:col-span-5">@media only screen and (min-width: 1920px) { ... }</div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    @mixin respond($breakpoint) {
      @if $breakpoint == mobile {
        @media only screen and (max-width: 768px) { @content };
      }
      @if $breakpoint == tablet {
        @media only screen and (min-width: 768px) and (max-width: 1095px) { @content };
      }
      @if $breakpoint == desktop {
        @media only screen and (min-width: 1095px) and (max-width: 1920px) { @content };
      }
      @if $breakpoint == large-desktop {
        @media only screen and (min-width: 1920px) { @content };
      }
    }
            </Prism>
          </pre>
        </template>
      </vue-accordion>
      <!-- Tailwind breakpoin End -->
    </vs-card>
    <!-- BREAKPOINTS ENDS -->
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";

  export default {
    data() {
        return {
      }
    },
    components: {
      Prism,
      VueAccordion,
    },
    methods: {
    },
  }
</script>