<template>
  <div class="mx-auto router-content flex-1 rellopay">
    <div id="page-top">
      <h1 class="page-title mb-5">Organized design sytem components with sidebar</h1>
      <div class="sidebar-layout mb-8">
        <div class="sidebar-layout-list">
          <Sidebar class="mb-20 sticky top-0" @show="showCards"/>
        </div>
        <div class="sidebar-layout-content">
          <vs-card type="toolbar-sidebar-page" class="isResponsiveCard self-start" style="background:#dfdfdf;">
            <!-- Colors start -->
            <div v-if="show == 'colors'">
              <DSColors />
            </div>
            <!-- Colors End -->

            <!-- Typography start -->
            <div v-if="show == 'typography'">
              <DSTypography />
            </div>
            <!-- Typography End -->

            <!-- Buttons start -->
            <div v-if="show == 'buttons'">
              <DSButtons />
            </div>
            <!-- Buttons End -->

            <!-- Icons start -->
            <div v-if="show == 'icons'">
              <h2 class="mb-5">Icons</h2>
            </div>
            <!-- Icons End -->

            <!-- Logos start -->
            <div v-if="show == 'logos'">
              <h2 class="mb-5">Logos</h2>
            </div>
            <!-- Logos End -->

            <!-- Badges start -->
            <div v-if="show == 'badges'">
              <DSBadges />
            </div>
            <!-- Badges End -->

            <!-- Input Fields start -->
            <div v-if="show == 'input-fields'">
              <DSInputFields />
            </div>
            <!-- Input Fields end -->

            <!-- Grids start -->
            <div v-if="show == 'grid'">
              <DSGrid />
            </div>
            <!-- Grids End -->

            <!-- Spacing start -->
            <div v-if="show == 'spacing'">
              <h2 class="mb-5">Spacing</h2>
            </div>
            <!-- Spacing End -->

            <!-- Backgrounds start -->
            <div v-if="show == 'backgrounds'">
              <h2 class="mb-5">Backgrounds</h2>
              <DSBackground />
            </div>
            <!-- Backgrounds End -->

            <!-- Navigations start -->
            <div v-if="show == 'navigation'">
              <DSNavigation />
            </div>
            <!-- Navigations End -->

            <!-- Headings start -->
            <div v-if="show == 'headings'">
              <DSHeadings />
            </div>
            <!-- Headings end -->

            <!-- layout cards start -->
            <div v-if="show == 'cards'">
              <DSCards />
            </div>
            <!-- layout cards end -->

            <!-- Summary cards start -->
            <div v-if="show == 'summary-card'">
              <DSSummaryCard />
            </div>
            <!-- Summary cards end -->

            <!-- Popups start -->
            <div v-if="show == 'popup'">
              <DSPopups />
            </div>
            <!-- Popups ends -->

            <!-- Messages start -->
            <div v-if="show == 'messages'">
              <DSMessages />
            </div>
            <!-- Messages ends -->

            <!-- Payment start -->
            <div v-if="show == 'payment'">
              <DSPayment />
            </div>
            <!-- Payment ends -->

            <!-- Extend Due date start -->
            <div v-if="show == 'extend-due-date'">
              <DSExtendDueDate />
            </div>
            <!-- Extend Due date  ends -->

            <!-- Change Payment method start -->
            <div v-if="show == 'change-payment'">
              <DSChangePayment />
            </div>
            <!-- Change Payment method ends -->

            <!-- Product Fields -->
            <div v-if="show == 'product-fields'">
              <DSProductFields />
            </div>
            <!-- Product Fields ends -->

            <!-- Container start -->
            <div v-if="show == 'sidebar-layout'" class="flex flex-col">
              <DSSidebar />
            </div>
            <!-- Containers ends -->

            <!-- Tables start -->
            <div v-if="show == 'tables'" class="flex flex-col">
              <DSTable />
            </div>
            <!-- Tables ends -->

            <!-- Make a payment start -->
            <div v-if="show == 'make-payment'" class="flex flex-col">
              <DSMakePayment />
            </div>
            <!-- Make a payment ends -->

            <!-- Communication card start -->
            <div v-if="show == 'communication-card'" class="flex flex-col">
              <DSCommunicationCard />
            </div>
            <!-- Communication card ends -->

            <!-- Progress indicator card start -->
            <div v-if="show == 'progress-indicator'" class="flex flex-col">
              <DSProgressIndicator />
            </div>
            <!-- Progress indicator card ends -->

            <!-- Transaction card start -->
            <div v-if="show == 'transaction-card'" class="flex flex-col">
              <DSTransactionCard />
            </div>
            <!-- Transaction card ends -->

            <!-- Agreement card start -->
            <div v-if="show == 'agreement-card'" class="flex flex-col">
              <DSAgreementCard />
            </div>
            <!-- Agreement card ends -->
          </vs-card>
        </div>
      </div>
    </div>
    <vs-button type="border" color="primary" size="large" icon="arrow_upward" class="fixed" v-scroll-to="'#page-top'" style="bottom: 40px;right:20px;z-index:999;">Top</vs-button>
  </div>
</template>

<script>
  // Design System Page Imports
  import Sidebar from "../components/design-sys-components/Sidebar";
  import DSSidebar from "../components/design-sys-components/DSSidebar";
  import DSInputFields from "../components/design-sys-components/DSInputFields";
  import DSBadges from "../components/design-sys-components/DSBadges";
  import DSTable from "../components/design-sys-components/DSTable";
  import DSButtons from "../components/design-sys-components/DSButtons";
  import DSColors from "../components/design-sys-components/DSColors";
  import DSMessages from "../components/design-sys-components/DSMessages";
  import DSPopups from "../components/design-sys-components/DSPopups";
  import DSHeadings from "../components/design-sys-components/DSHeadings";
  import DSCards from "../components/design-sys-components/DSCards";
  import DSGrid from "../components/design-sys-components/DSGrid";
  import DSPayment from "../components/design-sys-components/DSPayment";
  import DSExtendDueDate from "../components/design-sys-components/DSExtendDueDate";
  import DSChangePayment from "../components/design-sys-components/DSChangePayment";
  import DSMakePayment from "../components/design-sys-components/DSMakePayment";
  import DSBackground from "../components/design-sys-components/DSBackground";
  import DSProductFields from "../components/design-sys-components/DSProductFields";
  import DSSummaryCard from "../components/design-sys-components/DSSummaryCard";
  import DSTypography from "../components/design-sys-components/DSTypography";
  import DSCommunicationCard from "../components/design-sys-components/DSCommunicationCard";
  import DSProgressIndicator from "../components/design-sys-components/DSProgressIndicator";
  import DSTransactionCard from "../components/design-sys-components/DSTransactionCard";
  import DSAgreementCard from "../components/design-sys-components/DSAgreementCard";
  import DSNavigation from "../components/design-sys-components/DSNavigation";

  export default {
    data() {
      return {
        show: 'colors',
      }
    },
    components: {
      Sidebar,
      DSInputFields,
      DSBadges,
      DSButtons,
      DSColors,
      DSTable,
      DSMessages,
      DSSidebar,
      DSPopups,
      DSHeadings,
      DSCards,
      DSGrid,
      DSPayment,
      DSExtendDueDate,
      DSChangePayment,
      DSMakePayment,
      DSBackground,
      DSProductFields,
      DSSummaryCard,
      DSTypography,
      DSCommunicationCard,
      DSProgressIndicator,
      DSTransactionCard,
      DSAgreementCard,
      DSNavigation,
    },
    mounted() {
      document.body.classList.add('design-system');
    },
    updated() {
    },
    methods: {
      showCards(showContent) {
        this.show = showContent;
      },
    }
  }
</script>