<template>
  <div>
    <h2 class="mb-5">Grids</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#single-plan'">Single payment plan</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#multiple-plan'">Multiple payments plan</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#recurring'">Recurring</vs-button></div>
      </div>
    </vs-card>
    <h3 class="mb-5" id="base-grid">Base</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="single-plan">Single payment plan</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <h6 class="mb-6">At start of the plan</h6>
              <div class="rounded-t-2xl sm-rounded-t-3xl bg-container-80 p-4 sm:px-8">
                <div class="flex flex-row justify-between pb-2">
                  <div>
                    <p class="font-light text-body">
                      Amount
                    </p>
                    <h3>$ 8,400.00</h3>
                  </div>
                  <div class="flex flex-col text-right">
                    <p class="font-light text-body">Due date</p>
                    <h3>24 Jun 2023</h3>
                  </div>
                </div>
                <div class="relative" ref="progressBase">
                  <vs-progress :height="7" :percent="0" color="accent-300" class="mb-6"></vs-progress>
                  <div class="ancillary font-light text-body progress-info" ref="progressInfo" :data-current-value="0">60 days to go</div>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mb-6">Plan in progress</h6>
              <div class="rounded-t-2xl sm-rounded-t-3xl bg-container-80 p-4 sm:px-8">
                <div class="flex flex-row justify-between pb-2">
                  <div>
                    <p class="font-light text-body">
                      Amount
                    </p>
                    <h3>$ 8,400.00</h3>
                  </div>
                  <div class="flex flex-col text-right">
                    <p class="font-light text-body">Due date</p>
                    <h3>24 Jun 2023</h3>
                  </div>
                </div>
                <div class="relative">
                  <vs-progress :height="7" :percent="66.66" color="accent-300" class="mb-6"></vs-progress>
                  <div class="ancillary font-light text-body progress-info" ref="progressInfo" :data-current-value="66.66">20 days to go</div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="rounded-t-2xl sm-rounded-t-3xl bg-container-80 p-4 sm:px-8"&gt;
      &lt;div class="flex flex-row justify-between pb-2"&gt;
        &lt;div&gt;
          &lt;p class="font-light text-body"&gt;
            Amount
          &lt;/p&gt;
          &lt;h3&gt;$ 8,400.00&lt;/h3&gt;
        &lt;/div&gt;
        &lt;div class="flex flex-col text-right"&gt;
          &lt;p class="font-light text-body"&gt;Due date&lt;/p&gt;
          &lt;h3&gt;24 Jun 2023&lt;/h3&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div class="relative" ref="progressBase"&gt;
        &lt;vs-progress :height="7" :percent="0" color="accent-300" class="mb-6"&gt;&lt;/vs-progress&gt;
        &lt;div class="ancillary font-light text-body progress-info" ref="progressInfo" :data-current-value="0"&gt;60 days to go&lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            positionValue: '',
            currentValue: '',
          }
        },
        mounted() {
          let currentValue = this.$refs.progressInfo.getAttribute('data-current-value');
          let width = this.$refs.progressInfo.clientWidth;
          let baseWidth = this.$refs.progressBase.clientWidth;
          let progressBar = currentValue/100 * baseWidth;
          if (currentValue == 0) {
            this.positionValue = 0
            this.$refs.progressInfo.style.left = this.positionValue + '%';
          } else if (progressBar + width/2 &gt; baseWidth) {
            this.$refs.progressInfo.style.right = '0%';
          } else if ((progressBar - width/2) &gt; 0) {
            this.positionValue = currentValue;
            this.$refs.progressInfo.style.transform = "translateX(-50%)";
            this.$refs.progressInfo.style.left = this.positionValue + '%';
          }
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="multiple-plan">Multiple payments plan</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <h6 class="mb-6">Default</h6>
              <div class="rounded-t-2xl sm-rounded-t-3xl bg-container-80 p-4 sm:px-8">
                <div class="flex flex-row justify-between pb-2">
                  <div>
                    <p class="font-light text-body">
                      Amount Paid
                    </p>
                    <h3>$ 2,100.00</h3>
                  </div>
                  <div class="flex flex-col text-right">
                    <p class="font-light text-body">
                      Remaining balance
                    </p>
                    <h3>$ 6,300.00</h3>
                  </div>
                </div>
                <div class="relative">
                  <vs-progress :height="12" :percent="30" color="success-300" class="mb-2"></vs-progress>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mb-6">Plan in progress</h6>
              <div class="rounded-t-2xl sm-rounded-t-3xl bg-container-80 p-4 sm:px-8">
                <div class="flex flex-row justify-between pb-2">
                  <div>
                    <p class="font-light text-body">
                      Amount Paid
                    </p>
                    <h3>$ 4,200.00</h3>
                  </div>
                  <div class="flex flex-col text-right">
                    <p class="font-light text-body">
                      Remaining balance
                    </p>
                    <h3>$ 4,200.00</h3>
                  </div>
                </div>
                <div class="relative">
                  <vs-progress :height="12" :percent="50" color="success-300" class="mb-2"></vs-progress>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mb-6">When the start date of the plan is on a later date</h6>
              <div class="rounded-t-2xl sm-rounded-t-3xl bg-container-80 p-4 sm:px-8">
                <div class="flex flex-row justify-between pb-2">
                  <div>
                    <p class="font-light text-body">
                      Amount Paid
                    </p>
                    <h3>$ 0.00</h3>
                  </div>
                  <div class="flex flex-col text-right">
                    <p class="font-light text-body">
                      Remaining balance
                    </p>
                    <h3>$ 8,400.00</h3>
                  </div>
                </div>
                <div class="relative">
                  <vs-progress :height="12" :percent="0" color="success-300" class="mb-2"></vs-progress>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="rounded-t-2xl sm-rounded-t-3xl bg-container-80 p-4 sm:px-8"&gt;
      &lt;div class="flex flex-row justify-between pb-2"&gt;
        &lt;div&gt;
          &lt;p class="font-light text-body"&gt;
            Amount Paid
          &lt;/p&gt;
          &lt;h3&gt;$ 2,100.00&lt;/h3&gt;
        &lt;/div&gt;
        &lt;div class="flex flex-col text-right"&gt;
          &lt;p class="font-light text-body"&gt;
            Remaining balance
          &lt;/p&gt;
          &lt;h3&gt;$ 6,300.00&lt;/h3&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div class="relative"&gt;
        &lt;vs-progress :height="12" :percent="30" color="success-300" class="mb-2"&gt;&lt;/vs-progress&gt;
      &lt;/div&gt;
    &lt;/div&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="recurring">Recurring</h6>
        </template>
        <template v-slot:content>
          <div class="grid md:grid-cols-2 gap-6">
            <div>
              <h6 class="mb-6">At start of the plan</h6>
              <div class="rounded-t-2xl sm-rounded-t-3xl bg-container-80 p-4 sm:px-8">
                <div class="flex flex-row justify-between">
                  <div>
                    <p class="font-light text-body">
                      Payments complete
                    </p>
                    <h3>0</h3>
                  </div>
                  <div class="flex flex-col text-right">
                    <p class="font-light text-body">
                      Payments remaining
                    </p>
                    <h3>{{totalRecurring}}</h3>
                  </div>
                </div>
                <div class="pt-2">
                  <vs-progress :height="7" :percent="0" color="interaction-primary"></vs-progress>
                  <div class="progress-milestone">
                    <span v-for="n in totalRecurring + 1">&nbsp;</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mb-6">Plan in progress</h6>
              <div class="rounded-t-2xl sm-rounded-t-3xl bg-container-80 p-4 sm:px-8">
                <div class="flex flex-row justify-between">
                  <div>
                    <p class="font-light text-body">
                      Payments complete
                    </p>
                    <h3>6</h3>
                    </div>
                    <div class="flex flex-col text-right">
                      <p class="font-light text-body">
                        Payments remaining
                      </p>
                      <h3>6</h3>
                    </div>
                  </div>
                  <div class="pt-2">
                    <vs-progress :height="7" :percent="50" color="interaction-primary"></vs-progress>
                    <div class="progress-milestone">
                      <span v-for="n in totalRecurring + 1">&nbsp;</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="rounded-t-2xl sm-rounded-t-3xl bg-container-80 p-4 sm:px-8"&gt;
      &lt;div class="flex flex-row justify-between"&gt;
        &lt;div&gt;
          &lt;p class="font-light text-body"&gt;
            Payments complete
          &lt;/p&gt;
          &lt;h3&gt;0&lt;/h3&gt;
        &lt;/div&gt;
        &lt;div class="flex flex-col text-right"&gt;
          &lt;p class="font-light text-body"&gt;
            Payments remaining
          &lt;/p&gt;
          &lt;h3&gt;{{totalRecurring}}&lt;/h3&gt;
        &lt;/div&gt;
      &lt;/div&gt;
      &lt;div class="pt-2"&gt;
        &lt;vs-progress :height="7" :percent="0" color="interaction-primary"&gt;&lt;/vs-progress&gt;
        &lt;div class="progress-milestone"&gt;
          &lt;span v-for="n in totalRecurring + 1"&gt;&nbsp;&lt;/span&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/div&gt;

    &lt;script&gt;
      export default {
        data() {
          return {
            totalRecurring: 12,
          }
        },
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";

  export default {
    data() {
      return {
        positionValue: '',
        currentValue: '',
        totalRecurring: 12,
      }
    },
    components: {
      Prism,
      VueAccordion,
    },
    mounted() {
      let currentValue = this.$refs.progressInfo.getAttribute('data-current-value');
      let width = this.$refs.progressInfo.clientWidth;
      let baseWidth = this.$refs.progressBase.clientWidth;
      let progressBar = currentValue/100 * baseWidth;
      if (currentValue == 0) {
        this.positionValue = 0
        this.$refs.progressInfo.style.left = this.positionValue + '%';
      } else if (progressBar + width/2 > baseWidth) {
        this.$refs.progressInfo.style.right = '0%';
      } else if ((progressBar - width/2) > 0){
        this.positionValue = currentValue;
        this.$refs.progressInfo.style.transform = "translateX(-50%)";
        this.$refs.progressInfo.style.left = this.positionValue + '%';
      }
    }
  }
</script>