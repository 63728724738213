<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    :aria-labelledby="iconName"
    role="presentation"
    fill="none"
  >
    <path
      d="M19.4429 15.6658C18.4327 15.3243 17.0738 14.8013 15.5622 14.2496C16.4696 12.6567 17.1956 10.8441 17.6733 8.87394H12.6892V7.06375H18.7933V6.05357H12.6892V3.03497H10.1984C9.7614 3.03497 9.7614 3.46961 9.7614 3.46961V6.05118H3.58809V7.06136H9.7614V8.87155H4.66275V9.88173H14.5496C14.189 11.1379 13.7018 12.32 13.1263 13.3923C9.91901 12.3248 6.49444 11.4603 4.34513 11.9928C2.96957 12.3343 2.08358 12.9433 1.56297 13.5833C-0.825159 16.5136 0.887125 20.965 5.93084 20.965C8.91361 20.965 11.7865 19.2886 14.0123 16.5255C17.3341 18.1351 23.9086 20.9005 23.9086 20.9005V16.9601C23.911 16.9601 23.0848 16.8933 19.4429 15.6658ZM5.46038 19.2623C1.52714 19.2623 0.364126 16.1386 2.30806 14.4287C2.95763 13.8508 4.14214 13.569 4.77261 13.5069C7.10819 13.2753 9.27183 14.1732 11.8247 15.4293C10.0313 17.7888 7.74582 19.2623 5.46038 19.2623Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "icon",
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
      default: "#AAAAAA",
    },
  },
};
</script>
