<template>
  <div>
    <!-- Tables -->
    <h2 class="mb-5">Tables</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#data-table'">Data Table</vs-button></div>
      </div>
    </vs-card>
    <vue-accordion class="mb-6">
      <template v-slot:title>
        <h6 class="mt-5 mb-3 font-medium" id="data-table">Data Table</h6>
      </template>
      <template v-slot:content>
        <vs-card type="table">
          <vs-table :data="users" ref="sidebarContainer" noDataText="No data available">
            <template slot="header">
              <vs-sidebar type="table-sidebar" :parent="$refs.parentSidebar" position-right spacer v-model="active" click-not-close>
                <div slot="header">
                  <div class="table-sidebar-header flex items-center justify-between">
                    <div class="flex items-center">
                      <vs-button radius type="flat" icon="arrow_forward_ios" class="icon-small w-6 mr-2 text-text-body" @click="active = false"></vs-button>
                      <span class="table-sidebar-heading">Table Sidebar Heading</span>
                    </div>
                    <vs-button type="flat">Apply</vs-button>
                  </div>
                </div>
                <div>
                  <vs-input label='Label' placeholder="Required" v-model="value1"/>
                </div>
              </vs-sidebar>
              <div class="table-action-bar flex justify-between px-8 pb-6">
                <div class="table-action-toolbar mt-2">
                  <vs-button type="flat" icon="tune" @click="active=!active">Table Configuration</vs-button>
                  <vs-button type="flat" icon="tune">Export</vs-button>
                  <vs-button type="flat" icon="add">Add</vs-button>
                </div>
                <div>
                  <vs-button icon="delete" @click="users = []">Remove Data</vs-button>
                </div>
              </div>
            </template>
            <template slot="thead">
              <vs-th>Email</vs-th>
              <vs-th>Name</vs-th>
              <vs-th>Website</vs-th>
              <vs-th>Nro</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr :key="index" v-for="(tr, index) in data">
                <vs-td :data="data[index].email">{{ data[index].email }}</vs-td>
                <vs-td :data="data[index].username">{{ data[index].name }}</vs-td>
                <vs-td :data="data[index].id">{{ data[index].id }}</vs-td>
                <vs-td :data="data[index].id">{{ data[index].website }}</vs-td>
                <vs-td :data="data[index].id" class="text-right">
                  <a href="#" class="no-underline">
                    <vs-button type="flat" class="text-right">Action link</vs-button>
                  </a>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div class="pagination-widget flex justify-between items-end pt-6 px-8">
            <div class="inline-flex items-end">
              <div class="mr-8 text-text-body">Records per page</div>
              <vs-select class="no-label-select w-18 mb-0" :value="recordsPerPage" v-model="recordsPerPage">
                <vs-select-item :key="index" :value="item.id" :text="item.id" v-for="(item, index) in users" />
              </vs-select>
            </div>
            <vPagination v-model="recordsPerPage" :page-count="20" :total="200"></vPagination>
          </div>
        </vs-card>
      </template>
      <template v-slot:code>
        <pre>
          <Prism class="language-js">
      &lt;vs-card type="table"&gt;
        &lt;vs-table :data="users" ref="sidebarContainer" noDataText="No data available"&gt;
          &lt;template slot="header"&gt;
            &lt;vs-sidebar type="table-sidebar" :parent="$refs.parentSidebar" position-right spacer v-model="active" click-not-close&gt;
              &lt;div slot="header"&gt;
                &lt;div class="table-sidebar-header flex items-center justify-between"&gt;
                  &lt;div class="flex items-center"&gt;
                    &lt;vs-button radius type="flat" icon="arrow_forward_ios" class="icon-small w-6 mr-2 text-text-body" @click="active = false"&gt;&lt;/vs-button&gt;
                    &lt;span class="table-sidebar-heading"&gt;Table Sidebar Heading&lt;/span&gt;
                  &lt;/div&gt;
                  &lt;vs-button type="flat"&gt;Apply&lt;/vs-button&gt;
                &lt;/div&gt;
              &lt;/div&gt;
              &lt;div&gt;
                &lt;vs-input label="Label" placeholder="Required" v-model="value1"/&gt;
              &lt;/div&gt;
            &lt;/vs-sidebar&gt;
            &lt;div class="table-action-bar flex justify-between px-8 pb-6"&gt;
              &lt;div class="table-action-toolbar mt-2"&gt;
                &lt;vs-button type="flat" icon="tune" @click="active=!active"&gt;Table Configuration&lt;/vs-button&gt;
                &lt;vs-button type="flat" icon="tune"&gt;Export&lt;/vs-button&gt;
                &lt;vs-button type="flat" icon="add"&gt;Add&lt;/vs-button&gt;
              &lt;/div&gt;
              &lt;div&gt;
                &lt;vs-button icon="delete" @click="users = []"&gt;Remove Data&lt;/vs-button&gt;
              &lt;/div&gt;
            &lt;/div&gt;
          &lt;/template&gt;
          &lt;template slot="thead"&gt;
            &lt;vs-th&gt;Email&lt;/vs-th&gt;
            &lt;vs-th&gt;Name&lt;/vs-th&gt;
            &lt;vs-th&gt;Website&lt;/vs-th&gt;
            &lt;vs-th&gt;Nro&lt;/vs-th&gt;
          &lt;/template&gt;
          &lt;template slot-scope="{data}"&gt;
            &lt;vs-tr :key="index" v-for="(tr, index) in data"&gt;
              &lt;vs-td&gt;&nbsp;&lt;/vs-td&gt;
              &lt;vs-td&gt;&nbsp;&lt;/vs-td&gt;
              &lt;vs-td&gt;&nbsp;&lt;/vs-td&gt;
              &lt;vs-td&gt;&nbsp;&lt;/vs-td&gt;
              &lt;vs-td class="text-right"&gt;
                &lt;a href="#" class="no-underline"&gt;
                  &lt;vs-button type="flat" class="text-right"&gt;Action link&lt;/vs-button&gt;
                &lt;/a&gt;
              &lt;/vs-td&gt;
            &lt;/vs-tr&gt;
          &lt;/template&gt;
        &lt;/vs-table&gt;
        &lt;div class="pagination-widget flex justify-between items-end pt-6 px-8"&gt;
          &lt;div class="inline-flex items-end"&gt;
            &lt;div class="mr-8 text-text-body"&gt;Records per page&lt;/div&gt;
            &lt;vs-select class="no-label-select w-18 mb-0" :value="recordsPerPage" v-model="recordsPerPage"&gt;
              &lt;vs-select-item :key="index" :value="item.id" :text="item.id" v-for="(item, index) in users" /&gt;
            &lt;/vs-select&gt;
          &lt;/div&gt;
          &lt;vPagination v-model="recordsPerPage" :page-count="20" :total="200"&gt;&lt;/vPagination&gt;
        &lt;/div&gt;
      &lt;/vs-card&gt;

      &lt;script&gt;
        import vPagination from "@/views/components/pagination.vue";
        export default {
          data:() => ({
            active: false,
            recordsPerPage: 2,
            users:[
              {
                "id": 1,
                "name": "Leanne Graham",
                "username": "Bret",
                "email": "Sincere@april.biz",
                "website": "hildegard.org",
              },
              {
                "id": 2,
                "name": "Ervin Howell",
                "username": "Antonette",
                "email": "Shanna@melissa.tv",
                "website": "anastasia.net",
              },
              {
                "id": 3,
                "name": "Clementine cBauch",
                "username": "Samantha",
                "email": "Nathan@yesenia.net",
                "website": "ramiro.info",
              },
              {
                "id": 4,
                "name": "Patricia Lebsack",
                "username": "Karianne",
                "email": "Julianne.OConner@kory.org",
                "website": "kale.biz",
              },
              {
                "id": 5,
                "name": "Chelsey Dietrich",
                "username": "Kamren",
                "email": "Lucio_Hettinger@annie.ca",
                "website": "demarco.info",
              },
              {
                "id": 6,
                "name": "Mrs. Dennis Schulist",
                "username": "Leopoldo_Corkery",
                "email": "Karley_Dach@jasper.info",
                "website": "ola.org",
              },
              {
                "id": 7,
                "name": "Kurtis Weissnat",
                "username": "Elwyn.Skiles",
                "email": "Telly.Hoeger@billy.biz",
                "website": "elvis.io",
              },
              {
                "id": 8,
                "name": "Nicholas Runolfsdottir V",
                "username": "Maxime_Nienow",
                "email": "Sherwood@rosamond.me",
                "website": "jacynthe.com",
              },
              {
                "id": 9,
                "name": "Glenna Reichert",
                "username": "Delphine",
                "email": "Chaim_McDermott@dana.io",
                "website": "conrad.com",
              },
              {
                "id": 10,
                "name": "Clementina DuBuque",
                "username": "Moriah.Stanton",
                "email": "Rey.Padberg@karina.biz",
                "website": "ambrose.net",
              }
            ]
          }),
          components: { vPagination },
        }
      &lt;/script&gt;
          </Prism>
        </pre>
      </template>
    </vue-accordion>
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";
  import vPagination from "@/views/components/pagination.vue";
  export default {
    data: () => ({
      active: false,
      recordsPerPage: 2,
      users:[
        {
          "id": 1,
          "name": "Leanne Graham",
          "username": "Bret",
          "email": "Sincere@april.biz",
          "website": "hildegard.org",
        },
        {
          "id": 2,
          "name": "Ervin Howell",
          "username": "Antonette",
          "email": "Shanna@melissa.tv",
          "website": "anastasia.net",
        },
        {
          "id": 3,
          "name": "Clementine cBauch",
          "username": "Samantha",
          "email": "Nathan@yesenia.net",
          "website": "ramiro.info",
        },
        {
          "id": 4,
          "name": "Patricia Lebsack",
          "username": "Karianne",
          "email": "Julianne.OConner@kory.org",
          "website": "kale.biz",
        },
        {
          "id": 5,
          "name": "Chelsey Dietrich",
          "username": "Kamren",
          "email": "Lucio_Hettinger@annie.ca",
          "website": "demarco.info",
        },
        {
          "id": 6,
          "name": "Mrs. Dennis Schulist",
          "username": "Leopoldo_Corkery",
          "email": "Karley_Dach@jasper.info",
          "website": "ola.org",
        },
        {
          "id": 7,
          "name": "Kurtis Weissnat",
          "username": "Elwyn.Skiles",
          "email": "Telly.Hoeger@billy.biz",
          "website": "elvis.io",
        },
        {
          "id": 8,
          "name": "Nicholas Runolfsdottir V",
          "username": "Maxime_Nienow",
          "email": "Sherwood@rosamond.me",
          "website": "jacynthe.com",
        },
        {
          "id": 9,
          "name": "Glenna Reichert",
          "username": "Delphine",
          "email": "Chaim_McDermott@dana.io",
          "website": "conrad.com",
        },
        {
          "id": 10,
          "name": "Clementina DuBuque",
          "username": "Moriah.Stanton",
          "email": "Rey.Padberg@karina.biz",
          "website": "ambrose.net",
        }
      ]
    }),
    components: { 
      Prism,
      VueAccordion,
      vPagination 
    }
  }
</script>