<template>
  <div>
    <h2 class="mb-5">Messages</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#success-message'">Success System Message</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#error-message'">Error System Message</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#warning-message'">Warning System Message</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#danger-alert'">Danger Alert</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#success-alert'">Success Alert</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#warning-alert'">Warning Alert</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#validation-message'">Validation Message</vs-button></div>
      </div>
    </vs-card>

    <!-- Success system message -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="success-message">Success System Message</h6>
        </template>
        <template v-slot:content>
          <div class="text-center">
            <vs-button @click="showSuccessMessage">Success: System Message</vs-button>
          </div> 
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-button @click="showSuccessMessage"&gt;Success: System Message&lt;/vs-button&gt;
    &lt;script&gt;
      methods: {
        showSuccessMessage(title = 'Success', message='Success') {
          this.$toast.success(&lt;div class="container"&gt;&lt;h4&gt;Success heading&lt;/h4&gt;&lt;/div&gt;, {
            position: "bottom-left",
            timeout: 5000,
            closeOnClick: false,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: false,
            draggablePercent: 0.1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: {
              iconClass: "material-icons",
              iconChildren: "check_circle_outline",
              iconTag: "span"
            },
            rtl: false,
            message: message,
          });
        },
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- Error system message -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="error-message">Error System Message</h6>
        </template>
        <template v-slot:content>
          <div class="text-center">
            <vs-button @click="showErrorMessage">Error: System Message</vs-button>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-button @click="showErrorMessage"&gt;Error: System Message&lt;/vs-button&gt;
    &lt;script&gt;
      methods: {
        showErrorMessage(title = 'Error', message='Error') {
          this.$toast.error(&lt;div class="container"&gt;&lt;h4&gt;Error heading&lt;/h4&gt;&lt;p&gt;Error text lorem ipsum dolor lorem ipsum dolor.&lt;/p&gt;&lt;/div&gt;, {
            position: "bottom-left",
            timeout: false,
            closeOnClick: false,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: false,
            draggablePercent: 0.1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: {
              iconClass: "material-icons-outlined",
              iconChildren: "dangerous",
              iconTag: "span"
            },
            rtl: false,
            message: message,
          });
        },
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- Warning system message -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="warning-message">Warning System Message</h6>
        </template>
        <template v-slot:content>
          <div class="text-center">
            <vs-button @click="showWarningMessage">Warning: System Message</vs-button>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-button @click="showWarningMessage"&gt;Warning: System Message&lt;/vs-button&gt;
    &lt;script&gt;
      methods: {
        showWarningMessage(title = 'Warning', message='Warning') {
          this.$toast.warning(&lt;div class="container"&gt;&lt;h4&gt;Warning heading&lt;/h4&gt;&lt;p&gt;Waning text lorem ipsum dolor lorem ipsum dolor.&lt;/p>&lt;/div&gt;, {
            position: "bottom-left",
            timeout: false,
            closeOnClick: false,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            draggable: false,
            draggablePercent: 0.1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: {
              iconClass: "material-icons",
              iconChildren: "error_outline",
              iconTag: "span"
            },
            rtl: false,
            message: message,
          });
        }
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- danger message box -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="danger-alert">Danger Alert</h6>
        </template>
        <template v-slot:content>
          <vs-alert color="danger" icon="required_prop">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
          </vs-alert>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-alert color="danger" icon="required_prop"&gt;
      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
    &lt;/vs-alert&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- success message box -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="success-alert">Success Alert</h6>
        </template>
        <template v-slot:content>
          <vs-alert color="success" icon="check_circle_outline">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
          </vs-alert>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-alert color="success" icon="check_circle_outline"&gt;
      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
    &lt;/vs-alert&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- success message box -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="warning-alert">Warning Alert</h6>
        </template>
        <template v-slot:content>
          <vs-alert color="warning" icon="error_outline">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
          </vs-alert>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-alert color="warning" icon="error_outline"&gt;
      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
    &lt;/vs-alert&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- Validation message -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="validation-message">Validation message</h6>
        </template>
        <template v-slot:content>
          <vs-alert color="warning" icon="error_outlined" validation-error>Please populate all required fields before proceeding.</vs-alert>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-alert color="warning" icon="error_outlined" validation-error&gt;
      Please populate all required fields before proceeding.
    &lt;/vs-alert&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";
  export default {
    data: {},
    components: {
      Prism,
      VueAccordion,
    },
    methods: {
      showSuccessMessage(title = 'Success', message='Success') {
        this.$toast.success(<div class="container"><h4>Success heading</h4></div>, {
          position: "bottom-left",
          timeout: 5000,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: "material-icons",
            iconChildren: "check_circle_outline",
            iconTag: "span"
          },
          rtl: false,
          message: message,
        });
      },
      showWarningMessage(title = 'Warning', message='Warning') {
        this.$toast.warning(<div class="container"><h4>Warning heading</h4><p>Waning text lorem ipsum dolor lorem ipsum dolor.</p></div>, {
          position: "bottom-left",
          timeout: false,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: "material-icons",
            iconChildren: "error_outline",
            iconTag: "span"
          },
          rtl: false,
          message: message,
        });
      },
      showErrorMessage(title = 'Error', message='Error') {
        this.$toast.error(<div class="container"><h4>Error heading</h4><p>Error text lorem ipsum dolor lorem ipsum dolor.</p></div>, {
          position: "bottom-left",
          timeout: false,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: "material-icons-outlined",
            iconChildren: "dangerous",
            iconTag: "span"
          },
          rtl: false,
          message: message,
        });
      },
    }
  }
</script>