<template>
  <div>
    <h2 class="mb-5">Navigation</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#customer-nav'">Customer navigation</vs-button></div>
      </div>
    </vs-card>
    <!-- Customer navigation starts -->
    <h3 class="mb-5" id="customer-nav">Customer navigation</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Customer</h6>
        </template>
        <template v-slot:content>
          <vs-navbar class="pt-2 px-4 pb-4 sm:pt-6 sm:px-8 sm:pb-10">
            <div slot="title">
              <vs-navbar-title>
                <a href="#">
                  <img src="https://i.ibb.co/dJjP4c8/logo.png" alt="This is the company logo" class="h-10 sm:h-14">
                </a>
              </vs-navbar-title>
            </div>
            <vs-navbar-item>
              <vs-button type="flat" icon="logout" icon-size="small">Log out</vs-button>
            </vs-navbar-item>
          </vs-navbar>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-navbar class="pt-2 px-4 pb-4 sm:pt-6 sm:px-8 sm:pb-10"&gt;
      &lt;div slot="title"&gt;
        &lt;vs-navbar-title&gt;
          &lt;a href="#"&gt;
            &lt;img src="" alt="This is the company logo" class="h-10 sm:h-14"&gt;
          &lt;/a&gt;
        &lt;/vs-navbar-title&gt;
      &lt;/div&gt;
      &lt;vs-navbar-item&gt;
        &lt;vs-button type="flat" icon="logout" icon-size="small"&gt;Log out&lt;/vs-button&gt;
      &lt;/vs-navbar-item&gt;
    &lt;/vs-navbar&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";

export default {
  components: {
    Prism,
    VueAccordion,
  },
}
</script>