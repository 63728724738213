<template>
  <div>
    <h2 class="mb-5">Change payment method</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#payment'">Change method (Success)</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#change-payment-method'">Error message only</vs-button></div>
      </div>
    </vs-card>

    <!-- Payment method starts -->
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Change payment method</h6>
        </template>
        <template v-slot:content>
          <div v-if="!isExpanded && !isExpanded1" class="bg-container-60 px-4 py-4 md:px-8 rounded-2xl md-rounded-3xl items-center grid grid-cols-3 md:grid-cols-5">
            <div class="col-span-2 md:col-span-4">
              <p class="text-sm mb-2">Default payment method</p>
              <div class="flex items-center">
                <img :src="visaCard" alt="visa" />
                <p><span class="ml-4 md:ml-6">**** 1234</span><span class="ml-4 md:ml-6">01/24</span></p>
              </div>
            </div>
            <div class="col-span-1">
              <vs-button type="border" class="w-full" @click="toggleExpand">Change</vs-button>
            </div>
          </div>
          <div v-if="isExpanded && !isExpanded1" class="bg-container-60 px-4 py-4 md:px-8 rounded-2xl md-rounded-3xl">
            <h4 class="mb-6">Default payment method</h4>
            <div class="flex flex-col border-b-list">
              <div class="flex flex-row justify-between align-center py-4">
                <div class="flex flex-row items-center">
                  <vs-radio v-model="defaultPaymentMethod.paymentOption" vs-name="paymentOption" vs-value="option1" id="option1" />
                  <label for="option1" class="flex flex-row items-center pointer">
                    <img :src="visaCard" alt="visa" />
                    <p><span class="ml-4 md:ml-6">**** 1234</span><span class="ml-4 md:ml-6">01/24</span></p>
                  </label>
                </div>
                <vs-icon icon="delete_outline" class="cursor-pointer self-center icon-action" @click="warningOpen=true" v-if="defaultPaymentMethod.paymentOption != 'option1'" />
                <vs-popup title="Delete payment method" header-icon="warning" class="close-icon-hidden" :active.sync="warningOpen">
                  <p>This cannot be undone. Are you sure?</p>
                  <div class="popup-action-btn-container flex justify-end">
                    <vs-button size="large" action-btn>Delete</vs-button>
                    <vs-button @click="warningOpen=false" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
                  </div>
                </vs-popup>
              </div>
              <div class="flex flex-row justify-between py-4">
                <div class="flex flex-row items-center">
                  <vs-radio v-model="defaultPaymentMethod.paymentOption" vs-name="paymentOption" vs-value="option2" id="option2" />
                  <label for="option2" class="flex flex-row items-center pointer">
                    <img :src="visaCard" alt="visa" />
                    <p><span class="ml-4 md:ml-6">**** 1234</span><span class="ml-4 md:ml-6">01/24</span></p>
                  </label>
                </div>
                <vs-icon icon="delete_outline" class="cursor-pointer self-center icon-action" @click="warningOpen=true" v-if="defaultPaymentMethod.paymentOption != 'option2'" />
                <vs-popup title="Delete payment method" header-icon="warning" class="close-icon-hidden" :active.sync="warningOpen">
                  <p>This cannot be undone. Are you sure?</p>
                  <div class="popup-action-btn-container flex justify-end">
                    <vs-button size="large" action-btn>Delete</vs-button>
                    <vs-button @click="warningOpen=false" size="large" type="flat" class="ml-6 md:ml-10">Cancel</vs-button>
                  </div>
                </vs-popup>
              </div>
            </div>
            <hr class="mb-6" />
            <div class="text-center mb-8 pb-1"><vs-button type="flat" icon="add" class="mx-auto" @click="toggleExpand1" size="medium">Add payment method</vs-button></div>
            <vs-button color="primary" class="w-full max-w-420 block mx-auto mb-4" size="large" @click="showSuccessMessage">Save</vs-button>
            <vs-button type="flat" @click="toggleExpand" size="medium" class="block mx-auto mb-2">Cancel</vs-button>
          </div>
          <div v-show="isExpanded1" class="bg-container-60 rounded-2xl px-4 md:px-6 pt-3 md:pt-5 pb-5">
            <h4>Default payment method</h4>
            <payment-method @cancel="cancel" :showButton="true" :buttonText="Add" @handleAdd="showSuccessMessage"></payment-method>
          </div>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium">Error message only</h6>
        </template>
        <template v-slot:content>
          <div class="flex justify-center">
            <vs-button color="primary" class="w-full max-w-420" size="large" @click="showErrorMessage">Add</vs-button>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-button color="primary" class="w-full max-w-420" size="large" @click="showErrorMessage"&gt;Add&lt;/vs-button&gt;
    &lt;script&gt;
      export default {
        methods: {
          showErrorMessage(title = 'Error', message = 'Error') {
            this.$toast.error(&lt;div class="container"&gt;&lt;h4&gt;Error updating payment method&lt;/h4&gt;&lt;p&gt;&lt;Error code - 000&gt;&lt;/p&gt;&lt;/div&gt;, {
              position: "bottom-left",
              timeout: false,
              closeOnClick: false,
              pauseOnFocusLoss: false,
              pauseOnHover: false,
              draggable: false,
              draggablePercent: 0.1,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: {
                iconClass: "material-icons-outlined",
                iconChildren: "dangerous",
                iconTag: "span"
              },
              rtl: false,
              message: message,
            });
          },
        },
      }
    &lt;/script&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Payment method ends -->
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";
  import PaypalIcon from "../../components/design-sys-components/icons/PaypalIcon";
  import AliPayIcon from "../../components/design-sys-components/icons/AliPayIcon";
  import ApplePayIcon from "../../components/design-sys-components/icons/ApplePayIcon";
  import CardPayIcon from "../../components/design-sys-components/icons/CardPayIcon";
  import DirectDebitIcon from "../../components/design-sys-components/icons/DirectDebitIcon";
  import ToolTipIcon from "../../components/design-sys-components/icons/ToolTipIcon";
  import PaymentMethod from "../../components/PaymentMethod";
  // Third party import
  import Popper from 'vue-popperjs';

  // Credit Cards
  const visaCard = require("@/assets/images/cards/visa.svg");
  const masterCard = require("@/assets/images/cards/mastercard.svg");
  const amexCard = require("@/assets/images/cards/amex.svg");
  const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
  const unionpayCard = require("@/assets/images/cards/unionpay.svg");
  const directDebitCard = require("@/assets/images/cards/directdebit.svg");
  // Tooltip images
  const amex = require("@/assets/images/cards/amex.png");
  const amex1 = require("@/assets/images/cards/amex-1.png");

  export default {
    data() {
      return {
        visaCard: visaCard,
        masterCard: masterCard,
        amexCard: amexCard,
        dinersClubCard: dinersClubCard,
        unionpayCard: unionpayCard,
        directDebitCard: directDebitCard,
        amex: amex,
        amex1: amex1,
        isExpanded: false,
        isExpanded1: false,
        warningOpen: false,
        defaultPaymentMethod: {
          paymentOption: "option1",
        },
      }
    },
    components: {
      Prism,
      VueAccordion,
      Popper,
      PaypalIcon,
      AliPayIcon,
      ApplePayIcon,
      CardPayIcon,
      DirectDebitIcon,
      ToolTipIcon,
      PaymentMethod
    },
    methods: {
      toggleExpand() {
        this.isExpanded = !this.isExpanded;
      },

      toggleExpand1() {
        this.isExpanded1 = !this.isExpanded1;
      },

      showSuccessMessage(title = 'Success', message='Success') {
        this.$toast.success(<div class="container"><h4>Payment method updated</h4><p>Future payments for will now be charged to your new payment method.</p></div>, {
          position: "bottom-left",
          timeout: 5000,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: "material-icons",
            iconChildren: "check_circle_outline",
            iconTag: "span"
          },
          rtl: false,
          message: message,
        });
      },

      showErrorMessage(title = 'Error', message = 'Error') {
        this.$toast.error(<div class="container"><h4>Error updating payment method</h4><p>&lt;Error code - 000&gt;</p></div>, {
          position: "bottom-left",
          timeout: false,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: "material-icons-outlined",
            iconChildren: "dangerous",
            iconTag: "span"
          },
          rtl: false,
          message: message,
        });
      },

      cancel() {
        this.toggleExpand1();
      }
    },
  }
</script>