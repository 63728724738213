<template>
  <div>
    <h2 class="mb-5">Cards</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#collapsible-card'">Collapsible Card</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#table-card-tabs'">Table card [tabs]</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#table-card-standard'">Table card [standard]</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#page-card-standard'">Page card [standard]</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#page-card-toolbar'">Page card [toolbar]</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#page-card-right-toolbar'">Page card right[toolbar]</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#page-card-left'">Page card [left]</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#page-card-sidebar'">Page card [sidebar]</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#normal-tabs'">Tab bar</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#scrollable-tabs'">Scrollable Tab bar</vs-button></div>
      </div>
    </vs-card>

    <!-- collapsible card starts -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="collapsible-card">Collapsible Card</h6>
        </template>
        <template v-slot:content>
          <vs-collapse transitional-collapse>
            <vs-collapse-item>
              <h5 slot="header" class="in-card-heading__sm">
                <span class="in-card-heading-title">Card Heading</span>
              </h5>
              <vs-card type="transitional-collapsible-card">
                <div>
                  <label>Collapsible card main - for use in old UI layouts during the style transition. Will be removed after the redesign is complete</label>
                  <div>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </div>
                </div>
              </vs-card>
            </vs-collapse-item>
          </vs-collapse>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-collapse transitional-collapse&gt;
      &lt;vs-collapse-item&gt;
        &lt;h5 slot="header" class="in-card-heading__sm"&gt;
          &lt;span class="in-card-heading-title"&gt;Card Heading&lt;/span&gt;
        &lt;/h5&gt;
        &lt;vs-card type="transitional-collapsible-card"&gt;
          &lt;div&gt;
            &lt;label&gt;Collapsible card main - for use in old UI layouts during the style transition. Will be removed after the redesign is complete&lt;/label&gt;
            &lt;div&gt;
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/vs-card&gt;
      &lt;/vs-collapse-item&gt;
    &lt;/vs-collapse&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- collapsible card ends -->

    <!-- Table card [tabs] - Used when there is a tab component on top of the table card -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="table-card-tabs">Table card [tabs]</h6>
        </template>
        <template v-slot:content>
          <vs-card type="tab-table">
            <div>
              <label>Table card [tabs] - Used when there is a tab component on top of the table card</label>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </div>
            </div>
          </vs-card>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-card type="tab-table"&gt;
      &lt;div&gt;
        &lt;label&gt;Table card [tabs] - Used when there is a tab component on top of the table card&lt;/label&gt;
        &lt;div&gt;
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/vs-card&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- Table card [standard] - Used when the page only contain a table card and no other components -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="table-card-standard">Table card [standard]</h6>
        </template>
        <template v-slot:content>
          <vs-card type="table">
            <div>
              <label>Table card [standard] - Used when the page only contain a table card and no other components </label>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </div>
            </div>
          </vs-card>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-card type="table"&gt;
      &lt;div&gt;
        &lt;label&gt;Table card [standard] - Used when the page only contain a table card and no other components &lt;/label&gt;
        &lt;div&gt;
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/vs-card&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- Page card [standard] - Used when the page only contain a card and no other components -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="page-card-standard">Page card [standard]</h6>
        </template>
        <template v-slot:content>
          <vs-card type="page">
            <div>
              <label>Page card [standard] - Used when the page only contain a card and no other components</label>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </div>
            </div>
          </vs-card>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-card type="page"&gt;
      &lt;div&gt;
        &lt;label&gt;Page card [standard] - Used when the page only contain a card and no other components&lt;/label&gt;
        &lt;div&gt;
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/vs-card&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- Page card [toolbar] - Used when there is a button toolbar on top of the card -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="page-card-toolbar">Page card [toolbar]</h6>
        </template>
        <template v-slot:content>
          <vs-card type="toolbar-page">
            <div>
              <label>Page card [toolbar] - Used when there is a button toolbar on top of the card</label>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </div>
            </div>
          </vs-card>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-card type="toolbar-page"&gt;
      &lt;div&gt;
        &lt;label&gt;Page card [toolbar] - Used when there is a button toolbar on top of the card&lt;/label&gt;
        &lt;div&gt;
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/vs-card&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- Page card right[toolbar] -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="page-card-right-toolbar">Page card right [toolbar]</h6>
        </template>
        <template v-slot:content>
          <vs-card type="toolbar-page-right">
            <div>
              <label>Page card right [toolbar] - for use with button toolbar and sidebar components</label>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </div>
            </div>
          </vs-card>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-card type="toolbar-page-right"&gt;
      &lt;div&gt;
        &lt;label&gt;Page card right [toolbar] - for use with button toolbar and sidebar components&lt;/label&gt;
        &lt;div&gt;
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/vs-card&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- Page card [left] -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="page-card-left">Page card [left]</h6>
        </template>
        <template v-slot:content>
          <vs-card type="left-page">
            <div>
              <label>Page card [left]</label>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </div>
            </div>
          </vs-card>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-card type="left-page"&gt;
      &lt;div&gt;
        &lt;label&gt;Page card [left]&lt;/label&gt;
        &lt;div&gt;
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/vs-card&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- Page card [sidebar] - Used when there is a sidebar component to the left of the card -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="page-card-sidebar">Page card [sidebar]</h6>
        </template>
        <template v-slot:content>
          <vs-card type="sidebar-page">
            <div>
              <label>Page card [sidebar] - Used when there is a sidebar component to the left of the card</label>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </div>
            </div>
          </vs-card>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-card type="sidebar-page"&gt;
      &lt;div&gt;
        &lt;label&gt;Page card [sidebar] - Used when there is a sidebar component to the left of the card&lt;/label&gt;
        &lt;div&gt;
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/vs-card&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <h3 class="mb-5" id="normal-tabs">Tab bar</h3>
    <!-- Normal Tabs -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="variation-one">Variation One</h6>
        </template>
        <template v-slot:content>
          <div class="toolbar-btn-group flex justify-end mb-4">
            <vs-button class="toolbar-btn" type="border" icon="home">Label</vs-button>
            <vs-button class="toolbar-btn" type="border" icon="home">Label</vs-button>
            <vs-button class="toolbar-btn" type="border" icon="home">Label</vs-button>
            <vs-button class="toolbar-btn" type="border" icon="home">Label</vs-button>
            <vs-button class="toolbar-btn" icon="home">Label</vs-button>
            <vs-button class="toolbar-btn" icon="home">Label</vs-button>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="toolbar-btn-group flex justify-end mb-4"&gt;
      &lt;vs-button class="toolbar-btn" type="border" icon="home"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" type="border" icon="home"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" type="border" icon="home"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" type="border" icon="home"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" icon="home"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" icon="home"&gt;Label&lt;/vs-button&gt;
    &lt;/div&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-8">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="variation-two">Variation Two</h6>
        </template>
        <template v-slot:content>
          <div class="toolbar-btn-group bottom-right-rounded-toolbar flex justify-end">
            <vs-button class="toolbar-btn" type="border" icon="home">Label</vs-button>
            <vs-button class="toolbar-btn" type="border" icon="home">Label</vs-button>
            <vs-button class="toolbar-btn" type="border">Text</vs-button>
            <vs-button class="toolbar-btn" type="border">Text</vs-button>
            <vs-button class="toolbar-btn" icon="save">Text</vs-button>
            <vs-button class="toolbar-btn" icon="save">Text</vs-button>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="toolbar-btn-group bottom-right-rounded-toolbar flex justify-end"&gt;
      &lt;vs-button class="toolbar-btn" type="border" icon="home"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" type="border" icon="home"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" type="border"&gt;Text&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" type="border"&gt;Text&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" icon="save"&gt;Text&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" icon="save"&gt;Text&lt;/vs-button&gt;
    &lt;/div&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>

    <!-- Scrollable Tab bar -->
    <vs-card type="toolbar-sidebar-page" class="mb-6">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="scrollable-tabs">Scrollable Tab bar</h6>
        </template>
        <template v-slot:content>
          <div class="scrollable-tabs">
            <vue-horizontal class="scrollable-tabs-container" :displacement="0.5" :button-between="false">
              <template v-slot:btn-prev>
                <vs-button type="flat" icon="chevron_left"></vs-button>
              </template>

              <template v-slot:btn-next>
                <vs-button type="flat" icon="chevron_right"></vs-button>
              </template>

              <div class="tab">
                <span class="tab-label-container">
                  <span class="tab-label">Tab Label</span>
                </span>
              </div>

              <div class="tab tab--selected">
                <span class="tab-label-container">
                  <span class="tab-label">Tab Label</span>
                </span>
              </div>

              <div class="tab">
                <span class="tab-label-container">
                  <span class="tab-label">Tab Label</span>
                  <input class="tab-label-input" id='test-width' value="Tab Label" v-on:keyup="auto_expand('text')"/>
                </span>
                <vs-dropdown vs-trigger-click class="tab-actions">
                  <vs-button class="tab-action-btn" type="flat" icon="more_vert"></vs-button>
                  <vs-dropdown-menu>
                    <vs-dropdown-item>
                      <i class="material-icons tab-action-option-icon"> border_color </i>
                      <span class="tab-action-option-label">option 1</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <i class="material-icons tab-action-option-icon"> border_color </i>
                      <span class="tab-action-option-label">option 1</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <i class="material-icons tab-action-option-icon"> border_color </i>
                      <span class="tab-action-option-label">option 1</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <i class="material-icons tab-action-option-icon"> border_color </i>
                      <span class="tab-action-option-label">option 1</span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>

              <div class="tab tab--selected">
                <span class="tab-label-container isEditMode" id="tab-label-container-4">
                  <span class="tab-label">{{text}}</span>
                  <input class="tab-label-input" id='test-width' value="text" v-on:keyup="auto_expand('text')" v-model="text"/>
                </span>
                <vs-dropdown vs-trigger-click class="tab-actions">
                  <vs-button class="tab-action-btn" type="flat" icon="more_vert"></vs-button>
                  <vs-dropdown-menu>
                    <vs-dropdown-item>
                      <i class="material-icons tab-action-option-icon"> mood </i>
                      <span class="tab-action-option-label">option 1</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item>option 2</vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>

              <div class="tab tab--selected">
                <span class="tab-label-container" id="tab-label-container-4">
                  <span class="tab-label">Tab Label</span>
                  <input class="tab-label-input" id='test-width' value="text" v-on:keyup="auto_expand('text')" v-model="text"/>
                </span>
                <vs-dropdown vs-trigger-click class="tab-actions">
                  <vs-button class="tab-action-btn" type="flat" icon="more_vert"></vs-button>
                  <vs-dropdown-menu>
                    <vs-dropdown-item>
                      <i class="material-icons tab-action-option-icon"> mood </i>
                      <span class="tab-action-option-label">option 1</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item>option 2</vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>

              <div class="tab">
                <span class="tab-label-container">
                  <span class="tab-label">Tab Label</span>
                </span>
              </div>

              <div class="tab">
                <span class="tab-label-container">
                  <span class="tab-label">Tab Label</span>
                </span>
              </div>

              <div class="tab">
                <span class="tab-label-container">
                  <span class="tab-label">Tab Label</span>
                </span>
              </div>

              <div class="tab">
                <span class="tab-label-container">
                  <span class="tab-label">Tab Label</span>
                </span>
              </div>
            </vue-horizontal>
            <vs-button radius type="flat" icon="add" class="add-tab-btn"></vs-button>
          </div>

        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="scrollable-tabs"&gt;
      &lt;vue-horizontal class="scrollable-tabs-container" :displacement="0.5" :button-between="false"&gt;
        &lt;template v-slot:btn-prev&gt;
          &lt;vs-button type="flat" icon="chevron_left"&gt;&lt;/vs-button&gt;
        &lt;/template&gt;

        &lt;template v-slot:btn-next&gt;
          &lt;vs-button type="flat" icon="chevron_right"&gt;&lt;/vs-button&gt;
        &lt;/template&gt;

        &lt;div class="tab"&gt;
          &lt;span class="tab-label-container"&gt;
            &lt;span class="tab-label"&gt;Tab Label&lt;/span&gt;
          &lt;/span&gt;
        &lt;/div&gt;

        &lt;div class="tab tab--selected"&gt;
          &lt;span class="tab-label-container"&gt;
            &lt;span class="tab-label"&gt;Tab Label&lt;/span&gt;
          &lt;/span&gt;
        &lt;/div&gt;

        &lt;div class="tab"&gt;
          &lt;span class="tab-label-container"&gt;
            &lt;span class="tab-label"&gt;Tab Label&lt;/span&gt;
            &lt;input class="tab-label-input" id='test-width' value="Tab Label" v-on:keyup="auto_expand('text')"/&gt;
          &lt;/span&gt;
          &lt;vs-dropdown vs-trigger-click class="tab-actions"&gt;
            &lt;vs-button class="tab-action-btn" type="flat" icon="more_vert"&gt;&lt;/vs-button&gt;
            &lt;vs-dropdown-menu&gt;
              &lt;vs-dropdown-item&gt;
                &lt;i class="material-icons tab-action-option-icon"&gt; border_color &lt;/i&gt;
                &lt;span class="tab-action-option-label"&gt;option 1&lt;/span&gt;
              &lt;/vs-dropdown-item&gt;
              &lt;vs-dropdown-item&gt;
                &lt;i class="material-icons tab-action-option-icon"&gt; border_color &lt;/i&gt;
                &lt;span class="tab-action-option-label"&gt;option 1&lt;/span&gt;
              &lt;/vs-dropdown-item&gt;
              &lt;vs-dropdown-item&gt;
                &lt;i class="material-icons tab-action-option-icon"&gt; border_color &lt;/i&gt;
                &lt;span class="tab-action-option-label"&gt;option 1&lt;/span&gt;
              &lt;/vs-dropdown-item&gt;
              &lt;vs-dropdown-item&gt;
                &lt;i class="material-icons tab-action-option-icon"&gt; border_color &lt;/i&gt;
                &lt;span class="tab-action-option-label"&gt;option 1&lt;/span&gt;
              &lt;/vs-dropdown-item&gt;
            &lt;/vs-dropdown-menu&gt;
          &lt;/vs-dropdown&gt;
        &lt;/div&gt;

        &lt;div class="tab tab--selected"&gt;
          &lt;span class="tab-label-container isEditMode" id="tab-label-container-4"&gt;
            &lt;span class="tab-label"&gt;{{text}}&lt;/span&gt;
            &lt;input class="tab-label-input" id='test-width' value="text" v-on:keyup="auto_expand('text')" v-model="text"/&gt;
          &lt;/span&gt;
          &lt;vs-dropdown vs-trigger-click class="tab-actions"&gt;
            &lt;vs-button class="tab-action-btn" type="flat" icon="more_vert"&gt;&lt;/vs-button&gt;
            &lt;vs-dropdown-menu&gt;
              &lt;vs-dropdown-item&gt;
                &lt;i class="material-icons tab-action-option-icon"&gt; mood &lt;/i&gt;
                &lt;span class="tab-action-option-label"&gt;option 1&lt;/span&gt;
              &lt;/vs-dropdown-item&gt;
              &lt;vs-dropdown-item&gt;option 2&lt;/vs-dropdown-item&gt;
            &lt;/vs-dropdown-menu&gt;
          &lt;/vs-dropdown&gt;
        &lt;/div&gt;

        &lt;div class="tab tab--selected"&gt;
          &lt;span class="tab-label-container" id="tab-label-container-4"&gt;
            &lt;span class="tab-label"&gt;Tab Label&lt;/span&gt;
            &lt;input class="tab-label-input" id='test-width' value="text" v-on:keyup="auto_expand('text')" v-model="text"/&gt;
          &lt;/span&gt;
          &lt;vs-dropdown vs-trigger-click class="tab-actions"&gt;
            &lt;vs-button class="tab-action-btn" type="flat" icon="more_vert"&gt;&lt;/vs-button&gt;
            &lt;vs-dropdown-menu&gt;
              &lt;vs-dropdown-item&gt;
                &lt;i class="material-icons tab-action-option-icon"&gt; mood &lt;/i&gt;
                &lt;span class="tab-action-option-label"&gt;option 1&lt;/span&gt;
              &lt;/vs-dropdown-item&gt;
              &lt;vs-dropdown-item&gt;option 2&lt;/vs-dropdown-item&gt;
            &lt;/vs-dropdown-menu&gt;
          &lt;/vs-dropdown&gt;
        &lt;/div&gt;

        &lt;div class="tab"&gt;
          &lt;span class="tab-label-container"&gt;
            &lt;span class="tab-label"&gt;Tab Label&lt;/span&gt;
          &lt;/span&gt;
        &lt;/div&gt;

        &lt;div class="tab"&gt;
          &lt;span class="tab-label-container"&gt;
            &lt;span class="tab-label"&gt;Tab Label&lt;/span&gt;
          &lt;/span&gt;
        &lt;/div&gt;

        &lt;div class="tab"&gt;
          &lt;span class="tab-label-container"&gt;
            &lt;span class="tab-label"&gt;Tab Label&lt;/span&gt;
          &lt;/span&gt;
        &lt;/div&gt;

        &lt;div class="tab"&gt;
          &lt;span class="tab-label-container"&gt;
            &lt;span class="tab-label"&gt;Tab Label&lt;/span&gt;
          &lt;/span&gt;
        &lt;/div&gt;
      &lt;/vue-horizontal&gt;
      &lt;vs-button radius type="flat" icon="add" class="add-tab-btn"&gt;&lt;/vs-button&gt;
    &lt;/div&gt;

    &lt;script&gt;
      import VueHorizontal from 'vue-horizontal';

      export default {
        components: { VueHorizontal },
        data() {
          return {
            text: '!',
          }
        },
        methods: {
          auto_expand() {
            let element = document.getElementById("tab-label-container-4");
            let label = element.children[0];
            let input = element.children[1];
            input.style.width = "5px";
            input.style.width = (label.offsetWidth + 20)+"px";
          },
        },
        mounted() {
          this.auto_expand();
        },
        updated() {
          this.auto_expand();
        }
      }
    &lt;/script&gt;

            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";
  import VueHorizontal from "vue-horizontal";

  export default {
    data() {
      return {
        text: '!',
      }
    },
    components: {
      Prism,
      VueAccordion,
      VueHorizontal,
    },
    methods: {
      auto_expand() {
        let element = document.getElementById("tab-label-container-4");
        let label = element.children[0];
        let input = element.children[1];
        input.style.width = "5px";
        input.style.width = (label.offsetWidth + 20) + "px";
      },
    },
    mounted() {
      this.auto_expand();
    },
    updated() {
      this.auto_expand();
    }
  }
</script>