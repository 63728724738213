<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    :aria-labelledby="iconName"
    role="presentation"
    fill="none"
  >
    <path
      d="M20.8244 11.5346C20.1044 15.2146 17.6444 16.4746 14.5244 16.4746H14.0244C13.6424 16.4756 13.319 16.7565 13.2644 17.1346V17.3546L12.6644 21.2146V21.3746C12.6048 21.758 12.2724 22.0392 11.8844 22.0346H8.58441C8.44597 22.0405 8.31253 21.9823 8.22268 21.8768C8.13282 21.7713 8.09658 21.6303 8.12441 21.4946L8.38441 19.8146L9.82441 10.6846L10.3544 11.4346L8.82441 21.3546H11.8244C11.8244 21.3546 11.8844 21.3546 11.9044 21.3546C11.9244 21.3546 11.9044 21.3546 11.9044 21.2746V21.1346L12.5244 17.2546V17.0546C12.5849 16.7125 12.7615 16.4017 13.0244 16.1746C13.2865 15.9492 13.6188 15.8219 13.9644 15.8146H14.4844C15.1572 15.8168 15.8282 15.743 16.4844 15.5946C17.0704 15.4465 17.6256 15.1959 18.1244 14.8546C18.609 14.4997 19.0172 14.0507 19.3244 13.5346C19.6995 12.8777 19.9568 12.1602 20.0844 11.4146C20.2231 10.8048 20.2569 10.1758 20.1844 9.55458C20.1117 9.08291 19.9111 8.6402 19.6044 8.27458C19.4592 8.103 19.2827 7.96049 19.0844 7.85458C19.0844 7.73458 19.1444 7.61458 19.1644 7.49458L19.2244 7.13458C19.5576 7.30442 19.856 7.53505 20.1044 7.81458C20.9189 8.87108 21.1859 10.2505 20.8244 11.5346Z"
      :fill="color"
    />
    <path
      d="M18.4248 6.77482L18.0448 6.67482L17.6248 6.59482C17.0952 6.51563 16.5602 6.47885 16.0248 6.48482H11.1448C11.0308 6.48169 10.9177 6.50568 10.8148 6.55482C10.5829 6.66338 10.4213 6.8814 10.3848 7.13482L9.38477 13.7048V13.8948C9.45365 13.4624 9.82687 13.1443 10.2648 13.1448H12.0848C15.6648 13.1448 18.4748 11.6848 19.2948 7.48482L19.3548 7.11482C19.1394 7.00351 18.9153 6.90987 18.6848 6.83482L18.4248 6.77482Z"
      :fill="color"
    />
    <path
      d="M19.2853 7.11497C19.0801 7.0094 18.8659 6.92239 18.6453 6.85497C18.7486 6.32664 18.7486 5.78331 18.6453 5.25497C18.5181 4.79147 18.2696 4.37031 17.9253 4.03497C17.1853 3.17497 15.7653 2.75497 13.7053 2.75497H7.48528C7.43931 2.74354 7.39125 2.74354 7.34528 2.75497C7.30555 2.79046 7.28358 2.84173 7.28528 2.89497L4.70528 19.135H8.48528L8.38528 19.815H4.54528C4.38938 19.8143 4.24169 19.745 4.1415 19.6256C4.04132 19.5061 3.99881 19.3486 4.02528 19.195L6.60528 2.79497C6.67364 2.36072 7.0457 2.03939 7.48528 2.03497H13.7053C15.8453 2.03497 17.4853 2.49497 18.4853 3.53497C19.3157 4.52848 19.6138 5.86249 19.2853 7.11497Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "icon",
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
      default: "#AAAAAA",
    },
  },
};
</script>
