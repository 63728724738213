<template>
  <div>
    <h2 class="mb-5">Transaction cards</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#bnpl'">BNPL</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#bnpo'">BNPO</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#recurring'">Recurring</vs-button></div>
      </div>
    </vs-card>

    <!-- Agreement cards starts -->
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="bnpl">BNPL</h6>
        </template>
        <template v-slot:content>
          <vs-card class="ds-card mb-4 md:mb-6">
            <div slot="header">
              <h4>Your agreement</h4>
            </div>
            <div class="pt-3 sm:pt-6 pb-4">
              <div class="grid grid-cols-2 pb-2">
                <div>
                  <h5>Date</h5>
                </div>
                <div class="text-right">
                <h5>Amount</h5>
                </div>
              </div>
              <hr class="line-hr" />
              <div class="grid grid-cols-2 gap-2 pt-4 pb-6">
                <div>
                  <p>12 Feb 2021</p>
                </div>
                <div class="text-right">
                  <p>$ 200.00</p>
                </div>
                <div>
                  <p>12 Mar 2021</p>
                </div>
                <div class="text-right">
                  <p>$ 200.00</p>
                </div>
                <div>
                  <p>12 Apr 2021</p>
                </div>
                <div class="text-right">
                  <p>$ 200.00</p>
                </div>
                <div>
                  <p>12 May 2021</p>
                </div>
                <div class="text-right">
                  <p>$ 200.00</p>
                </div>
                <div>
                  <p>12 Jun 2021</p>
                </div>
                <div class="text-right">
                  <p>$ 200.00</p>
                </div>
              </div>
              <hr class="line-hr" />
              <div class="grid grid-cols-2 pb-4 sm:pb-6 pt-4 sm:pt-6">
                <div>
                  <h3>Total</h3>
                </div>
                <div class="text-right">
                <h3>$ 1,000.00</h3>
                </div>
              </div>
              <p class="text-ancillary mb-6">Your payments includes a &lt;MSF&gt;% service fee, totalling $50.00 across your plan.</p>
              <div class="grid grid-cols-1 gap-y-2 sm:gap-y-4">
                <p><a href="#" target="_blank">Statement</a></p>
                <p><a href="#" target="_blank">Terms & Conditions</a></p>
                <p><a href="#" target="_blank">Privacy policy</a></p>
              </div>
            </div>
          </vs-card>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-card class="ds-card mb-4 md:mb-6"&gt;
      &lt;div slot="header"&gt;
        &lt;h4&gt;Your agreement&lt;/h4&gt;
      &lt;/div&gt;
      &lt;div class="pt-3 sm:pt-6 pb-4"&gt;
        &lt;div class="grid grid-cols-2 pb-2"&gt;
          &lt;div&gt;
            &lt;h5&gt;Date&lt;/h5&gt;
          &lt;/div&gt;
          &lt;div class="text-right"&gt;
          &lt;h5&gt;Amount&lt;/h5&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;hr class="line-hr" /&gt;
        &lt;div class="grid grid-cols-2 gap-2 pt-4 pb-6"&gt;
          &lt;div&gt;
            &lt;p&gt;12 Feb 2021&lt;/p&gt;
          &lt;/div&gt;
          &lt;div class="text-right"&gt;
            &lt;p&gt;$ 200.00&lt;/p&gt;
          &lt;/div&gt;
          &lt;div&gt;
            &lt;p&gt;12 Mar 2021&lt;/p&gt;
          &lt;/div&gt;
          &lt;div class="text-right"&gt;
            &lt;p&gt;$ 200.00&lt;/p&gt;
          &lt;/div&gt;
          &lt;div&gt;
            &lt;p&gt;12 Apr 2021&lt;/p&gt;
          &lt;/div&gt;
          &lt;div class="text-right"&gt;
            &lt;p&gt;$ 200.00&lt;/p&gt;
          &lt;/div&gt;
          &lt;div&gt;
            &lt;p&gt;12 May 2021&lt;/p&gt;
          &lt;/div&gt;
          &lt;div class="text-right"&gt;
            &lt;p&gt;$ 200.00&lt;/p&gt;
          &lt;/div&gt;
          &lt;div&gt;
            &lt;p&gt;12 Jun 2021&lt;/p&gt;
          &lt;/div&gt;
          &lt;div class="text-right"&gt;
            &lt;p&gt;$ 200.00&lt;/p&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;hr class="line-hr" /&gt;
        &lt;div class="grid grid-cols-2 pb-4 sm:pb-6 pt-4 sm:pt-6"&gt;
          &lt;div&gt;
            &lt;h3&gt;Total&lt;/h3&gt;
          &lt;/div&gt;
          &lt;div class="text-right"&gt;
          &lt;h3&gt;$ 1,000.00&lt;/h3&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;p class="text-ancillary mb-6"&gt;Your payments includes a &lt;MSF&gt;% service fee, totalling $50.00 across your plan.&lt;/p&gt;
        &lt;div class="grid grid-cols-1 gap-y-2 sm:gap-y-4"&gt;
          &lt;p&gt;&lt;a href="#" target="_blank"&gt;Statement&lt;/a&gt;&lt;/p&gt;
          &lt;p&gt;&lt;a href="#" target="_blank"&gt;Terms & Conditions&lt;/a&gt;&lt;/p&gt;
          &lt;p&gt;&lt;a href="#" target="_blank"&gt;Privacy policy&lt;/a&gt;&lt;/p&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/vs-card&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="bnpo">BNPO</h6>
        </template>
        <template v-slot:content>
          <vs-card class="ds-card mb-4 md:mb-6">
            <div slot="header">
              <h4>Your agreement</h4>
            </div>
            <div class="pt-3 sm:pt-6 pb-4">
              <p class="para-def-light-anc mb-2 text-center">Our share of the proceeds will be deducted on 12 Feb 2021</p>
              <div class="grid grid-cols-2 gap-2 pt-4 pb-6">
                <div>
                  <p>Purchase amount</p>
                </div>
                <div class="text-right">
                  <p>$ 800.00</p>
                </div>
                <div>
                  <p>Service fee</p>
                </div>
                <div class="text-right">
                  <p>$ 200.00</p>
                </div>
              </div>
              <hr class="line-hr" />
              <div class="grid grid-cols-2 pb-4 sm:pb-6 pt-4 sm:pt-6">
                <div>
                  <h3>Total</h3>
                </div>
                <div class="text-right">
                <h3>$ 1,000.00</h3>
                </div>
              </div>
              <div class="grid grid-cols-1 gap-y-2 sm:gap-y-4">
                <p><a href="#" target="_blank">Terms & Conditions</a></p>
                <p><a href="#" target="_blank">Privacy policy</a></p>
              </div>
            </div>
          </vs-card>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-card class="ds-card mb-4 md:mb-6"&gt;
      &lt;div slot="header"&gt;
        &lt;h4&gt;Your agreement&lt;/h4&gt;
      &lt;/div&gt;
      &lt;div class="pt-3 sm:pt-6 pb-4"&gt;
        &lt;p class="para-def-light-anc mb-2 text-center"&gt;Our share of the proceeds will be deducted on 12 Feb 2021&lt;/p&gt;
        &lt;div class="grid grid-cols-2 gap-2 pt-4 pb-6"&gt;
          &lt;div&gt;
            &lt;p&gt;Purchase amount&lt;/p&gt;
          &lt;/div&gt;
          &lt;div class="text-right"&gt;
            &lt;p&gt;$ 800.00&lt;/p&gt;
          &lt;/div&gt;
          &lt;div&gt;
            &lt;p&gt;Service fee&lt;/p&gt;
          &lt;/div&gt;
          &lt;div class="text-right"&gt;
            &lt;p&gt;$ 200.00&lt;/p&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;hr class="line-hr" /&gt;
        &lt;div class="grid grid-cols-2 pb-4 sm:pb-6 pt-4 sm:pt-6"&gt;
          &lt;div&gt;
            &lt;h3&gt;Total&lt;/h3&gt;
          &lt;/div&gt;
          &lt;div class="text-right"&gt;
          &lt;h3&gt;$ 1,000.00&lt;/h3&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;div class="grid grid-cols-1 gap-y-2 sm:gap-y-4"&gt;
          &lt;p&gt;&lt;a href="#" target="_blank"&gt;Terms & Conditions&lt;/a&gt;&lt;/p&gt;
          &lt;p&gt;&lt;a href="#" target="_blank"&gt;Privacy policy&lt;/a&gt;&lt;/p&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/vs-card&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="my-6 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="recurring">Recurring</h6>
        </template>
        <template v-slot:content>
          <vs-card class="ds-card mb-4 md:mb-6">
            <div slot="header">
              <h4>Your agreement</h4>
            </div>
            <div class="sm:pt-2 pb-4">
              <div class="grid grid-cols-2 gap-4 pt-3 sm:pt-4 pb-6">
                <div>
                  <p>Recurring amount</p>
                </div>
                <div class="text-right">
                  <p>$ 100.00</p>
                </div>
                <div>
                  <p>Card surcharge</p>
                </div>
                <div class="text-right">
                  <p>$ 1.50</p>
                </div>
              </div>
              <hr class="line-hr" />
              <div class="grid grid-cols-2 pb-4 sm:pb-6 pt-4 sm:pt-6">
                <div>
                  <h3>Total recurring amount</h3>
                </div>
                <div class="text-right">
                <h3>$ 101.50</h3>
                </div>
              </div>
              <div class="grid grid-cols-1 gap-y-2 sm:gap-y-4">
                <p><a href="#" target="_blank">Terms & Conditions</a></p>
                <p><a href="#" target="_blank">Privacy policy</a></p>
              </div>
            </div>
          </vs-card>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;vs-card class="ds-card mb-4 md:mb-6"&gt;
      &lt;div slot="header"&gt;
        &lt;h4&gt;Your agreement&lt;/h4&gt;
      &lt;/div&gt;
      &lt;div class="sm:pt-2 pb-4"&gt;
        &lt;div class="grid grid-cols-2 gap-4 pt-3 sm:pt-4 pb-6"&gt;
          &lt;div&gt;
            &lt;p&gt;Recurring amount&lt;/p&gt;
          &lt;/div&gt;
          &lt;div class="text-right"&gt;
            &lt;p&gt;$ 100.00&lt;/p&gt;
          &lt;/div&gt;
          &lt;div&gt;
            &lt;p&gt;Card surcharge&lt;/p&gt;
          &lt;/div&gt;
          &lt;div class="text-right"&gt;
            &lt;p&gt;$ 1.50&lt;/p&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;hr class="line-hr" /&gt;
        &lt;div class="grid grid-cols-2 pb-4 sm:pb-6 pt-4 sm:pt-6"&gt;
          &lt;div&gt;
            &lt;h3&gt;Total recurring amount&lt;/h3&gt;
          &lt;/div&gt;
          &lt;div class="text-right"&gt;
          &lt;h3&gt;$ 101.50&lt;/h3&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;div class="grid grid-cols-1 gap-y-2 sm:gap-y-4"&gt;
          &lt;p&gt;&lt;a href="#" target="_blank"&gt;Terms & Conditions&lt;/a&gt;&lt;/p&gt;
          &lt;p&gt;&lt;a href="#" target="_blank"&gt;Privacy policy&lt;/a&gt;&lt;/p&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/vs-card&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
    <!-- Agreement cards ends -->
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";

  export default {
    data() {
      return {
      }
    },
    components: {
      Prism,
      VueAccordion,
    },
  }
</script>