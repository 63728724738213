<template>
    <div>
      <h2 class="mb-5">Buttons</h2>
      <vs-card type="toolbar-sidebar-page" class="mb-8">
        <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
          <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#large-buttons'">Large Buttons</vs-button></div>
          <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#medium-buttons'">Medium Buttons</vs-button></div>
          <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#small-buttons'">Small Buttons</vs-button></div>
          <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#toolbar-buttons'">Toolbar Buttons</vs-button></div>
          <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#link-buttons'">Link Buttons</vs-button></div>
          <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#icon-buttons'">Icon Buttons</vs-button></div>
          <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#contact-buttons'">Contact Buttons</vs-button></div>
          <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#segmented-buttons'">Segmented Control Buttons</vs-button></div>
        </div>
      </vs-card>
      <!-- Large Button Section starts -->
      <h3 class="mb-5" id="large-buttons">Large Buttons</h3>
      <vs-card type="toolbar-sidebar-page" class="mb-8">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Primary Base Buttons</h6>
          </template>
          <template v-slot:content>
            <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
              <div><h6 class="mb-4">Default</h6><vs-button color="primary" size="large">Label</vs-button></div>
              <div><h6 class="mb-4">Loading</h6><vs-button color="primary" loading="true" size="large">Base</vs-button></div>
              <div><h6 class="mb-4">Disabled</h6><vs-button color="primary" disabled="true" size="large">Disabled</vs-button></div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
      &lt;vs-button color="primary" size="large"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button color="primary" loading="true" size="large"&gt;Base&lt;/vs-button&gt;
      &lt;vs-button color="primary" disabled="true" size="large"&gt;Disabled&lt;/vs-button&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>

        <hr class="mt-2 mb-8 line-hr" />

        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Secondary Base Buttons</h6>
          </template>
          <template v-slot:content>
            <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
              <div><h6 class="mb-4">Default</h6><vs-button type="border" size="large">Label</vs-button></div>
              <div><h6 class="mb-4">Loading</h6><vs-button type="border" size="large" loading="true">Label</vs-button></div>
              <div><h6 class="mb-4">Disabled</h6><vs-button type="border" size="large" disabled>Label</vs-button></div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
      &lt;vs-button type="border" size="large"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" size="large" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" size="large" disabled&gt;Label&lt;/vs-button&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>

        <hr class="mt-2 mb-8 line-hr" />

        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Primary Left Icon</h6>
          </template>
          <template v-slot:content>
            <!-- LEFT ICON -->
            <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
              <div><h6 class="mb-4">Default</h6><vs-button icon="add" size="large">Label</vs-button></div>
              <div><h6 class="mb-4">Loading</h6><vs-button icon="add" size="large" loading="true">Label</vs-button></div>
              <div><h6 class="mb-4">Disabled</h6><vs-button icon="add" size="large" disabled>Label</vs-button></div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
      &lt;vs-button icon="add" size="large"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button icon="add" size="large" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button icon="add" size="large" disabled&gt;Label&lt;/vs-button&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>

        <hr class="mt-2 mb-8 line-hr" />

        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Secondary Left Icon</h6>
          </template>
          <template v-slot:content>
            <!-- SECONDARY-LEFT-ICON -->
            <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
              <div><h6 class="mb-4">Default</h6><vs-button type="border" icon="add" size="large">Label</vs-button></div>
              <div><h6 class="mb-4">Loading</h6><vs-button type="border" icon="add" size="large" loading="true">Label</vs-button></div>
              <div><h6 class="mb-4">Disabled</h6><vs-button type="border" icon="add" size="large" disabled>Label</vs-button></div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
      &lt;vs-button type="border" icon="add" size="large"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" icon="add" size="large" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" icon="add" size="large" disabled&gt;Label&lt;/vs-button&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>

        <hr class="mt-2 mb-8 line-hr" />

        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Primary Right Icon</h6>
          </template>
          <template v-slot:content>
            <!-- PRIMARY-RIGHT-ICON -->
            <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
              <div><h6 class="mb-4">Default</h6><vs-button icon-right="true" icon="send" size="large" class="icon-small">Label</vs-button></div>
              <div><h6 class="mb-4">Loading</h6><vs-button icon-right="true" icon="send" size="large" loading="true">Label</vs-button></div>
              <div><h6 class="mb-4">Disabled</h6><vs-button icon-right="true" icon="send" size="large" class="icon-small" disabled>Label</vs-button></div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
      &lt;vs-button icon-right="true" icon="send" size="large" class="icon-small"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button icon-right="true" icon="send" size="large" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button icon-right="true" icon="send" size="large" class="icon-small" disabled&gt;Label&lt;/vs-button&gt;
              </Prism>
            </pre>
            </template>
          </vue-accordion>

          <hr class="mt-2 mb-8 line-hr" />

          <vue-accordion class="mb-6">
            <template v-slot:title>
              <h6 class="mt-5 mb-3 font-medium">Secondary Right Icon</h6>
            </template>
            <template v-slot:content>
              <!-- SECONDARY-RIGHT-ICON -->
              <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div><h6 class="mb-4">Default</h6><vs-button type="border" icon-right="true" icon="send" size="large" class="icon-small">Label</vs-button></div>
                <div><h6 class="mb-4">Loading</h6><vs-button type="border" icon-right="true" icon="send" size="large" class="icon-small" loading="true">Label</vs-button></div>
                <div><h6 class="mb-4">Disabled</h6><vs-button type="border" icon-right="true" icon="send" size="large" class="icon-small" disabled>Label</vs-button></div>
              </div>
            </template>
            <template v-slot:code>
              <pre>
                <Prism class="language-js">
      &lt;vs-button type="border" icon-right="true" icon="send" size="large" class="icon-small"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" icon-right="true" icon="send" size="large" class="icon-small" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" icon-right="true" icon="send" size="large" class="icon-small" disabled&gt;Label&lt;/vs-button&gt;
                </Prism>
              </pre>
            </template>
          </vue-accordion>
        </vs-card>
        <!-- Large Button Section ends -->

        <!-- Medium Button Section starts -->
        <h3 class="mb-5" id="medium-buttons">Medium Buttons</h3>
        <vs-card type="toolbar-sidebar-page" class="mb-8">
          <vue-accordion class="mb-6">
            <template v-slot:title>
              <h6 class="mt-5 mb-3 font-medium">Primary Base Button</h6>
            </template>
            <template v-slot:content>
              <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div><h6 class="mb-4">Default</h6><vs-button color="primary">Label</vs-button></div>
                <div><h6 class="mb-4">Loading</h6><vs-button color="primary" loading="true">Base</vs-button></div>
                <div><h6 class="mb-4">Disabled</h6><vs-button color="primary" disabled="true">Disabled</vs-button></div>
              </div>
            </template>
            <template v-slot:code>
              <pre>
                <Prism class="language-js">
      &lt;vs-button color="primary"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button color="primary" loading="true"&gt;Base&lt;/vs-button&gt;
      &lt;vs-button color="primary" disabled="true"&gt;Disabled&lt;/vs-button&gt;
                </Prism>
              </pre>
            </template>
          </vue-accordion>

          <hr class="mt-2 mb-8 line-hr" />

          <vue-accordion class="mb-6">
            <template v-slot:title>
              <h6 class="mt-5 mb-3 font-medium">Secondary Base Button</h6>
            </template>
            <template v-slot:content>
              <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div><h6 class="mb-4">Default</h6><vs-button type="border">Label</vs-button></div>
                <div><h6 class="mb-4">Loading</h6><vs-button type="border" loading="true">Label</vs-button></div>
                <div><h6 class="mb-4">Disabled</h6><vs-button type="border" disabled>Label</vs-button></div>
              </div>
            </template>
            <template v-slot:code>
              <pre>
                <Prism class="language-js">
      &lt;vs-button type="border"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" disabled&gt;Label&lt;/vs-button&gt;
                </Prism>
              </pre>
            </template>
          </vue-accordion>

          <hr class="mt-2 mb-8 line-hr" />

          <vue-accordion class="mb-6">
            <template v-slot:title>
              <h6 class="mt-5 mb-3 font-medium">Medium Primary Left Icon</h6>
            </template>
            <template v-slot:content>
              <!-- LEFT ICON -->
              <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div><h6 class="mb-4">Default</h6><vs-button icon="add">Click</vs-button></div>
                <div><h6 class="mb-4">Loading</h6><vs-button icon="add" loading="true">Label</vs-button></div>
                <div><h6 class="mb-4">Disabled</h6><vs-button icon="add" disabled>Label</vs-button></div>
              </div>
            </template>
            <template v-slot:code>
              <pre>
                <Prism class="language-js">
      &lt;vs-button icon="add"&gt;Click&lt;/vs-button&gt;
      &lt;vs-button icon="add" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button icon="add" disabled&gt;Label&lt;/vs-button&gt;
                </Prism>
              </pre>
            </template>
          </vue-accordion>

          <hr class="mt-2 mb-8 line-hr" />

          <vue-accordion class="mb-6">
            <template v-slot:title>
              <h6 class="mt-5 mb-3 font-medium">Medium Secondary Left Icon</h6>
            </template>
            <template v-slot:content>
              <!-- PRIMARY-LEFT-ICON -->
              <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div><h6 class="mb-4">Default</h6><vs-button type="border" icon="add">Label</vs-button></div>
                <div><h6 class="mb-4">Loading</h6><vs-button type="border" icon="add" loading="true">Label</vs-button></div>
                <div><h6 class="mb-4">Disabled</h6><vs-button type="border" icon="add" disabled>Label</vs-button></div>
              </div>
            </template>
            <template v-slot:code>
              <pre>
                <Prism class="language-js">
      &lt;vs-button type="border" icon="add"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" icon="add" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" icon="add" disabled&gt;Label&lt;/vs-button&gt;
                </Prism>
              </pre>
            </template>
          </vue-accordion>

          <hr class="mt-2 mb-8 line-hr" />

          <vue-accordion class="mb-6">
            <template v-slot:title>
              <h6 class="mt-5 mb-3 font-medium">Medium Primary Right Icon</h6>
            </template>
            <template v-slot:content>
              <!-- PRIMARY-RIGHT-ICON -->
              <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div><h6 class="mb-4">Default</h6><vs-button icon-right="true" icon="send" class="icon-small">Label</vs-button></div>
                <div><h6 class="mb-4">Loading</h6><vs-button icon-right="true" icon="send" loading="true">Label</vs-button></div>
                <div><h6 class="mb-4">Disabled</h6><vs-button icon-right="true" icon="send" class="icon-small" disabled>Label</vs-button></div>
              </div>
            </template>
            <template v-slot:code>
              <pre>
                <Prism class="language-js">
      &lt;vs-button icon-right="true" icon="send" class="icon-small"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button icon-right="true" icon="send" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button icon-right="true" icon="send" class="icon-small" disabled&gt;Label&lt;/vs-button&gt;
                </Prism>
              </pre>
            </template>
          </vue-accordion>

          <hr class="mt-2 mb-8 line-hr" />

          <vue-accordion class="mb-6">
            <template v-slot:title>
              <h6 class="mt-5 mb-3 font-medium">Medium Secondary Right Icon</h6>
            </template>
            <template v-slot:content>
              <!-- SECONDARY-RIGHT-ICON -->
              <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div><h6 class="mb-4">Default</h6><vs-button type="border" icon-right="true" icon="send" class="icon-small" >Label</vs-button></div>
                <div><h6 class="mb-4">Loading</h6><vs-button type="border" icon-right="true" icon="send" loading="true">Label</vs-button></div>
                <div><h6 class="mb-4">Disabled</h6><vs-button type="border" icon-right="true" icon="send" class="icon-small" disabled>Label</vs-button></div>
              </div>
            </template>
            <template v-slot:code>
              <pre>
                <Prism class="language-js">
      &lt;vs-button type="border" icon-right="true" icon="send" class="icon-small" &gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" icon-right="true" icon="send" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" icon-right="true" icon="send" class="icon-small" disabled&gt;Label&lt;/vs-button&gt;
                </Prism>
              </pre>
            </template>
          </vue-accordion>
        </vs-card>
        <!-- Medium Button Section ends -->

        <!-- Small Button Section starts -->
        <h3 class="mb-5" id="small-buttons">Small Buttons</h3>
        <vs-card type="toolbar-sidebar-page" class="mb-8">
          <vue-accordion class="mb-6">
            <template v-slot:title>
              <h6 class="mt-5 mb-3 font-medium">Small Primary Base Button</h6>
            </template>
            <template v-slot:content>
              <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div><h6 class="mb-4">Default</h6><vs-button color="primary" size="small">Label</vs-button></div>
                <div><h6 class="mb-4">Loading</h6><vs-button color="primary" loading="true" size="small">Base</vs-button></div>
                <div><h6 class="mb-4">Disabled</h6><vs-button color="primary" disabled="true" size="small">Disabled</vs-button></div>
              </div>
            </template>
            <template v-slot:code>
              <pre>
                <Prism class="language-js">
      &lt;vs-button color="primary" size="small"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button color="primary" loading="true" size="small"&gt;Base&lt;/vs-button&gt;
      &lt;vs-button color="primary" disabled="true" size="small"&gt;Disabled&lt;/vs-button&gt;
                </Prism>
              </pre>
            </template>
          </vue-accordion>

          <hr class="mt-2 mb-8 line-hr" />

          <vue-accordion class="mb-6">
            <template v-slot:title>
              <h6 class="mt-5 mb-3 font-medium">Small Secondary Base Button</h6>
            </template>
            <template v-slot:content>
              <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div><h6 class="mb-4">Default</h6><vs-button type="border" size="small">Label</vs-button></div>
                <div><h6 class="mb-4">Loading</h6><vs-button type="border" size="small" loading="true">Label</vs-button></div>
                <div><h6 class="mb-4">Disabled</h6><vs-button type="border" size="small" disabled>Label</vs-button></div>
              </div>
            </template>
            <template v-slot:code>
              <pre>
                <Prism class="language-js">
      &lt;vs-button type="border" size="small"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" size="small" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" size="small" disabled&gt;Label&lt;/vs-button&gt;
                </Prism>
              </pre>
            </template>
          </vue-accordion>

          <hr class="mt-2 mb-8 line-hr" />

          <vue-accordion class="mb-6">
            <template v-slot:title>
              <h6 class="mt-5 mb-3 font-medium">Small Primary Left Icon</h6>
            </template>
            <template v-slot:content>
              <!-- LEFT ICON -->
              <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div><h6 class="mb-4">Default</h6><vs-button icon="add" size="small">Click</vs-button></div>
                <div><h6 class="mb-4">Loading</h6><vs-button icon="add" size="small" loading="true">Label</vs-button></div>
                <div><h6 class="mb-4">Disabled</h6><vs-button icon="add" size="small" disabled>Label</vs-button></div>
              </div>
            </template>
            <template v-slot:code>
              <pre>
                <Prism class="language-js">
      &lt;vs-button icon="add" size="small"&gt;Click&lt;/vs-button&gt;
      &lt;vs-button icon="add" size="small" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button icon="add" size="small" disabled&gt;Label&lt;/vs-button&gt;
                </Prism>
              </pre>
            </template>
          </vue-accordion>

          <hr class="mt-2 mb-8 line-hr" />

          <vue-accordion class="mb-6">
            <template v-slot:title>
              <h6 class="mt-5 mb-3 font-medium">Small Secondary Left Icon</h6>
            </template>
            <template v-slot:content>
              <!-- RIGHT ICON -->
              <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div><h6 class="mb-4">Default</h6><vs-button type="border" icon="add" size="small">Label</vs-button></div>
                <div><h6 class="mb-4">Loading</h6><vs-button type="border" icon="add" size="small" loading="true">Label</vs-button></div>
                <div><h6 class="mb-4">Disabled</h6><vs-button type="border" icon="add" size="small" disabled>Label</vs-button></div>
              </div>
            </template>
            <template v-slot:code>
              <pre>
                <Prism class="language-js">
      &lt;vs-button type="border" icon="add" size="small"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" icon="add" size="small" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" icon="add" size="small" disabled&gt;Label&lt;/vs-button&gt;
                </Prism>
              </pre>
            </template>
          </vue-accordion>

          <hr class="mt-2 mb-8 line-hr" />

          <vue-accordion class="mb-6">
            <template v-slot:title>
              <h6 class="mt-5 mb-3 font-medium">Small Primary Right Icon</h6>
            </template>
            <template v-slot:content>
              <!-- RIGHT ICON -->
              <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div><h6 class="mb-4">Default</h6><vs-button icon-right="true" icon="send" size="small" class="icon-small">Label</vs-button></div>
                <div><h6 class="mb-4">Loading</h6><vs-button icon-right="true" icon="send" size="small" class="icon-small" loading="true">Label</vs-button></div>
                <div><h6 class="mb-4">Disabled</h6><vs-button icon-right="true" icon="send" size="small" class="icon-small" disabled>Label</vs-button></div>
              </div>
            </template>
            <template v-slot:code>
              <pre>
                <Prism class="language-js">
      &lt;vs-button icon-right="true" icon="send" size="small" class="icon-small"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button icon-right="true" icon="send" size="small" class="icon-small" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button icon-right="true" icon="send" size="small" class="icon-small" disabled&gt;Label&lt;/vs-button&gt;
                </Prism>
              </pre>
            </template>
          </vue-accordion>

          <hr class="mt-2 mb-8 line-hr" />

          <vue-accordion class="mb-6">
            <template v-slot:title>
              <h6 class="mt-5 mb-3 font-medium">Small Secondary Right Icon</h6>
            </template>
            <template v-slot:content>
              <!-- SECONDARY-RIGHT-ICON -->
              <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                <div><h6 class="mb-4">Default</h6><vs-button type="border" icon-right="true" icon="send" class="icon-small" size="small">Label</vs-button></div>
                <div><h6 class="mb-4">Loading</h6><vs-button type="border" icon-right="true" icon="send" size="small" loading="true">Label</vs-button></div>
                <div><h6 class="mb-4">Disabled</h6><vs-button type="border" icon-right="true" icon="send" class="icon-small" size="small" disabled>Label</vs-button></div>
              </div>
            </template>
            <template v-slot:code>
              <pre>
                <Prism class="language-js">
      &lt;vs-button type="border" icon-right="true" icon="send" class="icon-small" size="small"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" icon-right="true" icon="send" size="small" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button type="border" icon-right="true" icon="send" class="icon-small" size="small" disabled&gt;Label&lt;/vs-button&gt;
                </Prism>
              </pre>
              </template>
            </vue-accordion>
          </vs-card>
          <!-- Small Button Section ends -->

          <!-- Toolbar Button Section starts -->
          <h3 class="mb-5" id="toolbar-buttons">Toolbar Buttons</h3>
          <vs-card type="toolbar-sidebar-page" class="mb-8">
            <vue-accordion class="mb-6">
              <template v-slot:title>
                <h6 class="mt-5 mb-3 font-medium">Primary Base</h6>
              </template>
              <template v-slot:content>
                <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                  <!-- default filled button -->
                  <div><h6 class="mb-4">Default</h6><vs-button class="toolbar-btn">Label</vs-button></div>
                  <div><h6 class="mb-4">Loading</h6><vs-button class="toolbar-btn" loading="true">Label</vs-button></div>
                  <div><h6 class="mb-4">Disabled</h6><vs-button class="toolbar-btn" disabled>Label</vs-button></div>
                </div>
              </template>
              <template v-slot:code>
                <pre>
                  <Prism class="language-js">
        &lt;vs-button class="toolbar-btn"&gt;Label&lt;/vs-button&gt;
        &lt;vs-button class="toolbar-btn" loading="true"&gt;Label&lt;/vs-button&gt;
        &lt;vs-button class="toolbar-btn" disabled&gt;Label&lt;/vs-button&gt;
                  </Prism>
                </pre>
              </template>
            </vue-accordion>

            <hr class="mt-2 mb-8 line-hr"/>

            <vue-accordion class="mb-6">
              <template v-slot:title>
                <h6 class="mt-5 mb-3 font-medium">Secondary Base</h6>
              </template>
              <template v-slot:content>
                <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                  <!-- default filled button -->
                  <div><h6 class="mb-4">Default</h6><vs-button class="toolbar-btn" type="border">Label</vs-button></div>
                  <div><h6 class="mb-4">Loading</h6><vs-button class="toolbar-btn" type="border" loading="true">Label</vs-button></div>
                  <div><h6 class="mb-4">Disabled</h6><vs-button class="toolbar-btn" type="border" disabled>Label</vs-button></div>
                </div>
              </template>
              <template v-slot:code>
                <pre>
                  <Prism class="language-js">
        &lt;vs-button class="toolbar-btn" type="border"&gt;Label&lt;/vs-button&gt;
        &lt;vs-button class="toolbar-btn" type="border" loading="true"&gt;Label&lt;/vs-button&gt;
        &lt;vs-button class="toolbar-btn" type="border" disabled&gt;Label&lt;/vs-button&gt;
                  </Prism>
                </pre>
              </template>
            </vue-accordion>

            <hr class="mt-2 mb-8 line-hr"/>

            <vue-accordion class="mb-6">
              <template v-slot:title>
                <h6 class="mt-5 mb-3 font-medium">Primary Left Icon</h6>
              </template>
              <template v-slot:content>
                <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                  <!-- default filled button -->
                  <div><h6 class="mb-4">Default</h6><vs-button class="toolbar-btn" icon="save">Label</vs-button></div>
                  <div><h6 class="mb-4">Loading</h6><vs-button class="toolbar-btn" icon="save" loading="true">Label</vs-button></div>
                  <div><h6 class="mb-4">Disabled</h6><vs-button class="toolbar-btn" icon="save" disabled>Label</vs-button></div>
                </div>
              </template>
              <template v-slot:code>
                <pre>
                  <Prism class="language-js">
      &lt;vs-button class="toolbar-btn" icon="save"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" icon="save" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" icon="save" disabled&gt;Label&lt;/vs-button&gt;
                  </Prism>
                </pre>
              </template>
            </vue-accordion>

            <hr class="mt-2 mb-8 line-hr"/>

            <vue-accordion class="mb-6">
              <template v-slot:title>
                <h6 class="mt-5 mb-3 font-medium">Secondary Left Icon</h6>
              </template>
              <template v-slot:content>
                <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                  <!-- default filled button -->
                  <div><h6 class="mb-4">Default</h6><vs-button class="toolbar-btn" type="border" icon="save">Label</vs-button></div>
                  <div><h6 class="mb-4">Loading</h6><vs-button class="toolbar-btn" type="border" icon="save" loading="true">Label</vs-button></div>
                  <div><h6 class="mb-4">Disabled</h6><vs-button class="toolbar-btn" type="border" icon="save" disabled>Label</vs-button></div>
                </div>
              </template>
              <template v-slot:code>
                <pre>
                  <Prism class="language-js">
      &lt;vs-button class="toolbar-btn" type="border" icon="save"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" type="border" icon="save" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" type="border" icon="save" disabled&gt;Label&lt;/vs-button&gt;
                  </Prism>
                </pre>
              </template>
            </vue-accordion>

            <hr class="mt-2 mb-8 line-hr"/>

            <vue-accordion class="mb-6">
              <template v-slot:title>
                <h6 class="mt-5 mb-3 font-medium">Primary Right Icon</h6>
              </template>
              <template v-slot:content>
                <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                  <div><h6 class="mb-4">Default</h6><vs-button class="toolbar-btn" icon="home" icon-right="true">Label</vs-button></div>
                  <div><h6 class="mb-4">Loading</h6><vs-button class="toolbar-btn" icon="home" icon-right="true" loading="true">Label</vs-button></div>
                  <div><h6 class="mb-4">Disabled</h6><vs-button class="toolbar-btn" icon="home" icon-right="true" disabled="true">Label</vs-button></div>
                </div>
              </template>
              <template v-slot:code>
                <pre>
                  <Prism class="language-js">
      &lt;vs-button class="toolbar-btn" icon="home" icon-right="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" icon="home" icon-right="true" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" icon="home" icon-right="true" disabled="true"&gt;Label&lt;/vs-button&gt;
              </Prism></pre>
          </template>
        </vue-accordion>

        <hr class="mt-2 mb-8 line-hr"/>

        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Secondary Right Icon</h6>
          </template>
          <template v-slot:content>
            <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
              <div><h6 class="mb-4">Default</h6><vs-button class="toolbar-btn" type="border" icon="home" icon-right="true">Label</vs-button></div>
              <div><h6 class="mb-4">Loading</h6><vs-button class="toolbar-btn" type="border" icon="home" icon-right="true" loading="true">Label</vs-button></div>
              <div><h6 class="mb-4">Disabled</h6><vs-button class="toolbar-btn" type="border" icon="home" icon-right="true" disabled="true">Label</vs-button></div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
      &lt;vs-button class="toolbar-btn" type="border" icon="home" icon-right="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" type="border" icon="home" icon-right="true" loading="true"&gt;Label&lt;/vs-button&gt;
      &lt;vs-button class="toolbar-btn" type="border" icon="home" icon-right="true" disabled="true"&gt;Label&lt;/vs-button&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>
      </vs-card>
      <!-- Toolbar Button Section ends -->

      <!-- Link Button Section starts -->
      <h3 class="mb-5" id="link-buttons">Link Buttons</h3>
      <vs-card type="toolbar-sidebar-page" class="mb-8">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Base Link Button</h6>
          </template>
          <template v-slot:content>
            <!-- Base -->
            <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
              <div><h6 class="mb-4">Default</h6><vs-button type="flat">Flat button</vs-button></div>
              <div><h6 class="mb-4">Disabled</h6><vs-button type="flat" disabled="true">Flat button</vs-button></div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
      &lt;vs-button type="flat"&gt;Flat button&lt;/vs-button&gt;
      &lt;vs-button type="flat" disabled="true"&gt;Flat button&lt;/vs-button&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>

        <hr class="mt-2 mb-8 line-hr" />

        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Left Icon Link Button</h6>
          </template>
          <template v-slot:content>
            <!-- Left Icon Buttons -->
            <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
              <div><h6 class="mb-4">Default</h6><vs-button type="flat" icon="add">Flat button</vs-button></div>
              <div><h6 class="mb-4">Disabled</h6><vs-button type="flat" icon="add" disabled="true">Flat button</vs-button></div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
      &lt;vs-button type="flat" icon="add"&gt;Flat button&lt;/vs-button&gt;
      &lt;vs-button type="flat" icon="add" disabled="true"&gt;Flat button&lt;/vs-button&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>

        <hr class="mt-2 mb-8 line-hr" />

        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Right Icon Link Button</h6>
          </template>
          <template v-slot:content>
            <!-- Right Icon Buttons -->
            <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
              <div><h6 class="mb-4">Default</h6><vs-button type="flat" icon="add" icon-right="true">Flat button</vs-button></div>
              <div><h6 class="mb-4">Disabled</h6><vs-button type="flat" icon="add" icon-right="true" disabled="true">Flat button</vs-button></div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
      &lt;vs-button type="flat" icon="add" icon-right="true"&gt;Flat button&lt;/vs-button&gt;
      &lt;vs-button type="flat" icon="add" icon-right="true" disabled="true"&gt;Flat button&lt;/vs-button&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>
      </vs-card>
      <!-- Link Button Section ends -->

      <!-- Icon Button Section starts -->
      <h3 class="mb-5" id="icon-buttons">Icon Buttons</h3>
      <vs-card type="toolbar-sidebar-page" class="mb-8">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Icon Buttons</h6>
          </template>
          <template v-slot:content>
            <!-- Base -->
            <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-6">
              <div>
                <h6 class="mb-4">Default</h6>
                <div class="flex flex-row gap-2">
                  <vs-button color="" variant="icon-button" icon="add"></vs-button>
                  <vs-button color="" variant="icon-button" icon="remove"></vs-button>
                  <vs-button color="" variant="icon-button" icon="chevron_left"></vs-button>
                  <vs-button color="" variant="icon-button" icon="chevron_right"></vs-button>
                </div>
              </div>
              <div>
                <h6 class="mb-4">Primary</h6>
                <div class="flex flex-row gap-2">
                  <vs-button color="primary" variant="icon-button" icon="add"></vs-button>
                  <vs-button color="primary" variant="icon-button" icon="remove"></vs-button>
                  <vs-button color="primary" variant="icon-button" icon="chevron_left"></vs-button>
                  <vs-button color="primary" variant="icon-button" icon="chevron_right"></vs-button>
                </div>
              </div>
              <div>
                <h6 class="mb-4">Secondary</h6>
                <div class="flex flex-row gap-2">
                  <vs-button color="secondary" variant="icon-button" icon="add"></vs-button>
                  <vs-button color="secondary" variant="icon-button" icon="remove"></vs-button>
                  <vs-button color="secondary" variant="icon-button" icon="chevron_left"></vs-button>
                  <vs-button color="secondary" variant="icon-button" icon="chevron_right"></vs-button>
                </div>
              </div>
              <div>
                <h6 class="mb-4">Success</h6>
                <div class="flex flex-row gap-2">
                  <vs-button color="success" variant="icon-button" icon="add"></vs-button>
                  <vs-button color="success" variant="icon-button" icon="remove"></vs-button>
                  <vs-button color="success" variant="icon-button" icon="chevron_left"></vs-button>
                  <vs-button color="success" variant="icon-button" icon="chevron_right"></vs-button>
                </div>
              </div>
              <div>
                <h6 class="mb-4">Danger</h6>
                <div class="flex flex-row gap-2">
                  <vs-button color="danger" variant="icon-button" icon="add"></vs-button>
                  <vs-button color="danger" variant="icon-button" icon="remove"></vs-button>
                  <vs-button color="danger" variant="icon-button" icon="chevron_left"></vs-button>
                  <vs-button color="danger" variant="icon-button" icon="chevron_right"></vs-button>
                </div>
              </div>
              <div>
                <h6 class="mb-4">Warning</h6>
                <div class="flex flex-row gap-2">
                  <vs-button color="warning" variant="icon-button" icon="add"></vs-button>
                  <vs-button color="warning" variant="icon-button" icon="remove"></vs-button>
                  <vs-button color="warning" variant="icon-button" icon="chevron_left"></vs-button>
                  <vs-button color="warning" variant="icon-button" icon="chevron_right"></vs-button>
                </div>
              </div>
              <div>
                <h6 class="mb-4">Disabled</h6>
                <div class="flex flex-row gap-2">
                  <vs-button color="primary" variant="icon-button" icon="add" disabled="true"></vs-button>
                  <vs-button color="primary" variant="icon-button" icon="remove" disabled="true"></vs-button>
                  <vs-button color="primary" variant="icon-button" icon="chevron_left" disabled="true"></vs-button>
                  <vs-button color="primary" variant="icon-button" icon="chevron_right" disabled="true"></vs-button>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
      &lt;vs-button color="" variant="icon-button" icon="add"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="" variant="icon-button" icon="remove"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="" variant="icon-button" icon="chevron_left"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="" variant="icon-button" icon="chevron_right"&gt;&lt;/vs-button&gt;
      ---------------------------------------------------------------------------------------------------------------------------
      &lt;vs-button color="primary" variant="icon-button" icon="add"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="primary" variant="icon-button" icon="remove"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="primary" variant="icon-button" icon="chevron_left"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="primary" variant="icon-button" icon="chevron_right"&gt;&lt;/vs-button&gt;
      ---------------------------------------------------------------------------------------------------------------------------
      &lt;vs-button color="secondary" variant="icon-button" icon="add"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="secondary" variant="icon-button" icon="remove"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="secondary" variant="icon-button" icon="chevron_left"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="secondary" variant="icon-button" icon="chevron_right"&gt;&lt;/vs-button&gt;
      ---------------------------------------------------------------------------------------------------------------------------
      &lt;vs-button color="success" variant="icon-button" icon="add"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="success" variant="icon-button" icon="remove"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="success" variant="icon-button" icon="chevron_left"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="success" variant="icon-button" icon="chevron_right"&gt;&lt;/vs-button&gt;
      ---------------------------------------------------------------------------------------------------------------------------
      &lt;vs-button color="danger" variant="icon-button" icon="add"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="danger" variant="icon-button" icon="remove"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="danger" variant="icon-button" icon="chevron_left"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="danger" variant="icon-button" icon="chevron_right"&gt;&lt;/vs-button&gt;
      ---------------------------------------------------------------------------------------------------------------------------
      &lt;vs-button color="warning" variant="icon-button" icon="add"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="warning" variant="icon-button" icon="remove"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="warning" variant="icon-button" icon="chevron_left"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="warning" variant="icon-button" icon="chevron_right"&gt;&lt;/vs-button&gt;
      ---------------------------------------------------------------------------------------------------------------------------
      &lt;vs-button color="primary" variant="icon-button" icon="add" disabled="true"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="primary" variant="icon-button" icon="remove" disabled="true"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="primary" variant="icon-button" icon="chevron_left" disabled="true"&gt;&lt;/vs-button&gt;
      &lt;vs-button color="primary" variant="icon-button" icon="chevron_right" disabled="true"&gt;&lt;/vs-button&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>
      </vs-card>
      <!-- Icon Button Section ends -->

      <!-- contact button starts -->
      <h3 class="mb-5" id="contact-buttons">Contact Buttons</h3>
      <vs-card type="toolbar-sidebar-page" class="mb-8">
        <div class="ds-wrapper">
          <h6 class="font-medium mt-5 mb-3">Contact button</h6>
          <div class="grid sm:grid-cols-2 gap-4">
            <div class="icon-small">
              <vs-button color="primary" variant="interactive-icon-button" class="w-7 h-7 md:w-9 md:h-9">
                <vs-icon icon="call" class="material-icons-outlined"></vs-icon>
                <span class="contact-details hidden ml-4 text-white">1800 000 000</span>
              </vs-button>
            </div>
            <div class="icon-small">
              <vs-button color="primary" variant="interactive-icon-button" class="w-7 h-7 md:w-9 md:h-9 material-icons-outlined">
                <vs-icon icon="mail" class="material-icons-outlined"></vs-icon>
                <span class="contact-details hidden ml-4 text-white">email@emailprovider.com</span>
              </vs-button>
            </div>
          </div>
        </div>
      </vs-card>
      <!-- contact button end -->
      <!-- Segmented Control Buttons start -->
      <h3 class="mb-5" id="segmented-buttons">Segmented control Buttons</h3>
      <vs-card type="toolbar-sidebar-page" class="mb-8">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium">Segmented Buttons</h6>
          </template>
          <template v-slot:content>
            <h6 class="mb-4">Medium sized segmented buttons</h6>
            <SegmentedButton @segmented-buttons="handleSegmentedButtons" :buttons="segmentButton" />

            <hr class="my-6" />

            <h6 class="mb-4">Small sized segmented buttons</h6>
            <SegmentedButton @segmented-buttons="handleSegmentedButtons" :buttons="segmentButton" :size="'small'" />
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
        &lt;SegmentedButton @segmented-buttons="handleSegmentedButtons" :buttons="segmentButton" /&gt;
        &lt;SegmentedButton @segmented-buttons="handleSegmentedButtons" :buttons="segmentButton" :size="'small'" /&gt;

        &lt;script&gt;
          import SegmentedButton from "./components/SegmentedButtons.vue";

          export default {
            components: {
              SegmentedButton,
            },

            data() {
              return {
                segmentBtn: {},
                segmentButton: [
                  {
                    label: "Label 1",
                    active: true,
                    value: '999'
                  },
                  {
                    label: "Label 2",
                    active: false,
                    value: '555'
                  },
                  {
                    label: "Label 3",
                    active: false,
                    value: '888'
                  }
                ]
              }
            },

            methods: {
              handleSegmentedButtons(data) {
                this.segmentBtn = data;
              }
            }
          }
        &lt;/script&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>
      </vs-card>
      <!-- Segmented Control Buttons end -->
    </div>
  </template>

  <script>
    import Prism from "vue-prism-component";
    import SegmentedButton from "./components/SegmentedButtons.vue";
    import VueAccordion from "./VueAccordion";

    export default {
      components: {
        Prism,
        SegmentedButton,
        VueAccordion,
      },

      data() {
        return {
          segmentBtn: {},
          segmentButton: [
            {
              label: "Label 1",
              active: true,
              value: '999'
            },
            {
              label: "Label 2",
              active: false,
              value: '555'
            },
            {
              label: "Label 3",
              active: false,
              value: '888'
            }
          ]
        }
      },

      methods: {
        handleSegmentedButtons(data) {
          this.segmentBtn = data;
        }
      }
    }
  </script>

