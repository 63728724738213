<template>
  <div>
    <h2 class="mb-5">Typography</h2>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#communication-list'">Communication list</vs-button></div>
      </div>
    </vs-card>
    <div class="flex flex-col mb-8">
      <vs-card type="toolbar-sidebar-page" class="mb-6">
        <vue-accordion class="mb-6">
          <template v-slot:title>
            <h6 class="mt-5 mb-3 font-medium" id="communication-list">Communication list</h6>
          </template>
          <template v-slot:content>
            <vs-card class="ds-card mb-4 md:mb-6">
              <div slot="header">
                <h4>Communications</h4>
              </div>
              <div>
                <div class="border-b-list">
                  <div v-if="showCommsOption" class="flex flex-row justify-between py-6 mx-4 sm:mx-8">
                    <div>
                      <h5>15 Sep 2022</h5>
                      <p class="text-ancillary">Secured payment request</p>
                    </div>
                    <div class="flex gap-x-8 items-start">
                      <vs-button type="flat" @click="emailDetail">Email</vs-button>
                      <vs-button type="flat" @click="smsDetail">SMS</vs-button>
                    </div>
                  </div>
                  <div class="pt-6 px-4 sm:px-8 bg-shade-white" v-if="showEmailDetail">
                    <h5>15 Sep 2022</h5>
                    <p class="text-ancillary mb-6">Secured payment request</p>
                    <div class="rounded-lg bg-neutral-100 card-shadow px-4 py-2 sm:px-6 sm:py-4 mb-6 sm:mb-8">
                      <div class="text-right -mb-3 sm:-mb-1">
                        <vs-icon type="flat" class="pointer" icon="close" icon-size="medium" @click="emailDetail" />
                      </div>
                      <h4 class="text-shade-black">Secure payment request</h4>
                      <p class="text-ancillary mb-4">Wed, Sep 15, 8:07 AM</p>
                      <div class="flex flex-row gap-x-4 mb-4">
                        <div>
                          <vs-avatar size="small" />
                        </div>
                        <div>
                          <div class="flex flex-row flex-wrap items-center sm:-mt-0.5 mb-1 gap-x-2">
                            <h5 class="text-sm">FlipPay</h5>
                            <p class="para-def-anc break-all">&lt;noreplay@flippay.com.au&gt;</p>
                          </div>
                          <p class="para-def-anc flex items-start flex-wrap gap-x-2"><span>to <span class="text-shade-black ml-1 align-top">Customer name</span></span> <span class="break-all">&lt;customer@email.com.au&gt;</span></p>
                        </div>
                      </div>
                      <div class="sm:ml-10">
                        <p class="mb-5 text-shade-black">Hi Sab,</p>
                        <p class="mb-5 text-shade-black">Here's your secure payment request from Apple of Apple.</p>
                        <p class="mb-5 text-shade-black">
                          Reference: Test 1<br/>
                          Amount: $ 1,000.00
                        </p>
                        <p class="mb-5"><a href="#" class="text-interaction-primary">View your payment options</a></p>
                        <p class="mb-5 text-shade-black">This link will expire in 4 days.</p>
                        <p class="mb-5 text-shade-black">
                          Questions?<br/>
                          Call: 0400 000 000<br/>
                          Email: email@email.com
                        </p>
                        <p class="mb-2 text-shade-black">M-0534-6293 / PR-4992-0145</p>
                      </div>
                    </div>
                  </div>
                  <div class="pt-6 px-4 sm:px-8 bg-shade-white" v-if="showSMSDetail">
                    <h5>15 Sep 2022</h5>
                    <p class="text-ancillary mb-6">Secured payment request</p>
                    <div class="rounded-lg bg-neutral-100 card-shadow pl-6 pr-4 py-2 sm:px-6 sm:py-4 mb-6 sm:mb-8">
                      <div class="text-right -mb-1">
                        <vs-icon type="flat" class="pointer" icon="close" icon-size="medium" @click="smsDetail" />
                      </div>
                      <div class="flex flex-col text-center gap-y-1">
                        <vs-avatar size="medium" class="mx-auto" />
                        <h4 class="text-shade-black sm:mt-0">FlipPay</h4>
                        <p class="text-ancillary mb-4 text-gray -mt-1">Wed, Sep 15, 8:07 AM</p>
                      </div>
                      <div class="rounded-3xl px-5 py-4 bg-white sm:w-1/2 mx-auto mb-2 sms-text">
                        <div>
                          <p class="mb-5 text-shade-black">Hi &lt;Customer name&gt;,</p>
                          <p class="mb-5 text-shade-black">
                            Your payment plan is approved, key details below:<br />
                            Request ID: PR-1234-1234<br/>
                            Schedule:
                          </p>
                          <p class="mb-5 text-shade-black">
                            Manage your payment plan here:<br />
                            <a href="#" class="text-interaction-primary">htttp://flippay.com.au/6bkjhb348he hkkdsjfnkn</a>
                          </p>
                          <p class="text-shade-black">
                            Thank you<br />
                            &lt;Merchant name&gt;
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between py-6 mx-4 sm:mx-8">
                    <div>
                      <h5>15 Sep 2022</h5>
                      <p class="text-ancillary">Secured payment request</p>
                    </div>
                    <div class="flex gap-x-8 items-start"> 
                      <vs-button type="flat">Email</vs-button>
                      <vs-button type="flat">SMS</vs-button>
                    </div>
                  </div>
                </div>
                <div v-if="showContactDetails" class="bg-container-30 rounded-b-2xl sm-rounded-b-3xl px-4 pt-4 pb-3 sm:pt-6 sm:px-8 sm:pb-6">
                  <p class="text-sm-anc mb-4">Your contact details</p>
                  <p class="mb-3 sm:mb-4 text-primary">Sally Samuels</p>
                  <div class="editable-field mb-2 sm:mb-3">
                    <vs-input placeholder="Required" readonly v-model="value1" />
                    <vs-icon type="flat" class="icon-gray edit-icon material-icons-outlined" icon="edit" icon-size="small" @click.prevent="toggleUpdatePhoneForm" />
                  </div>
                  <div class="editable-field mb-2 sm:mb-3">
                    <vs-input placeholder="Required" readonly v-model="value2" />
                    <vs-icon type="flat" class="icon-gray edit-icon material-icons-outlined" icon="edit" icon-size="small" @click.prevent="toggleUpdateEmailForm" />
                  </div>
                </div>
                <div v-show="showUpdatePhone" class="bg-container-30 rounded-b-2xl sm-rounded-b-3xl px-4 pt-4 pb-3 sm:pt-6 sm:px-8 sm:pb-6">
                  <div class="flex flex-row justify-between mb-10 sm:mb-8">
                    <h4>Update mobile number</h4>
                    <vs-button type="flat" @click.prevent="toggleUpdatePhoneForm">Cancel</vs-button>
                  </div>
                  <div class="md:flex md:justify-center md:items-center md:gap-x-6">
                    <div class="custom-input-field no-label-input md:w-80" v-if="!phoneOtp">
                      <the-mask
                        :mask="['#### ### ###']"
                        v-model="value1"
                        masked
                        class="w-full custom-input"
                        name="phoneNumber"
                        placeholder="0000 000 000"
                        id="phoneNumber1"
                      />
                    </div>
                    <div class="flex flex-row justify-center mt-6 mb-3 md:m-0">
                      <vs-button v-if="!phoneOtp" type="border" size="small" @click.prevent="toggleOtpField">Update</vs-button>
                    </div>
                  </div>
                  <div v-if="phoneOtp">
                    <div class="text-center">
                      <p class="mb-8">Please enter the 6-digit code we just sent to <br /> 0400 000 000.</p>
                      <div class="otp-input-wrap flex justify-center mb-2">
                        <v-otp-input
                          ref="otpInput1"
                          input-classes="otp-input"
                          separator=""
                          :num-inputs="6"
                          :should-auto-focus="true"
                          input-type="number"
                          @on-change="handleOnChange"
                          @on-complete="handleOnComplete"
                        />
                      </div>
                      <p class="text-sm mb-8">Didn’t get a code? <span class="text-interaction-primary cursor-pointer">Resend code</span></p>
                      <vs-button color="primary" size="large" class="w-full max-w-400 mb-3" @click.prevent="phoneOtpVerify">Verify</vs-button>
                    </div>
                  </div>
                </div>
                <div v-show="showUpdateEmail" class="bg-container-30 rounded-b-2xl sm-rounded-b-3xl px-4 pt-4 pb-3 sm:pt-6 sm:px-8 sm:pb-6">
                  <div class="flex flex-row justify-between mb-10 sm:mb-8">
                    <h4>Update email address</h4>
                    <vs-button type="flat" @click.prevent="toggleUpdateEmailForm">Cancel</vs-button>
                  </div>
                  <div class="md:flex md:justify-center md:items-center md:gap-x-6">
                    <vs-input class="no-label-input md:w-80" placeholder="Required" v-model="value2" id="emailAddress1" v-if="!emailOtp" />
                    <div class="flex flex-row justify-center mt-6 mb-3 md:m-0">
                      <vs-button v-if="!emailOtp" type="border" size="small" @click.prevent="toggleEmailOtp">Update</vs-button>
                    </div>
                  </div>
                  <div v-if="emailOtp">
                    <div class="text-center">
                      <p class="mb-8">Please enter the 6-digit code we just sent to <br /> email@emailprovider.com.</p>
                      <div class="otp-input-wrap flex justify-center mb-2">
                        <v-otp-input
                          ref="otpInput1"
                          input-classes="otp-input"
                          separator=""
                          :num-inputs="6"
                          :should-auto-focus="true"
                          input-type="number"
                          @on-change="handleOnChange"
                          @on-complete="handleOnComplete"
                        />
                      </div>
                      <p class="text-sm mb-8">Didn’t get a code? <span class="text-interaction-primary cursor-pointer">Resend code</span></p>
                      <vs-button color="primary" size="large" class="w-full max-w-400 mb-3" @click.prevent="emailOtpVerify">Verify</vs-button>
                    </div>
                  </div>
                </div>
              </div>
            </vs-card>
          </template>
          <template v-slot:code>
            <pre>
              <Prism class="language-js">
    &lt;vs-card class="ds-card mb-4 md:mb-6"&gt;
      &lt;div slot="header"&gt;
        &lt;h4&gt;Communications&lt;/h4&gt;
      &lt;/div&gt;
      &lt;div&gt;
        &lt;div class="border-b-list"&gt;
          &lt;div v-if="showCommsOption" class="flex flex-row justify-between py-6 mx-4 sm:mx-8"&gt;
            &lt;div&gt;
              &lt;h5&gt;15 Sep 2022&lt;/h5&gt;
              &lt;p class="text-ancillary"&gt;Secured payment request&lt;/p&gt;
            &lt;/div&gt;
            &lt;div class="flex gap-x-8 items-start"&gt;
              &lt;vs-button type="flat" @click="emailDetail"&gt;Email&lt;/vs-button&gt;
              &lt;vs-button type="flat" @click="smsDetail"&gt;SMS&lt;/vs-button&gt;
            &lt;/div&gt;
          &lt;/div&gt;
          &lt;div class="pt-6 px-4 sm:px-8 bg-shade-white" v-if="showEmailDetail"&gt;
            &lt;h5&gt;15 Sep 2022&lt;/h5&gt;
            &lt;p class="text-ancillary mb-6"&gt;Secured payment request&lt;/p&gt;
            &lt;div class="rounded-lg bg-neutral-100 card-shadow px-4 py-2 sm:px-6 sm:py-4 mb-6 sm:mb-8"&gt;
              &lt;div class="text-right -mb-3 sm:-mb-1"&gt;
                &lt;vs-icon type="flat" class="pointer" icon="close" icon-size="medium" @click="emailDetail" /&gt;
              &lt;/div&gt;
              &lt;h4 class="text-shade-black"&gt;Secure payment request&lt;/h4&gt;
              &lt;p class="text-ancillary mb-4"&gt;Wed, Sep 15, 8:07 AM&lt;/p&gt;
              &lt;div class="flex flex-row gap-x-4 mb-4"&gt;
                &lt;div&gt;
                  &lt;vs-avatar size="small" /&gt;
                &lt;/div&gt;
                &lt;div&gt;
                  &lt;div class="flex flex-row flex-wrap items-center sm:-mt-0.5 mb-1 gap-x-2"&gt;
                    &lt;h5 class="text-sm"&gt;FlipPay&lt;/h5&gt;
                    &lt;p class="para-def-anc break-all"&gt;&lt;noreplay@flippay.com.au&gt;&lt;/p&gt;
                  &lt;/div&gt;
                  &lt;p class="para-def-anc flex items-start flex-wrap gap-x-2"&gt;&lt;span&gt;to &lt;span class="text-shade-black ml-1 align-top"&gt;Customer name&lt;/span&gt;&lt;/span&gt; &lt;span class="break-all"&gt;&lt;customer@email.com.au&gt;&lt;/span&gt;&lt;/p&gt;
                &lt;/div&gt;
              &lt;/div&gt;
              &lt;div class="sm:ml-10"&gt;
                &lt;p class="mb-5 text-shade-black"&gt;Hi Sab,&lt;/p&gt;
                &lt;p class="mb-5 text-shade-black"&gt;Here's your secure payment request from Apple of Apple.&lt;/p&gt;
                &lt;p class="mb-5 text-shade-black"&gt;
                  Reference: Test 1&lt;br/&gt;
                  Amount: $ 1,000.00
                &lt;/p&gt;
                &lt;p class="mb-5"&gt;&lt;a href="#" class="text-interaction-primary"&gt;View your payment options&lt;/a&gt;&lt;/p&gt;
                &lt;p class="mb-5 text-shade-black"&gt;This link will expire in 4 days.&lt;/p&gt;
                &lt;p class="mb-5 text-shade-black"&gt;
                  Questions?&lt;br/&gt;
                  Call: 0400 000 000&lt;br/&gt;
                  Email: email@email.com
                &lt;/p&gt;
                &lt;p class="mb-2 text-shade-black"&gt;M-0534-6293 / PR-4992-0145&lt;/p&gt;
              &lt;/div&gt;
            &lt;/div&gt;
          &lt;/div&gt;
          &lt;div class="pt-6 px-4 sm:px-8 bg-shade-white" v-if="showSMSDetail"&gt;
            &lt;h5&gt;15 Sep 2022&lt;/h5&gt;
            &lt;p class="text-ancillary mb-6"&gt;Secured payment request&lt;/p&gt;
            &lt;div class="rounded-lg bg-neutral-100 card-shadow pl-6 pr-4 py-2 sm:px-6 sm:py-4 mb-6 sm:mb-8"&gt;
              &lt;div class="text-right -mb-1"&gt;
                &lt;vs-icon type="flat" class="pointer" icon="close" icon-size="medium" @click="smsDetail" /&gt;
              &lt;/div&gt;
              &lt;div class="flex flex-col text-center gap-y-1"&gt;
                &lt;vs-avatar size="medium" class="mx-auto" /&gt;
                &lt;h4 class="text-shade-black sm:mt-0"&gt;FlipPay&lt;/h4&gt;
                &lt;p class="text-ancillary mb-4 text-gray -mt-1"&gt;Wed, Sep 15, 8:07 AM&lt;/p&gt;
              &lt;/div&gt;
              &lt;div class="rounded-3xl px-5 py-4 bg-white sm:w-1/2 mx-auto mb-2 sms-text"&gt;
                &lt;div&gt;
                  &lt;p class="mb-5 text-shade-black"&gt;Hi &lt;Customer name&gt;,&lt;/p&gt;
                  &lt;p class="mb-5 text-shade-black"&gt;
                    Your payment plan is approved, key details below:&lt;br/&gt;
                    Request ID: PR-1234-1234&lt;br/&gt;
                    Schedule:
                  &lt;/p&gt;
                  &lt;p class="mb-5 text-shade-black"&gt;
                    Manage your payment plan here:&lt;br/&gt;
                    &lt;a href="#" class="text-interaction-primary"&gt;htttp://flippay.com.au/6bkjhb348he hkkdsjfnkn&lt;/a&gt;
                  &lt;/p&gt;
                  &lt;p class="text-shade-black"&gt;
                    Thank you&lt;br/&gt;
                    &lt;Merchant name&gt;
                  &lt;/p&gt;
                &lt;/div&gt;
              &lt;/div&gt;
            &lt;/div&gt;
          &lt;/div&gt;
          &lt;div class="flex flex-row justify-between py-6 mx-4 sm:mx-8"&gt;
            &lt;div&gt;
              &lt;h5&gt;15 Sep 2022&lt;/h5&gt;
              &lt;p class="text-ancillary"&gt;Secured payment request&lt;/p&gt;
            &lt;/div&gt;
            &lt;div class="flex gap-x-8 items-start"&gt;
              &lt;vs-button type="flat"&gt;Email&lt;/vs-button&gt;
              &lt;vs-button type="flat"&gt;SMS&lt;/vs-button&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;div v-if="showContactDetails" class="bg-container-30 rounded-b-2xl sm-rounded-b-3xl px-4 pt-4 pb-3 sm:pt-6 sm:px-8 sm:pb-6"&gt;
          &lt;p class="text-sm mb-4"&gt;Your contact details&lt;/p&gt;
          &lt;p class="mb-3 sm:mb-4"&gt;Sally Samuels&lt;/p&gt;
          &lt;div class="editable-field mb-2 sm:mb-3"&gt;
            &lt;vs-input placeholder="Required" readonly v-model="value1" /&gt;
            &lt;vs-icon type="flat" class="icon-gray edit-icon material-icons-outlined" icon="edit" icon-size="small" @click.prevent="toggleUpdatePhoneForm" /&gt;
          &lt;/div&gt;
          &lt;div class="editable-field mb-2 sm:mb-3"&gt;
            &lt;vs-input placeholder="Required" readonly v-model="value2" /&gt;
            &lt;vs-icon type="flat" class="icon-gray edit-icon material-icons-outlined" icon="edit" icon-size="small" @click.prevent="toggleUpdateEmailForm" /&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;div v-show="showUpdatePhone" class="bg-container-30 rounded-b-2xl sm-rounded-b-3xl px-4 pt-4 pb-3 sm:pt-6 sm:px-8 sm:pb-6"&gt;
          &lt;div class="flex flex-row justify-between mb-10 sm:mb-8"&gt;
            &lt;h4&gt;Update mobile number&lt;/h4&gt;
            &lt;vs-button type="flat" @click.prevent="toggleUpdatePhoneForm"&gt;Cancel&lt;/vs-button&gt;
          &lt;/div&gt;
          &lt;div class="md:flex md:justify-center md:items-center md:gap-x-6"&gt;
            &lt;div class="custom-input-field no-label-input md:w-80" v-if="!phoneOtp"&gt;
              &lt;the-mask
                :mask="['#### ### ###']"
                v-model="value1"
                masked
                class="w-full custom-input"
                name="phoneNumber"
                placeholder="0000 000 000"
                id="phoneNumber1"
              /&gt;
            &lt;/div&gt;
            &lt;div class="flex flex-row justify-center mt-6 mb-3 md:m-0"&gt;
              &lt;vs-button v-if="!phoneOtp" type="border" size="small" @click.prevent="toggleOtpField"&gt;Update&lt;/vs-button&gt;
            &lt;/div&gt;
          &lt;/div&gt;
          &lt;div v-if="phoneOtp"&gt;
            &lt;div class="text-center"&gt;
              &lt;p class="mb-8"&gt;Please enter the 6-digit code we just sent to &lt;br /&gt; 0400 000 000.&lt;/p&gt;
              &lt;div class="otp-input-wrap flex justify-center mb-2"&gt;
                &lt;v-otp-input
                  ref="otpInput1"
                  input-classes="otp-input"
                  separator=""
                  :num-inputs="6"
                  :should-auto-focus="true"
                  input-type="number"
                  @on-change="handleOnChange"
                  @on-complete="handleOnComplete"
                /&gt;
              &lt;/div&gt;
              &lt;p class="text-sm mb-8"&gt;Didn’t get a code? &lt;span class="text-interaction-primary cursor-pointer"&gt;Resend code&lt;/span&gt;&lt;/p&gt;
              &lt;vs-button color="primary" size="large" class="w-full max-w-400 mb-3" @click.prevent="phoneOtpVerify"&gt;Verify&lt;/vs-button&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
        &lt;div v-show="showUpdateEmail" class="bg-container-30 rounded-b-2xl sm-rounded-b-3xl px-4 pt-4 pb-3 sm:pt-6 sm:px-8 sm:pb-6"&gt;
          &lt;div class="flex flex-row justify-between mb-10 sm:mb-8"&gt;
            &lt;h4&gt;Update email address&lt;/h4&gt;
            &lt;vs-button type="flat" @click.prevent="toggleUpdateEmailForm"&gt;Cancel&lt;/vs-button&gt;
          &lt;/div&gt;
          &lt;div class="md:flex md:justify-center md:items-center md:gap-x-6"&gt;
            &lt;vs-input class="no-label-input md:w-80" placeholder="Required" v-model="value2" id="emailAddress1" v-if="!emailOtp" /&gt;
            &lt;div class="flex flex-row justify-center mt-6 mb-3 md:m-0"&gt;
              &lt;vs-button v-if="!emailOtp" type="border" size="small" @click.prevent="toggleEmailOtp"&gt;Update&lt;/vs-button&gt;
            &lt;/div&gt;
          &lt;/div&gt;
          &lt;div v-if="emailOtp"&gt;
            &lt;div class="text-center"&gt;
              &lt;p class="mb-8"&gt;Please enter the 6-digit code we just sent to &lt;br /&gt; email@emailprovider.com &lt;/p&gt;
              &lt;div class="otp-input-wrap flex justify-center mb-2"&gt;
                &lt;v-otp-input
                  ref="otpInput1"
                  input-classes="otp-input"
                  separator=""
                  :num-inputs="6"
                  :should-auto-focus="true"
                  input-type="number"
                  @on-change="handleOnChange"
                  @on-complete="handleOnComplete"
                /&gt;
              &lt;/div&gt;
              &lt;p class="text-sm mb-8"&gt;Didn’t get a code? &lt;span class="text-interaction-primary cursor-pointer"&gt;Resend code&lt;/span&gt;&lt;/p&gt;
              &lt;vs-button color="primary" size="large" class="w-full max-w-400 mb-3" @click.prevent="emailOtpVerify"&gt;Verify&lt;/vs-button&gt;
            &lt;/div&gt;
          &lt;/div&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/vs-card&gt;

    &lt;script&gt;
      import { mask, TheMask } from 'vue-the-mask';
      import OtpInput from "@bachdgvn/vue-otp-input";

      export default {
        data() {
          return {
            value1: '0400 000 000',
            value2: 'email@emailprovider.com',
            showContactDetails: true,
            showUpdatePhone: false,
            showUpdateEmail: false,
            phoneOtp: false,
            emailOtp: false,
            showCommsOption: true,
            showEmailDetail: false,
            showSMSDetail: false,
          }
        },
        components: {
          Prism,
          VueAccordion,
          TheMask,
          'v-otp-input': OtpInput,
        },
        directives: {
          mask
        },
        methods: {
          toggleUpdatePhoneForm() {
            this.showContactDetails = !this.showContactDetails;
            this.showUpdatePhone = !this.showUpdatePhone;
            this.phoneOtp = false;

            if (document.getElementById('phoneNumber1')) {
              window.setTimeout(() => document.getElementById('phoneNumber1').focus(), 200);
            }
          },

          toggleUpdateEmailForm() {
            this.showContactDetails = !this.showContactDetails;
            this.showUpdateEmail = !this.showUpdateEmail;
            this.emailOtp = false;

            if (document.getElementById('emailAddress1')) {
              window.setTimeout(() => document.getElementById('emailAddress1').focus(), 200);
            }
          },

          toggleOtpField() {
            this.phoneOtp = !this.phoneOtp;
          },

          phoneOtpVerify() {
            this.showSuccessMessage("Phone number updated");
            this.toggleUpdatePhoneForm();
          },

          toggleEmailOtp() {
            this.emailOtp = !this.emailOtp;
          },

          emailOtpVerify() {
            this.showSuccessMessage("Email address updated");
            this.toggleUpdateEmailForm();
          },

          emailDetail() {
            this.showCommsOption = !this.showCommsOption;
            this.showEmailDetail = !this.showEmailDetail;
          },

          smsDetail() {
            this.showCommsOption = !this.showCommsOption;
            this.showSMSDetail = !this.showSMSDetail;
          },

          showSuccessMessage(title = 'Success', message='Success') {
            this.$toast.success(&lt;div class="container"&gt;&lt;h4&gt;{title}&lt;/h4&gt;&lt;/div&gt;, {
              position: "bottom-left",
              timeout: 5000,
              closeOnClick: false,
              pauseOnFocusLoss: false,
              pauseOnHover: false,
              draggable: false,
              draggablePercent: 0.1,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: {
                iconClass: "material-icons",
                iconChildren: "check_circle_outline",
                iconTag: "span"
              },
              rtl: false,
              message: message,
            });
          },
        }
      }
    &lt;/script&gt;
              </Prism>
            </pre>
          </template>
        </vue-accordion>
      </vs-card>
    </div>
  </div>
</template>

<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";
  import { TheMask } from 'vue-the-mask';
  import OtpInput from "@bachdgvn/vue-otp-input";

  export default {
    data() {
      return {
        value1: '0400 000 000',
        value2: 'email@emailprovider.com',
        showContactDetails: true,
        showUpdatePhone: false,
        showUpdateEmail: false,
        phoneOtp: false,
        emailOtp: false,
        showCommsOption: true,
        showEmailDetail: false,
        showSMSDetail: false,
      }
    },
    components: {
      Prism,
      VueAccordion,
      TheMask,
      'v-otp-input': OtpInput,
    },
    methods: {
      toggleUpdatePhoneForm() {
        this.showContactDetails = !this.showContactDetails;
        this.showUpdatePhone = !this.showUpdatePhone;
        this.phoneOtp = false;

        if (document.getElementById('phoneNumber1')) {
          window.setTimeout(() => document.getElementById('phoneNumber1').focus(), 200);
        }
      },

      toggleUpdateEmailForm() {
        this.showContactDetails = !this.showContactDetails;
        this.showUpdateEmail = !this.showUpdateEmail;
        this.emailOtp = false;

        if (document.getElementById('emailAddress1')) {
          window.setTimeout(() => document.getElementById('emailAddress1').focus(), 200);
        }
      },

      toggleOtpField() {
        this.phoneOtp = !this.phoneOtp;
      },

      phoneOtpVerify() {
        this.showSuccessMessage("Phone number updated");
        this.toggleUpdatePhoneForm();
      },

      toggleEmailOtp() {
        this.emailOtp = !this.emailOtp;
      },

      emailOtpVerify() {
        this.showSuccessMessage("Email address updated");
        this.toggleUpdateEmailForm();
      },

      emailDetail() {
        this.showCommsOption = !this.showCommsOption;
        this.showEmailDetail = !this.showEmailDetail;
      },

      smsDetail() {
        this.showCommsOption = !this.showCommsOption;
        this.showSMSDetail = !this.showSMSDetail;
      },

      showSuccessMessage(title = 'Success', message='Success') {
        this.$toast.success(<div class="container"><h4>{title}</h4></div>, {
          position: "bottom-left",
          timeout: 5000,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: "material-icons",
            iconChildren: "check_circle_outline",
            iconTag: "span"
          },
          rtl: false,
          message: message,
        });
      },
    }
  }
</script>