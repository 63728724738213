<template>
  <div>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#half-background'">Half Background</vs-button></div>
        <div><vs-button type="flat" icon="keyboard_arrow_down" v-scroll-to="'#full-background'">Full Background</vs-button></div>
      </div>
    </vs-card>

    <h3 class="mb-5" id="base-grid">Backgrounds</h3>
    <vs-card type="toolbar-sidebar-page" class="mb-8">
      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="half-background">Half Background</h6>
        </template>
        <template v-slot:content>
          <div class="bg-main bg-half pos-before-after-abs bg-before-after-cover bg-page">
            <div class="w-full h-screen">
              <h6 class="p-6">Page Content</h6>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="bg-main bg-half"&gt;
      Page Content ...
    &lt;/div&gt;
            </Prism>
          </pre>
        </template>
      </vue-accordion>

      <hr class="mt-2 mb-8 line-hr" />

      <vue-accordion class="mb-6">
        <template v-slot:title>
          <h6 class="mt-5 mb-3 font-medium" id="full-background">Full Background</h6>
        </template>
        <template v-slot:content>
          <div class="bg-main bg-full pos-before-after-abs bg-before-after-cover bg-page">
            <div class="w-full h-screen">
              <h6 class="p-6">Page Content</h6>
            </div>
          </div>
        </template>
        <template v-slot:code>
          <pre>
            <Prism class="language-js">
    &lt;div class="bg-main bg-full"&gt;
      Page Content ...
    &lt;/div&gt;  
            </Prism>
          </pre>
        </template>
      </vue-accordion>
    </vs-card>
  </div>
</template>
  
<script>
  import Prism from "vue-prism-component";
  import VueAccordion from "./VueAccordion";
  export default {
    data: () => ({
    }),
    components: {
      VueAccordion,
      Prism,
    },
  }
</script>